import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { TOrderItems } from 'types/order';
import { useParams } from 'react-router';
import { OrderInfo } from './comp/OrderInfo';
import { OrderProductsTable } from './comp/OrderProductsTable';
import CircularProgress from '@mui/material/CircularProgress';
import { Card } from 'react-bootstrap';
import { useOrderStore } from 'providers/RootStoreProvider';

import 'styles/OrdersTable.scss';

export const OrderDetails = observer(() => {
	const { getOrder } = useOrderStore();
	const { id } = useParams();
	const [order, setOrder] = useState<TOrderItems | null>(null);
	const [isLoading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const init = async () => {
			setLoading(true);

			const order = await getOrder(parseInt(id));
			setOrder(order);

			setLoading(false);
		};
		init();
	}, [id]);

	const update = async () => {
		const order = await getOrder(parseInt(id));
		setOrder(order);
	};

	return (
		<>
			<Card className="mb-3">
				<Card.Body className="position-relative">
					{isLoading ? (
						<div className="p-5 d-flex justify-content-center align-items-center">
							<CircularProgress size={60} />
						</div>
					) : (
						<>
							{order && order.data ? (
								<>
									<h5>Детали заказа: #{id}</h5>
									<p className="fs--1 mt-1 mb-1">
										Дата создания:{' '}
										{dayjs(order.data.createdAt).format('DD.MM.YY HH:mm')}
									</p>
									<p className="mb-0">
										<strong>ПИН-код: {order.data.pinCode}</strong>
									</p>
								</>
							) : (
								<>
									<p className="fs--1 mt-1">Ошибка</p>
								</>
							)}
						</>
					)}
				</Card.Body>
			</Card>
			{!isLoading && order && (
				<>
					<OrderInfo
						docs={order.docs}
						data={order.data}
						paymentDocs={order.paymentDocs}
					/>
					<OrderProductsTable
						update={update}
						total={order.total}
						data={order.items}
					/>
				</>
			)}
		</>
	);
});
