import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import Avatar from '@mui/material/Avatar';

export const SupportModal = observer(() => {
	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Служба поддержки
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="ps-4 pb-4">
					<div className="d-flex flex-wrap flex-row align-items-center mb-3">
						<Avatar
							sx={{ width: 56, height: 56 }}
							src={
								'https://storage.yandexcloud.net/gettzap/managers/a_lobanov.jpg'
							}
						/>
						<h4 className="ms-4 mb-0">Лобанов Арам</h4>
					</div>

					<div className="d-flex flex-wrap flex-row">
						<div className="support-contact-item d-flex flex-row w-50">
							<div className="me-3">Телефон</div>
							<div>
								<strong>+7 963 990-98-07</strong>
							</div>
						</div>
						<div className="support-contact-item d-flex flex-row w-50">
							<div className="me-3">Email</div>
							<div>
								<strong>a.lobanov@gettzap.ru</strong>
							</div>
						</div>
						<div className="support-contact-item d-flex flex-row w-50">
							<div className="me-3">Whatsup</div>
							<div>
								<strong>+7 963 990-98-07</strong>
							</div>
						</div>
						<div className="support-contact-item d-flex flex-row w-50">
							<div className="me-3">Telegram</div>
							<div>
								<strong>+7 963 990-98-07</strong>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</>
	);
});
