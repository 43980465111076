import { makeAutoObservable } from 'mobx';
import { TPaymentsShopList } from 'types/paymentsShop';
import { fetchPaymentsShop } from './../../api/paymentsShop/paymentsShop';
import { RootStore } from 'stores/RootStore';

export class PaymentsShopStore {
	isLoading = true;
	list: TPaymentsShopList[] = [];
	total = 0;
	itemsPerPage = 10;
	page = 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	getPaymentsShopList = async () => {
		const res = await fetchPaymentsShop({
			per_page: this.itemsPerPage,
			page: this.page,
		});

		if (res === null) return;

		const {
			meta: { count },
		} = res;

		this.list = res.items;
		this.total = count;
	};

	init = async () => {
		this.isLoading = true;

		await this.getPaymentsShopList();

		this.isLoading = false;
	};
}
