import { defaultHeaders, b2bApiUrl, castError } from 'api/helpers';
import request from 'helpers/request';

export const fetchOffices = async () => {
	return request(
		fetch(b2bApiUrl(`admin/dashboard/locations`), {
			headers: {
				...defaultHeaders({}),
			},
		})
	).then(castError);
};
