import { useCallback, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

type TProps = {
	quantity: number;
	onChange: (value: number) => Promise<boolean>;
	onIncrease: () => Promise<boolean>;
	onDecrease: () => Promise<boolean>;
	btnClassName?: string;
	multiplicityStep: number;
	stock?: number;
};

export const QuantityController = ({
	quantity,
	onChange,
	onIncrease,
	onDecrease,
	btnClassName,
	multiplicityStep = 1,
	stock,
}: TProps) => {
	const [isLoadingDecrease, setIsLoadingDecrease] = useState(false);
	const [isLoadingIncrease, setIsLoadingIncrease] = useState(false);
	const [inputValue, setInputValue] = useState(quantity);

	const validate = async () => {
		if (multiplicityStep) {
			if (inputValue > 0) {
				let adjusted =
					Math.max(Math.ceil(inputValue / multiplicityStep), 1) *
					multiplicityStep;
				if (stock && adjusted > stock) {
					adjusted =
						Math.max(Math.floor(stock / multiplicityStep), 1) *
						multiplicityStep;
					const res = await onChange(adjusted);
					if (!res) {
						setInputValue(quantity);
					} else {
						setInputValue(adjusted);
					}
				} else {
					setInputValue(adjusted);
				}
			} else {
				setInputValue(
					Math.max(Math.ceil(inputValue / multiplicityStep), 1) *
						multiplicityStep
				);
			}
		} else {
			if (inputValue > 0) {
				if (stock && inputValue > stock) {
					setInputValue(stock);
					const res = await onChange(stock);
					if (!res) setInputValue(quantity);
				} else {
					const res = await onChange(inputValue);
					if (!res) setInputValue(quantity);
				}
			} else {
				setInputValue(quantity);
			}
		}
	};

	const handleInput = useCallback((value: number) => {
		setInputValue(value);
	}, []);

	const handleDecrease = async () => {
		setIsLoadingDecrease(true);
		await onDecrease();
		setIsLoadingDecrease(false);
	};

	const handleIncrease = async () => {
		if (stock && inputValue + multiplicityStep > stock) return;

		setIsLoadingIncrease(true);
		await onIncrease();
		setIsLoadingIncrease(false);
	};

	return (
		<div className="d-flex justify-content-center align-items-center">
			<Button
				variant="outline-secondary"
				style={{ width: '55px' }}
				size="sm"
				className={classNames(
					btnClassName,
					'quantity-btn-control',
					'right',
					'border-300',
					'h-100'
				)}
				onClick={handleDecrease}
				disabled={quantity === multiplicityStep}
			>
				{isLoadingDecrease ? (
					<CircularProgress color="inherit" size={10} />
				) : (
					'-'
				)}
			</Button>
			<Form.Control
				className="text-center px-2 input-spin-none h-100 border-radius-0 brx-0"
				type="number"
				min="1"
				// disabled={}
				step={multiplicityStep}
				max={stock}
				value={inputValue}
				onBlur={() => validate()}
				onChange={({ target }) => handleInput(parseInt(target.value))}
			/>
			<Button
				variant="outline-secondary"
				size="sm"
				style={{ width: '55px' }}
				className={classNames(
					btnClassName,
					'quantity-btn-control',
					'left',
					'border-300',
					'h-100'
				)}
				onClick={handleIncrease}
			>
				{isLoadingIncrease ? (
					<CircularProgress color="inherit" size={10} />
				) : (
					'+'
				)}
			</Button>
		</div>
	);
};
