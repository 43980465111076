import { Placeholder } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { TPriceList } from 'types/product';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { formDeliveryTime } from 'utils/deliveryTime';

export const DeliveryTime = observer(
	({
		isLoading,
		stock,
		priceList,
		externalApi,
	}: {
		cost: number;
		isLoading: boolean;
		stock: number;
		priceList: TPriceList;
		externalApi?: boolean;
	}) => {
		const Content = () => {
			const { deliveryTimeType, deliveryTime, maxDeliveryTime } = priceList;

			return (
				<SoftBadge
					pill
					bg={classNames({
						success: deliveryTimeType === 1,
						primary: deliveryTimeType === 2,
						secondary: deliveryTimeType === 3,
					})}
					style={{ width: '125px' }}
					className="d-block p-2 "
				>
					{externalApi
						? 'В наличии'
						: formDeliveryTime(
								deliveryTimeType,
								deliveryTime,
								maxDeliveryTime
						  )}{' '}
					({stock} шт.)
				</SoftBadge>
			);
		};

		return (
			<div className="cell-container d-flex justify-content-start align-items-center">
				{!isLoading ? <Content /> : <Placeholder xs={8} />}
			</div>
		);
	}
);
