import ru from 'date-fns/locale/ru';
import { observer } from 'mobx-react';
import { Form, Button } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useState, useCallback } from 'react';
import dayjs from 'dayjs';

registerLocale('ru', ru);

type TProps = {
	get: (period: { from: string; to: string }) => void;
};

export const BalanceFilter = observer(({ get }: TProps) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const getHistoryOnClick = useCallback(() => {
		get({
			from: dayjs(startDate).format('DD.MM.YYYY'),
			to: dayjs(endDate).format('DD.MM.YYYY'),
		});
	}, [startDate, endDate]);

	return (
		<div className="balace-filter py-3">
			<Form>
				<Form.Group
					className="mb-3 d-flex align-items-center gap-3"
					controlId="formHorizontalEmail"
				>
					<ReactDatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						className="form-control"
						dateFormat="dd-MM-yyyy"
						selectsStart
						startDate={startDate}
						endDate={endDate}
						locale="ru"
					/>
					<div className="d-flex">
						<span>—</span>
					</div>
					<ReactDatePicker
						selected={endDate}
						onChange={(date) => setEndDate(date)}
						className="form-control"
						dateFormat="dd-MM-yyyy"
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						locale="ru"
					/>
					<Button onClick={getHistoryOnClick}>Применить</Button>
				</Form.Group>
			</Form>
		</div>
	);
});
