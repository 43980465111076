import { TUser } from 'types/user.d';
import { axios } from './axios';
import {
	TUpdatePasswordRequestPayload,
	TSignInRequestPayload,
	TSignInResponse,
	TSignUpRequestPayload,
	TSignUpResponse,
} from './types/user';
import request from 'helpers/request';
import {
	merchantApiUrl,
	commonApiUrl,
	customerApiUrl,
	b2bApiUrl,
} from 'api/helpers';

export const fetchSupport = (): Promise<TUser> =>
	request(axios.get(b2bApiUrl('bayer/managers')));

export const fetchUserMe = (): Promise<TUser> =>
	request(axios.get(merchantApiUrl('users/me')));

export const fetchMyProfile = () =>
	request(axios.get(customerApiUrl('profile')));

export const signInFetch = (
	data: TSignUpRequestPayload
): Promise<TSignInResponse> =>
	request(axios.post(commonApiUrl('sessions'), data));

export const signUpFetch = (
	data: TSignInRequestPayload
): Promise<TSignUpResponse> =>
	request(axios.post('/b2b/api/common/register', data));

export const forgotPasswordFetch = (email: string): Promise<unknown> =>
	request(axios.post(commonApiUrl('reset_password'), { email }));

export const updatePasswordFetch = (
	data: TUpdatePasswordRequestPayload
): Promise<unknown> =>
	request(axios.patch(commonApiUrl('reset_password'), data));
