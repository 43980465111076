import { useState } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@mui/material/CircularProgress';

export const FileLink = observer(({ text, onClick, className = 'fs-13' }) => {
	const [isPending, setPending] = useState(false);

	const handleClick = async () => {
		setPending(true);
		await onClick();
		setPending(false);
	};

	return (
		<div onClick={handleClick} className={`link-type-text ${className}`}>
			{isPending && (
				<span
					className="me-3"
					style={{
						position: 'relative',
						top: '2px',
					}}
				>
					<CircularProgress size={14} />
				</span>
			)}
			{text}
		</div>
	);
});
