import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { updatePasswordFetch } from 'api/profile';
import { MaterialIcon } from 'components/common/MaterialIcon';

const INVALID_TOKEN_ERROR =
	'Токен не существует или его срок действия уже истек';

export const PasswordResetForm = ({ hasLabel }) => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const query = qs.parse(search, { ignoreQueryPrefix: true });
	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({
		password: '',
		confirmPassword: '',
	});

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault();
			if (!query.token) {
				return toast.error(INVALID_TOKEN_ERROR, {
					theme: 'colored',
				});
			}
			return updatePasswordFetch({
				password: formData.password,
				token: query.token,
			})
				.then(() => {
					toast.success('Пароль успешно обновлен', {
						theme: 'colored',
					});
					setTimeout(() => navigate('/'), 3000);
				})
				.catch((error) =>
					toast.error(error.message, {
						theme: 'colored',
					})
				);
		},
		[query, formData]
	);

	const handleFieldChange = useCallback(
		(e) => {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		},
		[formData]
	);

	return (
		<Form
			className={classNames('mt-3', { 'text-left': hasLabel })}
			onSubmit={handleSubmit}
		>
			<Form.Group className="mb-3 position-relative">
				{hasLabel && <Form.Label>Новый пароль</Form.Label>}
				<Form.Control
					placeholder={!hasLabel ? 'Новый пароль' : ''}
					value={formData.password}
					name="password"
					onChange={handleFieldChange}
					type={showPassword ? 'text' : 'password'}
				/>
				<MaterialIcon
					onClick={() => setShowPassword(!showPassword)}
					className={`eye-trigger ${showPassword ? 'active' : ''}`}
					name="remove_red_eye"
				/>
			</Form.Group>
			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>Confirm Password</Form.Label>}
				<Form.Control
					placeholder={!hasLabel ? 'Подтвердить пароль' : ''}
					value={formData.confirmPassword}
					name="confirmPassword"
					onChange={handleFieldChange}
					type="password"
				/>
			</Form.Group>
			<Button
				type="submit"
				className="w-100"
				disabled={!formData.password || !formData.confirmPassword}
			>
				Set password
			</Button>
		</Form>
	);
};

PasswordResetForm.propTypes = {
	hasLabel: PropTypes.bool,
};
