import { observer } from 'mobx-react';
import { Nav } from 'react-bootstrap';

export const NavbarLabel = observer(({ label }: { label: string }) => {
	return (
		<>
			<Nav.Item as="li" className="w-100 nav-label-container">
				<div className="mb-2 mt-2 d-flex flex-row justify-content-start align-items-center">
					<span className="navbar-label">{label}</span>
					<hr className="navbar-vertical-divider m-0"></hr>
				</div>
			</Nav.Item>
		</>
	);
});
