import { useRef, ReactNode, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Pagination } from 'components/common/Pagination';
import { NotFound } from 'components/common/NotFound';
import 'styles/Table.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { Collapse } from 'react-collapse';

type TProps = {
	isLoading: boolean;
	data: unknown[];
	columns: unknown[];
	showHeader?: boolean;
	setPage?: (page: number) => void;
	page?: number;
	pagination?: boolean;
	itemsPerPage?: number | null;
	totalItems?: number;
	header?: ReactNode;
	title?: string | ReactNode;
	notFoundText?: ReactNode;
	selection?: boolean;
	onRowSelect?: (ids: number[]) => void;
	selectionColumnWidth?: number;
	headerButton?: ReactNode;
	cardBodyClassName?: string;
	cardClassName?: string;
	fullWidth?: boolean;
	contained?: boolean;
	footer?: ReactNode;
	tableMinHeight?: string;
	footerClassName?: string;
	onRowClassName?: (body: unknown) => string;
	defaltSelected?: boolean;
	showEmpty?: boolean;
	onRowHover?: (data: unknown) => void;
	onRowLeave?: (data: unknown) => void;
	animationOff?: boolean;
	productAnimation?: boolean;
	loadingTableHeight?: string;
	showIconEmpty?: boolean;
	showFooter?: boolean;
	hide?: boolean;
	separateRow?: (data) => ReactNode;
	showWithShowMoreButton?: number;
};

export const Table = observer(
	({
		isLoading,
		showEmpty = true,
		data,
		totalItems,
		itemsPerPage = 999,
		setPage,
		page,
		columns,
		header,
		notFoundText,
		title = '',
		showHeader = true,
		pagination = true,
		selection = false,
		contained = false,
		onRowSelect,
		selectionColumnWidth = 30,
		headerButton,
		cardBodyClassName = 'p-0',
		fullWidth = true,
		cardClassName = 'w-100 mb-3',
		tableMinHeight = '400px',
		footerClassName = '',
		defaltSelected = false,
		footer = null,
		onRowClassName = () => '',
		onRowHover,
		onRowLeave,
		animationOff,
		productAnimation,
		loadingTableHeight = '270px',
		showIconEmpty = true,
		hide,
		showWithShowMoreButton,
		separateRow = null,
		showFooter = true,
	}: TProps) => {
		const totalPages = itemsPerPage
			? Math.ceil(totalItems / itemsPerPage)
			: 999;
		const cardRef = useRef(null);
		const isEmpty = !isLoading && !data.length;

		const maxWidth = columns.reduce(
			(value: number, item: { width: number }) => {
				value += item.width;
				return value;
			},
			selection ? selectionColumnWidth + 16 : 0
		);

		const onChangePage = useCallback(
			(page: number) => {
				cardRef.current.scrollIntoView();
				setPage(page);
			},
			[cardRef]
		);

		return (
			<>
				{contained ? (
					<Card className={cardClassName}>
						<Card.Header className={`${header ? 'p-0' : ''} w-100`}>
							{header ? (
								header
							) : (
								<div className="d-flex justify-content-between w-100">
									<div className="d-flex justify-content-start w-100">
										{typeof title === 'string' ? <h5>{title}</h5> : title}
									</div>
									{headerButton && headerButton}
								</div>
							)}
						</Card.Header>
						<hr className="m-0" />
						<Collapse isOpened={!hide}>
							<Card.Body>
								{isLoading ? (
									<div
										style={
											isLoading && {
												minHeight: loadingTableHeight,
											}
										}
										className="p-5 d-flex justify-content-center align-items-center"
									>
										<CircularProgress size={35} />
									</div>
								) : (
									<>
										{isEmpty ? (
											<>
												{showEmpty && (
													<NotFound
														hideIcon={!showIconEmpty}
														text={notFoundText}
													/>
												)}
											</>
										) : (
											<Card
												style={{
													maxWidth: fullWidth ? '100%' : maxWidth + 'px',
												}}
												ref={cardRef}
												className={`${cardClassName}`}
											>
												{/* @ts-ignore */}
												<AdvanceTableWrapper
													columns={columns}
													data={data}
													sortable
													pagination
													perPage={itemsPerPage}
													selection={selection}
													selectionColumnWidth={selectionColumnWidth}
													defaltSelected={defaltSelected}
												>
													<Card.Body
														className={cardBodyClassName}
														style={{
															minHeight: isLoading ? '400px' : tableMinHeight,
														}}
													>
														<div>
															<AdvanceTable
																// @ts-ignore
																table
																showWithShowMoreButton={showWithShowMoreButton}
																animationOff={animationOff}
																onRowClassName={onRowClassName}
																onRowSelect={onRowSelect}
																onRowHover={onRowHover}
																productAnimation={productAnimation}
																onRowLeave={onRowLeave}
																headerClassName="bg-200 text-900 text-nowrap align-middle"
																rowClassName="position-relative align-middle white-space-nowrap"
																separateRow={separateRow}
																tableProps={{
																	size: 'sm',
																	className: ' pb-4 fs--1 mb-0 ',
																}}
															/>
														</div>
													</Card.Body>
												</AdvanceTableWrapper>
												{showFooter && (
													<>
														{(footer || pagination) && (
															<Card.Footer className={footerClassName}>
																{pagination && totalPages > 1 && (
																	<Pagination
																		totalPages={totalPages}
																		activePage={page}
																		setActivePage={onChangePage}
																	/>
																)}
																{footer && <>{footer}</>}
															</Card.Footer>
														)}
													</>
												)}
											</Card>
										)}
									</>
								)}
							</Card.Body>
						</Collapse>
					</Card>
				) : (
					<Card
						style={{
							maxWidth: fullWidth ? '100%' : maxWidth + 'px',
						}}
						ref={cardRef}
						className={`${cardClassName}`}
					>
						{showHeader && (
							<Card.Header className={`${header ? 'p-0' : ''}`}>
								{header ? (
									header
								) : (
									<div className="d-flex justify-content-between w-100">
										<div className="d-flex justify-content-start">
											{typeof title === 'string' ? <h5>{title}</h5> : title}
											{isEmpty && (
												<span className="ms-2">Ничего не найдено</span>
											)}
										</div>
										{headerButton && headerButton}
									</div>
								)}
							</Card.Header>
						)}
						<Collapse isOpened={!hide}>
							<div>
								<AdvanceTableWrapper
									columns={columns}
									data={data}
									sortable
									pagination
									perPage={itemsPerPage}
									selection={selection}
									selectionColumnWidth={selectionColumnWidth}
									defaltSelected={defaltSelected}
								>
									<Card.Body
										className={cardBodyClassName}
										style={{
											minHeight: isLoading
												? loadingTableHeight
												: tableMinHeight,
										}}
									>
										{isLoading ? (
											<div
												style={
													isLoading && {
														minHeight: loadingTableHeight,
													}
												}
												className="p-5 d-flex justify-content-center h-100 align-items-center"
											>
												<CircularProgress size={35} />
											</div>
										) : (
											<>
												{isEmpty ? (
													<>
														{showEmpty && (
															<NotFound
																hideIcon={!showIconEmpty}
																text={notFoundText}
															/>
														)}
													</>
												) : (
													<div>
														<AdvanceTable
															// @ts-ignore
															table
															showWithShowMoreButton={showWithShowMoreButton}
															onRowClassName={onRowClassName}
															onRowSelect={onRowSelect}
															onRowHover={onRowHover}
															animationOff={animationOff}
															onRowLeave={onRowLeave}
															productAnimation={productAnimation}
															separateRow={separateRow}
															headerClassName="bg-200 text-900 text-nowrap align-middle"
															rowClassName="position-relative align-middle white-space-nowrap"
															tableProps={{
																size: 'sm',
																className: ' pb-4 fs--1 mb-0 ',
															}}
														/>
													</div>
												)}
											</>
										)}
									</Card.Body>
								</AdvanceTableWrapper>
								{showFooter && (
									<>
										{(footer || pagination) && (
											<Card.Footer className={footerClassName}>
												{pagination && totalPages > 1 && (
													<Pagination
														totalPages={totalPages}
														activePage={page}
														setActivePage={onChangePage}
													/>
												)}
												{footer && <>{footer}</>}
											</Card.Footer>
										)}
									</>
								)}
							</div>
						</Collapse>
					</Card>
				)}
			</>
		);
	}
);
