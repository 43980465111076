type EnvType = 'development' | 'staging' | 'production';
export const env = (process.env.NODE_ENV ||
  process.env.REACT_APP_ENV ||
  'development') as EnvType;

type ConfigType = {
  serverUrl: string;
  commonApi: string;
  commonApiV2: string;
  merchantApi: string;
  customerApi: string;
  dadataApi: string;
};

const configuration = {
  development: {
    serverUrl: 'https://gettzap.dnd.lunatic.cat'
  },
  production: {
    serverUrl: ''
  }
};

const getConfig = (env: EnvType): ConfigType => {
  const serverUrl = configuration[env].serverUrl;
  return {
    serverUrl,
    commonApi: '/api/common/v1',
    commonApiV2: '/api/common/v2',
    merchantApi: '/api/merchant/v1',
    customerApi: '/api/buyer',
    dadataApi: '/api/common/v1/dadata/suggestions/api/4_1/rs'
  };
};

export const apiConfig = getConfig(env);
