import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import success_image from 'assets/img/success.svg';
import { useParams } from 'react-router';

export const CheckoutSuccess = () => {
	const { id } = useParams();

	return (
		<Card className="text-center">
			<Card.Body className="p-5">
				<div className="display-1 text-300 fs-error">
					<img src={success_image} alt="" />
				</div>
				<p className="lead display-4  mt-4 text-800 font-sans-serif fw-semi-bold">
					Ваш заказ успешно оформлен
				</p>
				<hr />
				<p className="lead text-primary  mt-4  font-sans-serif fw-semi-bold">
					Номер заказа: #{id}
				</p>
				<p>Вы можете отслеживать заказы в разделе "Мои заказы"</p>
				<Link className="btn btn-success btn mt-3" to="/orders">
					Мои заказы
				</Link>
			</Card.Body>
		</Card>
	);
};
