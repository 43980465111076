export default class ValidationError extends Error {
  constructor(message, errors, name = 'ValidationError') {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
    this.name = name;
    this.message = message;
    this.errors = errors;
  }
}
