import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OrderSummary } from './OrderSummary';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import { DeliveryOrdersList } from './OfficeOrders/DeliveryOrdersList';
import { CustomerContact } from './DeliveryForms/CustomerContact';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { useAuthStore, useCartStore } from 'providers/RootStoreProvider';
import 'styles/Checkout.scss';

export const Checkout = observer(() => {
	const [activePayment, setActivePayment] = useState<Record<string, string>>(
		{}
	);
	const { indexes } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!indexes) navigate('/cart');
	}, [indexes]);

	const {
		checkout,
		deliveryProfiles,
		isLoading,
		customerContact,
		initCustomerContact,
		setCustomerContact,
		reset,
		deliveryProfileValidation,
		initCheckout,
		officeOrders,
		delivery,
		setDeliveryType,
		deliveryOrders,
		setDeliveryComment,
		changePaymentCart,
	} = useCartStore();

	const { user } = useAuthStore();

	useEffect(() => {
		if (user) {
			user.fetchProfileInfo();
			user.getBalance();
			initCustomerContact(user);
		}
	}, [user]);

	useEffect(() => {
		user.isProfileLoaded && initCheckout(indexes.split(','));
	}, [user.isProfileLoaded]);

	const handleCheckout = async () => {
		const orderId = await checkout();
		if (orderId) {
			navigate(`/checkout/success/${orderId.join(',')}`);
			reset();
		} else {
			return false;
		}
	};

	const handleSetActivePayment = (id: string, value: string) => {
		setActivePayment({ ...activePayment, [id]: value });
	};

	const checkAllPaymentSelected = () =>
		Object.keys(deliveryOrders).filter((key) => key in activePayment).length >
		0;

	if (!isLoading && isEmpty(officeOrders)) navigate('/cart');
	if (!indexes) return null;

	return (
		<>
			<Row className="mb-3">
				<Link className="mb-1" to="/cart">
					Вернуться в корзину
				</Link>
				<h4>Оформление заказа</h4>
			</Row>
			<LoadingWrapper isLoading={!user.isProfileLoaded}>
				{user.isProfileLoaded && (
					<>
						<Row className="g-3">
							<Col xl={8}>
								{Object.keys(deliveryOrders).map((key, index) => {
									return (
										<DeliveryOrdersList
											key={key}
											id={key}
											index={index}
											deliveryOrders={deliveryOrders}
											deliveryProfile={deliveryProfiles[key]}
											delivery={delivery[key]}
											setDeliveryType={setDeliveryType}
											setDeliveryComment={setDeliveryComment}
											user={user}
											changePaymentCart={changePaymentCart}
											setActivePayment={handleSetActivePayment}
											activePayment={activePayment[key]}
										/>
									);
								})}
							</Col>
							<Col xl={{ span: 4, order: 1 }}>
								<div className="sticky-container">
									<CustomerContact
										customerContact={customerContact}
										onSave={setCustomerContact}
									/>
									<OrderSummary
										officeOrders={officeOrders}
										checkout={handleCheckout}
										allPaymentChecked={checkAllPaymentSelected()}
										deliveryProfileValidation={deliveryProfileValidation}
									/>
								</div>
							</Col>
						</Row>
					</>
				)}
			</LoadingWrapper>
		</>
	);
});
