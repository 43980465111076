import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Fade } from 'react-reveal';

type TProps = {
	children: ReactNode;
	off?: boolean;
	isProducts?: boolean;
};

export const AnimatedListWrapper = observer(({ children, off }: TProps) => {
	return (
		<>
			{off ? (
				<>{children}</>
			) : (
				<div className="animated-list-wrapper">
					<Fade force bottom cascade duration={150}>
						<div>{children}</div>
					</Fade>
				</div>
			)}
		</>
	);
});
