import { observer } from 'mobx-react';
import { Placeholder } from 'react-bootstrap';

export const DeliveryNote = observer(
	({
		isLoading,
		deliveryMessage,
	}: {
		isLoading: boolean;
		deliveryMessage: string;
	}) => {
		return (
			<div className="cell-container">
				{!isLoading ? (
					<>
						<p className="fs-12 mt-1 mb-0">
							{deliveryMessage && (
								<div className="d-flex justify-content-start align-items-start">
									<div className="pe-3">
										<div
											dangerouslySetInnerHTML={{ __html: deliveryMessage }}
										/>
									</div>
								</div>
							)}
						</p>
					</>
				) : (
					<div style={{ width: '200px' }}>
						<Placeholder xs={3} /> <Placeholder xs={3} /> <Placeholder xs={3} />
					</div>
				)}
			</div>
		);
	}
);
