import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import useApi from 'hooks/useApi';
import { Card } from 'react-bootstrap';
import { VehicleInfo } from './comp/VehicleInfo';
import { ListImageMap } from './comp/ListImageMap';
import { ListDetailByUnitTable } from './comp/ListDetailByUnitTable';
import { TListDetailByUnit } from 'types/catalog';
import IconButton from '@mui/material/IconButton';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useNavigate } from 'react-router-dom';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const CatalogUnit = observer(() => {
	const { getUnitInfo, getListDetailByUnit, getVehicleInfo } =
		useCatalogStore();
	const { brand, code, ssd, unit_id, vehicle_id, category_id } = useParams();
	const navigate = useNavigate();
	const [hovered, setHovered] = useState<TListDetailByUnit[]>([]);

	const {
		data: vehicleInfo,
		loading: vehicleInfoLoading,
		fetch: fetchVehicleInfo,
	} = useApi(getVehicleInfo);

	const {
		data: listDetailByUnit,
		loading: listDetailByUnitLoading,
		fetch: fetchListDetailByUnit,
	} = useApi(getListDetailByUnit);

	const {
		data: unitInfo,
		loading: unitInfoLoading,
		fetch: fetchUnitInfo,
	} = useApi(getUnitInfo);

	useEffect(() => {
		fetchUnitInfo({
			catalog: code,
			ssd,
			unit_id,
		});
		fetchListDetailByUnit({
			catalog: code,
			ssd,
			unit_id,
		});
		fetchVehicleInfo({ catalog: code, ssd, vehicle_id });
	}, []);

	const onHoverId = useCallback(
		(id: string) => {
			const target = listDetailByUnit.filter((i) => i.codeonimage == id);
			setHovered(target);
		},
		[listDetailByUnit]
	);

	const onHover = useCallback(
		(item: TListDetailByUnit) => {
			if (item) {
				const target = listDetailByUnit.filter(
					(i) => i.codeonimage == item.codeonimage
				);
				setHovered(target);
			}
		},
		[listDetailByUnit]
	);

	//! REFACTOR
	const isLoaded = useCallback(
		(isLoading, data) => !isLoading && data && !!data.length,
		[]
	);
	const isLoadedUnit = isLoaded(unitInfoLoading, unitInfo);
	const isLoadedInfo = isLoaded(vehicleInfoLoading, vehicleInfo);
	const isLoadedList = isLoaded(listDetailByUnitLoading, listDetailByUnit);
	const isLoadedUnitList = isLoadedUnit && isLoadedList;

	const unitName = isLoadedUnitList
		? unitInfo[0].name + ' ' + unitInfo[0].code
		: '';
	const imageUrl = isLoadedUnitList ? unitInfo[0].largeimageurl : '';

	return (
		<>
			<div className="d-flex flex-row w-100 justify-content-start align-items-start">
				<div className="catalog-model-list-container me-3 d-flex flex-column justify-content-start align-items-start">
					<Card className="ps-2 p-3 w-100 mb-3">
						<div className="d-flex justify-content-start align-items-center flex-row">
							<IconButton
								color="primary"
								component="label"
								onClick={() =>
									navigate(
										`/catalog-model/quicklist/${brand}/${code}/${ssd}/${vehicle_id}/${category_id}`
									)
								}
								className={`fs-10 me-2 d-flex justify-content-center align-items-center`}
							>
								<MaterialIcon name="arrow_back" className="" />
							</IconButton>
							<VehicleInfo
								vehicleInfo={vehicleInfo}
								isLoading={!isLoadedInfo}
							/>
						</div>
					</Card>
					<LoadingWrapper
						isLoading={!isLoadedUnitList}
						className="w-100 mt-4"
						size={35}
					>
						{isLoadedUnitList && (
							<ListDetailByUnitTable
								hovered={hovered}
								onHover={onHover}
								listDetailByUnit={listDetailByUnit}
							/>
						)}
					</LoadingWrapper>
				</div>
				<Card className="p-3 w-100 h-100 no-transition-container">
					<LoadingWrapper
						isLoading={!(isLoadedInfo && isLoadedUnitList)}
						className="w-100 mt-4 mb-4"
						size={35}
					>
						{isLoadedInfo && isLoadedUnitList && (
							<ListImageMap
								unitName={unitName}
								imageUrl={imageUrl}
								hovered={hovered}
								onHoverId={onHoverId}
							/>
						)}
					</LoadingWrapper>
				</Card>
			</div>
		</>
	);
});
