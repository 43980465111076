import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Card, Table, Button } from 'react-bootstrap';
import { TCartItem } from 'types/product';
import { TDeliveryValidation } from 'types/delivery.d';
import { deliveryErrors } from 'consts/deliveryError';
import { TooltipElement } from 'components/common/TooltipElement';
import { observer } from 'mobx-react';

type TProps = {
	isCart?: boolean;
	officeOrders: Record<number, TCartItem[]>;
	checkout?: () => Promise<boolean>;
	deliveryProfileValidation?: TDeliveryValidation | null;
	allPaymentChecked?: boolean;
};

export const OrderSummary = observer(
	({
		isCart,
		officeOrders,
		checkout,
		deliveryProfileValidation = null,
		allPaymentChecked,
	}: TProps) => {
		const isEmpty = officeOrders ? !Object.keys(officeOrders).length : true;
		const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

		console.log({ allPaymentChecked });

		const handleCheckout = async () => {
			setIsLoadingCheckout(true);
			await checkout();
			setIsLoadingCheckout(false);
		};

		const getSumOfficeOrders = useCallback(
			(orders: TCartItem[], key: string) =>
				orders.reduce((sum, i) => {
					sum += i[key];
					return sum;
				}, 0),
			[]
		);

		const getTotal = useCallback(
			() =>
				Object.keys(officeOrders).reduce((sum, officeId) => {
					const orders = officeOrders[officeId];
					const officeCashback = getSumOfficeOrders(orders, 'total');
					sum += officeCashback;
					return sum;
				}, 0),
			[officeOrders]
		);

		const cashbackTotal = useCallback(
			() =>
				Object.keys(officeOrders).reduce((sum, officeId) => {
					const orders = officeOrders[officeId];
					const officeCashback = getSumOfficeOrders(orders, 'total_cashback');
					sum += officeCashback;
					return sum;
				}, 0),
			[officeOrders]
		);

		return (
			<Card className="mb-3">
				<Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
					<h5 className="mb-0">Ваш заказ</h5>
				</Card.Header>
				<Card.Body className={isEmpty && 'p-0'}>
					{!isEmpty && (
						<Table borderless className="fs--1 mb-0 p-3">
							<tbody>
								{Object.keys(officeOrders).map((key, index) => {
									const orders = officeOrders[key];
									const { office } = orders[0];
									const officeCashback = getSumOfficeOrders(
										orders,
										'total_cashback'
									);
									const officeTotal = getSumOfficeOrders(orders, 'total');

									return (
										<tr key={key} className="border-bottom">
											<th
												className={classNames('ps-0', {
													'pt-0': index === 0,
												})}
											>
												<div className="fw-bold">
													{office.name} ({orders.length})
												</div>
												<span className="fs-12 text-secondary">
													{office.city.name}, {office.address}
												</span>
											</th>
											<th
												style={{ minWidth: '100px' }}
												className={classNames('pe-0 text-end', {
													'pt-0': index === 0,
												})}
											>
												<>
													<strong className=" fw-bold">
														{officeTotal &&
															`${new Intl.NumberFormat('ru-RU').format(
																officeTotal
															)} ₽`}
													</strong>
													{officeCashback > 0 && (
														<div className="fs-12">
															Кешбэк{' '}
															<strong className="color-green">
																{`${new Intl.NumberFormat('ru-RU').format(
																	officeCashback
																)} ₽`}
															</strong>
														</div>
													)}
												</>
											</th>
										</tr>
									);
								})}
							</tbody>
						</Table>
					)}
				</Card.Body>
				<Card.Footer className="bg-light">
					<div className="d-flex w-100 justify-content-between align-items-center">
						<div className="fw-semi-bold">Итог</div>
						<div className="d-flex flex-column align-items-end justify-content-end">
							<div className="fw-bold">
								{new Intl.NumberFormat('ru-RU').format(getTotal())} ₽
							</div>
							{cashbackTotal() > 0 && (
								<div className="fs-12">
									Кешбэк{' '}
									<strong className="color-green">
										{`${new Intl.NumberFormat('ru-RU').format(
											cashbackTotal()
										)} ₽`}
									</strong>
								</div>
							)}
						</div>
					</div>
					<div className="mt-3">
						{!isCart && (
							<>
								<TooltipElement
									focus={true}
									value={<>{deliveryErrors[deliveryProfileValidation]}</>}
									disabled={!deliveryProfileValidation}
								>
									<Button
										onClick={handleCheckout}
										className="w-100"
										variant={'primary'}
										disabled={
											!allPaymentChecked ||
											isLoadingCheckout ||
											!!deliveryProfileValidation
										}
									>
										{isLoadingCheckout ? 'Загрузка...' : 'Оформить заказ'}
									</Button>
								</TooltipElement>
							</>
						)}
					</div>
				</Card.Footer>
			</Card>
		);
	}
);
