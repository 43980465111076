import { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { Popover } from 'react-bootstrap';

type TProps = {
	isActive: boolean;
	coords: { x: number; y: number; z: number };
	list: { name: string; oem: string; brand: string }[];
	size: { height: number; width: number };
	onClick: (oem: string) => void;
};

export const PopoverPointer = observer(
	({ isActive, coords: pointerCoords, onClick, list, size }: TProps) => {
		const popoverRef = useRef(null);
		const [coords, setCoords] = useState({
			x: 0,
			y: 0,
		});

		useEffect(() => {
			if (popoverRef.current && isActive) {
				const popoverWidth = popoverRef.current.offsetWidth;
				setCoords({
					x:
						(pointerCoords.x > popoverWidth
							? Math.abs(popoverWidth - pointerCoords.x)
							: pointerCoords.x - popoverWidth) -
						size.width / 2,
					y: pointerCoords.y,
				});
			}
		}, [pointerCoords, isActive, popoverRef.current]);

		return (
			<Popover
				placement="left-start"
				className="list-image-map-popover"
				ref={popoverRef}
				style={{
					zIndex: isActive ? pointerCoords.z : -10,
					opacity: isActive ? (pointerCoords.z < 0 ? 0 : 1) : 0,
					left: `${coords.x}px`,
					top: `${coords.y}px`,
				}}
			>
				{list.map(({ name, brand, oem }) => {
					return (
						<>
							<Popover.Header className="white-space-nowrap pe-4" as="h4">
								{name}
							</Popover.Header>
							<Popover.Body>
								<div className="tooltip-container-list-item d-flex flex-column justify-content-start aling-items-start">
									<div className="mb-2 d-flex flex-column justify-content-start aling-items-start">
										<div className="fs-12">
											{brand} | {oem}
										</div>
									</div>
									<Button
										size="sm"
										variant={'light'}
										className="border d-flex justify-content-center fs-12"
										onClick={() => onClick(oem)}
									>
										Цена
									</Button>
								</div>
							</Popover.Body>
						</>
					);
				})}
			</Popover>
		);
	}
);
