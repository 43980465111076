import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Layout } from './layouts/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CloseButton } from 'components/common/Toast';
import { useAuthStore, useAppStore } from 'providers/RootStoreProvider';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/Common.scss';

export const App = observer(() => {
	const { init } = useAuthStore();
	const { setTablet, setMobile } = useAppStore();

	useEffect(() => {
		init();
		window.addEventListener(
			'resize',
			() => {
				setTablet(window.innerWidth < 1200);
				setMobile(window.innerWidth < 768);
			},
			false
		);
	}, []);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<ToastContainer
				closeButton={CloseButton}
				icon={false}
				position={toast.POSITION.BOTTOM_LEFT}
			/>
			<Layout />
		</Router>
	);
});
