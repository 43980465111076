import { castError, defaultHeaders, customerApiUrl } from 'api/helpers';
import request from 'helpers/request';

export const patchMyProfile = (token, params) => {
	return request(
		fetch(customerApiUrl('profile'), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
				'X-Authorization': token,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(params),
		}).then(castError)
	);
};
