import { makeAutoObservable } from 'mobx';
import { TCompanies } from 'types/companies';
import { TManager } from 'types/manager';
import { fetchCompanies } from './../../api/companies/companies';
import { fetchManager } from './../../api/manager/manager';
import { RootStore } from 'stores/RootStore';

export class CompaniesStore {
	isLoading = true;
	list: TCompanies[] = [];
	manager: TManager = null;
	total = 0;
	itemsPerPage = 10;
	page = 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	getCompanies = async (searchItems?: { query: string }) => {
		const res = await fetchCompanies({
			per_page: this.itemsPerPage,
			page: this.page,
			query: searchItems?.query,
		});

		if (res === null) return;

		const {
			meta: { count },
		} = res;

		this.list = res.items;
		this.total = count;
	};

	getManager = async () => {
		const res = await fetchManager();

		if (res === null) return;

		this.manager = res;
	};

	init = async () => {
		this.isLoading = true;

		await this.getManager();
		await this.getCompanies();

		this.isLoading = false;
	};
}
