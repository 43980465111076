import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from '../NavbarVerticalMenuItem';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { NavbarVerticalMenu } from './NavbarVerticalMenu';
import { TRoute } from 'types/common';

export const NavbarCollapseItems = observer(({ route }: { route: TRoute }) => {
	const { pathname } = useLocation();

	const openCollapse = useCallback(
		(childrens) => {
			const checkLink = (children) => {
				if (children.to === pathname) {
					return true;
				}
				return (
					Object.prototype.hasOwnProperty.call(children, 'children') &&
					children.children.some(checkLink)
				);
			};
			return childrens.some(checkLink);
		},
		[pathname]
	);

	const [open, setOpen] = useState(openCollapse(route.children));

	return (
		<Nav.Item as="li">
			<Nav.Link
				onClick={() => {
					setOpen(!open);
				}}
				className={classNames('dropdown-indicator cursor-pointer')}
				aria-expanded={open}
			>
				<NavbarVerticalMenuItem route={route} />
			</Nav.Link>
			<Collapse in={open}>
				<Nav className="flex-column nav" as="ul">
					<NavbarVerticalMenu routes={route.children} />
				</Nav>
			</Collapse>
		</Nav.Item>
	);
});
