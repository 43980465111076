import { COMMON_API } from 'consts';
import qs from 'qs';

export const commonApiUrl = (path, query = null) => {
  return query
    ? `${COMMON_API}/${path}?${qs.stringify(query, {
        arrayFormat: 'brackets',
        skipNulls: true
      })}`
    : `${COMMON_API}/${path}`;
};
