import { makeAutoObservable } from 'mobx';
import { searchProducts } from 'api/product/products';
import { RootStore } from 'stores/RootStore';

export class SellerProductStore {
	DEFAULT = {
		total: 0,
		page: 0,
		products: [],
		isLoading: true,
	};

	total = 0;
	page = 0;
	products = [];
	isLoading = true;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	reset = () => {
		Object.keys(this.DEFAULT).map((key) => (this[key] = this.DEFAULT[key]));
	};

	setPage = (value) => {
		this.page = value;
	};

	update = async (payload) => {
		this.isLoading = true;

		const res = await searchProducts({
			...payload,
			page: this.page,
		});

		if (res === null) return;

		const { data, meta } = res;

		this.products = this.root.productStore.splitRegions(data);
		this.total = meta ? meta.count : 0;

		this.isLoading = false;
	};
}
