import { useEffect, useState, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { TFilterSearchItem } from 'types/filter';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { Offcanvas } from 'react-bootstrap';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { TFilters } from 'types/filter';
import { TMetroStation } from 'types/product';
import { FilterButton } from 'components/common/filter/FilterButton';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { SortSelect } from 'components/common/SortSelect';
import { TSortSelect } from 'types/sort';

type TProps = {
	isLoading: boolean;
	id: string;
	filters: TFilters;
	setFilter: (
		keys: string[],
		value: number | string | boolean | string[],
		update?: boolean
	) => void;
	toggleListItemFilter: (id: string, value: TFilterSearchItem) => void;
	getSource: (
		sortBy?: string
	) => Promise<TFilterSearchItem[] | TMetroStation[]>;
	applyFilters: (params: { resetPage: boolean }) => void;
	sort?: TSortSelect[];
	title: string;
};

export const ProductListFilterSearchCheck = observer(
	({
		isLoading,
		filters,
		id,
		getSource,
		toggleListItemFilter,
		setFilter,
		applyFilters,
		sort,
		title,
	}: TProps) => {
		const [show, setShow] = useState(false);
		const [search, setSearch] = useState('');
		const [source, setSource] = useState([]);
		const [initialSource, setIntialSource] = useState([]);
		const [isPending, setIsPending] = useState(true);

		const filterValue = filters[id];
		const isActive = filterValue.length > 0;

		const getNames = useCallback(() => {
			return filterValue.map((i) => i.name).join(', ');
		}, [filterValue]);

		const isIdActive = useCallback(
			(id: number) => {
				return filterValue.find((i) => i.id === id);
			},
			[filterValue]
		);

		const handleSearch = useCallback(
			(value: string) => {
				setSearch(value);
				const sourceFiltered = initialSource.filter(
					(i) => !i.name.toLowerCase().indexOf(value.toLowerCase())
				);
				setSource(value ? sourceFiltered : initialSource);
			},
			[initialSource]
		);

		const init = useCallback(
			async (sort?: TSortSelect) => {
				if (!isLoading && show) {
					setIsPending(true);
					const source = sort ? await getSource(sort.value) : await getSource();
					setSource(source);
					setIntialSource(source);
					setIsPending(false);
				}
			},
			[show, isLoading]
		);

		const onHideWithApply = useCallback(() => {
			applyFilters({ resetPage: true });
			setShow(false);
		}, []);

		useEffect(() => {
			!isLoading && show && init(sort ? sort[0] : undefined);
		}, [isLoading, show]);

		return (
			<>
				<Offcanvas
					className="product-list-filter-offcanvas-container"
					placement={'end'}
					show={show}
					onHide={() => setShow(false)}
				>
					<Offcanvas.Header className="bg-white w-100" closeButton>
						<Offcanvas.Title className="bg-white w-100">
							<span className="mb-2">{title}</span>
							<Form.Group className="w-100 mt-2">
								<Form.Control
									className="w-100"
									placeholder={'Поиск...'}
									value={search}
									name="search"
									onChange={({ target: { value } }) => handleSearch(value)}
									type="text"
								/>
							</Form.Group>
							{sort && (
								<SortSelect className="mt-3" sort={sort} onChange={init} />
							)}
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body className="search-filter-list">
						<div
							className={`scroll-container pt-2 pe-3 ps-3 ${
								sort ? 'with_sort' : ''
							}`}
						>
							<LoadingWrapper isLoading={isPending} size={32}>
								{source.length > 0 ? (
									<Form.Group className="mb-3 mt-4">
										{filterValue.map((i) => {
											return (
												<div
													key={`${id}_checkbox_item_search_filter_${i.id}`}
													className="cursor-pointer fs-14 d-flex mb-2 flex-row justify-content-start align-items-center"
												>
													<Form.Check
														onChange={() => toggleListItemFilter(id, i)}
														checked={!!isIdActive(i.id)}
														type={'checkbox'}
														className="me-2"
													/>
													<span onClick={() => toggleListItemFilter(id, i)}>
														{i.name}
													</span>
												</div>
											);
										})}
										{source.map((i) => {
											if (!filterValue.find((item) => item.id === i.id)) {
												return (
													<div
														key={`${id}_checkbox_item_search_filter_${i.id}`}
														className="cursor-pointer fs-14 d-flex mb-2 flex-row justify-content-start align-items-center"
													>
														<Form.Check
															onChange={() => toggleListItemFilter(id, i)}
															checked={!!isIdActive(i.id)}
															type={'checkbox'}
															className="me-2"
														/>
														<span onClick={() => toggleListItemFilter(id, i)}>
															{i.name}
														</span>
													</div>
												);
											}
										})}
									</Form.Group>
								) : (
									<>Ничего не найдено</>
								)}
							</LoadingWrapper>
						</div>
						<div className="footer p-3">
							<Button
								size="sm"
								variant={'outline-primary'}
								onClick={onHideWithApply}
							>
								Применить
							</Button>
							{filterValue.length > 0 && (
								<div className="ms-3 fs-14">Выбрано: {filterValue.length}</div>
							)}
						</div>
					</Offcanvas.Body>
				</Offcanvas>
				<span className={`filter-item-container select-filter-button`}>
					<div
						style={{ maxWidth: isActive ? '200px' : '150px' }}
						className="filter-toggle"
					>
						<FilterButton
							showReset={isActive}
							onClick={() => setShow(true)}
							onReset={() => setFilter([id], null, true)}
							isActive={isActive}
						>
							<MaterialIcon
								className={`pe-1 cursor-pointer fs-1`}
								name="filter_alt"
							/>
							<OverflowedTextTooltip
								placement="bottom"
								text={isActive ? getNames() : title}
							/>
						</FilterButton>
					</div>
				</span>
			</>
		);
	}
);
