import React, { useState, useRef, useCallback } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Placement } from 'react-bootstrap/types';

export const TooltipElement = observer(
	({
		value,
		children,
		focus,
		placement,
		disabled,
		show,
		stayOnBlur,
		...props
	}: {
		value?: React.ReactNode;
		style?: Record<string, string>;
		focus?: boolean;
		placement?: Placement;
		children?: React.ReactNode;
		disabled?: boolean;
		show?: boolean;
		className?: string;
		stayOnBlur?: boolean;
	}) => {
		const [showTooltip, setShowTooltip] = useState(show);
		const tooltip_target = useRef(null);

		const onTooltipHover = useCallback((value: boolean) => {
			setShowTooltip(value);
		}, []);

		const action = {
			onMouseEnter: () => !disabled && onTooltipHover(true),
			onMouseLeave: () => !disabled && !stayOnBlur && onTooltipHover(false),
		};

		const actions_container = focus ? action : null;
		const actions_text = focus ? null : action;

		return (
			<div ref={tooltip_target} {...props} {...actions_container}>
				<Overlay
					target={tooltip_target.current}
					show={showTooltip}
					placement={placement || 'top'}
				>
					{(props) => (
						<Tooltip className="fs-12" {...props}>
							{value}
						</Tooltip>
					)}
				</Overlay>
				<span {...actions_text}>{children}</span>
			</div>
		);
	}
);
