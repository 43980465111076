import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
	useAuthStore,
	useFilterStore,
	useProductStore,
} from 'providers/RootStoreProvider';

export const ProductLayout = observer(() => {
	const { initProfile, isAuthorized, isAuthLoaded } = useAuthStore();
	const { isLoading } = useProductStore();
	const { initFilterPresets } = useFilterStore();

	useEffect(() => {
		if (!isLoading && isAuthorized && !isAuthLoaded) {
			initProfile();
		}
		if (isAuthorized) {
			initFilterPresets();
		}
	}, [isLoading, isAuthorized, isAuthLoaded]);

	return <Outlet />;
});
