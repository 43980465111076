import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { TyreTable } from './comp/tyre-list/TyreTable';
import { TyreAggregationMulti } from './comp/TyreAggregationMulti';
import { TyreAggregation } from './comp/TyreAggregation';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { useTyreStore } from 'providers/RootStoreProvider';
import { FilterWrapper } from 'components/common/filter/FilterWrapper';
import { TyreFilterQuantity } from './comp/TyreFilterQuantity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Tyres = observer(() => {
	const {
		getAggregation,
		setParams,
		resetParams,
		setParamsArray,
		isLoading,
		params,
	} = useTyreStore();

	return (
		<>
			<div className="d-flex flex-row w-100 justify-content-start align-items-start">
				<div className="catalog-tyre-list-container me-3 d-flex flex-column justify-content-start align-items-start">
					<Card className="pt-3 px-3 w-100 h-100 ">
						<div className="tab-wrapper mb-2">
							<Tabs activeKey={0} className="mb-3 tyre-tab-wrapper">
								<Tab
									className="text-left"
									eventKey={0}
									title={'Подбор шин по параметрам'}
								>
									<TyreAggregationMulti
										value={params['width'] ?? []}
										label="Ширина, мм"
										get={() => getAggregation('width')}
										setValue={(value) => setParamsArray('width', value)}
									/>
									<TyreAggregationMulti
										value={params['height'] ?? []}
										label="Высота, %"
										get={() => getAggregation('height')}
										setValue={(value) => setParamsArray('height', value)}
									/>
									<TyreAggregationMulti
										value={params['diameter'] ?? []}
										label="Диаметр"
										get={() => getAggregation('diameter')}
										setValue={(value) => setParamsArray('diameter', value)}
									/>
									<TyreAggregation
										value={params['season']}
										label="Сезонность"
										get={() => getAggregation('season')}
										setValue={(value) => setParams('season', value)}
									/>
									<TyreAggregation
										value={params['spike']}
										label="Шипы"
										get={() => getAggregation('spike')}
										setValue={(value) => setParams('spike', value)}
									/>
									<TyreAggregationMulti
										value={params['brands'] ?? []}
										label="Производитель"
										get={() => getAggregation('brands')}
										setValue={(value) => setParamsArray('brands', value)}
									/>
									<h5 className="fs-14 mb-2">Количество</h5>
									<FilterWrapper classContent="d-flex justify-content-start align-items-center">
										<TyreFilterQuantity
											update={(value) => setParams('min_stock', { value })}
											disabled={isLoading}
											containerClassName="w-100"
											defaultValue={
												params['min_stock'] === null
													? 0
													: parseInt(params['min_stock'])
											}
											style={{ paddingRight: '30px' }}
										>
											{params['min_stock'] !== null && (
												<div
													style={{
														position: 'absolute',
														top: '8px',
														right: '14px',
													}}
													onClick={() =>
														setParams('min_stock', { value: null })
													}
												>
													<FontAwesomeIcon
														icon="times"
														className="cursor-pointer"
													/>
												</div>
											)}
										</TyreFilterQuantity>
									</FilterWrapper>
									<h5 className="fs-14 mb-2 mt-3">Цена</h5>
									<FilterWrapper classContent="d-flex justify-content-start align-items-center">
										<TyreFilterQuantity
											update={(value) => setParams('min_cost', { value })}
											disabled={isLoading}
											labelSecond="₽"
											isClearButton
											defaultValue={
												params['min_cost'] === null
													? 0
													: parseInt(params['min_cost'])
											}
										/>
										<span className="px-2 color-grey">|</span>
										<TyreFilterQuantity
											update={(value) => setParams('max_cost', { value })}
											disabled={isLoading}
											labelFirst="до"
											labelSecond="₽"
											isClearButton
											defaultValue={
												params['max_cost'] === null
													? 0
													: parseInt(params['max_cost'])
											}
										/>
									</FilterWrapper>
									<Button
										onClick={resetParams}
										variant="link"
										className="fs-12 mt-3 p-1"
									>
										Сбросить параметры
									</Button>
								</Tab>
							</Tabs>
						</div>
					</Card>
				</div>
				<div className="d-flex flex-column w-100 justify-content-start align-items-start">
					<TyreTable />
				</div>
			</div>
		</>
	);
});
