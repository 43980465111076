import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { PaginationWrapper } from 'components/common/PaginationWrapper';
import { CompaniesSearch } from './comp/CompaniesSearch';
import { NotFound } from 'components/common/NotFound';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import dayjs from 'dayjs';
import { useCompaniesStore } from 'providers/RootStoreProvider';

export const Companies = observer(() => {
	const { init, isLoading, list, itemsPerPage, setPage, page, total, manager } =
		useCompaniesStore();

	useEffect(() => {
		init();
	}, []);

	const openInNewTab = useCallback((url: string) => {
		window.open(url, '_blank', 'noreferrer');
	}, []);

	return (
		<LoadingWrapper isLoading={isLoading} size={48}>
			{!isLoading && (
				<>
					<Card className="mb-3">
						<Card.Body className="position-relative ">
							<h4 className="mb-0">{manager.name}</h4>
						</Card.Body>
					</Card>
					<CompaniesSearch />
					<PaginationWrapper
						totalItems={total}
						itemsPerPage={itemsPerPage}
						page={page}
						setPage={setPage}
					>
						{list.length ? (
							list.map((company) => {
								return (
									<Card
										onClick={() => openInNewTab(`/companies/${company.id}`)}
										className="mb-3 cursor-pointer"
										key={company.id}
									>
										<Card.Body>
											<div className="d-flex justify-content-between">
												<div className="d-flex flex-column">
													<div className="fs-16 fw-semi-bold">
														{company.name}
													</div>
													<div className="fs-13">{company.address}</div>
													<div className="fs-13">
														Дата регистрации:{' '}
														{dayjs(company.createdAt).format('DD/MM/YY HH:MM')}
													</div>
												</div>
												<div className="fs-15 fw-semi-bold">
													ID {company.id}
												</div>
											</div>
										</Card.Body>
									</Card>
								);
							})
						) : (
							<NotFound />
						)}
					</PaginationWrapper>
				</>
			)}
		</LoadingWrapper>
	);
});
