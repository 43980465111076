import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, useCallback } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { CompaniesTable } from './comp/CompaniesTable';
import { OrdersTable } from './comp/OrdersTable';
import { DashboardInfo } from './comp/DashboardInfo';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import { useDashboardStore } from 'providers/RootStoreProvider';

registerLocale('ru', ru);

export const Dashboard = observer(() => {
	const {
		init,
		isLoading,
		companiesCountByManager,
		ordersCountByManager,
		getDashboard,
	} = useDashboardStore();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		init();
	}, []);

	const getDashboardOnClick = useCallback(() => {
		getDashboard({
			from: dayjs(startDate).format('DD.MM.YYYY'),
			to: dayjs(endDate).format('DD.MM.YYYY'),
		});
	}, [startDate, endDate]);

	return (
		<>
			{isLoading ? (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
			) : (
				<>
					<Card className="mb-3">
						<Card.Body className="position-relative ">
							<h4 className="mb-0">Дашборд</h4>
						</Card.Body>
					</Card>

					<Form>
						<Form.Group
							className="mb-3 d-flex align-items-center gap-3 w-40"
							controlId="formHorizontalEmail"
						>
							<ReactDatePicker
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								className="form-control"
								dateFormat="dd-MM-yyyy"
								selectsStart
								startDate={startDate}
								endDate={endDate}
								locale="ru"
							/>
							<div className="d-flex">
								<span>—</span>
							</div>
							<ReactDatePicker
								selected={endDate}
								onChange={(date) => setEndDate(date)}
								className="form-control"
								dateFormat="dd-MM-yyyy"
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								minDate={startDate}
								locale="ru"
							/>
							<Button onClick={getDashboardOnClick} className="mw-100">
								Применить
							</Button>
						</Form.Group>
					</Form>
					<DashboardInfo />
					<div className="d-flex flex-row align-items-start">
						<CompaniesTable companiesCountByManager={companiesCountByManager} />
						<OrdersTable ordersCountByManager={ordersCountByManager} />
					</div>
				</>
			)}
		</>
	);
});
