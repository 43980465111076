import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Shop } from 'components/shop/Shop';

export const ShopDetails = observer(() => {
	return (
		<>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title></Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Shop />
			</Offcanvas.Body>
		</>
	);
});
