import { Card, Form, Button } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { observer } from 'mobx-react-lite';
import { useAppStore, useAuthStore } from 'providers/RootStoreProvider';
import { useCallback } from 'react';

export const AccountSettings = observer(() => {
	const {
		user: {
			notify_about_order_ready_for_issue,
			notify_about_order_rejection,
			email_for_notifications,
			setData,
			partner,
		},
	} = useAuthStore();
	const { setActiveModal } = useAppStore();

	const handleChange = useCallback(
		(e: { target: { name: string; checked: boolean } }) => {
			setData({ [e.target.name]: e.target.checked });
		},
		[]
	);

	const handleOpenInstruction = async () => {
		await setActiveModal('telegram_instruction');
	};

	return (
		<Card className="mb-3">
			<FalconCardHeader title="Настройка уведомлений" />
			<Card.Body className="bg-light">
				<div className="ps-2 mb-3">
					<div className="mb-3">
						<Form.Group controlId="email_for_notifications">
							<Form.Label>Email для уведомлений</Form.Label>
							<Form.Control
								type="email"
								placeholder="Email для уведомлений"
								value={email_for_notifications}
								name="email_for_notifications"
								onChange={({ target }) =>
									setData({ email_for_notifications: target.value })
								}
							/>
						</Form.Group>
					</div>
					<Form.Check
						type="checkbox"
						id="show-followers"
						label="Уведомлять об отмене заказа"
						className="form-label-nogutter"
						name="notify_about_order_rejection"
						onChange={handleChange}
						checked={notify_about_order_rejection}
					/>
					<Form.Check
						type="checkbox"
						id="show-email"
						label="Уведомлять о готовности к выдаче"
						className="form-label-nogutter"
						name="notify_about_order_ready_for_issue"
						onChange={handleChange}
						checked={notify_about_order_ready_for_issue}
					/>
				</div>
				{partner && (
					<Button
						className="d-flex align-center gap-2"
						onClick={handleOpenInstruction}
					>
						Чат-бот Gettzap в Telegram!
						<MaterialIcon name="telegram" className="" />
					</Button>
				)}
			</Card.Body>
		</Card>
	);
});
