import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';

type TProps = {
	modalData: unknown;
	close: () => void;
};

type TModalData = {
	add: (payload: unknown) => void;
	update: (payload: unknown) => void;
	initialData: Record<string, unknown>;
	fields: { name: string; label: string; placeholder: string }[];
	submitButtonText?: string;
	title?: string;
};

export const FormModal = observer(({ modalData, close }: TProps) => {
	const { add, update, initialData, fields, submitButtonText, title } =
		modalData as TModalData;
	const mode = initialData && initialData.id ? 'edit' : 'create';

	const handleSubmit = useCallback((payload) => {
		switch (mode) {
			case 'edit':
				close();
				return update(payload);
			case 'create':
				close();
				return add(payload);
				break;
		}
	}, []);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{title ? title : mode == 'edit' ? 'Изменить' : 'Добавить'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={
						initialData
							? initialData
							: fields.reduce((acc, item) => {
									acc[item.name] = '';
									return acc;
							  }, {})
					}
					enableReinitialize
					onSubmit={async (values) => {
						handleSubmit(values);
					}}
				>
					{({ values, handleChange, handleSubmit, isValid }) => (
						<Form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<div className="ps-2">
								{fields.map(({ name, label, placeholder }, i) => {
									return (
										<Form.Group key={'form_' + i + name}>
											<Form.Label className="mt-2">{label}</Form.Label>
											<Form.Control
												placeholder={placeholder ? placeholder : label}
												value={
													values && values[name]
														? (values[name] as string | number)
														: undefined
												}
												name={name}
												onChange={handleChange}
											/>
										</Form.Group>
									);
								})}
							</div>
							<div className="border-dashed-bottom my-3" />
							<Button
								disabled={!isValid}
								variant="primary"
								type="submit"
								className="me-4"
							>
								{submitButtonText ? submitButtonText : 'Сохранить'}
							</Button>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</>
	);
});
