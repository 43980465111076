import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TDefaultPagination } from 'types/common';
import { TListReponse } from 'types/common';
import { TCompanies, TCompaniesInfo } from 'types/companies';

export const fetchCompanies = async (
	query_params: TDefaultPagination & {query?: string;}
): Promise<TListReponse<TCompanies>> => {
	return request(
		fetch(b2bApiUrl(`manager/companies`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchCompaniesInfo = async (
	companyId: number
): Promise<TCompaniesInfo> => {
	return request(
		fetch(b2bApiUrl(`manager/companies/${companyId}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
