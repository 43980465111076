import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { ReactNode } from 'react';

type TProps = {
	onClick: () => void;
	isActive: boolean;
	onReset?: () => void;
	showReset?: boolean;
	disabled?: boolean;
	children: ReactNode;
	className?: string;
	style?: unknown;
};

export const FilterButton = observer(
	({
		showReset,
		onClick,
		onReset,
		isActive,
		disabled,
		children,
		className,
		style,
	}: TProps) => {
		return (
			<div className="position-relative">
				<Button
					disabled={disabled}
					className={`filter-button d-flex px-2 py-1 justify-content-center align-items-center ${className}`}
					size="sm"
					variant={isActive ? 'primary' : 'outline-primary'}
					onClick={onClick}
					style={style}
				>
					{children}
				</Button>
				{onReset && isActive && showReset && (
					<MaterialIcon
						onClick={onReset}
						className="filter-toggle-close-trigger cursor-pointer fs-16"
						name="close"
					/>
				)}
			</div>
		);
	}
);
