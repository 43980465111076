import { makeAutoObservable } from 'mobx';
import {
	TCashbackItem,
	TCashbackTotal,
	TCashbackRequisite,
	TCashbackRequisitePayload,
} from 'types/cashback';
import {
	fetchCashback,
	fetchRequisitesCashback,
	postRequisitesCashback,
	patchRequisitesCashback,
	deleteRequisitesCashback,
} from './../../api/cashback/cashback';
import { validateDate } from 'helpers/validateDate';
import { RootStore } from 'stores/RootStore';

type TSearchItems = {
	from?: string;
	to?: string;
};

export class CashbackStore {
	isLoading = true;
	total: TCashbackTotal = { await: 0, paid: 0, process: 0 };
	items: TCashbackItem[] = [];
	requisite: TCashbackRequisite[] | null = null;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	removeRequisitesCashback = async (id: number) => {
		const res = await deleteRequisitesCashback(id);
		if (res === null) return;
		this.requisite = null;
	};

	updateRequisites = async (payload: TCashbackRequisite) => {
		const itemIndex = this.requisite.findIndex((i) => i.id === payload.id);
		const res = await patchRequisitesCashback(payload.id, payload);

		if (res === null) return;
		this.requisite[itemIndex] = res;
	};

	addRequisites = async (payload: TCashbackRequisitePayload) => {
		const res = await postRequisitesCashback(payload);
		if (res === null) return;
		this.requisite.push(res);
	};

	getRequisitesCashback = async () => {
		const res = await fetchRequisitesCashback();

		if (res === null) return;

		this.requisite = res;
	};

	getCashback = async (searchItems?: TSearchItems) => {
		const obj = {} as TSearchItems;

		if (searchItems?.from && !validateDate(searchItems?.from, 'DD.MM.YYYY')) {
			obj.from = searchItems.from;
		}

		if (searchItems?.to && !validateDate(searchItems?.to, 'DD.MM.YYYY')) {
			obj.to = searchItems.to;
		}

		const res = await fetchCashback(obj);

		if (res === null) return;

		const { total, items } = res;
		this.total = total;
		this.items = items;
	};

	init = async () => {
		this.isLoading = true;

		await this.getCashback();
		await this.getRequisitesCashback();

		this.isLoading = false;
	};
}
