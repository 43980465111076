import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import { swap } from 'utils/common';
import { MaterialIcon } from 'components/common/MaterialIcon';

import 'styles/ImageModal.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

export const ImageModal = observer(
	({
		onHide,
		imageUrl,
		show,
		title,
		images,
		activeImage,
	}: {
		onHide: () => void;
		activeImage?: number;
		show: boolean;
		images?: string[];
		imageUrl?: string;
		title?: string;
	}) => {
		const [slides, setSlides] = useState<string[]>([]);
		const [thumbsSwiper, setThumbsSwiper] = useState(null);

		const handleHide = () => {
			setThumbsSwiper(null);
			onHide();
		};

		useEffect(() => {
			imageUrl &&
				setSlides(
					imageUrl
						.split(',')
						.filter((i) => i)
						.map((i) => 'https://gettzap.ru/imageproxy/' + i.replace(' ', ''))
				);
			activeImage && setSlides(swap(slides, 0, activeImage));
		}, []);

		const source = images.length > 0 ? images : slides;

		return (
			<Modal
				show={show}
				onHide={handleHide}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="small-size"
			>
				<Modal.Body>
					<div className="img-modal-content mb-3 ">
						{source.length > 0 && (
							<>
								<Swiper
									spaceBetween={10}
									navigation={true}
									thumbs={{
										swiper:
											thumbsSwiper && !thumbsSwiper.destroyed
												? thumbsSwiper
												: null,
									}}
									modules={[Navigation, Thumbs]}
									className="image-modal-swiper"
								>
									{source.map((img, i) => {
										return (
											<SwiperSlide
												style={{ background: `url(${img})` }}
												key={`slider_${i}`}
											/>
										);
									})}
								</Swiper>
								<br />
								<Swiper
									onSwiper={setThumbsSwiper}
									spaceBetween={10}
									slidesPerView={4}
									modules={[Navigation, Thumbs]}
									watchSlidesProgress={true}
									className="image-modal-thumb-swiper"
								>
									{source.map((img, i) => {
										return (
											<SwiperSlide
												className="cursor-pointer"
												style={{ background: `url(${img})` }}
												key={`thumb_slider_${i}`}
											/>
										);
									})}
								</Swiper>
							</>
						)}
					</div>
					{title && <h5>{title}</h5>}
					<div className="fs-12 mt-3 d-flex justify-content-start flex-row align-items-center">
						<MaterialIcon
							className={`cursor-pointer text-secondary me-1`}
							name="info"
						/>
						Изображение носит информационный характер
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant={'secondary'} onClick={onHide}>
						Закрыть
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
);
