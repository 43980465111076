import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';

type TProps = {
	selectedRows: string[];
	deleteSeleted: () => void;
	updatePrice: () => void;
};

export const NoteTableHeader = observer(
	({ selectedRows, updatePrice, deleteSeleted }: TProps) => {
		return (
			<>
				{selectedRows.length > 0 && (
					<div className="p-3 d-flex w-100 justify-content-between">
						<strong className="me-3">Выбрано ({selectedRows.length})</strong>
						<div>
							<Button
								onClick={updatePrice}
								variant="outline-primary"
								size="sm"
								className="me-3"
							>
								Обновить цену
							</Button>
							<Button
								onClick={deleteSeleted}
								variant="outline-danger"
								size="sm"
							>
								Удалить выбранные
							</Button>
						</div>
					</div>
				)}
			</>
		);
	}
);
