import { memo } from 'react';
import { observer } from 'mobx-react';
import { ProductTypeTabs } from './comp/ProductTypeTabs';

export const ProductTableHeaderSort = memo(
	observer(() => {
		return (
			<div className="d-flex justify-content-between align-items-center">
				<ProductTypeTabs />
			</div>
		);
	})
);
