import {
	castError,
	defaultHeaders,
	customerApiUrl,
	b2bApiUrl,
} from 'api/helpers';
import { TShopPassport, TShopFavorite } from 'types/shop';
import { TListReponse } from 'types/common';
import { TReview } from 'types/review';
import request from 'helpers/request';

export const postFavoriteShop = async (payload: {
	officeId: number;
}): Promise<TShopFavorite> => {
	return request(
		fetch(b2bApiUrl(`bayer/office_favorites`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const deleteFavoriteShop = async (
	id: number
): Promise<TShopFavorite> => {
	return request(
		fetch(b2bApiUrl(`bayer/office_favorites`, { id }), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchFavoriteShops = async (): Promise<TShopFavorite[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/office_favorites`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchShop = async (shop_id): Promise<TShopPassport> => {
	return request(
		fetch(customerApiUrl(`offices/${shop_id}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchReviews = async (params): Promise<TListReponse<TReview>> => {
	return request(
		fetch(customerApiUrl(`reviews`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
