import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { TOrderPaymentDocs } from 'types/order';

type TProps = {
	modalData: unknown;
	close: (state: boolean) => void;
};

type TModalData = {
	paymentDocs: TOrderPaymentDocs[];
};

export const OrderPaymentModal = observer(({ modalData }: TProps) => {
	const { paymentDocs } = modalData as TModalData;
	const orderId = paymentDocs[0].orderId;

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Оплата заказа №{orderId}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex py-3 flex-row justify-content-between align-items-center">
					{paymentDocs.map(({ paid, paykeeperQRId, totalCost, id }) => {
						if (!paid) {
							return (
								<div
									key={'payment_' + id}
									className="d-flex flex-column w-100 justify-content-center align-items-center"
								>
									<img
										src={`https://e-commerce.raiffeisen.ru/api/sbp/v1/qr/${paykeeperQRId}/image`}
										className="w-50"
										style={{ maxWidth: '250px' }}
										alt=""
									/>
									<h5 className="fs-16">
										Сумма оплаты:{' '}
										{`${new Intl.NumberFormat('ru-RU').format(totalCost)} ₽`}
									</h5>
								</div>
							);
						}
					})}
				</div>
			</Modal.Body>
		</>
	);
});
