import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TGaz } from 'types/gaz';
import { GazHeader } from './comp/GazHeader';
import { useGazStore } from 'providers/RootStoreProvider';

type TRowData = { row: { original: TGaz } };

export const GazTable = observer(() => {
	const { update, isLoading, data } = useGazStore();

	useEffect(() => {
		update();
	}, []);

	const columns = [
		TableColumn({
			header: 'Наименование работы',
			width: 400,
			cellClassName: 'pe-3',
			headerClassName: 'pe-1',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { name } = rowData.row.original;

				return <>{name}</>;
			},
		}),
		TableColumn({
			header: 'Код работы',
			width: 150,
			cellClassName: 'pe-3',
			headerClassName: 'pe-3',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { workCode } = rowData.row.original;
				return <>{workCode}</>;
			},
		}),

		TableColumn({
			width: 150,
			header: 'Время / Стоимость',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { workTime } = rowData.row.original;
				return <>{workTime}</>;
			},
		}),
	];

	return (
		<Table
			isLoading={isLoading}
			data={data}
			itemsPerPage={100000}
			columns={columns}
			header={<GazHeader />}
			pagination={false}
			contained={true}
		/>
	);
});
