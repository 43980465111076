import { Card } from 'react-bootstrap';
import { TCashbackTotal } from 'types/cashback';
import classNames from 'classnames';
import Background from 'components/common/Background';
import CountUp from 'react-countup';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import { observer } from 'mobx-react-lite';

type TProps = {
	total: TCashbackTotal;
};

export const CashbackInfo = observer(({ total }: TProps) => {
	return (
		<div className="d-flex flex-row justify-content-between align-items-start mb-3">
			<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
				<Background image={bg1} className="bg-card" />
				<Card.Body className="position-relative">
					<h6>Всего выплачено:</h6>
					<div
						className={classNames(
							'text-warning',
							'display-4 fs-4 mb-2 fw-normal font-sans-serif'
						)}
					>
						<CountUp
							start={0}
							end={total.paid}
							duration={2.75}
							suffix={'₽'}
							prefix={''}
							separator=","
							decimals={2}
							decimal="."
						/>
					</div>
				</Card.Body>
			</Card>
			<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
				<Background image={bg2} className="bg-card" />
				<Card.Body className="position-relative">
					<h6>Ожидает выплаты:</h6>
					<div
						className={classNames(
							'text-info',
							'display-4 fs-4 mb-2 fw-normal font-sans-serif'
						)}
					>
						<CountUp
							start={0}
							end={total.await}
							duration={2.75}
							suffix={'₽'}
							prefix={''}
							separator=","
							decimals={2}
							decimal="."
						/>
					</div>
				</Card.Body>
			</Card>
			<Card className={classNames('overflow-hidden', 'w-100')}>
				<Background image={bg3} className="bg-card" />
				<Card.Body className="position-relative">
					<h6>В обработке:</h6>
					<div
						className={classNames(
							'text-success',
							'display-4 fs-4 mb-2 fw-normal font-sans-serif'
						)}
					>
						<CountUp
							start={0}
							end={total.process}
							duration={2.75}
							suffix={'₽'}
							prefix={''}
							separator=","
							decimals={2}
							decimal="."
						/>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
});
