import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { FilterButton } from 'components/common/filter/FilterButton';
import { FilterInput } from '../../product/product-list/comp/table-header/comp/FilterInput';

export const FilterQuantity = observer(
	({
		update,
		textButton = '',
		disabled,
		isLabeled = true,
		defaultValue = 0,
		value = undefined,
		...rest
	}) => {
		const [quantity, setQuantity] = useState(defaultValue);

		const handleFilter = useDebouncedCallback(() => update(quantity), 500);

		useEffect(() => {
			if (disabled) return;
			if (value !== undefined) {
				update(quantity);
			} else {
				handleFilter();
			}
		}, [quantity]);

		return (
			<>
				{textButton && (
					<FilterButton
						disabled={disabled}
						onClick={() => setQuantity(0)}
						isActive={!quantity}
					>
						{textButton}
					</FilterButton>
				)}
				<div
					className={`filter-input fs-14 d-flex justify-content-between align-items-center ${
						quantity ? 'active' : ''
					}`}
				>
					{isLabeled && <span className="mx-2">от</span>}
					<FilterInput
						value={value !== undefined ? value : quantity}
						onChange={setQuantity}
						disabled={disabled}
						min={0}
						className={!textButton ? 'full-border' : ''}
						{...rest}
					/>
					{isLabeled && <span className="mx-2">шт.</span>}
				</div>
			</>
		);
	}
);
