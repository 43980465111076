import { Button, Placeholder } from 'react-bootstrap';
import { observer } from 'mobx-react';
import Flex from 'components/common/Flex';
import { TCoreProduct, TCoreProductID } from 'types/product';
import { ProductImage } from 'components/product/product-item/ProductImage';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useLocation, Link } from 'react-router-dom';
import { TooltipElement } from 'components/common/TooltipElement';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';

type TProps = {
	saveCoreProduct: (payload: TCoreProductID) => void;
	unsaveCoreProduct: (payload: TCoreProductID) => void;
	coreProduct: TCoreProduct;
	isAuthorized: boolean;
	isLoading: boolean;
};

export const ProductTableHeaderCoreProduct = observer(
	({
		coreProduct,
		saveCoreProduct,
		unsaveCoreProduct,
		isAuthorized,
		isLoading,
	}: TProps) => {
		const { pathname } = useLocation();

		return (
			<div className="w-100">
				{isLoading ? (
					<Flex
						style={{ minWidth: '200px' }}
						direction="row"
						alignItems="center"
						justifyContent="between"
						className="pe-3"
					>
						<Flex
							style={{ width: '100%' }}
							direction="row"
							alignItems="center"
							justifyContent="between"
						>
							<Placeholder xs={7} /> <Placeholder xs={4} />
						</Flex>
					</Flex>
				) : (
					<>
						{coreProduct ? (
							<div className="w-100 d-flex justify-content-between align-items-start flex-row flex-wrap">
								<div
									style={{
										minWidth: '300px',
										maxWidth: '800px',
									}}
									className="d-flex w-100 justify-content-start align-items-center flex-row"
								>
									<ProductImage coreProduct={coreProduct} />
									<div className="ms-3 d-flex justify-content-start align-items-start flex-column">
										<h5 className="product-list-header-brand-title d-flex justify-content-center mb-0">
											<p className="blue-color me-2 mb-0">
												{coreProduct.number}
											</p>
											{coreProduct.core_brand.name}
										</h5>
										<div
											style={{
												maxWidth: '50vw',
											}}
											className="w-100"
										>
											<OverflowedTextTooltip
												text={coreProduct.name}
												focus={false}
												placement="bottom"
											/>
										</div>
									</div>
								</div>
								<div
									style={{ minWidth: '300px', height: '65px' }}
									className="d-flex justify-content-start align-items-center"
								>
									{coreProduct && (
										<TooltipElement
											disabled={isAuthorized}
											focus={true}
											value={
												<>
													Чтобы сохранить нужно{' '}
													<Link to={`/authentication/login`}>
														авторизоваться
													</Link>
												</>
											}
										>
											<Button
												size="sm"
												variant={coreProduct.saved ? 'success' : 'primary'}
												className="page me-0"
												disabled={!isAuthorized}
												onClick={() =>
													coreProduct.saved
														? unsaveCoreProduct({
																brand_name: coreProduct.core_brand.name,
																code: coreProduct.number,
														  })
														: saveCoreProduct({
																brand_name: coreProduct.core_brand.name,
																code: coreProduct.number,
														  })
												}
											>
												{coreProduct.saved ? 'Номер сохранен' : 'Сохранить'}
											</Button>
										</TooltipElement>
									)}
									<Link to={`${pathname}/map`} className="text-decoration-none">
										<Button
											size="sm"
											variant={'outline-primary'}
											className="d-flex ms-2 justify-content-center align-items-center"
										>
											<MaterialIcon
												className={`me-1 cursor-pointer fs-1`}
												name="location_on"
											/>
											Смотреть на карте
										</Button>
									</Link>
								</div>
							</div>
						) : (
							<div className="pe-3 fs-0 mb-0 text-nowrap py-3 d-flex justify-content-center align-items-center">
								<h5 className="product-list-header-brand-title d-flex justify-content-center mb-0">
									Ничего не найдено
								</h5>
							</div>
						)}
					</>
				)}
			</div>
		);
	}
);
