import React from 'react';
import { observer } from 'mobx-react';
import { ImageProductItem } from 'components/common/ImageProductItem';
import Flex from 'components/common/Flex';

export const SavedProductBrand = observer(
	({ preview, brand_name }: { preview: string; brand_name: string }) => {
		return (
			<Flex alignItems="center">
				<ImageProductItem preview={preview} />
				<strong>{brand_name}</strong>
			</Flex>
		);
	}
);
