import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordFetch } from 'api/profile';

export const ForgetPasswordForm = () => {
	// State
	const navigate = useNavigate();
	const [email, setEmail] = useState('');

	const successMessage = useCallback(() => {
		toast.success(`Успешно отправлена!`, {
			theme: 'colored',
		});
		setTimeout(() =>
			navigate('/authentication/confirm-mail', { state: email })
		);
	}, [email]);

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault();
			return forgotPasswordFetch(email)
				.then(successMessage)
				.catch((error) =>
					toast.error(error.message, {
						theme: 'colored',
					})
				);
		},
		[email]
	);

	return (
		<Form className="mt-4" onSubmit={handleSubmit}>
			<Form.Group className="mb-3">
				<Form.Control
					placeholder="Email"
					value={email}
					name="email"
					onChange={({ target }) => setEmail(target.value)}
					type="email"
				/>
			</Form.Group>

			<Form.Group className="mb-3">
				<Button className="w-100" type="submit" disabled={!email}>
					Восстановить пароль
				</Button>
			</Form.Group>
		</Form>
	);
};

ForgetPasswordForm.propTypes = {
	layout: PropTypes.string,
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };
