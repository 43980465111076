import { makeAutoObservable } from 'mobx';
import {
	fetchListCatalog,
	fetchCatalogInfo,
	fetchVehicleInfo,
	fetchListQuickGroup,
	fetchListQuickDetail,
	fetchListCategories,
	fetchListUnits,
	fetchWizardFindCar,
	fetchWizard,
	fetchUnitInfo,
	fetchListImageMapByUnit,
	fetchListDetailByUnit,
	fetchCatalogSearchHistory,
	fetchSeacrhByVin,
	fetchSeacrhByStateNumber,
} from 'api/catalog/catalog';
import { fetchUid } from 'api/product/products';
import {
	TListCatalog,
	TAlhabetListCatalog,
	TOriginalCatalogParams,
	TCatalogSearchHistory,
	TSearchByVin,
	TSearchByStateNumber,
} from 'types/catalog';
import { RootStore } from 'stores/RootStore';

export class CatalogStore {
	isLoading = true;
	listCatalog: TListCatalog[] = [];
	alhabetListCatalog: TAlhabetListCatalog[] = [];
	quickGroupId = '';
	TABS = [
		{ key: 'quicklist', label: 'Поиск по группам' },
		{ key: 'original', label: 'Поиск по узлам' },
	];
	SSD = '';
	activeTab = this.TABS[0];
	catalogSearchHistory: TCatalogSearchHistory[] = [];
	SearchByStateNumber: TSearchByStateNumber = {
		vin: '',
	};
	SearchByVin: TSearchByVin[] = [];

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setActiveTab = (tab) => {
		this.activeTab = tab;
	};

	setSSD = (ssd: string) => {
		this.SSD = ssd;
	};

	getUid = async (oem: string, brand_name: string) => {
		const number = oem.replaceAll(' ', '').replaceAll('-', '');

		const res = await fetchUid({ number, brand_name });
		if (res === null) {
			return null;
		}
		return res;
	};

	setQuickGroupId = (id: string) => {
		this.quickGroupId = id;
	};

	getListDetailByUnit = async (params: TOriginalCatalogParams) => {
		const res = await fetchListDetailByUnit(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getCatalogInfo = async (params: TOriginalCatalogParams) => {
		const res = await fetchCatalogInfo(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getVehicleInfo = async (params: TOriginalCatalogParams) => {
		const res = await fetchVehicleInfo(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getListQuickGroup = async (params: TOriginalCatalogParams) => {
		const res = await fetchListQuickGroup(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getListImageMapByUnit = async (params: TOriginalCatalogParams) => {
		const res = await fetchListImageMapByUnit(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getUnitInfo = async (params: TOriginalCatalogParams) => {
		const res = await fetchUnitInfo(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getListQuickDetail = async (params: TOriginalCatalogParams) => {
		const res = await fetchListQuickDetail(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getListCategories = async (params: TOriginalCatalogParams) => {
		const res = await fetchListCategories(params);
		if (res === null) {
			return null;
		}
		return res;
	};
	getListUnits = async (params: TOriginalCatalogParams) => {
		const res = await fetchListUnits(params);
		if (res === null) {
			return null;
		}
		return res;
	};

	wizardFindModel = async (params) => {
		const res = await fetchWizardFindCar(params);
		if (res === null) {
			return null;
		}
		return res;
	};

	wizardSearch = async (params) => {
		const res = await fetchWizard(params);
		if (res === null) {
			return null;
		}
		return res;
	};

	getListCatalog = async () => {
		this.isLoading = true;
		this.alhabetListCatalog = [];

		const res = await fetchListCatalog();

		if (res === null) {
			this.isLoading = false;
		}

		this.listCatalog = res;
		this.listCatalog
			.map(({ brand, code }) => ({
				brand,
				code,
				firstLetter: brand.split('')[0],
			}))
			.forEach(({ firstLetter, code, brand }) => {
				const lastIndex = this.alhabetListCatalog.length - 1;
				const lastContainer: TAlhabetListCatalog =
					this.alhabetListCatalog[lastIndex];
				if (!lastContainer) {
					this.alhabetListCatalog.push({
						firstLetter,
						items: [{ code, brand }],
					});
				} else {
					if (firstLetter !== lastContainer.firstLetter) {
						this.alhabetListCatalog.push({
							firstLetter,
							items: [{ code, brand }],
						});
					} else {
						lastContainer.items.push({ code, brand });
					}
				}
			});

		this.isLoading = false;
	};

	getCatalogSearchHistory = async () => {
		// this.isLoading = true;

		const res = await fetchCatalogSearchHistory();
		if (res === null) return;

		this.catalogSearchHistory = res.data;

		// this.isLoading = false;
	};

	getSearchByVin = async (searchItems?: { ident_string?: string }) => {
		this.SearchByVin = [];

		const res = await fetchSeacrhByVin({
			ident_string: searchItems?.ident_string,
		});

		if (res === null) return;

		this.SearchByVin = res;
	};

	getSearchByStateNumber = async (searchItems?: { number?: string }) => {
		const res = await fetchSeacrhByStateNumber({
			number: searchItems?.number,
		});

		if (res === null) return;

		this.SearchByStateNumber = res;
		return res.vin;
	};
}
