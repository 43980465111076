import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TProduct } from 'types/product';
import { ProductListItem } from './ProductListItem';
import CircularProgress from '@mui/material/CircularProgress';
import {
	useAuthStore,
	useCoreProductStore,
	useProductStore,
	useSellerProductStore,
	useShopStore,
} from 'providers/RootStoreProvider';
import 'styles/Map.scss';

type TProps = {
	onClose: () => void;
	showActiveProducts: boolean;
	activeProducts: TProduct[];
};

export const ActiveProductsList = observer(
	({ activeProducts, onClose, showActiveProducts }: TProps) => {
		const { coreProduct } = useCoreProductStore();
		const { isLoading } = useSellerProductStore();
		const { addToCart } = useProductStore();
		const { activateShop } = useShopStore();
		const { isAuthorized } = useAuthStore();
		return (
			<div
				className={`product-map-active-list-container ${
					showActiveProducts && 'active'
				}`}
			>
				<div className="product-map-active-list-content">
					<Card className="h-100">
						<Card.Header className="position-relative p-3 pe-5 active-product-list-content-card-header shadow-small">
							<h5 className="fs-14">{coreProduct && coreProduct.name}</h5>
							<MaterialIcon
								onClick={onClose}
								className={`p-1 cursor-pointer list-close-trigger`}
								name="close"
							/>
						</Card.Header>
						<Card.Body className="active-product-list-content-card-body p-3">
							{isLoading ? (
								<div className="p-5 d-flex justify-content-center align-items-center">
									<CircularProgress size={40} />
								</div>
							) : (
								<>
									{activeProducts.length ? (
										<>
											{activeProducts.map((product) => {
												const { office, uniqId } = product;

												return (
													<ProductListItem
														key={uniqId + office.id}
														product={product}
														isAuthorized={isAuthorized}
														isLoading={isLoading}
														onShopName={() => activateShop(office.id)}
														addToCart={(item) => addToCart(item)}
													/>
												);
											})}
										</>
									) : (
										<span className="fs-12">Ничего не найдено</span>
									)}
								</>
							)}
						</Card.Body>
					</Card>
				</div>
			</div>
		);
	}
);
