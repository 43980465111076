import React from 'react';
import { Nav } from 'react-bootstrap';
import { SearchBox } from 'components/navbar/top/Searchbox/SearchBox';
import { observer } from 'mobx-react';
import { SearchLocationButton } from './SearchLocationButton';
import { MultiSearchButton } from '../MultiSearchButton';
import { useNavigate } from 'react-router-dom';

import 'styles/Navbar.scss';

export const SearchBoxContainer = observer(() => {
	const navigate = useNavigate();

	return (
		<Nav
			navbar
			className={`align-items-center d-block search-container`}
			as="ul"
		>
			<Nav.Item
				className="search-input d-flex flex-row justify-content-between align-items-center"
				as="li"
			>
				<SearchBox
					onSelect={({ uid }) => {
						navigate(`/products/${uid}`);
					}}
				/>
				<MultiSearchButton />
				<SearchLocationButton />
			</Nav.Item>
		</Nav>
	);
});
