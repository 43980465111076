import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductFilter } from '../../product-list/comp/product-list-table-header/product-list-table-filter/ProductFilter';
import { Card } from 'react-bootstrap';
import { BadgeItemRowList } from 'components/common/BadgeItemRowList';
import { TSearchItem } from 'types/search';
import { useAppStore, useMultiSearchStore } from 'providers/RootStoreProvider';

export const MultiSearchHeader = observer(() => {
	const { update, searchItems, removeSearchItem } = useMultiSearchStore();
	const { setActiveModal } = useAppStore();

	const onClickSearchItem = useCallback(
		async (item: TSearchItem, officeSpecified?: boolean) => {
			await setActiveModal('analogs', 'xl-size', {
				...item,
				officeSpecified,
			});
		},
		[]
	);
	const handleRemove = useCallback(async (index: number) => {
		await removeSearchItem(index);
		update();
	}, []);

	return (
		<Card className="mb-3">
			<h4 className="pt-3 ps-3">Мультипоиск</h4>
			<Card.Body>
				<BadgeItemRowList
					list={searchItems}
					keyName={['code', 'brand']}
					add={() => setActiveModal('multi_search')}
					onClickItem={onClickSearchItem}
					remove={handleRemove}
					tooltipKey="name"
				/>
				<hr />
				<ProductFilter />
			</Card.Body>
		</Card>
	);
});
