import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { useAppStore, useAuthStore } from 'providers/RootStoreProvider';

export const SuperLayout = observer(() => {
	const { addHistory } = useAppStore();
	const { setToken } = useAuthStore();
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const token = searchParams.get('token');
		token && setToken(token);
	}, []);

	useEffect(() => {
		addHistory(pathname);
	}, [pathname]);

	return <Outlet />;
});
