import { makeAutoObservable } from 'mobx';
import { TTasks, TTasksPayload } from 'types/tasks';
import {
	fetchTasks,
	postTasks,
	patchTasksSuccess,
	patchTasks,
	deleteTask,
} from 'api/tasks/tasks';
import { toast } from 'react-toastify';
import { RootStore } from 'stores/RootStore';

export class TasksStore {
	isLoading = true;
	tasks: TTasks[] = [];
	total = 0;
	itemsPerPage = 10;
	page = 1;
	activeId: number = null;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	setActiveId = (id: number | null) => {
		this.activeId = id;
	};

	getTasks = async () => {
		const res = await fetchTasks({
			per_page: this.itemsPerPage,
			page: this.page,
		});

		if (res === null) return;

		const {
			meta: { count },
		} = res;

		this.tasks = res.items;
		this.total = count;
	};

	createTasks = async (payload: TTasksPayload): Promise<TTasks> => {
		const res = await postTasks(payload);
		if (res === null) return;
		this.tasks.unshift(res);

		toast.success('Задача успешно создана', {
			theme: 'colored',
		});

		return res;
	};

	editTasks = async (id: number, payload: TTasksPayload) => {
		const res = await patchTasks(id, payload);
		if (res === null) return;

		const index = this.tasks.findIndex((i) => i.id === id);
		this.tasks[index] = res;

		toast.success('Задача отредактирована', {
			theme: 'colored',
		});
		return res;
	};

	successTasks = async (
		id: number,
		payload: TTasksPayload
	): Promise<TTasks> => {
		const res = await patchTasksSuccess(id, payload);
		if (res === null) return;

		const index = this.tasks.findIndex((i) => i.id === id);
		this.tasks[index] = res;

		toast.success('Задача выполнена', {
			theme: 'colored',
		});
		return res;
	};

	removeTask = async (id: number): Promise<TTasks> => {
		const res = await deleteTask(id);
		if (res === null) return;

		const index = this.tasks.findIndex((i) => i.id === id);

		this.tasks.splice(index, 1);

		toast.success('Задача успешно удалена', {
			theme: 'colored',
		});

		return res;
	};

	init = async () => {
		this.isLoading = true;

		await this.getTasks();

		this.isLoading = false;
	};
}
