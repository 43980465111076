import { Fragment, useCallback } from 'react';
import { Nav } from 'react-bootstrap';
import { NavbarVerticalMenu } from './NavbarVerticalMenu';
import { routes } from 'routes/routes';
import { capitalize } from 'helpers/utils';
import { observer } from 'mobx-react-lite';
import { NavbarLabel } from './NavbarLabel';
import { useAuthStore } from 'providers/RootStoreProvider';

export const NavbarVerticalMenuList = observer(() => {
	const { user } = useAuthStore();
	const checkRole = useCallback(
		(roles: string[]) => user?.roles.filter((role) => roles.includes(role)),
		[user]
	);

	return (
		<div className="vertical-navbar-content scrollbar">
			<Nav className="flex-column" as="ul">
				{routes.map((route) => {
					if (!!checkRole(route.roles) && checkRole(route.roles).length) {
						return (
							<Fragment key={route.label}>
								<NavbarLabel label={capitalize(route.label)} />
								<NavbarVerticalMenu routes={route.children} />
							</Fragment>
						);
					}
				})}
			</Nav>
		</div>
	);
});
