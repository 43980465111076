import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDay } from 'helpers/formatDay';
import { Table } from 'react-bootstrap';
import { TCashbackItem } from 'types/cashback';
import { observer } from 'mobx-react-lite';

type TProps = {
	items: TCashbackItem[];
};

enum OrderItemStatus {
	'Новый',
	'В работе',
	'Пришло на склад',
	'Выдан',
	'Приостановлен',
	'Отклонён',
}

enum PaymentStatus {
	Awaiting = 'Ожидает оплаты',
	Paid = 'Выплачено',
}

export const CashbackTable = observer(({ items = [] }: TProps) => {
	return (
		<Table>
			<thead className="bg-200">
				<tr>
					<th className="py-2 fs--1">Дата</th>
					<th className="py-2 fs--1">Номер заказа</th>
					<th className="py-2 fs--1">Статус заказа</th>
					<th className="py-2 fs--1">Статус выплаты</th>
					<th className="py-2 fs--1">Сумма</th>
				</tr>
			</thead>
			<tbody>
				{items.length ? (
					items.map((item, index) => (
						<tr
							key={`cashback_${item.orderId + index}`}
							style={{ backgroundColor: item.paid ? '#d5f4d5' : '' }}
						>
							<td className="py-2 fs--1">{formatDay(item.createdAt)}</td>
							<td className="py-2 fs--1">
								<span>{item.orderId}</span>
								<a
									href={`/order-details/${item.orderId}`}
									target="_blank"
									rel="noreferrer"
									style={{ marginLeft: '10px', color: '#5e6e82' }}
								>
									<FontAwesomeIcon icon="external-link-alt" />
								</a>
							</td>
							<td className="py-2 fs--1">
								{OrderItemStatus[item.orderItemStatus - 1]}
							</td>
							<td className="py-2 fs--1">
								{item.paid ? PaymentStatus.Paid : PaymentStatus.Awaiting}
							</td>
							<td className="py-2 fs--1">{item.amount} ₽</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan={5} style={{ textAlign: 'center' }}>
							Нет заказов.
						</td>
					</tr>
				)}
			</tbody>
		</Table>
	);
});
