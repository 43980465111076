import FalconCardHeader from 'components/common/FalconCardHeader';
import { Button, Card, Form } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import YupPassword from 'yup-password';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAuthStore } from 'providers/RootStoreProvider';

YupPassword(Yup);

const ValidationSchema = Yup.object().shape({
	oldPassword: Yup.string()
		.min(8, 'Минимальная длинна пароля 8 символов')
		.max(24, 'Максимальная длинна пароля 24 символов')
		.required(''),
	newPassword: Yup.string()
		.password()
		.min(8, 'Минимальная длинна пароля 8 символов')
		.max(24, 'Максимальная длинна пароля 24 символов')
		.minSymbols(0, 'Пароль должен содержать минимум 1 символ')
		.minLowercase(
			1,
			'Пароль должен содержать минимум 1 строчную латинскую букву'
		)
		.minUppercase(
			1,
			'Пароль должен содержать минимум 1 заглавную латинскую букву'
		)
		.minNumbers(1, 'Пароль должен содержать минимум 1 цифру')
		.required(''),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать')
		.required(''),
});

export const ChangePassword = observer(() => {
	const {
		user: { updatePassword },
	} = useAuthStore();

	return (
		<Card className="mb-3">
			<FalconCardHeader title="Смена пароля" />
			<Card.Body className="bg-light">
				<Formik
					isInitialValid={false}
					initialValues={{
						oldPassword: '',
						newPassword: '',
						confirmPassword: '',
					}}
					validationSchema={ValidationSchema}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						const result = await updatePassword(
							values.oldPassword,
							values.newPassword
						);
						result && resetForm();
						setSubmitting(false);
					}}
				>
					{({
						values,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting,
						isValid,
					}) => {
						return (
							<Form onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="oldPassword">
									<Form.Label>Текущий пароль</Form.Label>
									<Form.Control
										type="password"
										value={values.oldPassword}
										name="oldPassword"
										onChange={handleChange}
									/>
									<Form.Text>{errors.oldPassword}</Form.Text>
								</Form.Group>
								<Form.Group className="mb-3" controlId="newPassword">
									<Form.Label>Новый пароль</Form.Label>
									<Form.Control
										type="password"
										value={values.newPassword}
										name="newPassword"
										onChange={handleChange}
									/>
									<Form.Text>{errors.newPassword}</Form.Text>
								</Form.Group>
								<Form.Group className="mb-3" controlId="confirmPassword">
									<Form.Label>Подтверждение пароля</Form.Label>
									<Form.Control
										type="password"
										value={values.confirmPassword}
										name="confirmPassword"
										onChange={handleChange}
									/>
									<Form.Text>{errors.confirmPassword}</Form.Text>
								</Form.Group>
								<Button disabled={!isValid} className="w-100" type="submit">
									{isSubmitting ? 'Обновление...' : 'Обновить пароль'}
								</Button>
							</Form>
						);
					}}
				</Formik>
			</Card.Body>
		</Card>
	);
});
