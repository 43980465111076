export const defaultRegions = [
	{
		id: 43,
		uid: 'region-43',
		name: 'Москва и МО',
		type: 'region',
		metro_map: 'moscow-metro',
		region: null,
		region_id: null,
		location: null,
	},
	{
		id: 37,
		uid: 'region-37',
		name: 'Санкт-Петербург и ЛО',
		type: 'region',
		metro_map: 'piter-metro',
		region: null,
		region_id: null,
		location: null,
	},
	{
		id: 46,
		uid: 'region-46',
		name: 'Нижний Новгород и область',
		type: 'region',
		metro_map: null,
		region: null,
		region_id: null,
		location: null,
	},
	{
		id: 63,
		uid: 'region-63',
		name: 'Екатеринбург и область',
		type: 'region',
		metro_map: null,
		region: null,
		region_id: null,
		location: null,
	},
	{
		id: 48,
		uid: 'region-48',
		name: 'Новосибирск и область',
		type: 'region',
		metro_map: null,
		region: null,
		region_id: null,
		location: null,
	},
];

export const russia = {
	id: 0,
	uid: 'russia-0',
	name: 'Вся Россия',
	type: 'country',
	metro_map: null,
	region: null,
	region_id: null,
	location: { lat: 61.52401, lng: 105.318756 },
};
