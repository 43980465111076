import { useState } from 'react';
import { Placeholder, Badge } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { ImageModal } from 'components/product/ImageModal';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';

export const ProductItemName = observer(
	({ isLoading, used, brand, code, name, imageUrl }) => {
		const [showImage, setShowImage] = useState(false);

		return (
			<>
				{showImage && (
					<ImageModal
						title={`${brand} (${code}) ${name}`}
						imageUrl={imageUrl}
						show={showImage}
						onHide={() => setShowImage(false)}
					/>
				)}
				<div className="cell-container d-flex justify-start align-items-center flex-wrap">
					{!isLoading ? (
						<>
							<div className="product-name-text-container  w-100">
								<OverflowedTextTooltip
									text={`${brand} (${code})`}
									value={
										<div className="d-flex justify-start align-items-center flex-wrap">
											{used && (
												<Badge className="me-2" bg="danger">
													Б/у
												</Badge>
											)}
											{imageUrl && (
												<Badge
													onClick={() => setShowImage(true)}
													className="me-2 cursor-pointer"
													bg="success"
												>
													Фото
												</Badge>
											)}
											<div>
												<strong>{brand}</strong> ({code})
											</div>
										</div>
									}
								/>
							</div>
						</>
					) : (
						<div className="w-100">
							<Placeholder xs={6} />
							<br />
							<Placeholder xs={10} />
						</div>
					)}{' '}
					{!isLoading && (
						<>
							<div className="d-flex w-100 justify-content-between align-items-center">
								<OverflowedTextTooltip multiline text={`${name}`} />
							</div>
						</>
					)}
				</div>
			</>
		);
	}
);
