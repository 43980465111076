import { useCallback } from 'react';
import Select from 'react-select';
import { TWizard, TWizardOption } from 'types/catalog';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';

interface TProps {
	isDetermined?: boolean;
	label?: string;
	data: TWizard;
	selectsOptions: { [key: string]: string };
	onChange: (name: string, label: string, value: string) => void;
}

const labels: Record<string, string> = {
	'Vehicle Family': 'Модель',
	'Vehicle Type': 'Тип',
	Engine: 'Двигатель',
	Catalog: 'Каталог',
	Transmission: 'Трансмиссия',
	Body: 'Кузов',
	Market: 'Регион',
	Year: 'Год',
};

export const SelectModel = observer(
	({ isDetermined, label, data, onChange }: TProps) => {
		const { value, name, options } = data;

		const getlabel = label ?? labels[name] ?? name;

		const formatOptions = useCallback((options: TWizardOption[]) => {
			return options.map((i) => ({
				label: i.value as string,
				value: i.key as string,
			}));
		}, []);

		if (isDetermined) {
			return (
				<Form.Group className="mb-3" style={{ opacity: 0.8 }}>
					<Form.Control
						disabled={isDetermined}
						type="text"
						placeholder={value}
						value={value}
						className="ps-2"
					/>
				</Form.Group>
			);
		}

		return (
			<Select
				placeholder={name}
				inputValue={isDetermined ? value : ''}
				//@ts-ignore
				label={getlabel}
				name={name}
				controlShouldRenderValue={true}
				variant="filled"
				//@ts-ignore
				options={
					value
						? [{ label: value as string, value: value as string }]
						: formatOptions(options)
				}
				//@ts-ignore
				onChange={({ label, value }: { label: string; value: string }) =>
					onChange(name, label, value)
				}
			/>
		);
	}
);
