import { makeAutoObservable } from 'mobx';
import {
	postNote,
	fetchNote,
	updatePrice,
	deleteNote,
	patchNote,
	fetchPdf,
} from 'api/note/note';
import { TNote } from 'types/note';
import { toast } from 'react-toastify';
import { ButtonAlertLink } from 'components/common/ButtonAlertLink';
import { TCar } from 'types/car';
import { RootStore } from 'stores/RootStore';

export class NoteStore {
	isLoading = true;
	id: number;
	note: TNote[] = [];
	car: TCar = null;
	totalCostCash = 0;
	customerCost = 0;
	totalCostBank = 0;
	totalItems = 0;
	page = 1;
	itemsPerPage = 1000;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setQuantity = async (id: number, value: number) => {
		const noteIndex = this.note.findIndex((i) => i.id === id);

		const res = await patchNote(id, {
			carId: this.note[noteIndex].carsId,
			note: this.note[noteIndex].note,
			uniqId: this.note[noteIndex].uniqId,
			officeId: this.note[noteIndex].officeId,
			quantity: value,
			customerCost: this.note[noteIndex].customerCost,
		});
		if (res === null) {
			return false;
		}

		this.note[noteIndex] = res;

		this.getNote(this.note[noteIndex].carsId);

		return true;
	};

	downloadPdf = async (id: number) => {
		const res = await fetchPdf(id);
		return res ? res.blob() : null;
	};

	setPage = async (page: number) => {
		this.page = page;
		await this.getNote(this.id);
	};

	cancel = () => {
		this.isLoading = false;
		return null;
	};

	init = async (id: number) => {
		this.id = id;
		await this.getNote(id);
	};

	updateNotesPrice = async (ids: number[]) => {
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			const itemIndex = this.note.findIndex((i) => i.id === id);
			const res = await updatePrice(id);
			this.note[itemIndex] = res;
		}
	};

	deleteNotes = async (ids: number[]) => {
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			const itemIndex = this.note.findIndex((i) => i.id === id);
			await deleteNote(id);
			this.note.splice(itemIndex, 1);
		}

		toast.success('Удалено успешно', {
			theme: 'colored',
		});
	};

	editNote = async (id, { quantity, customerCost, note, name }) => {
		const noteIndex = this.note.findIndex((i) => i.id === id);

		const res = await patchNote(id, {
			carId: this.note[noteIndex].carsId,
			name,
			note,
			uniqId: this.note[noteIndex].uniqId,
			officeId: this.note[noteIndex].officeId,
			quantity,
			customerCost,
		});

		this.note[noteIndex] = res;

		this.getNote(this.note[noteIndex].carsId);
	};

	addToNote = async (uniqId: string, officeId: number, quantity: number) => {
		const id = await this.root.appStore.setActiveModal(
			'car_table',
			'middle-size',
			{
				mode: 'table',
			}
		);
		const note = await this.root.appStore.setActiveModal(
			'car_note',
			'middle-size'
		);

		const res = await postNote({
			carId: id as number,
			note: note as string,
			uniqId,
			officeId,
			quantity,
			customerCost: null,
		});

		if (res === null) return this.cancel();

		const { carsId } = res;

		toast.success(
			ButtonAlertLink({
				title: 'Товар успешно добавлен в блокнот',
				buttonText: 'Перейти в блокнот',
				link: '/car-notes/' + carsId,
			}),
			{
				theme: 'colored',
			}
		);
	};

	getNote = async (carId: number): Promise<TNote[]> => {
		this.isLoading = true;

		const res = await fetchNote(carId, {
			page: this.page,
			per_page: this.itemsPerPage,
		});

		if (res === null) return this.cancel();

		const {
			items,
			meta: { count, totalCostCash, totalCostBank, customerCost },
		} = res;

		this.note = items;
		this.totalItems = count;
		this.totalCostCash = totalCostCash;
		this.totalCostBank = totalCostBank;
		this.customerCost = customerCost;
		this.isLoading = false;
	};
}
