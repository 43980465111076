import { castError, defaultHeaders, merchantApiUrl } from 'api/helpers';
import { TDefaultPagination, TListReponse } from 'types/common';
import { TWarehouse } from 'types/warehouse';
import request from 'helpers/request';

export const fetchPriceLists = async (
	query_params: TDefaultPagination
): Promise<TListReponse<TWarehouse>> => {
	return request(
		fetch(merchantApiUrl(`price_lists`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
