import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CatalogSearchByVin } from './CatalogSearchByVin';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { AlphabetListCatalog } from './comp/AlphabetListCatalog';
import { useCatalogStore } from 'providers/RootStoreProvider';
import { CatalogSearchHistory } from './CatalogSearchHistory';

import 'styles/Catalog.scss';

export const Catalog = observer(() => {
	const { getListCatalog, alhabetListCatalog, isLoading } = useCatalogStore();

	useEffect(() => {
		if (!alhabetListCatalog.length) getListCatalog();
	}, []);

	return (
		<>
			<LoadingWrapper className="pt-5" isLoading={isLoading} size={48}>
				{!isLoading && (
					<div className="catalog-container">
						<AlphabetListCatalog />
						<CatalogSearchByVin />
					</div>
				)}
			</LoadingWrapper>
			<div className="mt-3">
				<CatalogSearchHistory />
			</div>
		</>
	);
});
