import { ApiError } from 'api/errors';
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

export interface IUseApi<T> {
	data: T | null;
	loading: boolean;
	loaded: boolean;
	error: Error | null;
	fetch: (...args: unknown[]) => unknown;
	clear: () => void;
	set: Dispatch<SetStateAction<T | null>>;
	progress: number | null;
}

export interface IUseApiOptions<T> {
	initialData?: T;
	onSuccess?: (data: T) => unknown;
	onFailure?: (error: ApiError) => unknown;
	onFinally?: () => void;
}

export type ApiFunction<T> = (...args: unknown[]) => Promise<T>;

// use hook useSimpleApiFetch if you don't need progress handling
function useApi<T>(
	api: ApiFunction<T>,
	params: IUseApiOptions<T> = {}
): IUseApi<T> {
	const isFirstRun = useRef<boolean>(true);
	const [data, setData] = useState<T | null>(params.initialData || null);
	const [loading, setLoading] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [progress, setProgress] = useState<number | null>(null);

	const fetch = useCallback(
		async (args) => {
			if (params.initialData && isFirstRun.current) {
				isFirstRun.current = false;
				return;
			}

			setLoading(true);
			setError(null);
			setProgress(0.01);
			try {
				const payload = await api(args);
				setData(payload);
				setLoaded(true);
				if (params.onSuccess) {
					params.onSuccess(payload);
				}
			} catch (e) {
				if (params.onFailure) {
					params.onFailure(e);
				}
			} finally {
				setProgress(null);
				setLoading(false);
				if (params.onFinally) {
					params.onFinally();
				}
			}
		},
		[api, params.onSuccess, params.onFailure, params.onFinally]
	);

	const clear = useCallback(() => {
		setData(null);
	}, [api]);

	return {
		data,
		loading,
		loaded,
		error,
		fetch,
		clear,
		set: setData,
		progress,
	};
}

export default useApi;
