import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export class AppStore {
	activeModal = '';
	activeModalSize = '';
	activeModalData = {};

	activeOffcanvas = '';
	offcanvasClassName = '';
	isMenuOpen = window.innerWidth > 1440;
	isWideDesktop = window.innerWidth > 1440;
	isMobile = window.innerWidth < 768;
	isTablet = window.innerWidth < 1200;
	history: string[] = [];

	resolve_modal_promise: (result: unknown) => void;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
		this.isMenuOpen = Boolean(localStorage.getItem('isMenuOpen'));
	}

	addHistory = (pageUrl) => {
		this.history.unshift(pageUrl);
	};

	onResize = (width: number) => {
		if (width > 1440) {
			this.isWideDesktop = true;
			!this.isMenuOpen && this.setMenuOpen(true);
		} else {
			// this.isMenuOpen && this.setMenuOpen(false);
			this.isWideDesktop = false;
		}

		if (width < 1200) {
			this.isTablet = true;
			// this.isMenuOpen && this.setMenuOpen(false);
		} else {
			this.isTablet = false;
		}

		if (width < 768) {
			this.isMobile = true;
			// this.isMenuOpen && this.setMenuOpen(false);
		} else {
			this.isMobile = false;
		}
	};

	setMobile = (state: boolean) => {
		this.isMobile = state;
	};

	setTablet = (state: boolean) => {
		this.isTablet = state;
	};

	setMenuOpen = (state: boolean) => {
		state
			? localStorage.setItem('isMenuOpen', 'true')
			: localStorage.removeItem('isMenuOpen');
		this.isMenuOpen = state;
	};

	setActiveModal = async (
		modalName: string,
		size?: 'xl-size' | 'middle-size' | 'small-size',
		data?: unknown
	): Promise<unknown> => {
		this.activeModal = modalName;
		this.activeModalSize = size || 'middle-size';
		this.activeModalData = data;

		return new Promise((resolve) => {
			this.resolve_modal_promise = (result) => {
				this.resolve_modal_promise = null;
				if (result !== undefined) resolve(result);
			};
		});
	};

	setActiveOffcanvas = (offcanvas_name: string) => {
		this.activeOffcanvas = offcanvas_name;
	};

	setOffcanvasClassName = (offcanvas_className: string) => {
		this.offcanvasClassName = offcanvas_className;
	};

	closeOffcanvas = () => {
		this.activeOffcanvas = '';
	};

	closeModal = (modalResponse?: unknown) => {
		this.activeModal = '';
		this.activeModalData = {};
		this.root.appStore.resolve_modal_promise &&
			this.root.appStore.resolve_modal_promise(modalResponse);
	};
}
