import { makeAutoObservable } from 'mobx';
import { TVendor } from 'types/vendor';
import { fetchVendors, enableVendor, disableVendor } from 'api/vendor/vendor';
import { RootStore } from 'stores/RootStore';

export class VendorStore {
	isLoading = true;
	vendors: TVendor[] = [];

	page = 1;
	totalItems = 0;
	itemsPerPage = 25;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	enable = async (id: number) => {
		await enableVendor(id);
		this.init();
	};

	disable = async (id: number) => {
		await disableVendor(id);
		this.init();
	};

	init = async () => {
		this.isLoading = true;
		const res = await fetchVendors({
			per_page: this.itemsPerPage,
			page: this.page,
		});
		if (res === null) return;

		this.vendors = res;
		// this.totalItems = count;

		this.isLoading = false;
	};
}
