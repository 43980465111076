import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { TWizardVehicleAttributes, TWizardVehicle } from 'types/catalog';

type TProps = {
	name: string;
	data: TWizardVehicle[];
	defaultAttributes: TWizardVehicleAttributes[];
};

type TRowData = {
	row: { original: TWizardVehicleAttributes };
};

export const CatalogTable = observer(
	({ name, data, defaultAttributes }: TProps) => {
		const { brand, code } = useParams();
		const [columns, setColumns] = useState([]);
		const [list, setList] = useState<TWizardVehicleAttributes[]>([]);
		const navigate = useNavigate();

		const formatColumns = useCallback(() => {
			return defaultAttributes.map(({ name, value }, index) => {
				return TableColumn({
					header: name,
					width: Math.min(Math.max(value.length * 8, 150), 200),
					cellClassName: 'py-2 cursor-pointer',
					cell: (rowData: TRowData) => {
						const data = rowData.row.original[index];

						if (!data) return null;

						return (
							<div
								onClick={() =>
									navigate(
										`/catalog-model/quicklist/${brand}/${code}/${data.ssd}/${data.vehicleid}/2`
									)
								}
								className="w-100 pe-4"
							>
								<OverflowedTextTooltip
									text={data.value}
									focus={true}
									placement="bottom"
								/>
							</div>
						);
					},
				});
			});
		}, [defaultAttributes, brand, code]);

		const formatList = useCallback(
			(list: TWizardVehicle[]) => {
				return list.reduce((result, { attributes, vehicleid, ssd }) => {
					const attrs = attributes.reduce((result, item, index) => {
						if (item.key !== defaultAttributes[index].key) {
							result.push({ ...defaultAttributes[index], value: '---' });
						}
						result.push({ ...item, vehicleid, ssd });

						return result;
					}, []);
					return [...result, attrs];
				}, []);
			},
			[defaultAttributes]
		);

		useEffect(() => {
			if (data && !list.length) {
				setList(formatList(data));
				setColumns(formatColumns());
			}
		}, [data]);

		return (
			<Table
				isLoading={false}
				data={list.length > 0 ? list : []}
				pagination={false}
				columns={columns}
				title={name}
				contained={true}
				tableMinHeight={'0px'}
				footerClassName={'p-0'}
				footer={<></>}
			/>
		);
	}
);
