import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { setItemToStore, getItemFromStore, clearData } from 'helpers/utils';
import { User } from './user/User';
import { Support } from './user/Support';
import {
	fetchUserMe,
	signInFetch,
	signUpFetch,
	fetchSupport,
} from 'api/profile';
import { TSignInRequestPayload, TSignUpRequestPayload } from 'api/types/user';
import { RootStore } from './RootStore';

export class AuthStore {
	isAuthorized = false;
	isAuthLoaded = false;
	user: User = null;
	support: Support = null;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	getSupport = async () => {
		this.support = new Support({});
		const res = await fetchSupport();
		if (res === null) return false;
		this.support = new Support(res);
	};

	signUp = async (formData: TSignInRequestPayload) => {
		const res = await signUpFetch(formData);
		if (res === null) return false;
		return true;
		// storage.setAccessToken(res.token);
		// await this.initProfile();

		// toast.success(`Успешно, ${formData.name}!`, {
		// theme: 'colored',
		// });
	};

	signIn = async (formData: TSignUpRequestPayload) => {
		const signin_res = await signInFetch(formData);
		if (signin_res === null) return;

		setItemToStore('token', signin_res.token);
		this.isAuthorized = true;

		const profile_res = await this.initMe();
		if (profile_res === null) return;

		toast.success(
			`Вы успешно авторизовались, ${profile_res?.name || profile_res?.email}!`,
			{
				theme: 'colored',
			}
		);

		this.initProfile();
	};

	signOut = () => {
		this.user = null;
		this.isAuthorized = false;
		this.isAuthLoaded = true;
		clearData('token');

		this.root.cartStore.reset();
		this.root.savedProductsStore.reset();
	};

	initProfile = async () => {
		this.root.savedProductsStore.init();
		this.root.companyStore.init();
		this.root.cartStore.initCart();
	};

	activateJivosite = () => {
		try {
			const scriptTag = document.createElement('script');
			scriptTag.src = '//code.jivo.ru/widget/Pxs9e5wejD';
			document.head.appendChild(scriptTag);
		} catch (error) {
			console.log(error);
		}
	};

	setToken = (token: string) => {
		setItemToStore('token', token);
	};

	initMe = async () => {
		const res = await fetchUserMe();
		if (res === null) {
			this.signOut();
			return;
		}
		// if (!res.company_id) {
		// 	this.signOut();

		// 	toast.warning(`Нет доступа`, {
		// 		theme: 'colored',
		// 	});

		// 	return;
		// }

		this.user = new User(this.root, res);
		if (res.partner) this.activateJivosite();

		this.isAuthLoaded = true;
		return res;
	};

	init = async () => {
		const token = getItemFromStore('token');
		if (!token) {
			this.isAuthLoaded = true;
			return;
		}
		this.isAuthorized = true;
	};
}
