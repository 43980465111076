import { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { TCar } from 'types/car';

type TProps = {
	car: TCar;
	handleDelete: () => void;
	handleEdit: () => void;
	handleDownload?: () => Promise<void>;
};

export const CarItemControl = observer(
	({ car, handleDelete, handleEdit, handleDownload }: TProps) => {
		const [isPending, setPending] = useState(false);
		const isDisabled = !car;

		const download = useCallback(async () => {
			setPending(true);
			await handleDownload();
			setPending(false);
		}, [car]);

		return (
			<div className="car-control d-flex justify-content-end align-items-center flex-row">
				{handleDownload && (
					<Button
						size="sm"
						className="white-space-nowrap border me-2 d-flex justify-content-center align-items-center"
						variant={'light'}
						onClick={download}
						disabled={isDisabled}
					>
						Скачать PDF
						<LoadingWrapper isLoading={isPending} className="ms-2" size={14}>
							<MaterialIcon name="file_download" className="ms-2 fs-16" />
						</LoadingWrapper>
					</Button>
				)}
				<Button
					size="sm"
					className="border me-2 d-flex justify-content-center"
					variant={'light'}
					onClick={handleEdit}
					disabled={isDisabled}
				>
					<MaterialIcon name="edit" className="" />
				</Button>
				<Button
					size="sm"
					variant={'light'}
					className="border d-flex justify-content-center"
					onClick={handleDelete}
					name="delete"
					disabled={isDisabled}
				>
					<MaterialIcon name="delete" className="" />
				</Button>
			</div>
		);
	}
);
