import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Table, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { fetchCompaniesInfo } from 'api/companies/companies';
import { fetchTasksWithId } from 'api/tasks/tasks';
import CountUp from 'react-countup';
import classNames from 'classnames';
import { TCompaniesInfo } from 'types/companies';
import { TTasks } from 'types/tasks';
import { CompanyTasks } from './comp/CompanyTasks';
import Background from 'components/common/Background';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-3.png';
import dayjs from 'dayjs';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { useAppStore } from 'providers/RootStoreProvider';

export const CompaniesInfo = observer(() => {
	const { setActiveModal } = useAppStore();
	const { id: companyId } = useParams();
	const [info, setInfo] = useState<TCompaniesInfo>();
	const [isLoading, setIsLoading] = useState(true);
	const [tasks, setTasks] = useState<TTasks[]>();

	useEffect(() => {
		getCompaniesInfo();
		getTasksInfo();
	}, []);

	const getCompaniesInfo = useCallback(async () => {
		const info = await fetchCompaniesInfo(+companyId);
		setInfo(info);
		setIsLoading(false);
	}, [companyId]);

	const getTasksInfo = useCallback(async () => {
		const tasks = await fetchTasksWithId({ company_id: +companyId });
		setTasks(tasks.items);
	}, [companyId]);

	const handleCreateTask = useCallback(async () => {
		await setActiveModal('create_task');
	}, []);

	return (
		<LoadingWrapper isLoading={isLoading} size={48}>
			{!isLoading && (
				<>
					<Card className="mb-3">
						<Card.Body className="position-relative ">
							<div className="d-flex justify-content-between">
								<div className="d-flex flex-column">
									<h4 className="mb-0">{info.company.name}</h4>
									<div className="fs-15">{info.company.address}</div>
									<div className="fs-15">
										Дата регистрации:{' '}
										{dayjs(info.company.createdAt).format('DD/MM/YY HH:MM')}
									</div>
								</div>
								<div className="fs-15 fw-semi-bold">ID {info.company.id}</div>
							</div>
						</Card.Body>
					</Card>
					<div className="d-flex flex-row justify-content-between align-items-start mb-3">
						<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
							<Background image={bg1} className="bg-card" />
							<Card.Body className="position-relative">
								<h6>Количество заказов:</h6>
								<div
									className={classNames(
										'text-warning',
										'display-4 fs-4 mb-2 fw-normal font-sans-serif'
									)}
								>
									<CountUp
										start={0}
										end={info.orderItemsCount}
										duration={2.3}
										suffix={''}
										prefix={''}
										separator=","
										decimals={0}
										decimal="."
									/>
								</div>
							</Card.Body>
						</Card>
						<Card className={classNames('overflow-hidden', 'w-100')}>
							<Background image={bg2} className="bg-card" />
							<Card.Body className="position-relative">
								<h6>Количество поисков:</h6>
								<div
									className={classNames(
										'text-success',
										'display-4 fs-4 mb-2 fw-normal font-sans-serif'
									)}
								>
									<CountUp
										start={0}
										end={info.searchHistoriesCount}
										duration={2.3}
										suffix={''}
										prefix={''}
										separator=","
										decimals={0}
										decimal="."
									/>
								</div>
							</Card.Body>
						</Card>
					</div>
					<Card className="p-4 mb-3">
						<Table>
							<thead className="bg-200">
								<tr>
									<th className="py-2 fs--1">ФИО</th>
									<th className="py-2 fs--1">Номер телефона</th>
									<th className="py-2 fs--1">Email</th>
								</tr>
							</thead>
							<tbody>
								{info.users.length ? (
									info.users.map((user) => (
										<tr key={`${user.id}`}>
											<td className="py-2 fs--1">{user.name}</td>
											<td className="py-2 fs--1">{user.phone}</td>
											<td className="py-2 fs--1">{user.email}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan={5} style={{ textAlign: 'center' }}>
											Нет пользователей
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Card>
					<Card>
						<Card.Header>
							<div className="d-flex justify-content-between">
								<h4 className="mb-0">Список задач</h4>
								<Button onClick={handleCreateTask}>Создать задачу</Button>
							</div>
						</Card.Header>
						<hr />
						<Card.Body>
							<CompanyTasks tasks={tasks} />
						</Card.Body>
					</Card>
				</>
			)}
		</LoadingWrapper>
	);
});
