import axiosLib, { AxiosRequestConfig } from 'axios';
import { storage } from 'utils/storage';
import { apiConfig, env } from './api-config';
import { ServerErrorHandler } from 'helpers/ServerErrorHandler';

const getAxiosInstance = () => {
	const instanceConfig: AxiosRequestConfig = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	if (env === 'development') {
		instanceConfig.proxy = {
			host: apiConfig.serverUrl,
			port: 80,
		};
	}
	return axiosLib.create(instanceConfig);
};

const axios = getAxiosInstance();

axios.interceptors.request.use((request) => {
	if (!request.headers) {
		request.headers = {};
	} else {
		request.headers['X-Authorization'] = storage.getAccessToken();
		request.headers['Authorization'] = storage.getAccessToken();
	}
	return request;
});

axios.interceptors.response.use(
	({ data }) => data,
	(err) => {
		return ServerErrorHandler(err.response.data);
	}
);

export { axios };
