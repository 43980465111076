import React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { TWarehouse } from 'types/warehouse';

type TProps = {
	data: TWarehouse;
	// handleDelete: (id: number) => void;
	// handleEdit: (id: number) => void;
	handleClick: () => void;
};

export const ListItem = observer(({ data, handleClick }: TProps) => {
	const { name, active_price_list_version } = data;

	return (
		<div className="d-flex justify-content-between align-items-center flex-row">
			<div
				onClick={handleClick}
				className="car-info w-100 d-flex justify-content-flex-start flex-column"
			>
				<div className="fs-16 fw-semi-bold">{name}</div>
				<div className="fs-13">
					Количество позиций: {active_price_list_version.total}
				</div>
				<div className="fs-13">
					Дата обновления:{' '}
					{dayjs(active_price_list_version.created_at).format('DD/MM/YY HH:MM')}
				</div>
			</div>
		</div>
	);
});
