import { observer } from 'mobx-react-lite';
import { Button, Form, Modal } from 'react-bootstrap';
import { TCarPayload } from 'types/car';
import { Formik } from 'formik';
import { useCarStore } from 'providers/RootStoreProvider';

type TProps = {
	modalData: unknown;
	close: (id: number) => void;
};

type TModalData = {
	initialData: TCarPayload;
};

export const CarFormModal = observer(({ modalData = {}, close }: TProps) => {
	const { addToCars, updateCar } = useCarStore();
	const { initialData } = modalData as TModalData;

	return (
		<Formik
			initialValues={
				initialData
					? initialData
					: {
							name: '',
							carNumber: '',
							vin: '',
							year: null,
							model: '',
							customerCost: null,
					  }
			}
			enableReinitialize
			onSubmit={async (values, actions) => {
				const { id } = initialData
					? await updateCar(initialData.id, values)
					: await addToCars(values);
				close(id);
				actions.resetForm();
			}}
		>
			{({ values, handleChange, handleSubmit, isValid }) => (
				<Form
					onSubmit={async (e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Проценка
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="pb-3 ps-2">
							<Form.Group>
								<Form.Label>Название</Form.Label>
								<Form.Control
									placeholder="Название проценки"
									value={values.name}
									name="name"
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Модель автомобиля</Form.Label>
								<Form.Control
									placeholder="Модель автомобиля"
									value={values.model}
									name="model"
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Гос. номер</Form.Label>
								<Form.Control
									placeholder="Гос. номер"
									value={values.carNumber}
									name="carNumber"
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>VIN Номер</Form.Label>
								<Form.Control
									placeholder="VIN Номер"
									value={values.vin}
									name="vin"
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Год</Form.Label>
								<Form.Control
									type="number"
									placeholder="Год"
									value={values.year}
									name="year"
									onChange={handleChange}
								/>
							</Form.Group>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button disabled={!isValid} variant="primary" type="submit">
							{!initialData ? 'Добавить' : 'Сохранить'}
						</Button>
					</Modal.Footer>
				</Form>
			)}
		</Formik>
	);
});
