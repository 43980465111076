import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { TListDetailByUnit } from 'types/catalog';
import { PopoverPointer } from './PopoverPointer';

type TProps = {
	data: {
		code: string;
		x1: string;
		y1: string;
		x2: string;
		y2: string;
		brand: string;
	};
	getRelatedCoordX: (num: string) => number;
	getRelatedCoordY: (num: string) => number;
	hovered: TListDetailByUnit[];
	openInNewTab: (oem: string) => void;
	onHoverId: (id: string) => void;
};

export const ImagePointer = observer(
	({
		data: { code, x1, y1, x2, y2, brand },
		hovered,
		getRelatedCoordX,
		getRelatedCoordY,
		openInNewTab,
		onHoverId,
	}: TProps) => {
		const [coords, setCoords] = useState({
			x: 0,
			y: 0,
			z: 1000,
		});
		const [size, setSize] = useState({ width: 15, height: 15 });
		const isActive = hovered.length && code === hovered[0].codeonimage;

		const update = useCallback(() => {
			setCoords({
				x: getRelatedCoordX(x1),
				y: getRelatedCoordY(y1),
				z: 1000,
			});
			setSize({
				width: (getRelatedCoordX(x2) - getRelatedCoordX(x1)) * 1.15,
				height: (getRelatedCoordX(y2) - getRelatedCoordX(y1)) * 1.15,
			});
		}, [x1, y1, x2, y2]);

		const resetCoords = useCallback(() => {
			setCoords({
				x: coords.x,
				y: coords.y,
				z: -1,
			});
		}, [coords]);

		const handleResized = useDebouncedCallback(() => update(), 200);
		const handleStartResizing = useDebouncedCallback(() => resetCoords(), 200, {
			leading: true,
			trailing: false,
		});

		useEffect(() => {
			setTimeout(() => {
				update();
			}, 0);
		}, []);

		useEffect(() => {
			window.addEventListener('resize', () => {
				handleResized();
				handleStartResizing();
			});
			return () => {
				window.removeEventListener('resize', () => {
					handleResized();
					handleStartResizing();
				});
			};
		}, [handleResized, handleStartResizing]);

		return (
			<>
				<div
					className={`list-image-map-item cursor-pointer ${
						isActive ? 'active ' : ''
					}`}
					onClick={(e) => {
						// @ts-ignore
						if (!e.target.closest('.tooltip-container-list-item')) {
							onHoverId(isActive ? null : code);
						}
					}}
					style={{
						position: 'absolute',
						left: `${coords.x}px`,
						top: `${coords.y}px`,
						height: `${size.height}px`,
						width: `${size.width}px`,
						zIndex: coords.z,
					}}
				></div>
				{!!hovered.length && (
					<PopoverPointer
						size={size}
						coords={coords}
						isActive={isActive}
						list={hovered.map((i) => ({ name: i.name, oem: i.oem, brand }))}
						onClick={openInNewTab}
					/>
				)}
			</>
		);
	}
);
