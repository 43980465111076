import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Tab, Tabs } from 'react-bootstrap';

type TProps = {
	tabsList: { key: string; label: string }[];
	children: React.ReactNode[];
	activeKey?: string;
	handleChange?: (tab: string) => void;
};

export const TabWrapper = observer(
	({ activeKey, tabsList, children, handleChange }: TProps) => {
		const [activeTab, setActiveTab] = useState(
			activeKey ? activeKey : tabsList[0].key
		);
		return (
			<>
				<div className="tab-wrapper">
					<Tabs
						activeKey={activeKey ? activeKey : activeTab}
						className="mb-3"
						onSelect={(tab) =>
							handleChange ? handleChange(tab) : setActiveTab(tab)
						}
					>
						{tabsList.map(({ label, key }, i) => {
							return (
								<Tab key={`tab_${key}_${i}`} eventKey={key} title={label}>
									{children[i]}
								</Tab>
							);
						})}
					</Tabs>
				</div>
			</>
		);
	}
);
