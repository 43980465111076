import { useState } from 'react';
import imagePlaceholderIcon from 'assets/img/icons/image-placeholder-icon.svg';
import { observer } from 'mobx-react-lite';
import { ImageModal } from 'components/product/ImageModal';
import { TCoreProduct } from 'types/product';
import { ProductSingleImage } from 'components/common/ProductSingleImage';

export const ProductImage = observer(
	({ coreProduct }: { coreProduct: TCoreProduct }) => {
		const source = coreProduct?.core_product_images;
		const [showImage, setShowImage] = useState([]);

		return (
			<>
				{showImage && (
					<ImageModal
						title={`${coreProduct.core_brand.name} (${coreProduct.number}) ${coreProduct.name}`}
						images={source.slice(0, 100).map((photo) => photo.image_url)}
						show={showImage.length > 0}
						onHide={() => setShowImage([])}
					/>
				)}
				{source && source.length ? (
					<ProductSingleImage
						onClick={() => setShowImage(source)}
						image={source[0].image_url}
					/>
				) : (
					<div className="image-placeholder">
						<img src={imagePlaceholderIcon} alt="" />
					</div>
				)}
			</>
		);
	}
);
