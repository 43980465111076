import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import { AdminModalForm } from './comp/AdminModalForm';
import { TAdminCompanyPayload } from 'types/adminCompanies';
import { useAdminCompaniesStore } from 'providers/RootStoreProvider';

type TProps = {
	close: (result?: unknown) => void;
};

export const AdminCompaniesModal = observer(({ close }: TProps) => {
	const {
		activeId,
		getCompanyById,
		updateCompany,
		managers,
		getAdminManagers,
	} = useAdminCompaniesStore();
	const [initialData, setInitialData] = useState(null);

	useEffect(() => {
		const getInitialData = async () => {
			const company = await getCompanyById(activeId);
			company && setInitialData(company);
		};

		getInitialData();
		getAdminManagers();
	}, []);

	const handleEditSubmit = async (payload: TAdminCompanyPayload) => {
		await updateCompany(activeId, payload);
		close();
	};

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Компания</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<AdminModalForm
					initialData={initialData}
					onSubmit={handleEditSubmit}
					managers={managers}
				/>
			</Modal.Body>
		</>
	);
});
