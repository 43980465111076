import { makeAutoObservable } from 'mobx';
import {
	TOrder,
	TOrderItems,
	TOrderPartner,
	TOrderPartnerTableItem,
} from 'types/order';
import {
	fetchOrders,
	cancelOrder,
	fetchOrder,
	fetchPDF,
	fetchPDFTemporary,
	fetchPartnerOrders,
	docCreate,
	patchStatusPartnerItem,
	fetchBrokerPDF,
	patchOrderNote,
	fetchXLS,
	returnOrder,
} from 'api/order/order';
import { toast } from 'react-toastify';
import { RootStore } from 'stores/RootStore';

export class OrderStore {
	isLoading = true;
	orders: TOrder[] = [];
	partnerOrders: TOrderPartner[] = [];
	partnerOrdersSplitted: TOrderPartnerTableItem[] = [];
	page = 1;
	pageBroker = 1;
	totalCost = 0;
	totalItems = 0;
	partnerTotalItems = 0;
	itemsPerPage = 25;
	orderfilterStatus = null;
	orderItemsfilterStatus = 1;

	ORDER_STATUS_KEYS = {
		in_work: [1, 2, 3, 5],
		issued: [4],
		canceled: [6],
	};

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	downloadXLS = async (filters) => {
		const res = await fetchXLS({
			order_item_statuses: filters.status,
			api_data_note: filters.comment,
			product_name: filters.name,
			cash: true,
			...filters,
		});

		return res ? res.blob() : null;
	};

	editNote = async (id, note) => {
		const res = await patchOrderNote(id, note);

		if (res === null) return false;

		toast.success(`Примечание обновлено`, {
			theme: 'colored',
		});

		this.updateListOrders({});
		return true;
	};

	downloadBrokerPdf = async (id: number) => {
		const res = await fetchBrokerPDF(id);
		return res ? res.blob() : null;
	};

	changeStatusPartnerItem = async (
		order_items_id: number[],
		status: number
	) => {
		const res = await patchStatusPartnerItem(order_items_id, status);

		if (res === null) return;

		toast.success(`Заказ обновлён`, {
			theme: 'colored',
		});

		return res;
	};

	setFilterStatus = (status: number) => {
		this.orderfilterStatus = this.orderfilterStatus !== status ? status : null;
		this.updateListPartnerOrders({});
	};

	setItemsFilterStatus = (status: number) => {
		this.orderItemsfilterStatus =
			this.orderItemsfilterStatus !== status ? status : 1;
		this.updateListPartnerOrders({});
	};

	docCreate = async (order_items_id: number[]) => {
		const res = await docCreate(order_items_id);
		if (res === null) return;

		toast.success(`Заказ обновлён`, {
			theme: 'colored',
		});

		return res;
	};

	downloadPDF = async (orderId: number, docType: string) => {
		const res = await fetchPDF(orderId, docType);
		return res ? res.blob() : null;
	};

	downloadPDFTemporary = async (orderId: number, docType: string) => {
		const res = await fetchPDFTemporary(orderId, docType);
		return res ? res.blob() : null;
	};

	getOrder = async (carId: number): Promise<TOrderItems> => {
		const res = await fetchOrder(carId);

		if (res === null) return;

		return res;
	};

	returnOrder = async (payload: { order_id: number }) => {
		const res = await returnOrder(payload);

		if (res === null) {
			this.isLoading = false;
			return;
		}

		toast.success(`Заявка на возврат отправлена`, {
			theme: 'colored',
		});

		this.updateListOrders({});
		return true;
	};

	cancelOrder = async (payload: { order_id: number }) => {
		const res = await cancelOrder(payload);

		if (res === null) {
			this.isLoading = false;
			return;
		}

		toast.success(`Заказ отменен`, {
			theme: 'colored',
		});

		this.updateListOrders({});
		return true;
	};

	setPage = (page: number) => {
		this.page = page;
		this.updateListOrders({});
	};

	setPageBroker = (page: number) => {
		this.pageBroker = page;
		this.updateListPartnerOrders({});
	};

	updateListOrders = async ({
		resetPage,
		status,
		from,
		to,
		comment,
		code,
		brand,
		name,
		cash,
		order_id,
	}: {
		resetPage?: boolean;
		status?: number;
		from?: string;
		to?: string;
		comment?: string;
		code?: string;
		brand?: string;
		name?: string;
		cash?: boolean;
		order_id?: number;
	}) => {
		if (resetPage) this.page = 1;

		const res = await fetchOrders({
			per_page: this.itemsPerPage,
			page: this.page,
			...(status && { order_item_statuses: status }),
			...(from && { from }),
			...(code && { code }),
			...(brand && { brand }),
			...(name && { product_name: name }),
			...(cash !== null && { cash }),
			...(to && { to }),
			...(comment && { api_data_note: comment }),
			...(order_id && { order_id: order_id }),
		});

		if (res === null) {
			this.isLoading = false;
			return;
		}

		const {
			items,
			meta: { count },
			totalCost,
		} = res;

		this.orders = items;
		this.totalItems = count;
		this.totalCost = totalCost;

		this.isLoading = false;
	};

	formatParnersOrders = (data: TOrderPartner[]) => {
		return data.reduce((result, item) => {
			result = [...result, { ...item, separator: true }, ...item.items];
			return result;
		}, []);
	};

	updateListPartnerOrders = async ({ resetPage }: { resetPage?: boolean }) => {
		if (resetPage) this.page = 1;

		const res = await fetchPartnerOrders(
			this.orderfilterStatus,
			this.orderItemsfilterStatus,
			this.pageBroker,
			this.itemsPerPage
		);

		if (res === null) {
			this.isLoading = false;
			return;
		}

		const {
			data,
			meta: { count },
		} = res;

		this.partnerOrders = data;
		this.partnerOrdersSplitted = this.formatParnersOrders(data);
		this.partnerTotalItems = count;

		this.isLoading = false;
	};

	initPartners = async () => {
		await this.updateListPartnerOrders({});
	};

	update = async (filters: {
		status: number;
		from?: string;
		to?: string;
		comment?: string;
	}) => {
		await this.updateListOrders({ ...filters, resetPage: true });
	};
}
