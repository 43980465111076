import { castError, b2bApiUrl, defaultHeaders } from 'api/helpers';
import { TPresetFilterItem } from 'types/filter';
import { TAnalogBrand, TMetroStation } from 'types/product';
import request from 'helpers/request';

export const fetchFilterPresets = async (): Promise<TPresetFilterItem[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/search_filter`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const activateFilterPreset = async (
	searchFilterId
): Promise<TPresetFilterItem> => {
	return request(
		fetch(b2bApiUrl(`bayer/search_filter/activate/${searchFilterId}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const toggleListItemFilterPreset = async (
	payload: TPresetFilterItem
): Promise<TPresetFilterItem> => {
	return request(
		fetch(b2bApiUrl(`bayer/search_filter`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const updateFilterPreset = async (
	payload: TPresetFilterItem
): Promise<TPresetFilterItem> => {
	return request(
		fetch(b2bApiUrl(`bayer/search_filter/${payload.id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const deleteFilterPreset = async (
	id: number
): Promise<TPresetFilterItem> => {
	return request(
		fetch(b2bApiUrl(`bayer/search_filter/${id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const analogsBrandFetch = async (params): Promise<TAnalogBrand[]> => {
	return request(
		fetch(b2bApiUrl(`common/core_products/analogs-brands`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchMetroStations = async (
	location: 'city' | 'region',
	id: number
): Promise<TMetroStation[]> => {
	return request(
		fetch(b2bApiUrl(`common/metro-stations/${location}`, { id }), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
