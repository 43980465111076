import { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { TShopFavorite } from 'types/shop';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import {
	useFavoriteShopStore,
	useShopStore,
} from 'providers/RootStoreProvider';

type TRowData = { row: { original: TShopFavorite } };

export const SavedShopTable = observer(() => {
	const {
		isLoading,
		favoriteShops,
		init,
		deleteFavoriteShop,
		itemsPerPage,
		page,
		setPage,
		totalItems,
	} = useFavoriteShopStore();
	const { activateShop } = useShopStore();

	useEffect(() => {
		init();
	}, []);

	const columns = [
		TableColumn({
			header: 'Название',
			width: 350,
			headerClassName: 'pe-1',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { name, id } = rowData.row.original;

				const onShopName = () => activateShop(id);

				return (
					<div
						onClick={onShopName}
						className="w-100 link-type-text me-2 d-flex flex-row justify-start align-items-start"
					>
						<OverflowedTextTooltip text={name} />{' '}
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Город',
			width: 300,
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { city } = rowData.row.original;
				return <>{city}</>;
			},
		}),
		TableColumn({
			width: 150,
			header: 'Дата добавления',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { createdAt } = rowData.row.original;
				return <>{dayjs(createdAt).format('DD/MM/YY HH:MM')}</>;
			},
		}),
		TableColumn({
			width: 100,
			cellStyle: {
				alignItems: 'flex-end',
			},
			cell: (rowData: TRowData) => {
				const { id } = rowData.row.original;

				const handleDelete = async (id: number) => {
					await deleteFavoriteShop(id);
				};

				return (
					<div className="py-1">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item onClick={() => handleDelete(id)}>
									Удалить
								</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
				);
			},
		}),
	];

	return (
		<Table
			isLoading={isLoading}
			data={favoriteShops}
			totalItems={totalItems}
			itemsPerPage={itemsPerPage}
			setPage={setPage}
			page={page}
			columns={columns}
			title="Cохраненные магазины"
			contained={true}
		/>
	);
});
