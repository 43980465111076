import { makeAutoObservable } from 'mobx';
import { searchProductsPartnersGettzap } from 'api/product/products';
import dayjs from 'dayjs';
import { RootStore } from 'stores/RootStore';

export class GettzapProductStore {
	DEFAULT = {
		total: 0,
		page: 0,
		products: [],
		isLoading: true,
	};

	total = 0;
	page = 0;
	products = [];
	filteredProducts = [];
	isLoading = true;
	isApiSearch = false;

	filter = {
		costInvert: {
			value: null,
			key: 'b2bCostCash',
			invert: true,
		},
		cost: {
			value: null,
			key: 'b2bCostCash',
			invert: false,
		},
		stock: {
			value: null,
			key: 'stock',
			invert: false,
		},
	};

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setApiSearch = (value: boolean) => {
		this.isApiSearch = value;
		this.update(
			this.root.productStore.productSearchPayload([
				'brands',
				'metro_stations',
				'company_id',
			])
		);
	};

	isFiltered = () => {
		return Object.values(this.filter).find((i) => i.value !== null);
	};

	updateFilterStock = (value: number) => {
		this.setFilter('stock', value);
	};

	updateFilterSort = ({ value, format }: { value: string; format: string }) => {
		this.filteredProducts = this.products.sort((a, b) => {
			if (format === 'date') {
				return dayjs(a[value]).isAfter(b[value]) ? 1 : -1;
			}
			return a[value] - b[value];
		});
	};

	updateFilterCost = (cost: number, costInvert: number) => {
		this.filter['cost'].value = cost ? cost : null;
		this.filter['costInvert'].value = costInvert ? costInvert : null;
		this.filterNumbers();
	};

	setFilter = (key: string, value: number) => {
		this.filter[key].value = value ? value : null;
		this.filterNumbers();
	};

	filterNumbers = () => {
		this.isLoading = true;

		setTimeout(() => {
			this.filteredProducts = this.products.filter((item) => {
				return Object.values(this.filter).reduce(
					(result, { value, key, invert }) => {
						if (!result) return false;
						if (!value) return true;
						if (invert) return item[key] <= parseInt(value);
						return item[key] >= parseInt(value);
					},
					true
				);
			});
			this.isLoading = false;
		}, 300);
	};

	reset = () => {
		Object.keys(this.DEFAULT).map((key) => (this[key] = this.DEFAULT[key]));
	};

	setPage = (value) => {
		this.page = value;
	};

	update = async (payload) => {
		this.isLoading = true;

		const res = await searchProductsPartnersGettzap({
			...payload,
			page: this.page,
			api: this.isApiSearch,
		});

		if (res === null) return;

		const { data, meta } = res;

		const formattedData = data.map((item) => ({ ...item, time: meta.time }));
		this.products = this.root.productStore.splitRegions(formattedData);
		this.total = meta ? meta.count : 0;
		this.isLoading = false;
	};
}
