import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { BillingTable } from './comp/BillingTable';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { useAppStore, useBillingStore } from 'providers/RootStoreProvider';

export const Billing = observer(() => {
	const { sendBill } = useBillingStore();
	const { setActiveModal } = useAppStore();

	const handleButton = useCallback(() => {
		setActiveModal('modal_form', 'middle-size', {
			add: sendBill,
			initialData: null,
			fields: [{ name: 'amount', label: 'Сумма' }],
			submitButtonText: 'Отправить',
		});
	}, []);

	return (
		<div className="company-billing">
			<Card className="p-4">
				<BillingTable />
				<div className="mt-4">
					<Button
						variant="primary"
						className="button-download"
						size="sm"
						onClick={handleButton}
					>
						Выставить счёт
					</Button>
				</div>
			</Card>
		</div>
	);
});
