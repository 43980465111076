import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import { PlaceholderWrapper } from 'components/common/PlaceholderWrapper';
import { TVehicleInfo } from 'types/catalog';

type TProps = {
	vehicleInfo: TVehicleInfo[];
	isLoading: boolean;
};

type TimageResponse = {
	default: string;
	twice: string;
};

export const VehicleInfo = observer(({ vehicleInfo, isLoading }: TProps) => {
	const [image, setImage] = useState<TimageResponse | null>(null);
	const { brand } = useParams();

	useEffect(() => {
		if (vehicleInfo && vehicleInfo.length > 0) {
			import('../utils/getImage').then((getImage) => {
				setImage(getImage.default(vehicleInfo[0].brand) || null);
			});
		}
	}, [vehicleInfo]);

	return (
		<div className="d-flex justify-content-start align-items-center flex-row w-100">
			<PlaceholderWrapper
				placeholder={[[8, 3], [6]]}
				isLoading={isLoading}
				className="w-100"
			>
				{vehicleInfo && (
					<>
						<div className="me-3">
							{image ? (
								<img
									src={image.default}
									srcSet={`${image.twice} 2x`}
									alt={`${vehicleInfo[0].brand.toLowerCase()} logo`}
								/>
							) : (
								<>No image</>
							)}
						</div>
						<div>
							{brand} {vehicleInfo[0].name}
						</div>
					</>
				)}
			</PlaceholderWrapper>
		</div>
	);
});
