import { observer } from 'mobx-react';
import { CarItemInfo } from './CarItemInfo';
import { TCar } from 'types/car';
import { CarItemControl } from './CartItemControl';

type TProps = {
	car: TCar;
	handleDelete: () => void;
	handleEdit: () => void;
	handleClick: () => void;
	handleDownload?: () => Promise<void>;
};

export const CarItem = observer(
	({ car, handleDelete, handleEdit, handleClick, handleDownload }: TProps) => {
		return (
			<div className="d-flex justify-content-between align-items-center flex-row">
				<CarItemInfo car={car} handleClick={handleClick} />
				<CarItemControl
					car={car}
					handleDownload={handleDownload}
					handleEdit={handleEdit}
					handleDelete={handleDelete}
				/>
			</div>
		);
	}
);
