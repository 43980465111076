import qs from 'qs';
import { MERCHANT_API_URL } from 'consts';

export const merchantApiUrl = (path, query = null) => {
  return query
    ? `${MERCHANT_API_URL}/${path}?${qs.stringify(query, {
        arrayFormat: 'brackets',
        skipNulls: true
      })}`
    : `${MERCHANT_API_URL}/${path}`;
};
