import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { Card } from 'react-bootstrap';
import { ListItem } from './comp/ListItem';
import { NotFound } from 'components/common/NotFound';
import { PaginationWrapper } from 'components/common/PaginationWrapper';
import { AnimatedListWrapper } from 'components/common/AnimatedListWrapper';
import { useWarehouseStore } from 'providers/RootStoreProvider';

export const Warehouse = observer(() => {
	const { init, warehouses, isLoading, setPage, page, itemsPerPage, total } =
		useWarehouseStore();
	useEffect(() => {
		init();
	}, []);

	return (
		<>
			<Card className="mb-3">
				<Card.Body className="position-relative ">
					<div className="d-flex flex-row justify-content-between">
						<h4 className="mb-0">Мой склад</h4>
					</div>
				</Card.Body>
			</Card>
			{isLoading ? (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
			) : (
				<PaginationWrapper
					totalItems={total}
					itemsPerPage={itemsPerPage}
					page={page}
					setPage={setPage}
				>
					{warehouses.length ? (
						<>
							<AnimatedListWrapper>
								{warehouses.map((warehouse) => {
									if (!warehouse.active_price_list_version) return null;

									return (
										<Card key={`car_${warehouse.id}`} className="mb-3">
											<Card.Body>
												<ListItem
													handleClick={() => console.log('warehouse')}
													data={warehouse}
												/>
											</Card.Body>
										</Card>
									);
								})}
							</AnimatedListWrapper>
						</>
					) : (
						<NotFound />
					)}
				</PaginationWrapper>
			)}
		</>
	);
});
