import { ValidationError } from 'api/errors';

const isNotifiicationNeeded = (code: string) => {
	switch (code) {
		case 'UNAUTHENTICATED_ERROR':
			return false;
		default:
			return true;
	}
};

export const ServerErrorHandler = (data) => {
	if (data.code) {
		if (!isNotifiicationNeeded(data.code)) return null;

		if (data.errors) {
			const error_messages = Object.values(data.errors)
				.map((i) => i)
				.join('\n');
			throw new ValidationError(error_messages);
		} else {
			throw new ValidationError(data.message);
		}
	} else {
		throw new ValidationError('Validation error');
	}
};
