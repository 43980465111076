import { observer } from 'mobx-react-lite';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { useAppStore, useSearchStore } from 'providers/RootStoreProvider';
import 'styles/Navbar.scss';

export const SearchLocationButton = observer(() => {
	const { searchRegion } = useSearchStore();
	const { setActiveModal } = useAppStore();

	const text = searchRegion?.name || '';

	return (
		<div
			onClick={() => setActiveModal('region_search')}
			className="location-button cursor-pointer ms-2 p-2  d-flex flex-row justify-content-start align-items-center"
		>
			<MaterialIcon name="location_on" className="me-1" />
			<span className="text-primary white-space-nowrap me-1">
				<OverflowedTextTooltip placement="bottom" text={text} />
			</span>
		</div>
	);
});
