import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { useCompaniesStore } from 'providers/RootStoreProvider';

export const CompaniesSearch = observer(() => {
	const { getCompanies } = useCompaniesStore();
	const [search, setSearch] = useState('');

	const handleKeyEnter = useCallback((e) => {
		if (e.keyCode === 13) {
			getCompanies({ query: search });
		}
	}, []);

	return (
		<InputGroup className="mb-3">
			<Form.Control
				placeholder="Поиск"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onKeyDown={handleKeyEnter}
			/>
			<Button variant="primary" onClick={() => getCompanies({ query: search })}>
				Поиск
			</Button>
		</InputGroup>
	);
});
