import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { NotFound } from 'components/common/NotFound';
import dayjs from 'dayjs';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { AnimatedListWrapper } from 'components/common/AnimatedListWrapper';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const CatalogSearchHistory = observer(() => {
	const { getCatalogSearchHistory, catalogSearchHistory, isLoading } =
		useCatalogStore();

	useEffect(() => {
		getCatalogSearchHistory();
	}, []);

	const openInNewTab = useCallback((url: string) => {
		window.open(url, '_blank', 'noreferrer');
	}, []);

	return (
		<>
			<Card className="mb-3">
				<Card.Body className="position-relative ">
					<h4 className="mb-0">История поиска в каталоге</h4>
				</Card.Body>
			</Card>
			<LoadingWrapper isLoading={isLoading} size={40} className="mt-5">
				<AnimatedListWrapper>
					{catalogSearchHistory.length ? (
						catalogSearchHistory.map((item) => {
							return (
								<Card
									onClick={() =>
										openInNewTab(`/catalog-model/quicklist/${item.brand}/${item.catalog}/${item.ssd}/${item.vehicle_id}/2
                                            `)
									}
									className="mb-3 cursor-pointer"
									key={item.id}
								>
									<Card.Body>
										<div className="fs-16 fw-semi-bold">
											{item.brand} {item.name}
										</div>
										<div className="fs-13">
											Дата поиска: {dayjs(item.created_at).format('DD/MM/YY')}
										</div>
									</Card.Body>
								</Card>
							);
						})
					) : (
						<NotFound />
					)}
				</AnimatedListWrapper>
			</LoadingWrapper>
		</>
	);
});
