import CircularProgress from '@mui/material/CircularProgress';
import ru from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, useCallback } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { CashbackInfo } from './comp/CashbackInfo';
import { CashbackTable } from './comp/CashbackTable';
import { ListItem } from './comp/ListItem';
import dayjs from 'dayjs';
import { useAppStore, useCashbackStore } from 'providers/RootStoreProvider';

registerLocale('ru', ru);

export const Cashback = observer(() => {
	const {
		init,
		isLoading,
		total,
		items,
		addRequisites,
		requisite,
		updateRequisites,
		getCashback,
	} = useCashbackStore();
	const { setActiveModal } = useAppStore();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		init();
	}, []);

	const activateFormModal = useCallback(
		(index?: number) => {
			setActiveModal('modal_form', 'middle-size', {
				add: addRequisites,
				update: updateRequisites,
				initialData: index !== undefined ? requisite[index] : null,
				fields: [
					{
						name: 'name',
						label: 'Название',
						placeholder: 'Например, Сбербанк',
					},
					{
						name: 'fullName',
						label: 'ФИО владельца карты',
						placeholder: 'Иванов Иван Иванович',
					},
					{ name: 'inn', label: 'ИНН', placeholder: 'ИНН физического лица' },
					{ name: 'bik', label: 'БИК', placeholder: 'БИК банка' },
					{
						name: 'bankAccount',
						label: 'Расчётный счёт',
						placeholder: 'Номер расчётного счёта',
					},
				],
				submitButtonText: 'Сохранить',
			});
		},
		[requisite]
	);

	if (isLoading) {
		return (
			<div className="p-5 d-flex justify-content-center align-items-center">
				<CircularProgress size={60} />
			</div>
		);
	}

	return (
		<>
			<Card className="mb-3">
				<Card.Body className="position-relative ">
					<div className="d-flex flex-row justify-content-between">
						<h4 className="mb-0">Кешбэк</h4>
						<Button
							size="sm"
							variant={'outline-primary'}
							onClick={() => activateFormModal()}
						>
							Реквизиты для вывода кешбэка
						</Button>
					</div>
				</Card.Body>
			</Card>
			{requisite.map((item, index) => (
				<Card key={item.id + 'list_item' + index} className="p-4 mb-3">
					<ListItem data={item} handleEdit={() => activateFormModal(index)} />
				</Card>
			))}
			<CashbackInfo total={total} />
			<Card className="p-4">
				<Form>
					<Form.Group
						className="mb-3 d-flex align-items-center gap-3 w-40"
						controlId="formHorizontalEmail"
					>
						<ReactDatePicker
							selected={startDate}
							onChange={(date) => setStartDate(date)}
							className="form-control"
							dateFormat="dd-MM-yyyy"
							selectsStart
							startDate={startDate}
							endDate={endDate}
							locale="ru"
						/>
						<div className="d-flex">
							<span>—</span>
						</div>
						<ReactDatePicker
							selected={endDate}
							onChange={(date) => setEndDate(date)}
							className="form-control"
							dateFormat="dd-MM-yyyy"
							selectsEnd
							startDate={startDate}
							endDate={endDate}
							minDate={startDate}
							locale="ru"
						/>
						<Button
							onClick={() =>
								getCashback({
									from: dayjs(startDate).format('DD.MM.YYYY'),
									to: dayjs(endDate).format('DD.MM.YYYY'),
								})
							}
							className="mw-100"
						>
							Применить
						</Button>
					</Form.Group>
				</Form>
				<CashbackTable items={items} />
			</Card>
		</>
	);
});
