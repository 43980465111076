import { useState, useCallback } from 'react';
import { Modal, InputGroup, Form, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ReviewModal = observer(
	({
		onHide,
		show,
		sendReview,
		message,
		rating,
	}: {
		onHide: () => void;
		show: boolean;
		sendReview: ({
			rating,
			message,
		}: {
			rating: number;
			message: string;
		}) => Promise<boolean>;
		message?: string;
		rating?: number;
	}) => {
		const [messageValue, setMessageValue] = useState(message || '');
		const [ratingValue, setRatingValue] = useState<number | null>(null);

		const handleClose = useCallback(() => {
			setMessageValue('');
			onHide();
		}, []);

		const handleSendReview = useCallback(() => {
			sendReview({ rating: ratingValue, message: messageValue });
			onHide();
		}, []);

		return (
			<Modal
				show={show}
				onHide={onHide}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="middle-size"
			>
				<>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Оставить отзыв
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InputGroup>
							<Form.Control
								style={{ width: '100%', minHeight: '150px' }}
								as="textarea"
								placeholder="Поделитесь своими впечатлениями о сотрудничестве"
								value={messageValue}
								onChange={({ target }) => setMessageValue(target.value)}
							/>
							<div className="py-3">
								<p className="mb-2">Ваша оценка:</p>
								{/* @ts-ignore */}
								<Rating
									onChange={setRatingValue}
									fractions={1}
									initialRating={ratingValue}
									fullSymbol={
										<FontAwesomeIcon icon="star" className="text-warning" />
									}
									emptySymbol={
										<FontAwesomeIcon icon="star" className="text-300" />
									}
									direction={'ltr'}
								/>
							</div>
						</InputGroup>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={!message || !rating}
							variant={'success'}
							onClick={handleSendReview}
						>
							Отправить
						</Button>
						<Button variant={'secondary'} onClick={handleClose}>
							Закрыть
						</Button>
					</Modal.Footer>
				</>
			</Modal>
		);
	}
);
