import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

type TProps = {
	at?: 'navbar-vertical' | 'navbar-top' | 'auth';
	width?: number | 58;
	className?: string;
	logoTextClass?: string;
	style?: Record<string, string>;
};

const Logo = observer(
	({ at, className, logoTextClass, style, ...rest }: TProps) => {
		return (
			<Link
				to="/"
				className={classNames(
					'text-decoration-none',
					{ 'navbar-brand text-left': at === 'navbar-vertical' },
					{ 'navbar-brand text-left': at === 'navbar-top' }
				)}
				{...rest}
			>
				<div
					className={classNames(
						'd-flex',
						{
							'align-items-center py-3': at === 'navbar-vertical',
							'align-items-center': at === 'navbar-top',
							'flex-center fw-bolder fs-5 mb-4': at === 'auth',
						},
						className
					)}
				>
					<span
						style={style}
						className={classNames('font-sans-serif', 'logo', logoTextClass)}
					>
						gettzap b2b
					</span>
				</div>
			</Link>
		);
	}
);

export default Logo;
