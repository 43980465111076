import { makeAutoObservable } from 'mobx';
import {
	postOrderReview,
	getMyReviews,
	patchOrderReview,
	deleteOrderReview,
} from 'api/review/review';
import { TReview, TReviewPostBody, TReviewPatchBody } from 'types/review';
import { toast } from 'react-toastify';
import { ButtonAlertLink } from 'components/common/ButtonAlertLink';
import { RootStore } from 'stores/RootStore';

export class ReviewStore {
	isLoading = true;
	reviews: TReview[] = [];
	page = 1;
	totalItems = 0;
	itemsPerPage = 25;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	editReview = async (review_id: number, payload: TReviewPatchBody) => {
		const res = await patchOrderReview(review_id, payload);
		if (res === null) return;

		toast.success('Отзыв отредактирован', {
			theme: 'colored',
		});
		this.updateListReviews({});

		return true;
	};

	deleteReview = async (review_id: number) => {
		const res = await deleteOrderReview(review_id);
		if (res === null) return;

		toast.success('Отзыв удален', {
			theme: 'colored',
		});
		this.updateListReviews({});

		return true;
	};

	sendReview = async (payload: TReviewPostBody) => {
		const res = await postOrderReview(payload);
		if (res === null) return;

		toast.success(
			ButtonAlertLink({
				title: `Отзыв успешно отправлен!`,
				buttonText: 'Перейти к отзывам',
				link: '/reviews',
			}),
			{
				theme: 'colored',
			}
		);
		return true;
	};

	setPage = (page: number) => {
		this.page = page;
		this.updateListReviews({});
	};

	updateListReviews = async ({ resetPage }: { resetPage?: boolean }) => {
		this.isLoading = true;
		if (resetPage) this.page = 1;

		const res = await getMyReviews({
			per_page: this.itemsPerPage,
			page: this.page,
			real_user_review_exists_true: true,
		});

		if (res === null) return;

		const {
			data,
			meta: { count },
		} = res;

		this.reviews = data;
		this.totalItems = count;

		this.isLoading = false;
	};

	init = async () => {
		await this.updateListReviews({ resetPage: true });
	};
}
