import { makeAutoObservable } from 'mobx';
import { fetchBalanceInfo, fetchBalanceHistory } from 'api/balance/balance';
import { TBalanceHistory } from 'types/balance';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { validateDate } from 'helpers/validateDate';
import { RootStore } from 'stores/RootStore';

dayjs.extend(isBetween);

type TFilter = {
	from: Dayjs;
	to: Dayjs;
};

type TSearchItems = {
	from?: string;
	to?: string;
};

export class BalanceStore {
	isLoading = true;
	balance = 0;
	completedOrderItemsSum = 0;
	notCompletedOrderItemsSum = 0;
	availableBalance = 0;
	history: TBalanceHistory[] = [];
	filtered_history: TBalanceHistory[] = [];
	filters: TFilter = {
		from: dayjs().subtract(30, 'day'),
		to: dayjs(),
	};

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	getBalanceHistory = async (searchItems?: TSearchItems) => {
		this.history = [];
		const obj = {} as TSearchItems;

		if (searchItems?.from && !validateDate(searchItems?.from, 'DD.MM.YYYY')) {
			obj.from = searchItems.from;
		}

		if (searchItems?.to && !validateDate(searchItems?.to, 'DD.MM.YYYY')) {
			obj.to = searchItems.to;
		}

		const res = await fetchBalanceHistory(obj);

		if (res === null) return;

		this.history = res;
		return res;
	};

	getBalance = async () => {
		const res = await fetchBalanceInfo();

		if (res === null) return;

		const {
			balance,
			completedOrderItemsSum,
			notCompletedOrderItemsSum,
			availableBalance,
		} = res;

		this.balance = balance;
		this.completedOrderItemsSum = completedOrderItemsSum;
		this.notCompletedOrderItemsSum = notCompletedOrderItemsSum;
		this.availableBalance = availableBalance;
	};

	setDateFilter = async (type, date) => {
		this.filters[type] = dayjs(date);

		this.filtered_history = this.history.filter((i) => {
			return dayjs(i.createdAt).isBetween(this.filters.from, this.filters.to);
		});
	};

	init = async () => {
		this.isLoading = true;

		await this.getBalance();

		this.isLoading = false;
	};
}
