import React from 'react';
import { ConfirmIcon } from 'components/common/ConfirmIcon';
import { Placeholder } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TCitySmall, TMetroStation, TShopSmall } from 'types/product';
import { TOffice } from 'types/office';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { reviewsWord } from 'utils/product';

export const ProductItemShop = observer(
	({
		metroStation,
		isLoading,
		shop,
		office,
		onShopName,
		city,
	}: {
		metroStation: TMetroStation;
		isLoading: boolean;
		shop: TShopSmall;
		office: TOffice;
		city: TCitySmall;
		onShopName: () => void;
	}) => {
		return (
			<>
				{!isLoading ? (
					<>
						<div className="cell-container d-flex flex-column justify-start align-items-start">
							<div className="d-flex flex-row justify-start align-items-center flex-wrap w-100">
								<div
									onClick={onShopName}
									className="link-type-text me-2 max-w-60 d-flex flex-row justify-start align-items-start"
								>
									<OverflowedTextTooltip text={office.name} />{' '}
									<ConfirmIcon className="ms-1" confirmed={shop.confirmed} />
								</div>
								<div>
									<FontAwesomeIcon
										icon="star"
										className={`
											${office.ratingCount > 0 ? `text-warning` : 'text-300'} me-1
											`}
									/>{' '}
									{office.ratingCount > 0 && (
										<>
											{office.ratingValue && office.ratingValue.toFixed(1)} (
											{office.ratingCount} {reviewsWord(office.ratingCount)})
										</>
									)}
								</div>
							</div>
							<OverflowedTextTooltip text={`${city.name}, ${office.address}`} />
							<div className="d-flex justify-start align-items-center flex-wrap">
								{metroStation && (
									<span className="me-2">
										<span
											className="metro-icon small me-2"
											style={{ background: `#${metroStation.color}` }}
										>
											M
										</span>
										{metroStation.name}
									</span>
								)}
								<span>{office.phone1}</span>
							</div>
						</div>
					</>
				) : (
					<Placeholder xs={12} />
				)}
			</>
		);
	}
);
