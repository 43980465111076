import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import useApi from 'hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { TListUnits } from 'types/catalog';
import CircularProgress from '@mui/material/CircularProgress';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const ListUnits = observer(() => {
	const { getListUnits } = useCatalogStore();
	const navigate = useNavigate();
	const { brand, code, ssd, vehicle_id, category_id } = useParams();

	const {
		data: listUnits,
		loading: listUnitsLoading,
		fetch: fetchListUnits,
	} = useApi(getListUnits);

	useEffect(() => {
		const payload = {
			catalog: code,
			ssd,
			vehicle_id,
		};

		if (category_id !== 'null') {
			payload['category_id'] = category_id;
		}

		fetchListUnits(payload);
	}, [category_id]);

	const formatImageUrl = useCallback((url) => {
		return url.replace('%size%', 250);
	}, []);

	const ListUnitItem = ({ data }: { data: TListUnits }) => {
		return (
			<Card
				onClick={() =>
					navigate(
						`/catalog-unit/${brand}/${code}/${data.ssd}/${data.unitid}/${vehicle_id}/${category_id}`
					)
				}
				className="list-quick-detail cursor-pointer w-100 d-flex"
			>
				<div className="d-flex w-100 flex-column justify-content-start align-items-center">
					<div className="list-unit-title link-type-text fs-14 p-3">
						{data.name}
					</div>
					<div className="list-unit-image p-3">
						<img className="w-100" src={formatImageUrl(data.imageurl)} alt="" />
					</div>
				</div>
			</Card>
		);
	};

	const isLoaded = !listUnitsLoading && listUnits && listUnits.length;

	return (
		<>
			{isLoaded ? (
				<div className="list-unit-container">
					{listUnits.map((i) => {
						return <ListUnitItem key={i.code + i.name} data={i} />;
					})}
				</div>
			) : (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={35} />
				</div>
			)}
		</>
	);
});
