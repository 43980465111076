import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { NoteModal } from 'components/e-commerce/SavedProducts/comp/NoteModal';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CarItem } from './comp/CarItem';
import { NotFound } from 'components/common/NotFound';
import { PaginationWrapper } from 'components/common/PaginationWrapper';
import { LoadingWrapper } from 'components/common/LoadingWrapper';
import { AnimatedListWrapper } from 'components/common/AnimatedListWrapper';
import { TCar } from 'types/car';
import { useCarStore, useAppStore } from 'providers/RootStoreProvider';

import 'styles/Car.scss';

export const SavedCars = observer(() => {
	const {
		init,
		isLoading,
		cars,
		updateNote,
		removeCar,
		total,
		itemsPerPage,
		setPage,
		page,
	} = useCarStore();
	const { setActiveModal } = useAppStore();
	const [activeNote, setActiveNote] = useState('');
	const [activeNoteId, setActiveNoteId] = useState<number>(null);

	const navigate = useNavigate();

	useEffect(() => {
		init();
	}, []);

	const onSaveNote = useCallback(
		async (note: string) => {
			await updateNote(activeNoteId, note);
			setActiveNote('');
			setActiveNoteId(null);
		},
		[activeNoteId]
	);

	const handleCreateNew = useCallback(async () => {
		await setActiveModal('car_form', 'middle-size');
	}, []);

	const handleEdit = useCallback(async (car: TCar) => {
		await setActiveModal('car_form', 'middle-size', {
			initialData: car,
		});
	}, []);

	const handleDelete = useCallback(async (id: number) => {
		await removeCar(id);
	}, []);

	return (
		<>
			<Card className="mb-3">
				<Card.Body className="position-relative ">
					<div className="d-flex flex-row justify-content-between">
						<h4 className="mb-0">Проценка</h4>
						<Button
							size="sm"
							variant={'outline-primary'}
							onClick={handleCreateNew}
						>
							Создать проценку
						</Button>
					</div>
				</Card.Body>
			</Card>
			<LoadingWrapper isLoading={isLoading} className="p-5">
				{!!activeNoteId && (
					<NoteModal
						note={activeNote}
						show={!!activeNoteId}
						onHide={() => setActiveNoteId(null)}
						onSave={onSaveNote}
					/>
				)}
				<PaginationWrapper
					totalItems={total}
					itemsPerPage={itemsPerPage}
					page={page}
					setPage={setPage}
				>
					{cars.length ? (
						<AnimatedListWrapper>
							{cars.map((car) => {
								return (
									<Card className="mb-3 cursor-pointer" key={`car_${car.id}`}>
										<Card.Body>
											<CarItem
												handleClick={() => navigate(`/car-notes/${car.id}`)}
												handleDelete={() => handleDelete(car.id)}
												handleEdit={() => handleEdit(car)}
												car={car}
											/>
										</Card.Body>
									</Card>
								);
							})}
						</AnimatedListWrapper>
					) : (
						<NotFound />
					)}
				</PaginationWrapper>
			</LoadingWrapper>
		</>
	);
});
