import React from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { LoginForm } from 'components/authentication/LoginForm';

export const Login = () => {
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Войти</h5>
        <p className="fs--1 text-600 mb-0">
          или <Link to="/authentication/register">Создать аккаунт</Link>
        </p>
      </Flex>
      <LoginForm />
    </>
  );
};
