import React from 'react';
import { observer } from 'mobx-react';
import { Card } from 'react-bootstrap';
import { ProductFilter } from 'components/product/product-list/comp/product-list-table-header/product-list-table-filter/ProductFilter';
import CircularProgress from '@mui/material/CircularProgress';

export const ProductMapFilter = observer(({ showFilterMap, isLoading }) => {
	return (
		<div
			className={`product-map-filter-container ${showFilterMap && 'active'}`}
		>
			<div className="product-map-active-list-content">
				<Card className="h-100">
					<Card.Body className="active-product-list-content-card-body d-flex justify-content-start flex-row align-items-center pb-3">
						<ProductFilter />
						{isLoading && (
							<div className="ms-3">
								<CircularProgress size={24} />
							</div>
						)}
					</Card.Body>
				</Card>
			</div>
		</div>
	);
});
