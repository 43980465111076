export const routes = [
	{
		label: 'Каталоги',
		name: 'favorite',
		roles: ['owner', 'shop_admin', 'broker'],
		children: [
			// {
			// 	material_icon: 'search',
			// 	name: 'Поиск по VIN',
			// 	modal: 'search_by_vin',
			// },
			// {
			// 	material_icon: 'history',
			// 	name: 'История поиска',
			// 	to: '/cars_search_logs',
			// },
			{
				material_icon: 'font_download',
				name: 'Оригинальный каталог',
				to: '/catalog',
			},
			{
				custom_icon: 'tyre',
				name: 'Каталог шин',
				to: '/tyres',
			},
		],
	},
	{
		label: 'Избранное',
		name: 'favorite',
		roles: ['owner', 'shop_admin', 'broker'],
		children: [
			{
				material_icon: 'history',
				name: 'Сохранённые товары',
				to: '/saved-products',
				authRequired: true,
			},

			{
				material_icon: 'history',
				name: 'История поиска',
				to: '/search-history',
				authRequired: true,
			},
		],
	},

	{
		label: 'Мои заказы',
		name: 'orders',
		roles: ['owner', 'shop_admin', 'broker'],
		children: [
			{
				material_icon: 'assignment',
				name: 'Все заказы',
				to: '/orders',
				authRequired: true,
			},
			{
				material_icon: 'assignment_returned',
				name: 'Отгрузки',
				to: '/shipments',
				authRequired: true,
			},
		],
	},
	{
		label: 'Аккаунт пользователя',
		name: 'account',
		roles: ['owner', 'shop_admin', 'broker'],
		children: [
			{
				material_icon: 'payments',
				name: 'Кешбэк',
				to: '/user/cashback',
				authRequired: true,
			},
			{
				material_icon: 'account_circle',
				name: 'Настройки аккаунта',
				to: '/user/settings',
				authRequired: true,
			},

			{
				material_icon: 'contact_support',
				name: 'Служба поддержки',
				modal: 'support',
				authRequired: true,
			},
		],
	},
	{
		label: 'Организации',
		name: 'organization',
		roles: ['owner', 'shop_admin', 'broker'],
		children: [
			{
				material_icon: 'payments',
				id: 'requsites',
				name: 'Реквизиты',
				to: '/user/requisites',
				authRequired: true,
			},
			{
				material_icon: 'account_balance_wallet',
				name: 'Баланс и платежи',
				to: '/user/balance-payment',
				authRequired: true,
			},
			{
				material_icon: 'receipt_long',
				name: 'Пополнить баланс',
				to: '/user/billing',
				authRequired: true,
			},
		],
	},
	{
		label: 'Документы',
		name: 'docs',
		roles: ['owner', 'shop_admin', 'broker'],
		children: [
			{
				material_icon: 'document_scanner',
				name: 'Оферта',
				to: '/docs/oferta',
			},
		],
	},
	// b2b_manager role
	{
		label: 'Компании',
		name: 'company',
		roles: ['b2b_manager'],
		children: [
			{
				material_icon: 'payments',
				name: 'Мои компании',
				to: '/companies',
			},
		],
	},
	{
		label: 'Задачи',
		name: 'tasks',
		roles: ['b2b_manager'],
		children: [
			{
				material_icon: 'tasks',
				name: 'Мои задачи',
				to: '/tasks',
			},
		],
	},
	// admin role
	{
		label: 'Дашборд',
		name: 'dashboards',
		roles: ['admin'],
		children: [
			{
				material_icon: 'payments',
				name: 'Дашборд',
				to: '/dashboards',
			},
			{
				material_icon: 'location_on',
				name: 'Карта поставщиков',
				to: '/office-map',
			},
		],
	},
	{
		label: 'Компании',
		name: 'company-admin',
		roles: ['admin'],
		children: [
			{
				material_icon: 'store',
				name: 'Список компаний',
				to: '/admin_сompanies',
			},
		],
	},
	// role keeper
	{
		label: 'Выплаты',
		name: 'payments-shop',
		roles: ['keeper'],
		children: [
			{
				material_icon: 'account_balance_wallet',
				name: 'Выплаты',
				to: '/payments-shop',
			},
		],
	},
];
