export const ORDER_STATUS = [
	{},
	{ status: 'Новый', type: 'info' },
	{ status: 'В работе', type: 'info' },
	{ status: 'Пришло на склад', type: 'secondary' },
	{ status: 'Выдан', type: 'success' },
	{ status: 'Приостановлен', type: 'warning' },
	{ status: 'Отклонён', type: 'danger' },
];

export const PARTNER_ORDER_ITEM_STATUS = [
	{},
	{ status: 'Новый', type: 'info', color: 'rgb(45 123 229)' },
	{ status: 'В работе', type: 'info', color: 'rgb(45 123 229)' },
	{ status: 'Пришло на склад', type: 'secondary', color: 'rgb(106 97 97)' },
	{ status: 'Выдан', type: 'success', color: 'rgb(34 160 62)' },
	{ status: 'Приостановлен', type: 'warning', color: 'rgb(230 147 58)' },
	{ status: 'Отклонён', type: 'danger', color: 'rgb(202 28 28)' },
];

export const PARTNER_ORDER_STATUS = [
	{},
	{ status: 'Новый', type: 'info', color: 'rgb(45 123 229)' },
	{ status: 'В работе', type: 'info', color: 'rgb(45 123 229)' },
	{
		status: 'Отправлено поставщику',
		type: 'secondary',
		color: 'rgb(106 97 97)',
	},
	{ status: 'Пришло на склад', type: 'secondary', color: 'rgb(106 97 97)' },
	{ status: 'Частично выдан', type: 'secondary', color: 'rgb(106 97 97)' },
	{ status: 'Выдан', type: 'success', color: 'rgb(34 160 62)' },
	{ status: 'Приостановлен', type: 'warning', color: 'rgb(230 147 58)' },
	{ status: 'Отклонён', type: 'danger', color: 'rgb(202 28 28)' },
	{ status: 'Удалён', type: 'danger', color: 'rgb(202 28 28)' },
	{ status: 'Ждёт предоплату', type: 'info', color: 'rgb(45 123 229)' },
	{ status: 'Ждёт подтверждения', type: 'info', color: 'rgb(45 123 229)' },
	{ status: 'Отменён покупателем', type: 'danger', color: 'rgb(202 28 28)' },
];
