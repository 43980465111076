import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/envelope.png';

export const ConfirmMailContent = ({ titleTag: TitleTag }) => {
  const { state } = useLocation();

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <TitleTag>Посмотрите свою почту!</TitleTag>
      <p>
        Благодарим Вас за использование сервиса Gettzap. На указанный email{' '}
        <strong>{state || ''}</strong> отправлена информация по восстановлению
        пароля.
      </p>
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`/authentication/login`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Вернуться к авторизации
      </Button>
    </>
  );
};

ConfirmMailContent.propTypes = {
  email: PropTypes.string,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };
