import { TDashboardManagerInfo } from 'types/dashboard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import { Card, Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { fetchDashboardByManager } from 'api/dashboard/dashboard';
import CountUp from 'react-countup';
import Background from 'components/common/Background';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-3.png';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import { validateDate } from 'helpers/validateDate';

registerLocale('ru', ru);

export const ManagerDetail = () => {
	const { id } = useParams();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [managerInfo, setManagerInfo] = useState<TDashboardManagerInfo>();

	type TSearchItems = {
		from?: string;
		to?: string;
	};

	useEffect(() => {
		getDashboardByManager();
	}, []);

	const getDashboardByManager = async (searchItems?: TSearchItems) => {
		const obj = {} as TSearchItems;

		if (searchItems?.from && !validateDate(searchItems?.from, 'DD.MM.YYYY')) {
			obj.from = searchItems.from;
		}

		if (searchItems?.to && !validateDate(searchItems?.to, 'DD.MM.YYYY')) {
			obj.to = searchItems.to;
		}

		const managerInfo = await fetchDashboardByManager(+id, obj);
		setManagerInfo(managerInfo);
	};

	return (
		<>
			<Card className="mb-3">
				<Card.Body className="position-relative ">
					<h4 className="mb-0">{managerInfo?.manager.name}</h4>
				</Card.Body>
			</Card>

			<Card className="p-4">
				<Form>
					<Form.Group
						className="mb-3 d-flex align-items-center gap-3 w-40"
						controlId="formHorizontalEmail"
					>
						<ReactDatePicker
							selected={startDate}
							onChange={(date) => setStartDate(date)}
							className="form-control"
							dateFormat="dd-MM-yyyy"
							selectsStart
							startDate={startDate}
							endDate={endDate}
							locale="ru"
						/>
						<div className="d-flex">
							<span>—</span>
						</div>
						<ReactDatePicker
							selected={endDate}
							onChange={(date) => setEndDate(date)}
							className="form-control"
							dateFormat="dd-MM-yyyy"
							selectsEnd
							startDate={startDate}
							endDate={endDate}
							minDate={startDate}
							locale="ru"
						/>
						<Button
							onClick={() =>
								getDashboardByManager({
									from: dayjs(startDate).format('DD.MM.YYYY'),
									to: dayjs(endDate).format('DD.MM.YYYY'),
								})
							}
							className="mw-100"
						>
							Применить
						</Button>
					</Form.Group>
				</Form>

				<div className="d-flex flex-row justify-content-between align-items-start mb-3">
					<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
						<Background image={bg1} className="bg-card" />
						<Card.Body className="position-relative">
							<h6>Количество компаний:</h6>
							<div
								className={classNames(
									'text-warning',
									'display-4 fs-4 mb-2 fw-normal font-sans-serif'
								)}
							>
								<CountUp
									start={0}
									end={managerInfo?.b2b_companies_count}
									duration={1}
									suffix={''}
									prefix={''}
									separator=","
									decimals={0}
									decimal="."
								/>
							</div>
						</Card.Body>
					</Card>
					<Card className={classNames('overflow-hidden', 'w-100')}>
						<Background image={bg2} className="bg-card" />
						<Card.Body className="position-relative">
							<h6>Количество заказов:</h6>
							<div
								className={classNames(
									'text-success',
									'display-4 fs-4 mb-2 fw-normal font-sans-serif'
								)}
							>
								<CountUp
									start={0}
									end={managerInfo?.order_items_count}
									duration={1}
									suffix={''}
									prefix={''}
									separator=","
									decimals={0}
									decimal="."
								/>
							</div>
						</Card.Body>
					</Card>
				</div>
			</Card>
		</>
	);
};
