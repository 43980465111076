import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TListReponse } from 'types/common';
import { TTyre } from 'types/tyre';

export const fetchTyres = async (
	query_params
): Promise<TListReponse<TTyre>> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/search`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchWidth = async (query_params): Promise<number[]> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/agg-by-width`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchHeight = async (query_params): Promise<number[]> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/agg-by-height`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchSeason = async (query_params): Promise<string[]> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/agg-by-season`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchDiameter = async (query_params): Promise<number[]> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/agg-by-diameter`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchBrand = async (query_params): Promise<string[]> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/agg-by-brand`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchSpike = async (query_params): Promise<string[]> => {
	return request(
		fetch(b2bApiUrl(`common/tyres/agg-by-spike`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const aggregationFetch = {
	width: fetchWidth,
	height: fetchHeight,
	brands: fetchBrand,
	season: fetchSeason,
	spike: fetchSpike,
	diameter: fetchDiameter,
};
