import { ReactNode } from 'react';
import { observer } from 'mobx-react';

type TProps = {
	children?: ReactNode;
	classContent?: string;
	classContainer?: string;
};

export const FilterWrapper = observer(
	({ classContainer, classContent, children }: TProps) => {
		return (
			<span className={`filter-item-container ${classContainer}`}>
				<div className={`filter-toggle ${classContent}`}>{children}</div>
			</span>
		);
	}
);
