import { makeAutoObservable } from 'mobx';
import { TCoreProduct, TCoreProductID } from 'types/product';
import { findCoreProduct } from 'api/product/products';
import { getImageURL } from 'utils/common';
import { RootStore } from 'stores/RootStore';

export class CoreProductStore {
	coreProduct: TCoreProduct = null;
	isLoading = true;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	unSave = async (payload: TCoreProductID) => {
		await this.root.savedProductsStore.removeFromSavedProducts(
			this.root.savedProductsStore.getSaved(payload).id
		);
		this.coreProduct.saved = false;
	};

	setSave = async (payload: TCoreProductID) => {
		await this.root.savedProductsStore.addToSavedProducts(payload);
		this.coreProduct.saved = true;
	};

	getCoreProduct = async (uid: string) => {
		this.isLoading = true;
		const res = await findCoreProduct(uid);

		if (res === null) {
			this.isLoading = false;
			return;
		}

		this.coreProduct = res;

		this.coreProduct['image'] = getImageURL(
			this.coreProduct.id,
			this.coreProduct.number
		);
		this.coreProduct['saved'] = !!this.root.savedProductsStore.getSaved({
			brand_name: this.coreProduct.core_brand.name,
			code: this.coreProduct.number,
		});

		this.isLoading = false;
	};
}
