import { useState, useCallback } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { QuantityController } from '../QuantityController';
import { ImageModal } from 'components/product/ImageModal';
import CircularProgress from '@mui/material/CircularProgress';
import { TCartItem } from 'types/product';
import { TableColumn } from 'helpers/TableColumn';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { isLinkToProduct as isLinkToProducts } from 'utils/product';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { Table } from 'components/common/Table';
import { TooltipElement } from 'components/common/TooltipElement';
import {
	useAppStore,
	useCartStore,
	useSavedProductsStore,
} from 'providers/RootStoreProvider';
import dayjs from 'dayjs';
import { NoteEdit } from './components/NoteEdit';

type TRowData = { row: { original: TCartItem } };

export const CartTable = observer(() => {
	const { history: appHistory } = useAppStore();
	const {
		isLoading,
		cartItems,
		removeItem,
		setQuantity,
		removeItemMany,
		selected,
		setSelected,
		editNote,
	} = useCartStore();
	const { addToSavedProducts, getSaved } = useSavedProductsStore();
	const navigate = useNavigate();
	const isEmpty = cartItems ? !cartItems.length : true;

	const handleQuantityChange = useCallback((id: number, value: number) => {
		return setQuantity(id, value);
	}, []);

	const columns = [
		TableColumn({
			header: 'Название товара',
			width: 450,
			headerClassName: 'pe-1',
			cellClassName: 'py-2 pe-3',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { id, name, core_brand, product, code, office, product_data } =
					rowData.row.original;
				const imageUrl = product ? product.image_url || product.imageUrl : null;

				const codeProductId = {
					brand_name: core_brand.name,
					code,
				};
				const [showImage, setShowImage] = useState(false);

				const isSaved = getSaved(codeProductId);

				return (
					<>
						{showImage && (
							<ImageModal
								title={`${core_brand.name} (${code}) ${name}`}
								imageUrl={imageUrl}
								show={showImage}
								onHide={() => setShowImage(false)}
							/>
						)}
						<div className="order-name w-100 pe-3">
							<div className="d-flex justify-content-start align-items-start ">
								<div className="d-flex w-100 justify-content-start flex-column">
									<div className="d-flex flex-row">
										{imageUrl && (
											<Badge
												onClick={() => setShowImage(true)}
												className="mt-1 me-2 cursor-pointer"
												bg="success"
											>
												Фото
											</Badge>
										)}
										<div className="fw-bold me-1">
											{core_brand.name} ({code})
											{product_data?.api_data?.isReturnable !== undefined &&
												product_data?.api_data?.isReturnable === false && (
													<span className="ms-2" style={{ color: '#e63757' }}>
														Возврату не подлежит
													</span>
												)}
										</div>
									</div>
									<strong>{name}</strong>
									<OverflowedTextTooltip
										text={`${office.city.name}, ${office.address}`}
									/>
									{product_data?.api_data?.deliveryDate && (
										<div className="color-red mt-1">
											Доставка:{' '}
											{dayjs(product_data.api_data.deliveryDate).format(
												'DD.MM.YY'
											)}{' '}
											{product_data?.api_data?.time && (
												<>(актуально на {product_data?.api_data?.time})</>
											)}
										</div>
									)}
								</div>
							</div>
							<NoteEdit
								onSave={(note) => editNote(id, note)}
								note={product_data?.api_data?.note}
							/>
							<div className="order-name-action pt-1">
								<span
									onClick={() => addToSavedProducts(codeProductId)}
									className={`text-${
										isSaved ? 'success' : 'primary'
									} cursor-pointer me-3`}
								>
									{isSaved ? <>Сохранено</> : <>В избранное</>}
								</span>
								<span onClick={() => removeItem(id)} className="cursor-pointer">
									Удалить
								</span>
							</div>
						</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Количество',
			width: 150,
			headerClassName: 'pe-1',
			cellClassName: 'py-2 pe-5',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { id, quantity, product_data } = rowData.row.original;

				const multiplicityStep =
					product_data && product_data.multiplicity
						? product_data.multiplicity
						: 1;

				return (
					<div className="w-100 d-flex justify-content-center">
						<QuantityController
							stock={
								product_data && product_data.api_data
									? product_data.api_data.stock
									: undefined
							}
							quantity={quantity}
							multiplicityStep={multiplicityStep}
							onChange={(value) => handleQuantityChange(id, value)}
							onIncrease={() =>
								handleQuantityChange(id, quantity + multiplicityStep)
							}
							onDecrease={() =>
								quantity > 1 &&
								handleQuantityChange(id, quantity - multiplicityStep)
							}
							btnClassName="px-2 d-flex justify-content-center align-items-center"
						/>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Цена',
			width: 150,
			headerClassName: 'ps-3',
			cellClassName: 'ps-3',
			cell: (rowData: TRowData) => {
				const { total, total_cashback, product_data } = rowData.row.original;

				const customerCost = product_data?.api_data?.customerCost;

				return (
					<>
						<div className="d-flex flex-column justify-content-start align-items-start w-100">
							{customerCost ? (
								<>
									<span>
										<TooltipElement value={<>Цена с вашей наценкой</>}>
											<b className="fs-16 blue-color me-2">
												{new Intl.NumberFormat('ru-RU').format(customerCost)} ₽
											</b>
										</TooltipElement>
									</span>
									<span className=" fs-14">
										{total &&
											`${new Intl.NumberFormat('ru-RU').format(total)} ₽`}{' '}
									</span>
								</>
							) : (
								<>
									<b className="fs-16">
										{total &&
											`${new Intl.NumberFormat('ru-RU').format(total)} ₽`}{' '}
									</b>
								</>
							)}
						</div>
						{total_cashback > 0 && (
							<div className="fs-12">
								Кешбэк{' '}
								{`${new Intl.NumberFormat('ru-RU').format(total_cashback)} ₽`}
							</div>
						)}
					</>
				);
			},
		}),
	];

	const onRowSelect = useCallback((selected) => {
		const selectedIndexes = Object.keys(selected);
		setSelected(selectedIndexes);
	}, []);

	const deleteSeleted = useCallback(() => {
		if (selected.length > 1) {
			removeItemMany(selected);
		} else {
			removeItem(cartItems[selected[0]].id);
		}
		setSelected([]);
	}, [selected, cartItems]);

	if (isLoading) {
		return (
			<div className="p-5 d-flex justify-content-center align-items-center">
				<CircularProgress size={60} />
			</div>
		);
	}

	return (
		<Table
			selection={true}
			isLoading={isLoading}
			data={cartItems}
			columns={columns}
			title="История поиска"
			onRowSelect={onRowSelect}
			defaltSelected={true}
			selectionColumnWidth={60}
			pagination={false}
			header={
				<div className="d-flex p-3 w-100 justify-content-between flex-row">
					<div className="d-flex justify-content-between">
						<h5 className="mb-0 me-3">Корзина</h5>
						{!cartItems.length && <p className="mb-0">Корзина пуста</p>}
					</div>
					<div className="d-flex  align-items-center justify-content-end flex-row">
						{selected.length > 0 && (
							<strong className="me-3 ms-3">Выбрано ({selected.length})</strong>
						)}
						{selected.length > 0 && (
							<Button
								onClick={deleteSeleted}
								variant="outline-danger"
								size="sm"
								className="me-3"
							>
								Удалить выбранные
							</Button>
						)}
						<div className="d-flex flex-row justify-content-between">
							{appHistory[1] && isLinkToProducts(appHistory[1]) && (
								<Button
									className="w-100 me-3 d-flex justify-content-center align-items-center"
									style={{ minWidth: '225px' }}
									disabled={isEmpty}
									size="sm"
									variant="outline-secondary"
									onClick={() => navigate(appHistory[1])}
								>
									<MaterialIcon
										className={`pe-1 cursor-pointer fs-1`}
										name="arrow_back"
									/>
									Вернуться к покупкам
								</Button>
							)}
							{!isEmpty && !!selected.length && (
								<Button
									// @ts-ignore
									as={Link as unknown}
									className="w-100"
									size="sm"
									to={`/checkout/${selected.join(',')}`}
									variant="primary"
								>
									Продолжить
								</Button>
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
});
