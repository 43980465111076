import { ApiError } from 'api/errors';
import { ServerErrorHandler } from 'helpers/ServerErrorHandler';

export const castError = async (res) => {
	const json = await res.json().catch(() => null);

	if (res.ok && json !== undefined) {
		return json;
	}

	if (res.status >= 500) {
		throw new ApiError('Что-то пошло не так. Попробуйте позже', {}, res.status);
	}

	if (res.status === 422) {
		ServerErrorHandler(json);
	}

	switch (res.status) {
		case 400:
			throw new ApiError('Ничего не найдено', json || {}, res.status);
		case 401:
			throw new ApiError('Нужно войти в систему', json || {}, res.status);
		case 403:
			if (json && json.error) {
				throw new Error(json.error);
			}
			throw new ApiError('Нет доступа', json || {}, res.status);
		case 404:
			if (json && json.message) {
				throw new ApiError(json.message, json, res.status);
			}
			throw new ApiError('Запрашиваемый ресурс не был найден', {}, res.status);
		default:
			if (json && json.message) {
				throw new ApiError(json.message, json, res.status);
			}
			throw new ApiError('Undefined error', json, res.status);
	}
};
