import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import CountUp from 'react-countup';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg4 from 'assets/img/icons/spot-illustrations/corner-4.png';
import { observer } from 'mobx-react-lite';

type TProps = {
	balance: number;
	completedOrderItemsSum: number;
	notCompletedOrderItemsSum: number;
	availableBalance: number;
};

export const BalanceInfo = observer(
	({
		balance,
		completedOrderItemsSum,
		notCompletedOrderItemsSum,
		availableBalance,
	}: TProps) => {
		return (
			<div className="d-flex flex-row justify-content-between align-items-start mb-3">
				<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
					<Background image={bg1} className="bg-card" />
					<Card.Body className="position-relative">
						<h6>Всего зачислено:</h6>
						<div
							className={classNames(
								'text-warning',
								'display-4 fs-4 mb-2 fw-normal font-sans-serif'
							)}
						>
							<CountUp
								start={0}
								end={balance}
								duration={1}
								suffix={'₽'}
								prefix={''}
								separator=","
								decimal="."
							/>
						</div>
					</Card.Body>
				</Card>
				<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
					<Background image={bg1} className="bg-card" />
					<Card.Body className="position-relative">
						<h6>Расход за всё время:</h6>
						<div
							className={classNames(
								'text-warning',
								'display-4 fs-4 mb-2 fw-normal font-sans-serif'
							)}
						>
							<CountUp
								start={0}
								end={completedOrderItemsSum}
								duration={1}
								suffix={'₽'}
								prefix={''}
								separator=","
								decimal="."
							/>
						</div>
					</Card.Body>
				</Card>
				<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
					<Background image={bg2} className="bg-card" />
					<Card.Body className="position-relative">
						<h6>Заморожено средств:</h6>
						<div
							className={classNames(
								'text-info',
								'display-4 fs-4 mb-2 fw-normal font-sans-serif'
							)}
						>
							<CountUp
								start={0}
								end={notCompletedOrderItemsSum}
								duration={1}
								suffix={'₽'}
								prefix={''}
								separator=","
								decimal="."
							/>
						</div>
					</Card.Body>
				</Card>
				<Card className={classNames('overflow-hidden', 'w-100')}>
					<Background image={bg4} className="bg-card" />
					<Card.Body className="position-relative">
						<h6>Доступный остаток:</h6>
						<div
							className={classNames(
								'text-success',
								'display-4 fs-4 mb-2 fw-normal font-sans-serif'
							)}
						>
							<CountUp
								start={0}
								end={availableBalance}
								duration={1}
								suffix={'₽'}
								prefix={''}
								separator=","
								decimal="."
							/>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
);
