export const formDeliveryTime = (
	deliveryTimeType: number,
	deliveryTime: number,
	maxDeliveryTime: number
) => {
	if (deliveryTimeType === 1) return `В наличии`;
	if (deliveryTimeType === 2) return `0-${deliveryTime * 10} часов`;
	if (deliveryTimeType === 3) return `${deliveryTime}-${maxDeliveryTime} дня`;
	return '';
};
