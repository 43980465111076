import { useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { TListDetailByUnit } from 'types/catalog';
import CircularProgress from '@mui/material/CircularProgress';
import { ImagePointer } from './ImagePointer';
import { useCatalogStore } from 'providers/RootStoreProvider';
import useApi from 'hooks/useApi';

type TProps = {
	hovered: TListDetailByUnit[];
	imageUrl: string;
	onHoverId: (id: string) => void;
	unitName: string;
};

export const ListImageMap = observer(
	({ hovered, imageUrl, onHoverId, unitName }: TProps) => {
		const { getListImageMapByUnit, getUid } = useCatalogStore();
		const imageRef = useRef(null);
		const [imageSrc, setImageSrc] = useState('');
		const [imageLoaded, setImageLoaded] = useState(false);
		const { brand, code, ssd, unit_id } = useParams();

		const {
			data: listImageMapByUnit,
			loading: listImageMapByUnitLoading,
			fetch: fetchListImageMapByUnit,
		} = useApi(getListImageMapByUnit);

		const isLoaded =
			!listImageMapByUnitLoading &&
			listImageMapByUnit &&
			listImageMapByUnit.length;

		const getRelatedCoordX = useCallback(
			(number: string) => {
				if (imageRef.current) {
					const factor =
						imageRef.current.clientWidth / imageRef.current.naturalWidth;
					return parseInt(number) * factor;
				}
			},
			[imageRef]
		);

		const getRelatedCoordY = useCallback(
			(number: string) => {
				if (imageRef.current) {
					const factor =
						imageRef.current.clientHeight / imageRef.current.naturalHeight;
					return parseInt(number) * factor;
				}
			},
			[imageRef]
		);

		useEffect(() => {
			fetchListImageMapByUnit({
				catalog: code,
				ssd,
				unit_id,
			});
		}, []);

		useEffect(() => {
			setImageSrc(imageUrl.replace('%size%', 'source'));
		}, [imageUrl]);

		const openInNewTab = useCallback(
			async (oem: string) => {
				const uid = await getUid(oem, brand);
				window.open(`/products/${uid}`, '_blank').focus();
			},
			[brand]
		);

		return (
			<>
				{isLoaded ? (
					<div className="list-image-map ">
						<div className="d-flex justify-content-center">
							<strong>{unitName}</strong>
						</div>
						<div className="position-relative">
							<img
								ref={imageRef}
								onLoad={() => setImageLoaded(true)}
								className="w-100"
								src={imageSrc}
								alt=""
							/>
							{imageLoaded && (
								<>
									{listImageMapByUnit.map((i, index) => {
										return (
											<ImagePointer
												key={index + i.code}
												data={{ ...i, brand }}
												getRelatedCoordY={getRelatedCoordX}
												getRelatedCoordX={getRelatedCoordY}
												hovered={hovered}
												openInNewTab={openInNewTab}
												onHoverId={onHoverId}
											/>
										);
									})}
								</>
							)}
						</div>
					</div>
				) : (
					<div className="p-5 d-flex justify-content-center align-items-center">
						<CircularProgress size={35} />
					</div>
				)}
			</>
		);
	}
);
