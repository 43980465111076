import { castError, b2bApiUrl, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TVendor } from 'types/vendor';

export const fetchVendors = async (query): Promise<TVendor[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/company-vendors/all`, query), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const disableVendor = async (id: number): Promise<void> => {
	return request(
		fetch(b2bApiUrl(`bayer/company-vendors/${id}/disable`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const enableVendor = async (id: number): Promise<void> => {
	return request(
		fetch(b2bApiUrl(`bayer/company-vendors/${id}/enable`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
