import { Offcanvas } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { ShopDetails } from './comp/ShopDetails';
import { useAppStore } from 'providers/RootStoreProvider';

export const OffcanvasContainer = observer(() => {
	const { activeOffcanvas, closeOffcanvas, offcanvasClassName } = useAppStore();

	return (
		<Offcanvas
			show={!!activeOffcanvas}
			onHide={closeOffcanvas}
			className={offcanvasClassName}
		>
			{activeOffcanvas === 'shop' && <ShopDetails />}
		</Offcanvas>
	);
});
