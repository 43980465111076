import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const ButtonAlertLink = ({
	title,
	buttonText,
	link,
}: {
	title: string;
	buttonText: string;
	link: string;
}) => {
	return (
		<div>
			{title}
			<Button
				// @ts-ignore
				as={Link}
				variant="outline-light"
				size="sm"
				className="mt-3"
				to={link}
			>
				{buttonText}
			</Button>
		</div>
	);
};
