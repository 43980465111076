import { makeAutoObservable } from 'mobx';
import { TSavedProduct, TCoreProductID, TImage } from 'types/product';
import { toast } from 'react-toastify';
import {
	fetchSavedProducts,
	saveToSavedProducts,
	removeSavedProducts,
	saveNoteSavedProducts,
} from 'api/product/products';
import { RootStore } from 'stores/RootStore';

export class SavedProductsStore {
	savedProducts: TSavedProduct[] = [];
	isLoading = false;
	totalItems = 0;

	itemsPerPage = 25;
	page = 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	reset = () => {
		this.savedProducts = [];
		this.isLoading = false;
		this.totalItems = 0;
		this.itemsPerPage = 10;
		this.page = 1;
	};

	setPage = (page: number) => {
		this.page = page;
		this.getSavedProducts();
	};

	getSavedProducts = async () => {
		this.isLoading = true;
		const res = await fetchSavedProducts({
			per_page: this.itemsPerPage,
			page: this.page,
		});

		if (res === null) {
			this.isLoading = false;
			return;
		}
		const { data, meta } = res;

		this.totalItems = meta.count;
		this.savedProducts = this.formatSavedProducts(data);

		this.isLoading = false;
	};

	formatSavedProducts = (savedProducts: TSavedProduct[]) => {
		return savedProducts.map((i) => {
			if (i.core_product_images.length > 0) {
				i.preview = this.getImageUrl(i.core_product_images[0]);
			}

			return i;
		});
	};

	getSaved = ({ brand_name, code }: TCoreProductID) => {
		return this.savedProducts.find(
			(i) => i.brand_name === brand_name && i.code === code
		);
	};

	getImageUrl = ({ image_url }: TImage) => {
		return `${process.env.REACT_APP_BACKEND_URL}/${image_url}`;
	};

	addToSavedProducts = async (payload: TCoreProductID) => {
		const res = await saveToSavedProducts(payload);
		if (res === null) return;

		this.savedProducts.push(this.formatSavedProducts([res])[0]);
		toast.success('Добавлено', {
			theme: 'colored',
		});
	};

	removeFromSavedProducts = async (id: number) => {
		const res = await removeSavedProducts(id);
		if (res === null) return;

		this.savedProducts = this.savedProducts.filter((i) => {
			return i.id !== id;
		});
		this.totalItems -= 1;
		toast.success('Удалено', {
			theme: 'colored',
		});
	};

	editNoteItem = async (id: number, note: string) => {
		const res = await saveNoteSavedProducts(id, note);
		if (res === null) return;

		const targetIndex = this.savedProducts.findIndex((i) => i.id === id);
		this.savedProducts[targetIndex] = res;
	};

	init = async () => {
		await this.getSavedProducts();
	};
}
