import { makeAutoObservable } from 'mobx';
import { fetchShipments, fetchShipmentPDF } from 'api/order/order';
import { TShipment } from 'types/order';
import { RootStore } from 'stores/RootStore';

export class ShipmentStore {
	total = 0;
	page = 1;
	orders: TShipment[] = [];
	isLoading = true;
	filterPaid = 'all';
	itemsPerPage = 25;

	root: RootStore;

	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	downloadPDF = async (id: number) => {
		const res = await fetchShipmentPDF(id);
		return res ? res.blob() : null;
	};

	setPaidFilter = (value: string) => {
		this.filterPaid = value;
		this.update();
	};

	setPage = (value) => {
		this.page = value;
		this.update();
	};

	update = async () => {
		this.isLoading = true;

		const res = await fetchShipments({
			page: this.page,
			per_page: this.itemsPerPage,
			...(this.filterPaid !== 'all' && { paid: this.filterPaid }),
		});

		if (res === null) return;

		const { items, meta } = res;

		this.orders = items;
		this.total = meta ? meta.count : 0;

		this.isLoading = false;
	};
}
