import { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/common/Flex';
import { Overlay, Popover } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';

type TProps = {
	setActiveNote: (value: string) => void;
	setActiveNoteId: (value: number) => void;
	id: number;
	note: string;
};

export const SavedComment = observer(
	({ setActiveNote, setActiveNoteId, id, note }: TProps) => {
		const [showNote, setShowNote] = useState(false);
		const iconRef = useRef(null);

		return (
			<>
				<div
					onMouseEnter={() => note && setShowNote(true)}
					onMouseLeave={() => note && setShowNote(false)}
				>
					<Overlay target={iconRef.current} show={showNote} placement="right">
						{(props) => (
							<Popover id="popover-basic" {...props}>
								<Popover.Header as="h3">
									<Flex
										direction="row"
										alignItems="between"
										justifyContent="between"
									>
										Примечание
										<MaterialIcon
											className={`ps-3 cursor-pointer fs-1 text-primary`}
											name="edit"
										/>
									</Flex>
								</Popover.Header>
								<Popover.Body>{note}</Popover.Body>
							</Popover>
						)}
					</Overlay>
					<span
						ref={iconRef}
						onClick={() => {
							if (!note) {
								setActiveNote(note);
								setActiveNoteId(id);
							}
						}}
						className={`cursor-${
							note ? 'default' : 'pointer'
						} material-icons fs-2 text-${note ? 'primary' : 'secondary'}`}
					>
						comment_bank
					</span>
				</div>
			</>
		);
	}
);
