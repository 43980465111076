import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { formDeliveryTime } from 'utils/deliveryTime';
import { TNote } from 'types/note';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { QuantityController } from 'components/e-commerce/QuantityController';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { NoteTableHeader } from './NoteTableHeader';
import { NoteTableFooter } from './NoteTableFooter';
import { useAppStore, useNoteStore } from 'providers/RootStoreProvider';

type TRowData = { row: { original: TNote } };

export const NoteTable = observer(() => {
	const {
		init,
		updateNotesPrice,
		deleteNotes,
		isLoading,
		totalItems,
		itemsPerPage,
		page,
		note,
		setPage,
		totalCostCash,
		totalCostBank,
		customerCost,
		editNote,
		setQuantity,
	} = useNoteStore();
	const { setActiveModal } = useAppStore();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [isPending, setIsPending] = useState<number[]>([]);
	const [defaltSelected, setDefaltSelected] = useState(false);
	const { id: carId } = useParams();

	useEffect(() => {
		init(parseInt(carId));
	}, []);

	const clearSelection = useCallback(() => {
		setSelectedRows([]);
		setDefaltSelected(true);
		setTimeout(() => {
			setDefaltSelected(false);
		}, 0);
	}, []);

	const onRowSelect = useCallback((selected) => {
		const selectedIndexes = Object.keys(selected);
		setSelectedRows(selectedIndexes);
	}, []);

	const getIds = useCallback(() => {
		return note.map((n, i) => {
			if (selectedRows.includes(`${i}`)) return n.id;
		});
	}, [note, selectedRows]);

	const deleteSeleted = useCallback(
		async (id?: number) => {
			const selected = getIds();
			const ids = id ? [id] : selected;

			setIsPending(ids);

			await deleteNotes(ids);
			await init(parseInt(carId));

			clearSelection();
			setIsPending([]);
		},
		[selectedRows, note]
	);

	const updatePrice = useCallback(
		async (id?: number) => {
			const selected = getIds();
			const ids = id ? [id] : selected;

			setIsPending(ids);

			await updateNotesPrice(ids);

			clearSelection();
			setIsPending([]);

			toast.success('Цена обновлена', {
				theme: 'colored',
			});
		},
		[selectedRows, note]
	);

	const handleEdit = useCallback(({ id, quantity, name, note }: TNote) => {
		setActiveModal('modal_form', 'middle-size', {
			add: (payload) => editNote(id, payload),
			initialData: { customerCost, note, quantity, name },
			title: 'Редактирование',
			fields: [
				{ name: 'name', label: 'Наименование запчасти' },
				{ name: 'customerCost', label: 'Цена клиента' },
				{ name: 'note', label: 'Комментарий' },
				{ name: 'quantity', label: 'Количество' },
			],
			submitButtonText: 'Сохранить',
		});
	}, []);

	const handleChange = useCallback((id: number, quantity: number) => {
		return setQuantity(id, quantity);
	}, []);

	const columns = [
		TableColumn({
			header: 'Дата',
			width: 120,
			cellClassName: 'py-2',
			headerClassName: 'ps-0',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { createdAt } = rowData.row.original;
				return (
					<>
						<div>{dayjs(createdAt).format('YYYY-MM-DD')}</div>
						<div>{dayjs(createdAt).format('HH:MM')}</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Название',
			width: 235,
			headerClassName: 'py-2 ps-0',
			cellClassName: 'py-2',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { name, code, brand, uid, officeId } = rowData.row.original;
				return (
					<>
						<div>
							<Link
								to={`/products/${uid}?office_id=${officeId}`}
								className="text-primary"
							>
								<OverflowedTextTooltip text={`Арт. ${code}, ${brand}`} />
							</Link>
						</div>
						<div>{name}</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Наличие',
			width: 100,
			headerClassName: 'ps-0 py-2 ',
			cell: (rowData: TRowData) => {
				const { note, deliveryTimeType, deliveryTime, maxDeliveryTime } =
					rowData.row.original;
				return (
					<>
						<div className="mb-2">
							<SoftBadge
								pill
								bg={classNames({
									success: deliveryTimeType === 1,
									primary: deliveryTimeType === 2,
									secondary: deliveryTimeType === 3,
								})}
								className="d-block p-2"
							>
								{formDeliveryTime(
									deliveryTimeType,
									deliveryTime,
									maxDeliveryTime
								)}
							</SoftBadge>
						</div>
						{note && <div>{note}</div>}
					</>
				);
			},
		}),
		TableColumn({
			header: 'Продавец',
			width: 325,
			cellClassName: 'pe-4',
			headerClassName: 'ps-0',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { officeName, officeAddress, officePhone, cityName } =
					rowData.row.original;
				return (
					<>
						<div className="w-100">
							<OverflowedTextTooltip
								text={`${officeName}, ${cityName}, ${officeAddress}`}
								value={
									<>
										{officeName} <br />
										{cityName} {officeAddress} <br />
									</>
								}
							/>
						</div>
						<div>{officePhone}</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Количество',
			width: 150,
			headerClassName: 'ps-0 pe-1',
			cellClassName: 'py-2 pe-5',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { id, quantity, multiplicity } = rowData.row.original;
				const multiplicityStep = multiplicity ? multiplicity : 1;

				return (
					<div className="w-100 d-flex justify-content-center">
						<QuantityController
							quantity={quantity ? quantity : 1}
							multiplicityStep={multiplicityStep}
							onChange={(value) => handleChange(id, value)}
							onIncrease={() => handleChange(id, quantity + multiplicityStep)}
							onDecrease={() =>
								quantity > 1 && handleChange(id, quantity - multiplicityStep)
							}
							btnClassName="px-2 d-flex justify-content-center align-items-center"
						/>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Цена, безнал',
			width: 125,
			cellClassName: 'py-2',
			headerClassName: 'ps-0',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { costBank } = rowData.row.original;
				return (
					<div>
						{costBank ? (
							<strong>
								{`${new Intl.NumberFormat('ru-RU').format(costBank)} ₽`}
							</strong>
						) : (
							<hr />
						)}
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Цена, нал',
			width: 125,
			cellClassName: ' py-2',
			headerClassName: 'ps-0',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { costCash } = rowData.row.original;
				return (
					<div>
						<strong>
							{`${new Intl.NumberFormat('ru-RU').format(costCash)} ₽`}
						</strong>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Цена, клиент',
			width: 125,
			cellClassName: 'py-2',
			headerClassName: 'ps-0',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { customerCost } = rowData.row.original;
				return (
					<>
						{customerCost ? (
							<strong>
								{`${new Intl.NumberFormat('ru-RU').format(customerCost)} ₽`}
							</strong>
						) : (
							<div className="w-50">
								<hr className="border" />
							</div>
						)}
					</>
				);
			},
		}),
		TableColumn({
			width: 10,
			cellStyle: {
				alignItems: 'flex-end',
			},
			cell: (rowData) => {
				const { id } = rowData.row.original;

				return (
					<div className="py-1">
						{isPending.includes(id) ? (
							<div className="pe-3">
								<CircularProgress size={16} />
							</div>
						) : (
							<CardDropdown>
								<div className="py-2">
									<Dropdown.Item
										onClick={() => handleEdit(rowData.row.original)}
									>
										Редактировать
									</Dropdown.Item>
									<Dropdown.Item onClick={() => updatePrice(id)}>
										Обновить цену
									</Dropdown.Item>
									<Dropdown.Item onClick={() => deleteSeleted(id)}>
										Удалить
									</Dropdown.Item>
								</div>
							</CardDropdown>
						)}
					</div>
				);
			},
		}),
	];

	return (
		<Table
			selection={true}
			onRowSelect={onRowSelect}
			isLoading={isLoading}
			data={note}
			columns={columns}
			pagination={false}
			totalItems={totalItems}
			defaltSelected={defaltSelected}
			setPage={setPage}
			page={page}
			itemsPerPage={itemsPerPage}
			selectionColumnWidth={40}
			tableMinHeight={'100px'}
			header={
				<NoteTableHeader
					selectedRows={selectedRows}
					updatePrice={() => updatePrice()}
					deleteSeleted={() => deleteSeleted()}
				/>
			}
			footer={
				<NoteTableFooter
					totalCostCash={totalCostCash}
					totalCostBank={totalCostBank}
					customerCost={customerCost}
				/>
			}
		/>
	);
});
