import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { PaginationWrapper } from 'components/common/PaginationWrapper';
import { NotFound } from 'components/common/NotFound';
import { ListItem } from './comp/ListItem';
import {
	useAdminCompaniesStore,
	useAppStore,
} from 'providers/RootStoreProvider';

export const AdminCompanies = observer(() => {
	const {
		init,
		isLoading,
		list,
		itemsPerPage,
		setActiveId,
		setPage,
		page,
		total,
	} = useAdminCompaniesStore();
	const { setActiveModal } = useAppStore();

	useEffect(() => {
		init();
	}, []);

	const handleEdit = async (id: number) => {
		setActiveId(id);
		await setActiveModal('admin-edit_company');
	};

	return (
		<>
			{isLoading ? (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
			) : (
				<>
					<Card className="mb-3">
						<Card.Body className="position-relative ">
							<h4 className="mb-0">Список компаний</h4>
						</Card.Body>
					</Card>

					<PaginationWrapper
						totalItems={total}
						itemsPerPage={itemsPerPage}
						page={page}
						setPage={setPage}
					>
						{list.length ? (
							list.map((company) => {
								return (
									<Card className="mb-3" key={company.id}>
										<Card.Body>
											<ListItem handleEdit={handleEdit} company={company} />
										</Card.Body>
									</Card>
								);
							})
						) : (
							<NotFound />
						)}
					</PaginationWrapper>
				</>
			)}
		</>
	);
});
