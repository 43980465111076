import { STORAGE_PHOTO_PARTS_URL } from 'consts/storage';

export const swap = (array, indexA, indexB) => {
	const tmp = array[indexA];
	array[indexA] = array[indexB];
	array[indexB] = tmp;
	return array;
};

export const getImageURL = (id: number, number: string) => {
	return `${STORAGE_PHOTO_PARTS_URL}/${id}/${number}.jpg`;
};

export const addNumToArray = (
	arr: unknown[],
	value: unknown,
	index: number
) => {
	return index !== -1
		? [...arr.slice(0, index + 1), value, ...arr.slice(index + 1)]
		: arr;
};

function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
	};
}
