import { useEffect } from 'react';
import ru from 'date-fns/locale/ru';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import { FilterButton } from 'components/common/filter/FilterButton';
import { ORDER_STATUS } from 'consts/order';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useDebouncedCallback } from 'use-debounce';

registerLocale('ru', ru);

type TFilters = {
	status: number;
	from?: string;
	to?: string;
	comment?: string;
	code?: string;
	brand?: string;
	name?: string;
	cash?: boolean;
	order_id?: number;
};

type TProps = {
	update: (filters: {
		status: number;
		from?: string;
		to?: string;
		comment?: string;
		code?: string;
		brand?: string;
		name?: string;
		cash?: boolean;
		order_id?: number;
	}) => void;
	totalCost: number;
	filters: TFilters;
	setFilter: (value) => void;
};

export const OrderFilters = observer(
	({ update, totalCost, filters, setFilter }: TProps) => {
		const { comment, status, from, to, code, brand, name, cash, order_id } =
			filters;

		const handleUpdate = () => {
			update({
				from: from ? dayjs(from).format('DD.MM.YYYY') : null,
				to: to ? dayjs(to).format('DD.MM.YYYY') : null,
				comment,
				status,
				code,
				brand,
				name,
				cash,
				order_id,
			});
		};

		const handleUpdateDebounce = useDebouncedCallback(
			() => handleUpdate(),
			500
		);

		useEffect(() => {
			handleUpdateDebounce();
		}, [comment, code, name, brand, order_id]);

		useEffect(() => {
			handleUpdate();
		}, [from, to, status, cash]);

		const handleFilter = (data) => {
			setFilter((filters) => ({ ...filters, ...data }));
		};

		return (
			<div className="balace-filter d-flex justify-content-start  align-items-center flex-column">
				<div className="d-flex flex-row justify-content-start align-items-center mb-3">
					<Form.Group
						className="d-flex align-items-center me-3"
						controlId="formHorizontalEmail"
						style={{ maxWidth: '300px' }}
					>
						<div className="d-flex justify-content-start align-items-center flex-row position-relative">
							{from && (
								<MaterialIcon
									onClick={() => handleFilter({ from: null })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							{/* @ts-ignore */}
							<ReactDatePicker
								selected={from}
								onChange={(date) => handleFilter({ from: date })}
								className="form-control fs-14"
								dateFormat="dd-MM-yyyy"
								placeholderText="ОТ"
								selectsStart
								startDate={from}
								endDate={to}
								locale="ru"
							/>
						</div>
						<div className="d-flex mx-2">
							<span>—</span>
						</div>
						<div className="d-flex justify-content-start align-items-center flex-row position-relative">
							{to && (
								<MaterialIcon
									onClick={() => handleFilter({ to: null })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							{/* @ts-ignore */}
							<ReactDatePicker
								selected={to}
								onChange={(date) => handleFilter({ to: date })}
								className="form-control fs-14"
								dateFormat="dd-MM-yyyy"
								placeholderText="ДО"
								selectsEnd
								startDate={from}
								endDate={to}
								locale="ru"
							/>
						</div>
					</Form.Group>
					<Form.Group
						className="d-flex align-items-center me-3"
						style={{ minWidth: '150px' }}
					>
						<div className="w-100 d-flex justify-content-start align-items-center flex-row position-relative">
							{order_id && (
								<MaterialIcon
									onClick={() => handleFilter({ order_id: '' })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							<Form.Control
								placeholder={'Поиск по id заказа'}
								className="fs-14 w-100"
								value={order_id}
								onChange={({ target: { value } }) =>
									handleFilter({ order_id: value })
								}
							/>
						</div>
					</Form.Group>
					<Form.Group
						className="d-flex align-items-center me-3"
						style={{ minWidth: '150px' }}
					>
						<div className="w-100 d-flex justify-content-start align-items-center flex-row position-relative">
							{comment && (
								<MaterialIcon
									onClick={() => handleFilter({ comment: '' })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							<Form.Control
								placeholder={'Поиск по примечанию'}
								className="fs-14 w-100"
								value={comment}
								onChange={({ target: { value } }) =>
									handleFilter({ comment: value })
								}
							/>
						</div>
					</Form.Group>
					<Form.Group
						className="d-flex align-items-center me-3"
						style={{ minWidth: '150px' }}
					>
						<div className="w-100 d-flex justify-content-start align-items-center flex-row position-relative">
							{code && (
								<MaterialIcon
									onClick={() => handleFilter({ code: '' })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							<Form.Control
								placeholder={'Поиск по артикулу'}
								className="fs-14 w-100"
								value={code}
								onChange={({ target: { value } }) =>
									handleFilter({ code: value })
								}
							/>
						</div>
					</Form.Group>

					<Form.Group
						className="d-flex align-items-center me-3"
						style={{ minWidth: '150px' }}
					>
						<div className="w-100 d-flex justify-content-start align-items-center flex-row position-relative">
							{brand && (
								<MaterialIcon
									onClick={() => handleFilter({ brand: '' })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							<Form.Control
								placeholder={'Поиск по бренду'}
								className="fs-14 w-100"
								value={brand}
								onChange={({ target: { value } }) =>
									handleFilter({ brand: value })
								}
							/>
						</div>
					</Form.Group>
					<Form.Group
						className="d-flex align-items-center me-3"
						style={{ minWidth: '150px' }}
					>
						<div className="w-100 d-flex justify-content-start align-items-center flex-row position-relative">
							{name && (
								<MaterialIcon
									onClick={() => handleFilter({ name: '' })}
									className="date-reset-trigger cursor-pointer fs-16"
									name="close"
								/>
							)}
							<Form.Control
								placeholder={'Поиск по наименованию'}
								className="fs-14 w-100"
								value={name}
								onChange={({ target: { value } }) =>
									handleFilter({ name: value })
								}
							/>
						</div>
					</Form.Group>
				</div>
				<div className="d-flex justify-content-start align-items-center w-100">
					{ORDER_STATUS.map((item, index) => {
						const id = index;
						const isActive = status === id;

						if (item.status) {
							return (
								<FilterButton
									key={`order_item_filter_${id}`}
									onClick={() => handleFilter({ status: id })}
									isActive={isActive}
									className="me-2 white-space-nowrap"
								>
									{item.status}
								</FilterButton>
							);
						} else {
							return (
								<FilterButton
									key={`order_item_filter_${id}`}
									onClick={() => handleFilter({ status: undefined })}
									isActive={status === null || undefined}
									className="me-2 white-space-nowrap"
								>
									Все
								</FilterButton>
							);
						}
					})}
					<span className="mx-2">|</span>
					<div className="mx-1 d-flex justify-content-start align-items-center">
						<FilterButton
							onClick={() => handleFilter({ cash: cash ? false : true })}
							isActive={cash !== null}
							className="me-2 white-space-nowrap"
						>
							{cash === null ? 'Нал / Безнал' : cash ? 'Нал' : 'Безнал'}
						</FilterButton>
						{cash !== null && (
							<MaterialIcon
								onClick={() => handleFilter({ cash: null })}
								className=" cursor-pointer fs-16"
								name="close"
							/>
						)}
					</div>
					<div className="white-space-nowrap ms-2">
						<span className="fs-14">Сумма заказов: </span>
						<b className="fs-16">{`${new Intl.NumberFormat('ru-RU').format(
							totalCost
						)} ₽`}</b>
					</div>
				</div>
			</div>
		);
	}
);
