import { useState } from 'react';
import confirmBadge from 'assets/img/icons/confirm-badge.svg';
import notConfirmBadge from 'assets/img/icons/not-confirm-badge.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { observer } from 'mobx-react';

export const ConfirmIcon = observer(
	({ confirmed, className }: { confirmed: boolean; className?: string }) => {
		const [show, setShow] = useState(false);

		const ShopConfirmTooltip = (props) => (
			<Tooltip className="fill-width" {...props}>
				{confirmed
					? 'Компания прошла юридическую проверку'
					: 'Компания не предоставила уставные документы на проверку'}
			</Tooltip>
		);

		return (
			<OverlayTrigger
				show={show}
				placement="bottom"
				delay={{ show: 250, hide: 400 }}
				overlay={ShopConfirmTooltip}
			>
				<img
					onMouseEnter={() => setShow(true)}
					onMouseLeave={() => setShow(false)}
					src={confirmed ? confirmBadge : notConfirmBadge}
					className={className}
					alt=""
				/>
			</OverlayTrigger>
		);
	}
);
