import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthLayout } from './AuthLayout';
import is from 'is_js';
import { MainLayout } from './MainLayout';
import { ProductLayout } from './ProductLayout';
import ErrorLayout from './ErrorLayout';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import { Dashboard } from 'components/dashboard/Dashboard';
import { Login } from 'components/authentication/Login';
import { Registration } from 'components/authentication/Registration';
import { PasswordReset } from '../components/authentication/PasswordReset';
import { ConfirmMail } from 'components/authentication/ConfirmMail';
import { ForgetPassword } from 'components/authentication/ForgetPassword';
import { PrivateRoute } from 'routes/PrivateRoute';
import { Settings } from 'components/user/settings/Settings';
import { ShoppingCart } from 'components/e-commerce/cart/ShoppingCart';
import { Checkout } from 'components/e-commerce/checkout/Checkout';
import { SavedProductsTable } from 'components/e-commerce/SavedProducts/SavedProductsTable';
import { ReviewsTable } from 'components/reviews/ReviewsTable';
import { Support } from 'components/support/Support';
import { Company } from 'components/user/company/Company';
import { Balance } from 'components/user/balance/Balance';
import { Billing } from 'components/user/billing/Billing';
import { SearchHistoryTable } from 'components/search/SearchHistoryTable';
import { OrdersTable } from 'components/orders/OrdersTable';
import { SingleSearch } from 'components/product/SingleSearch';
import { ProductMapPage } from 'components/product/product-map/ProductMapPage';
import { CheckoutSuccess } from 'components/e-commerce/checkout/CheckoutSuccess';
import { OrderDetails } from 'components/orders/OrderDetails';
import { SavedShopTable } from 'components/shop/comp/SavedShopTable';
import { AppLayout } from './AppLayout';
import { SuperLayout } from './SuperLayout';
import { SavedCars } from 'components/cars/SavedCars';
import { NoteCarTable } from 'components/notes/NoteCarTable';
import { SuccessRegistration } from 'components/authentication/SuccessRegistration';
import { MultiSearch } from 'components/product/multisearch/MultiSearch';
import { CompanyRequisites } from 'components/user/company/CompanyRequisites';
import { VendorTable } from 'components/vendor/VendorTable';
import { Cashback } from 'components/user/cashback/Cashback';
import { Warehouse } from 'components/warehouse/Warehouse';
import { Companies } from 'components/companies/Companies';
import { CompaniesInfo } from 'components/companies/CompaniesInfo';
import { Docs } from 'components/docs/Docs';
import { Catalog } from 'components/catalog/Catalog';
import { CatalogSelect } from 'components/catalog/CatalogSelect';
import { CatalogTables } from 'components/catalog/CatalogTables';
import { CatalogModel } from 'components/catalog/CatalogModel';
import { CatalogUnit } from 'components/catalog/CatalogUnit';
import { PaymentsShopList } from 'components/paymentsShop/PaymentsShopList';
import { PaymentsShopItem } from 'components/paymentsShop/PaymentsShopItem';
import { CatalogSearchHistory } from 'components/catalog/CatalogSearchHistory';
import { ManagerDetail } from 'components/dashboard/ManagerDetail';
import { AdminCompanies } from 'components/adminCompanies/AdminCompanies';
import { Tasks } from 'components/tasks/Tasks';
import { OfficeMapPage } from 'components/office/OfficeMapPage';
import { GazTable } from 'components/gaz/GazTable';
import { ShipmentsTable } from 'components/orders/ShipmentsTable';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/RootStoreProvider';
import { Tyres } from 'components/tyres/Tyres';

export const Layout = observer(() => {
	const { isAuthorized, isAuthLoaded } = useAuthStore();
	const HTMLClassList = document.getElementsByTagName('html')[0].classList;

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows');
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome');
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox');
		}
	}, [HTMLClassList]);

	return (
		<>
			<Routes>
				<Route element={<SuperLayout />}>
					<Route element={<ErrorLayout />}>
						<Route path="errors/404" element={<Error404 />} />
						<Route path="errors/500" element={<Error500 />} />
					</Route>
					<Route element={<AuthLayout />}>
						<Route path="authentication/login" element={<Login />} />
						<Route
							path="authentication/success-sign-up"
							element={<SuccessRegistration />}
						/>
						<Route path="authentication/register" element={<Registration />} />
						<Route
							path="authentication/forgot-password"
							element={<ForgetPassword />}
						/>
						<Route path="reset_password" element={<PasswordReset />} />
						<Route
							path="authentication/confirm-mail"
							element={<ConfirmMail />}
						/>
					</Route>
					<Route element={<AppLayout />}>
						<Route path="docs/:doc" element={<Docs />} />
						<Route element={<ProductLayout />}>
							<Route path="multisearch" element={<MultiSearch />} />
							<Route path="products/:uid" element={<SingleSearch />} />
							<Route path="products/:uid/map" element={<ProductMapPage />} />
						</Route>
						<Route element={<MainLayout />}>
							<Route path="office-map" element={<OfficeMapPage />} />
							<Route
								path="/"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Catalog />
									</PrivateRoute>
								}
							/>
							<Route
								path="/shipments"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<ShipmentsTable />
									</PrivateRoute>
								}
							/>
							<Route path="support" element={<Support />} />
							<Route
								path="warehouse"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Warehouse />
									</PrivateRoute>
								}
							/>
							<Route
								path="saved-cars"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<SavedCars />
									</PrivateRoute>
								}
							/>
							<Route
								path="tyres"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Tyres />
									</PrivateRoute>
								}
							/>
							<Route
								path="car-notes/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<NoteCarTable />
									</PrivateRoute>
								}
							/>

							<Route
								path="search-history"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<SearchHistoryTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="catalog-norm-gaz"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<GazTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="catalog"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Catalog />
									</PrivateRoute>
								}
							/>
							<Route
								path="catalog/:brand/:code"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CatalogSelect />
									</PrivateRoute>
								}
							/>
							<Route
								path="companies"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Companies />
									</PrivateRoute>
								}
							/>
							<Route
								path="companies/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CompaniesInfo />
									</PrivateRoute>
								}
							/>
							<Route
								path="catalog/:brand/:code/:ssd"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CatalogTables />
									</PrivateRoute>
								}
							/>
							<Route
								path="dashboards"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Dashboard />
									</PrivateRoute>
								}
							/>
							<Route
								path="catalog-model/:mode/:brand/:code/:ssd/:vehicle_id/:category_id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CatalogModel />
									</PrivateRoute>
								}
							/>

							<Route
								path="catalog-unit/:brand/:code/:ssd/:unit_id/:vehicle_id/:category_id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CatalogUnit />
									</PrivateRoute>
								}
							/>
							<Route
								path="catalog/:brand/:code/:ssd"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CatalogTables />
									</PrivateRoute>
								}
							/>
							<Route
								path="saved-products"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<SavedProductsTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="saved-shops"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<SavedShopTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="reviews"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<ReviewsTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="orders"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<OrdersTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="order-details/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<OrderDetails />
									</PrivateRoute>
								}
							/>
							<Route
								path="cart"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<ShoppingCart />
									</PrivateRoute>
								}
							/>
							<Route
								path="checkout/:indexes"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Checkout />
									</PrivateRoute>
								}
							/>
							<Route
								path="checkout"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Checkout />
									</PrivateRoute>
								}
							/>
							<Route
								path="checkout/success/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CheckoutSuccess />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/cashback"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Cashback />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/settings"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Settings />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/my-reviews"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<ReviewsTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/organization"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Company />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/requisites"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CompanyRequisites />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/vendor"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<VendorTable />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/balance-payment"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Balance />
									</PrivateRoute>
								}
							/>
							<Route
								path="user/billing"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Billing />
									</PrivateRoute>
								}
							/>
							<Route
								path="companies"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Companies />
									</PrivateRoute>
								}
							/>
							<Route
								path="companies/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CompaniesInfo />
									</PrivateRoute>
								}
							/>
							<Route
								path="payments-shop"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<PaymentsShopList />
									</PrivateRoute>
								}
							/>
							<Route
								path="payments-shop/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<PaymentsShopItem />
									</PrivateRoute>
								}
							/>
							<Route
								path="cars_search_logs"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<CatalogSearchHistory />
									</PrivateRoute>
								}
							/>
							<Route
								path="dashboards/manager_id/:id"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<ManagerDetail />
									</PrivateRoute>
								}
							/>
							<Route
								path="admin_сompanies"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<AdminCompanies />
									</PrivateRoute>
								}
							/>
							<Route
								path="tasks"
								element={
									<PrivateRoute
										redirectedPath="/authentication/login"
										isLoaded={isAuthLoaded}
										isAllowed={isAuthorized}
									>
										<Tasks />
									</PrivateRoute>
								}
							/>
						</Route>
					</Route>
					{/* <Route path="*" element={<Navigate to="/errors/404" replace />} /> */}
				</Route>
			</Routes>
		</>
	);
});
