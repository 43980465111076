import React from 'react';
import classNames from 'classnames';
import Background from './Background';
import { Container } from 'react-bootstrap';

type TProps = {
  fluid?: boolean | false;
  bg?: string;
  image?: string;
  overlay?: string | boolean;
  position?: string | { x: string; y: string };
  video?: string[];
  bgClassName?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
};

const Section = ({
  fluid,
  bg,
  image,
  overlay,
  position,
  video,
  className,
  children,
  ...rest
}: TProps) => {
  const bgProps = { image, overlay, position, video };

  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
      {image && <Background {...bgProps} />}
      <Container fluid={fluid}>{children}</Container>
    </section>
  );
};

export default Section;
