import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore, useAuthStore } from 'providers/RootStoreProvider';

export const AuthLayout = observer(() => {
	const { isAuthorized } = useAuthStore();
	const { history: appHistory } = useAppStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthorized) {
			navigate(appHistory[1] ? appHistory[1] : '/');
		}
	}, [isAuthorized]);

	return (
		<Section className="py-0">
			<Row className="flex-center min-vh-100 py-6">
				<Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
					<Logo style={{ lineHeight: '0' }} at="navbar-top" />
					<Card>
						<Card.Body className="p-4 p-sm-5">
							<Outlet />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Section>
	);
});
