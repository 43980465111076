import { observer } from 'mobx-react';
import { TPriceList } from 'types/product';
import { TOffice } from 'types/office';
import { Placeholder } from 'react-bootstrap';
import { TooltipElement } from 'components/common/TooltipElement';
import IconButton from '@mui/material/IconButton';

export const ProductItemDeliveryNote = observer(
	({
		isLoading,
		priceList,
		office,
		hideDelivery,
		deliveryMessage,
	}: {
		isLoading: boolean;
		priceList: TPriceList;
		office: TOffice;
		hideDelivery?: boolean;
		deliveryMessage: string;
	}) => {
		return (
			<div className="cell-container">
				{!isLoading ? (
					<>
						{!hideDelivery && (
							<>
								{(priceList.pickup ||
									priceList.delivery ||
									priceList.transportCompany) && (
									<strong>
										{priceList.pickup && 'Самовывоз'}
										{priceList.pickup &&
											(priceList.delivery || priceList.transportCompany) &&
											', '}
										{priceList.delivery && 'Курьер'}
										{priceList.delivery && priceList.transportCompany && ', '}
										{priceList.transportCompany && 'Отправка ТК'}
									</strong>
								)}
							</>
						)}
						<p className="fs-12 mt-1 mb-0">
							{deliveryMessage ? (
								<div className="d-flex justify-content-start align-items-start">
									<div className="pe-3">
										<div
											dangerouslySetInnerHTML={{ __html: deliveryMessage }}
										/>
									</div>
									<TooltipElement
										focus={true}
										value={
											<>
												<div
													dangerouslySetInnerHTML={{ __html: office.notes }}
												/>
											</>
										}
									>
										<IconButton
											color="primary"
											component="label"
											style={{
												fontSize: '12px',
												width: '18px',
												height: '18px',
											}}
											className={`me-2 d-flex border justify-content-center align-items-center`}
										>
											?
										</IconButton>
									</TooltipElement>
								</div>
							) : (
								<div dangerouslySetInnerHTML={{ __html: office.notes }} />
							)}
						</p>
					</>
				) : (
					<div style={{ width: '200px' }}>
						<Placeholder xs={3} /> <Placeholder xs={3} /> <Placeholder xs={3} />
					</div>
				)}
				<div className="d-flex justify-content-start flex-column pt-1">
					<img
						className="product-item-delivery-note-pricelist-logo-vendors"
						src={`https://gettzap.hb.ru-msk.vkcloud-storage.ru/uploads/logo_vendors/${priceList.id}.jpg`}
						alt=""
						// @ts-ignore
						onError={(i) => (i.target.style.display = 'none')}
					/>
				</div>
			</div>
		);
	}
);
