import { useCallback, useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { TOrderData, TOrderDoc, TOrderPaymentDocs } from 'types/order';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import {
	useAppStore,
	useAuthStore,
	useOrderStore,
} from 'providers/RootStoreProvider';

const DOC_TYPE = {
	torg12: 'torg12',
	invoice: 'invoice',
	upd: 'upd',
	invoiceCash: 'invoice-cash',
};

type TProps = {
	data: TOrderData;
	docs: TOrderDoc[];
	paymentDocs: TOrderPaymentDocs[];
};

export const OrderInfo = observer(({ data, docs, paymentDocs }: TProps) => {
	const { downloadPDF, downloadPDFTemporary } = useOrderStore();
	const { user } = useAuthStore();
	const { setActiveModal } = useAppStore();
	const [isPending, setPending] = useState<string>('');
	const isNeedToPay = paymentDocs.find((i) => !i.paid);

	const handleDownload = useCallback(
		async (id: number, docType: string, fileName: string) => {
			setPending(fileName);
			const blob = await downloadPDF(id, docType);
			setPending('');
			download(blob, fileName);
		},
		[]
	);

	const handleDownloadTemporary = useCallback(
		async (docType: string, fileName: string) => {
			setPending(fileName);
			const blob = await downloadPDFTemporary(data.orderId, docType);
			setPending('');
			download(blob, fileName);
		},
		[data]
	);

	const download = useCallback((blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}, []);

	const handlePayment = useCallback(() => {
		setActiveModal('order-payment', 'middle-size', { paymentDocs });
	}, [paymentDocs]);

	return (
		<Card className="mb-3">
			<Card.Body>
				<Row>
					<Col md={6} lg={4} className="mb-4 mb-lg-0">
						<h5 className="mb-3 fs-0">Поставщик</h5>
						<h6 className="mb-2">{data.officeName}</h6>
						<p className="mb-1 fs--1">
							{data.cityName}, {data.officeAddress}
						</p>
						<p className="mb-0 fs--1">
							<strong>
								Почта:{' '}
								<a href={`mailto:${data.officeEmail1}`}>{data.officeEmail1}</a>
							</strong>
						</p>
						<p className="mb-0 fs--1">
							<strong>Телефон: {data.officePhone1}</strong>
						</p>
					</Col>
					<Col md={6} lg={4} className="mb-4 mb-lg-0">
						<h5 className="mb-3 fs-0">Покупатель</h5>
						<h6 className="mb-2">{data.companyName}</h6>
						<p className="mb-1 fs--1">{data.companyAddress}</p>
						<p className="mb-0 fs--1">
							<strong>
								Почта:{' '}
								<a href={`mailto:${data.customerEmail}`}>
									{data.customerEmail}
								</a>
							</strong>
						</p>
						<p className="mb-1 fs--1">
							<strong>Телефон: {data.customerPhone}</strong>
						</p>

						{data.comment && (
							<p className="mb-1 fs--1">Комментарий: {data.comment}</p>
						)}
					</Col>
					<Col md={6} lg={4} className="mb-4 mb-lg-0">
						<h5 className="mb-3 fs-0">Документы</h5>
						<div className="d-flex flex-column">
							{docs && docs.length ? (
								<>
									{docs.map((doc) => {
										let fileName;

										if (doc.docType === 'torg12') {
											fileName = `${data.orderId}-${doc.id}-ТОРГ12.pdf`;
										}
										if (doc.docType === 'invoice') {
											fileName = `${data.orderId}-${doc.id}-СЧЁТ-ФАКТУРА.pdf`;
										}
										if (doc.docType === 'upd') {
											fileName = `${data.orderId}-${doc.id}-УПД.pdf`;
										}
										if (doc.docType === 'invoiceCash') {
											fileName = `${data.orderId}-${doc.id}-РАСХОДНАЯ-НАКЛАДНАЯ.pdf`;
										}

										return (
											<div
												key={doc.id + 'doc'}
												onClick={() =>
													handleDownload(
														doc.id,
														DOC_TYPE[doc.docType],
														fileName
													)
												}
												className="link-type-text fs-13 text-decoration-none"
											>
												{fileName}{' '}
												{isPending === fileName && (
													<span className="ms-2">
														<CircularProgress size={13} />
													</span>
												)}
											</div>
										);
									})}
								</>
							) : (
								<>
									{user && user.partner ? (
										<>
											<div className="fs-14">
												Документы будут сформированы после передачи заказа на
												доставку
											</div>
										</>
									) : (
										<>
											{!data.cash && !data.shopVat && (
												<>
													<div
														onClick={() =>
															handleDownloadTemporary(
																'upd',
																`${data.orderId}-УПД.pdf`
															)
														}
														className="link-type-text fs-13 text-decoration-none"
													>
														УПД.pdf{' '}
														{isPending === `${data.orderId}-УПД.pdf` && (
															<span className="ms-2">
																<CircularProgress size={13} />
															</span>
														)}
													</div>
													<div
														onClick={() =>
															handleDownloadTemporary(
																'torg12',
																`${data.orderId}-ТОРГ12.pdf`
															)
														}
														className="link-type-text fs-13 text-decoration-none"
													>
														ТОРГ12.pdf{' '}
														{isPending === `${data.orderId}-ТОРГ12.pdf` && (
															<span className="ms-2">
																<CircularProgress size={13} />
															</span>
														)}
													</div>
												</>
											)}
											{!data.cash && data.shopVat && (
												<>
													<div
														onClick={() =>
															handleDownloadTemporary(
																'invoice',
																`${data.orderId}-СЧЁТ-ФАКТУРА.pdf`
															)
														}
														className="link-type-text fs-13 text-decoration-none"
													>
														СЧЁТ-ФАКТУРА.pdf{' '}
														{isPending ===
															`${data.orderId}-СЧЁТ-ФАКТУРА.pdf` && (
															<span className="ms-2">
																<CircularProgress size={13} />
															</span>
														)}
													</div>
													<div
														onClick={() =>
															handleDownloadTemporary(
																'torg12',
																`${data.orderId}-ТОРГ12.pdf`
															)
														}
														className="link-type-text fs-13 text-decoration-none"
													>
														ТОРГ12.pdf{' '}
														{isPending === `${data.orderId}-ТОРГ12.pdf` && (
															<span className="ms-2">
																<CircularProgress size={13} />
															</span>
														)}
													</div>
												</>
											)}
											{data.cash && (
												<>
													<div
														onClick={() =>
															handleDownloadTemporary(
																'invoice-cash',
																`${data.orderId}-РАСХОДНАЯ-НАКЛАДНАЯ.pdf`
															)
														}
														className="link-type-text fs-13 text-decoration-none"
													>
														РАСХОДНАЯ-НАКЛАДНАЯ.pdf{' '}
														{isPending ===
															`${data.orderId}-РАСХОДНАЯ-НАКЛАДНАЯ.pdf` && (
															<span className="ms-2">
																<CircularProgress size={13} />
															</span>
														)}
													</div>
													<div
														onClick={() =>
															handleDownloadTemporary(
																'torg12',
																`${data.orderId}-ТОРГ12.pdf`
															)
														}
														className="link-type-text fs-13 text-decoration-none"
													>
														ТОРГ12.pdf{' '}
														{isPending === `${data.orderId}-ТОРГ12.pdf` && (
															<span className="ms-2">
																<CircularProgress size={13} />
															</span>
														)}
													</div>
												</>
											)}
										</>
									)}
								</>
							)}
							{isNeedToPay && (
								<div>
									<Button
										onClick={handlePayment}
										className="mt-3 fs-12 px-2"
										size="sm"
									>
										Требуется оплата
									</Button>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
});
