import { observer } from 'mobx-react-lite';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useAppStore, useAuthStore } from 'providers/RootStoreProvider';

import 'styles/Navbar.scss';

export const MultiSearchButton = observer(() => {
	const { setActiveModal } = useAppStore();
	const { user } = useAuthStore();

	if (user && user.partner) return null;

	return (
		<div
			onClick={() => setActiveModal('multi_search')}
			className="multi-search-button cursor-pointer ms-2 p-2  d-flex flex-row justify-content-start align-items-center"
		>
			<MaterialIcon name="fact_check" className="me-1" />
			<span className="white-space-nowrap me-1">Мультипоиск</span>
		</div>
	);
});
