import { observer } from 'mobx-react';
import CircularProgress from '@mui/material/CircularProgress';
import { TOffice } from 'types/office';

type TProps = {
	office: TOffice;
	totalCost: number;
	foundedItems: number;
	searchedItems: number;
	isLoading: boolean;
};

export const MultiSearchTableHeader = observer(
	({ office, totalCost, foundedItems, searchedItems, isLoading }: TProps) => {
		return (
			<div className="p-3 d-flex flex-row align-items-center justify-content-between">
				<div>
					<strong>{office.name}</strong>
					<span className="ms-3">
						{`${new Intl.NumberFormat('ru-RU').format(totalCost)} ₽`}
					</span>
					{isLoading && (
						<span className="ms-3">
							<CircularProgress size={16} />
						</span>
					)}
					<div className="fs-12">
						{office.city} {office.address}
					</div>
				</div>

				<div className="fs-12">
					Найдено {foundedItems} из {searchedItems}
				</div>
			</div>
		);
	}
);
