import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { Table } from 'components/common/Table';
import { TableColumn } from 'helpers/TableColumn';
import { TUnitDetail, TListQuickDetailUnit } from 'types/catalog';
import { Link } from 'react-router-dom';
import { useCatalogStore } from 'providers/RootStoreProvider';
import CircularProgress from '@mui/material/CircularProgress';
import useApi from 'hooks/useApi';

type TRowData = {
	row: { original: TUnitDetail };
};

export const ListQuickDetail = observer(() => {
	const { getListQuickDetail, getUid } = useCatalogStore();
	const { brand, code, ssd, vehicle_id, category_id } = useParams();

	const {
		data: listQuickDetail,
		loading: listQuickDetailLoading,
		fetch: fetchListQuickDetail,
	} = useApi(getListQuickDetail);

	const isLoaded = !listQuickDetailLoading;

	useEffect(() => {
		fetchListQuickDetail({
			catalog: code,
			ssd,
			vehicle_id,
			quick_group_id: category_id,
		});
	}, [category_id]);

	const formatImageUrl = useCallback((url) => {
		return url.replace('%size%', 250);
	}, []);

	const formatTableData = useCallback((data: TListQuickDetailUnit) => {
		return data.details.filter((i) => i.match);
	}, []);

	const columns = [
		TableColumn({
			header: 'OEM',
			width: 100,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { oem } = rowData.row.original;

				const hanleClick = async () => {
					const uid = await getUid(oem, brand);
					window.open(`/products/${uid}`, '_blank').focus();
				};

				return (
					<>
						<div onClick={hanleClick} className="link-type-text">
							{oem}
						</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Наименование детали',
			width: 200,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { name } = rowData.row.original;

				return <>{name}</>;
			},
		}),
	];

	const ListQuickDetailItem = ({ data }: { data: TListQuickDetailUnit }) => {
		const [openedOtherDetails, setOpenOtherDetail] = useState(false);

		return (
			<Card className="list-quick-detail p-3 w-100 mb-3 d-flex">
				<div className="d-flex w-100 justify-content-start align-items-start">
					<Link
						to={`/catalog-unit/${brand}/${code}/${data.ssd}/${data.unitid}/${vehicle_id}/${category_id}`}
					>
						<div className="me-3 preview-image">
							<img src={formatImageUrl(data.imageurl)} alt="" />
						</div>
					</Link>
					<div className="d-flex w-100 flex-column justify-content-between align-items-start">
						<div className="w-100">
							<Table
								isLoading={false}
								data={openedOtherDetails ? data.details : formatTableData(data)}
								columns={columns}
								contained={false}
								tableMinHeight={'10px'}
								footer={
									<>
										{data.details.length > formatTableData(data).length && (
											<div
												onClick={() => setOpenOtherDetail(!openedOtherDetails)}
												className="link-type-text fs-14 p-3"
											>
												{openedOtherDetails
													? 'Показать искомые детали узла'
													: 'Показать все детали узла'}
											</div>
										)}
									</>
								}
								title={
									<div className="link-type-text">
										<Link
											to={`/catalog-unit/${brand}/${code}/${data.ssd}/${data.unitid}/${vehicle_id}/${category_id}`}
										>
											({data.code}) {data.name}
										</Link>
									</div>
								}
							/>
						</div>
					</div>
				</div>
			</Card>
		);
	};

	return (
		<>
			{isLoaded ? (
				<>
					{listQuickDetail.length > 0 ? (
						<>
							{listQuickDetail.map((item, i) => (
								<>
									{item.unit.map((data) => (
										<ListQuickDetailItem
											key={`list_quick_detail_${data.code}_${i}`}
											data={data}
										/>
									))}
								</>
							))}
						</>
					) : (
						<div className="p-3">Ничего не найдено</div>
					)}
				</>
			) : (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={35} />
				</div>
			)}
		</>
	);
});
