import { makeAutoObservable } from 'mobx';
import { fetchOffices } from 'api/office/office';
import { TOfficeMapPoint } from 'types/office';
import { RootStore } from 'stores/RootStore';

export class OfficeStore {
	offices: TOfficeMapPoint[] = [];
	b2b_companies: TOfficeMapPoint[] = [];

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	getOffices = async () => {
		const res = await fetchOffices();

		if (res === null) {
			return;
		}
		const { offices, b2b_companies } = res;

		this.offices = offices.map((i) => {
			i.type = 'office';
			return i;
		});
		this.b2b_companies = b2b_companies.map((i) => {
			i.type = 'b2b_companies';
			return i;
		});
	};
}
