import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import useApi from 'hooks/useApi';
import { TreeviewListItem } from 'components/common/TreeviewListItem';
import { Form } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { TListCategory } from 'types/catalog';
import CircularProgress from '@mui/material/CircularProgress';
import { useCatalogStore } from 'providers/RootStoreProvider';

const searchInTree = (nodes, phrase) => {
	return nodes
		.map((record) => {
			const children = searchInTree(record.children || [], phrase);
			if (record.name.toLowerCase().includes(phrase) || children.length > 0) {
				return {
					...record,
					children,
				};
			}
			return null;
		})
		.filter((record) => !!record);
};

export const ListCategories = observer(() => {
	const { getListCategories } = useCatalogStore();
	const navigate = useNavigate();
	const { brand, code, ssd, vehicle_id, category_id } = useParams();
	const [treeviewData, setTreeviewData] = useState([]);
	const [isOpenAll, setIsOpenAll] = useState(false);
	const [query, setQuery] = useState('');
	const [data, setData] = useState(null);

	const {
		data: listCategories,
		loading: listCategoriesLoading,
		fetch: fetchListCategories,
	} = useApi(getListCategories);

	const formatTreeviewData = useCallback(() => {
		return listCategories.reduce((result, item) => {
			if (item.childrens !== 'false') {
				const children = listCategories.filter((category) => {
					return category.parentcategoryid === item.categoryid;
				});

				result.push({
					...item,
					children,
				});
			} else {
				result.push(item);
			}
			return result;
		}, []);
	}, [listCategories]);

	const handleClick = useCallback(
		({ ssd, categoryid }: TListCategory) => {
			navigate(
				`/catalog-model/original/${brand}/${code}/${ssd}/${vehicle_id}/${categoryid}`
			);
		},
		[brand, code, vehicle_id]
	);

	const handleChange = useCallback((e) => {
		const query = e.target.value;
		setQuery(query);
	}, []);

	useEffect(() => {
		fetchListCategories({ catalog: code, ssd, vehicle_id });
	}, []);

	useEffect(() => {
		listCategories &&
			listCategories.length &&
			setTreeviewData(formatTreeviewData());
	}, [listCategories]);

	useEffect(() => {
		reset();
	}, [treeviewData]);

	const reset = useCallback(() => {
		listCategories && listCategories.length && setData(treeviewData);
	}, [listCategories, treeviewData]);

	useEffect(() => {
		if (query) {
			setData(searchInTree(data, query.toLowerCase()));
		} else {
			reset();
		}
		setIsOpenAll(!!query);
	}, [query]);

	return (
		<div className="list-categories-container">
			<Form.Group className="search-input pb-3">
				<Form.Control
					placeholder={'Поиск по названию детали'}
					value={query}
					onChange={handleChange}
					type="text"
				/>
				{query && (
					<IconButton
						color="primary"
						component="label"
						onClick={() => setQuery('')}
						className={`clear-trigger cursor-pointer fs-16 fs-10 me-2 d-flex justify-content-center align-items-center`}
					>
						<MaterialIcon className="" name="close" />
					</IconButton>
				)}
			</Form.Group>
			{!listCategoriesLoading && listCategories ? (
				<>
					<div className="treeview-container">
						{data.map((category, index) => {
							return (
								<TreeviewListItem
									query={query}
									isOpenAll={isOpenAll}
									activeKey={'categoryid'}
									activeValue={category_id}
									key={index}
									isLink={() => true}
									keyKey={'code'}
									nameKey={'name'}
									item={category}
									handleClick={handleClick}
								/>
							);
						})}
					</div>
				</>
			) : (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={35} />
				</div>
			)}
		</div>
	);
});
