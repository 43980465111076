import { createContext, ReactNode, useContext } from 'react';
import { RootStore } from 'stores/RootStore';

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

export function useRootStore() {
	const context = useContext(StoreContext);
	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider');
	}
	return context;
}

export function useAuthStore() {
	const { authStore } = useRootStore();
	return authStore;
}
export function useAppStore() {
	const { appStore } = useRootStore();
	return appStore;
}
export function useSearchStore() {
	const { searchStore } = useRootStore();
	return searchStore;
}
export function useProductStore() {
	const { productStore } = useRootStore();
	return productStore;
}
export function useCartStore() {
	const { cartStore } = useRootStore();
	return cartStore;
}
export function useSavedProductsStore() {
	const { savedProductsStore } = useRootStore();
	return savedProductsStore;
}
export function useCompanyStore() {
	const { companyStore } = useRootStore();
	return companyStore;
}
export function useOrderStore() {
	const { orderStore } = useRootStore();
	return orderStore;
}
export function useReviewStore() {
	const { reviewStore } = useRootStore();
	return reviewStore;
}
export function useFilterStore() {
	const { filterStore } = useRootStore();
	return filterStore;
}
export function useShopStore() {
	const { shopStore } = useRootStore();
	return shopStore;
}
export function useNoteStore() {
	const { noteStore } = useRootStore();
	return noteStore;
}
export function useFavoriteShopStore() {
	const { favoriteShopStore } = useRootStore();
	return favoriteShopStore;
}
export function useCarStore() {
	const { carStore } = useRootStore();
	return carStore;
}
export function useRequisitesStore() {
	const { requisitesStore } = useRootStore();
	return requisitesStore;
}
export function useVendorStore() {
	const { vendorStore } = useRootStore();
	return vendorStore;
}
export function useBalanceStore() {
	const { balanceStore } = useRootStore();
	return balanceStore;
}
export function useBillingStore() {
	const { billingStore } = useRootStore();
	return billingStore;
}
export function useCashbackStore() {
	const { cashbackStore } = useRootStore();
	return cashbackStore;
}
export function useWarehouseStore() {
	const { warehouseStore } = useRootStore();
	return warehouseStore;
}
export function useCompaniesStore() {
	const { companiesStore } = useRootStore();
	return companiesStore;
}
export function useCatalogStore() {
	const { catalogStore } = useRootStore();
	return catalogStore;
}
export function useDashboardStore() {
	const { dashboardStore } = useRootStore();
	return dashboardStore;
}
export function useCoreProductStore() {
	const { coreProductStore } = useRootStore();
	return coreProductStore;
}
export function useMultiSearchStore() {
	const { multiSearchStore } = useRootStore();
	return multiSearchStore;
}
export function useGazStore() {
	const { gazStore } = useRootStore();
	return gazStore;
}
export function useSellerProductStore() {
	const { sellerProductStore } = useRootStore();
	return sellerProductStore;
}
export function usePartnerProductStore() {
	const { partnerProductStore } = useRootStore();
	return partnerProductStore;
}
export function usePaymentsShopStore() {
	const { paymentsShopStore } = useRootStore();
	return paymentsShopStore;
}
export function useAdminCompaniesStore() {
	const { adminCompaniesStore } = useRootStore();
	return adminCompaniesStore;
}
export function useTasksStore() {
	const { tasksStore } = useRootStore();
	return tasksStore;
}
export function useOfficeStore() {
	const { officeStore } = useRootStore();
	return officeStore;
}
export function useGettzapProductStore() {
	const { gettzapProductStore } = useRootStore();
	return gettzapProductStore;
}
export function useShipmentStore() {
	const { shipmentStore } = useRootStore();
	return shipmentStore;
}
export function useTyreStore() {
	const { tyreStore } = useRootStore();
	return tyreStore;
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
	// only create root store once (store is a singleton)
	const root = store ?? new RootStore();
	const { Provider } = StoreContext;

	return <Provider value={root}>{children}</Provider>;
}
