import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, Card } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { TCustomerContact } from 'types/cart';
import { OrderHeader } from '../OfficeOrders/OrderHeader';
import { isEqual } from 'lodash';
import { AddressSuggections } from 'components/common/AddressSuggections';

import { Formik } from 'formik';
import 'yup-phone';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
	customer_name: Yup.string(),
	customer_email: Yup.string().email('Не верный формат email'),
	customer_phone: Yup.string().phone(
		'ru',
		true,
		'Не верный формат номера телефона'
	),
	address: Yup.string(),
});

type TProps = {
	onSave: (customerContact: TCustomerContact) => void;
	customerContact: TCustomerContact;
};

export const CustomerContact = observer(
	({ onSave, customerContact }: TProps) => {
		const isChanged = useCallback(
			(values) => {
				return isEqual(values, customerContact);
			},
			[customerContact]
		);

		return (
			<Card className="mb-3">
				<Card.Header className="order-header bg-light btn-reveal-trigger d-flex flex-between-center">
					<OrderHeader title="Покупатель" />
				</Card.Header>
				<Card.Body>
					<Formik
						initialValues={customerContact}
						enableReinitialize
						validationSchema={ValidationSchema}
						onSubmit={onSave}
					>
						{({
							values,
							errors,
							handleChange,
							handleSubmit,
							setFieldValue,
						}) => (
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Form.Group
									className="mb-3"
									as={Col}
									lg={12}
									controlId="fullname"
								>
									<Form.Control
										type="text"
										placeholder="ФИО"
										value={values.customer_name}
										name="customer_name"
										onChange={handleChange}
									/>
									<Form.Text>{errors.customer_name}</Form.Text>
								</Form.Group>
								<Form.Group className="mb-3" as={Col} lg={12} controlId="email">
									<Form.Control
										type="email"
										placeholder="Email"
										value={values.customer_email}
										name="customer_email"
										onChange={handleChange}
									/>
									<Form.Text>{errors.customer_email}</Form.Text>
								</Form.Group>
								<Form.Group className="mb-3" as={Col} lg={12} controlId="phone">
									<InputMask
										mask="+7 (999) 999 99-99"
										value={values.customer_phone}
										onChange={handleChange}
									>
										{(inputProps) => (
											<Form.Control
												type="text"
												placeholder="Телефон"
												name="customer_phone"
												{...inputProps}
											/>
										)}
									</InputMask>
									<Form.Text>{errors.customer_phone}</Form.Text>
								</Form.Group>
								<Form.Group
									className="mb-3"
									as={Col}
									lg={12}
									controlId="address"
								>
									<AddressSuggections
										placeholder="Адрес"
										initValue={values.address}
										setValue={({ name }) => setFieldValue('address', name)}
									/>
									<Form.Text>{errors.address}</Form.Text>
								</Form.Group>
								<div className="d-flex justify-content-end align-items-end mt-4">
									<Button
										variant={isChanged(values) ? 'success' : 'outline-success'}
										type="submit"
									>
										{isChanged(values) ? 'Сохранено' : 'Сохранить'}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
		);
	}
);
