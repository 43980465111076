import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { RegionSearchModal } from './modal-content/RegionSearchModal';
import { CarTableModal } from 'components/modals/CarTableModal';
import { MultiSearchModal } from './MutliSearchModal';
import { PaymentMethodModal } from './PaymentMethodModal';
import { AnalogsModal } from './AnalogsModal';
import { AnalogsSingleSearchModal } from './AnalogsSingleSearchModal';
import { FormModal } from './modal-content/FormModal/FormModal';
import { SearchByVin } from './SearchByVinModal';
import { AdminCompaniesModal } from './modal-content/AdminCompaniesModal/AdminCompaniesModal';
import { TasksModal } from './modal-content/TasksModal/TasksModal';
import { TaskSuccessModal } from './modal-content/TasksModal/TaskSuccessModal';
import { TaskEditModal } from './modal-content/TasksModal/TaskEditModal';
import { PriceListModal } from 'components/modals/PriceListModal';
import { StockListModal } from 'components/modals/StockListModal';
import { CarFormModal } from 'components/modals/CarFormModal';
import { NoteFormModal } from 'components/modals/NoteFormModal';
import { ConsentModal } from './modal-content/ConsentModal/ConsentModal';
import { AddressModal } from './modal-content/AddressModal/AddressModal';
import { SupportModal } from './modal-content/Common/SupportModal';
import { TelegramInstructionModal } from './TelegramInstructionModal';
import { OrderPaymentModal } from './OrderPaymentModal';
import { useAppStore } from 'providers/RootStoreProvider';
import { RequisitesModal } from './RequisitesModal';

export const Modals = observer(() => {
	const { activeModal, activeModalSize, closeModal, activeModalData } =
		useAppStore();

	return (
		<Modal
			show={!!activeModal}
			onHide={closeModal}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className={`${activeModalSize} ${
				['analogs', 'car_table'].includes(activeModal) ? 'hidden' : ''
			}`}
		>
			{activeModal === 'company-price-list' && <PriceListModal />}
			{activeModal === 'stock-list' && <StockListModal />}
			{activeModal === 'payment_method' && (
				<PaymentMethodModal data={activeModalData} close={closeModal} />
			)}
			{activeModal === 'multi_search' && (
				<MultiSearchModal close={closeModal} />
			)}
			{activeModal === 'region_search' && (
				<RegionSearchModal close={closeModal} />
			)}
			{activeModal === 'analogs' && (
				// @ts-ignore
				<AnalogsModal data={activeModalData} close={closeModal} />
			)}
			{activeModal === 'analogs_single_search' && (
				<AnalogsSingleSearchModal data={activeModalData} close={closeModal} />
			)}
			{activeModal === 'car_form' && (
				<CarFormModal
					modalData={activeModalData as unknown}
					close={closeModal}
				/>
			)}
			{activeModal === 'support' && <SupportModal />}
			{activeModal === 'car_note' && <NoteFormModal close={closeModal} />}
			{activeModal === 'car_table' && (
				<CarTableModal
					modalData={activeModalData as unknown}
					close={closeModal}
				/>
			)}
			{activeModal === 'consent' && (
				<ConsentModal
					modalData={activeModalData as unknown}
					close={closeModal}
				/>
			)}
			{activeModal === 'order-payment' && (
				<OrderPaymentModal
					modalData={activeModalData as unknown}
					close={closeModal}
				/>
			)}
			{activeModal === 'requisites' && <RequisitesModal close={closeModal} />}
			{activeModal === 'address_modal' && <AddressModal close={closeModal} />}
			{activeModal === 'modal_form' && (
				<FormModal modalData={activeModalData as unknown} close={closeModal} />
			)}
			{activeModal === 'search_by_vin' && <SearchByVin />}
			{activeModal === 'admin-edit_company' && (
				<AdminCompaniesModal close={closeModal} />
			)}
			{activeModal === 'create_task' && <TasksModal close={closeModal} />}
			{activeModal === 'success_task' && (
				<TaskSuccessModal close={closeModal} />
			)}
			{activeModal === 'edit_task' && <TaskEditModal close={closeModal} />}
			{activeModal === 'telegram_instruction' && <TelegramInstructionModal />}
		</Modal>
	);
});
