import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useCatalogStore } from 'providers/RootStoreProvider';
import { useCallback } from 'react';

export const AlphabetListCatalog = observer(() => {
	const { alhabetListCatalog } = useCatalogStore();
	const capitalize = useCallback(
		(str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(),
		[]
	);

	return (
		<Card className="alphabet-list-catalog p-2 me-3">
			<div className="alphabet-list-content catalog__items">
				{alhabetListCatalog.map(({ firstLetter, items }) => (
					<div
						key={'list_catalog_' + firstLetter}
						className="d-flex list-catalog-item p-2"
					>
						<p className="firstletter fs-0">{firstLetter}</p>
						<div className="ms-2 list_catalog_container">
							{items.map(({ brand, code }) => (
								<div
									className="list_catalog_item p-0 fs--1"
									key={'list_catalog_item_' + firstLetter + '_' + code}
								>
									<Link to={`/catalog/${brand}/${code}`}>
										{capitalize(brand)}
									</Link>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</Card>
	);
});
