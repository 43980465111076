import React from 'react';
import { observer } from 'mobx-react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { TShopPassport } from 'types/shop';

type TProps = {
	shopPassport: TShopPassport;
};

export const ShopMap = observer(({ shopPassport: { location } }: TProps) => {
	return (
		<div className="shop-map">
			<YMaps>
				<Map
					className="yandex-map"
					defaultState={{ center: [location.lat, location.lng], zoom: 12 }}
				>
					<Placemark geometry={[location.lat, location.lng]} />
				</Map>
			</YMaps>
		</div>
	);
});
