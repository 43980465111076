import { makeAutoObservable } from 'mobx';
import { TFilters, TPresetFilterItem, TFilterSearchItem } from 'types/filter';
import {
	fetchFilterPresets,
	toggleListItemFilterPreset,
	updateFilterPreset,
	deleteFilterPreset,
	analogsBrandFetch,
	fetchMetroStations,
	activateFilterPreset,
} from 'api/product/filters';
import { isEqual } from 'lodash';
import { FILTER_VALUE } from 'consts/filter_values';
import { TMetroStation } from 'types/product';
import { RootStore } from 'stores/RootStore';

export class FilterStore {
	DEFAULT_FILTERS: TFilters = {
		delivery_time_type: null,
		used: null,
		brands: [],
		metro_stations: [],
		min_rating_value: null,
		min_rating_count: null,
		confirmed: null,
		min_cashback: null,
		payment: null,
		delivery: null,
		office_favorites: null,
		office_id: null,
		api: null,
		stock: null,
		customer_markup: null,
	};

	isLoading = true;
	filterPresetList: TPresetFilterItem[] = [];
	activeFilterPreset: TPresetFilterItem = null;
	customerCost = 0;
	customerMarkup = 0;

	filters: TFilters = { ...this.DEFAULT_FILTERS };
	showFilter = '';

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setShowFilter = (filter: string) => {
		this.showFilter = filter;
	};

	isFiltersActive = () => {
		return !isEqual(this.filters, this.DEFAULT_FILTERS);
	};

	isActivePresetChanged = () => {
		return this.activeFilterPreset
			? !isEqual(this.filters, this.activeFilterPreset.filter)
			: false;
	};

	deleteFilterPreset = async (id: number) => {
		const res = await deleteFilterPreset(id);
		if (res === null) return;

		this.filterPresetList = this.filterPresetList.filter((i) => i.id !== id);
		if (id === this.activeFilterPreset.id) {
			this.activeFilterPreset = null;
		}
	};

	toggleListItemFilterPreset = async (name: string) => {
		const res = await toggleListItemFilterPreset({
			filter: this.filters,
			active: true,
			name: name,
		});
		if (res === null) return;

		this.filterPresetList.push(res);

		if (this.activeFilterPreset) {
			await this.updateFilterPreset({
				...this.activeFilterPreset,
				active: false,
			});
		}

		this.activeFilterPreset = res;
	};

	updateFilterPreset = async (
		preset: TPresetFilterItem,
		updateFilters?: boolean
	) => {
		const presetIndex = this.filterPresetList.findIndex(
			(i) => i.id === preset.id
		);

		if (updateFilters) {
			preset.filter = this.filters;
		}

		const res = await updateFilterPreset(preset);
		if (res === null) return null;

		this.filterPresetList[presetIndex] = res;

		if (res.active) {
			this.activeFilterPreset = res;
		}
	};

	activateFilterPreset = async (preset: TPresetFilterItem) => {
		const presetIndex = this.filterPresetList.findIndex(
			(i) => i.id === preset.id
		);
		const isActive = this.filterPresetList[presetIndex].active;

		const updateStates = () => {
			this.filterPresetList.forEach((item) => {
				item.active = false;
			});
			this.filters = { ...this.DEFAULT_FILTERS };
			this.activeFilterPreset = null;
		};

		if (!isActive) {
			const res = await activateFilterPreset(preset.id);
			if (res === null) return null;

			updateStates();

			this.filterPresetList[presetIndex] = res;
			this.filters = res.filter;
			this.activeFilterPreset = res;

			this.root.productStore.update();
		} else {
			updateStates();

			const res = await updateFilterPreset({ ...preset, active: false });
			if (res === null) return null;

			this.root.productStore.update();
		}
	};

	initFilterPresets = async () => {
		this.isLoading = true;
		const res = await fetchFilterPresets();

		if (res === null) {
			this.isLoading = false;
			return;
		}

		this.filterPresetList = res;
		const activePreset = res.find((i) => i.active);

		if (activePreset) {
			this.filters = activePreset.filter;
			this.activeFilterPreset = activePreset;
		}

		this.isLoading = false;
	};

	saveFiltersLocalStorage = () => {
		localStorage.setItem('filters', JSON.stringify(this.filters));
	};

	toggleCheckFilter = (filter: string) => {
		this.filters[filter] = !this.filters[filter];
	};

	toggleListItemFilter = (id: string, value: TFilterSearchItem) => {
		const target = this.filters[id].find((i) => i.id === value.id);
		if (target) {
			this.filters[id] = this.filters[id].filter((i) => i.id !== value.id);
		} else {
			this.filters[id].push(value);
		}
	};

	setCustomerCost = (value: number) => {
		this.customerCost = value;
		localStorage.setItem('customer_cost', JSON.stringify(value));
		this.customerMarkup = ((100 + value) as number) / 100;

		this.root.productStore.update();
	};

	setFilter = (
		keys: string[],
		value: number | boolean | string,
		update?: boolean
	) => {
		keys.forEach((key) => {
			this.filters[key] = value;
		});

		if (update) this.root.productStore.update();
	};

	getFiltersParams = (exception?: string[]) => {
		let filters = {
			...(this.customerMarkup > 1 && { customer_markup: this.customerMarkup }),
		};
		const FILTER_KEYS = Object.keys(FILTER_VALUE);
		const FILTER_KEYS_SEARCH = ['analogs', 'brands', 'metro_stations'];

		Object.keys(this.filters).forEach((filterName) => {
			if (!exception || !exception.includes(filterName)) {
				const filterValue = this.filters[filterName];
				if (FILTER_KEYS.includes(filterName)) {
					if (filterValue) {
						const value = FILTER_VALUE[filterName][filterValue];
						if (typeof value === 'object') {
							filters = { ...filters, ...value };
						} else {
							filters[filterName] = value;
						}
					}
				} else if (FILTER_KEYS_SEARCH.includes(filterName)) {
					if (filterValue.length > 0) {
						filters[filterName] = filterValue.map((i) => i.id).join(',');
					}
				} else if (filterValue) {
					filters[filterName] = filterValue;
				}
			}
		});

		return filters;
	};

	getMetroStations = async (): Promise<TMetroStation[]> => {
		const res = await fetchMetroStations(
			'region',
			this.root.searchStore.searchRegion.id
		);
		if (res === null) return;

		return res;
	};

	getAnalogBrands = async (sortBy?: string): Promise<TFilterSearchItem[]> => {
		const res = await analogsBrandFetch({
			uid: this.root.productStore.uid,
			delivery_region_id: this.root.searchStore.searchRegion.id,
			sort_by: sortBy,
		});

		if (res === null) return;

		return res.map((i) => {
			i.name = i.name + ` (${i.count}) от ${i.minCost} ₽`;
			return i;
		});
	};
}
