import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TManager } from 'types/manager';

export const fetchManager = async (): Promise<TManager> => {
	return request(
		fetch(b2bApiUrl(`manager/manager-info`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
