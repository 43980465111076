import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MaterialIcon } from 'components/common/MaterialIcon';
import Collapse from '@mui/material/Collapse';
import Highlighter from 'react-highlight-words';

export const TreeviewListItem = observer(
	({
		item,
		handleClick,
		nameKey,
		keyKey,
		isLink,
		isOpenAll,
		query,
		activeKey,
		activeValue,
	}) => {
		const [open, setOpen] = useState(isOpenAll ? isOpenAll : false);

		useEffect(() => {
			const findTarget = (items) => {
				return items.find((i) => {
					if (i[activeKey] == activeValue) {
						return true;
					} else {
						if (i.children && Array.isArray(i.children)) {
							return findTarget(i.children);
						}
					}
				});
			};
			if (item.children && Array.isArray(item.children)) {
				const target = findTarget(item.children);
				setOpen(!!target);
			}
		}, []);

		return (
			<li>
				{Object.prototype.hasOwnProperty.call(item, 'children') ? (
					<>
						<div className="toggle-container d-flex justify-content-start align-items-center">
							{item.children.length > 0 && (
								<MaterialIcon
									onClick={() => setOpen(!open)}
									name="arrow_forward_ios"
									className={classNames('toggle-trigger', {
										open: isOpenAll ? isOpenAll : open,
									})}
								/>
							)}
							<span
								className={classNames('collapse-toggle', {
									open: isOpenAll ? isOpenAll : open,
								})}
							>
								<p
									onClick={() =>
										isLink(item) ? handleClick(item) : setOpen(!open)
									}
									className={classNames('treeview-text', {
										link: isLink(item),
										active: activeValue
											? item[activeKey] === activeValue
											: false,
									})}
								>
									<Highlighter
										highlightClassName="highlight"
										searchWords={[query]}
										autoEscape={true}
										textToHighlight={item[nameKey]}
									/>
								</p>
							</span>
						</div>
						<Collapse in={isOpenAll ? isOpenAll : open}>
							<div className="treeview-children">
								{item.children.map((item, index) => {
									return (
										<TreeviewListItem
											query={query}
											activeKey={activeKey}
											activeValue={activeValue}
											isOpenAll={isOpenAll}
											key={`${index}_${item[keyKey]}`}
											item={item}
											handleClick={handleClick}
											nameKey={nameKey}
											keyKey={keyKey}
											isLink={isLink}
										/>
									);
								})}
							</div>
						</Collapse>
					</>
				) : (
					<div className="treeview-item">
						<p
							onClick={() => handleClick(item)}
							className={classNames('treeview-text', {
								link: true,
								active: activeValue ? item[activeKey] === activeValue : false,
							})}
						>
							<Highlighter
								highlightClassName="highlight"
								searchWords={[query]}
								autoEscape={true}
								textToHighlight={item.name}
							/>
						</p>
					</div>
				)}
			</li>
		);
	}
);
