export default class ApiError extends Error {
  constructor(message, response, status) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
    this.name = 'ApiError';
    this.message = message;
    this.errors = response.errors;
    this.code = response.code;
    this.httpStatus = status;
    this.response = response;
  }
}
