import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { Button } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { TBilling } from 'types/billing';
import { useBillingStore } from 'providers/RootStoreProvider';

import 'styles/Billing.scss';

type TRowData = {
	row: { original: TBilling };
};

export const BillingTable = observer(() => {
	const { isLoading, init, billing, downloadPdf } = useBillingStore();
	const [isPending, setPending] = useState<number | null>(null);

	useEffect(() => {
		init();
	}, []);

	const columns = [
		TableColumn({
			header: '№',
			width: 50,
			cell: (rowData: TRowData) => {
				const { id } = rowData.row.original;
				return <div>{id}</div>;
			},
		}),
		TableColumn({
			header: 'Дата',
			cellClassName: 'p-2',
			width: 100,
			cell: (rowData: TRowData) => {
				const { createdAt } = rowData.row.original;
				return (
					<div>
						{dayjs(createdAt).format('YYYY-MM-DD')}{' '}
						{dayjs(createdAt).format('HH:MM')}
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Сумма',
			width: 100,
			cell: (rowData: TRowData) => {
				const { amount } = rowData.row.original;
				return (
					<div>{`${new Intl.NumberFormat('ru-RU').format(amount)} ₽`}</div>
				);
			},
		}),
		TableColumn({
			header: 'Статус',
			width: 100,
			cell: (rowData: TRowData) => {
				const { paid } = rowData.row.original;
				return <div>{paid ? 'Оплачен' : 'Ожидает оплаты'}</div>;
			},
		}),
		TableColumn({
			header: 'Платёжное поручение',
			width: 100,
			cell: (rowData: TRowData) => {
				const { paymentOrder } = rowData.row.original;
				return <div>{paymentOrder}</div>;
			},
		}),
		TableColumn({
			header: 'Счёт',
			width: 150,
			cell: (rowData: TRowData) => {
				const { id } = rowData.row.original;

				const handleDownload = async () => {
					setPending(id);
					const blob = await downloadPdf(id);
					download(blob, `Счёт_Gettzap_${id}.pdf`);
					setPending(null);
				};

				function download(blob, filename) {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = filename;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}

				return (
					<Button
						variant="link"
						className="button-download p-0 text-left"
						size="sm"
						onClick={handleDownload}
					>
						Скачать счёт #{id}
						{isPending === id && (
							<span className="ms-2">
								<CircularProgress size={12} />
							</span>
						)}
					</Button>
				);
			},
		}),
	];

	return (
		<Table
			isLoading={isLoading}
			data={billing}
			columns={columns}
			title="Журнал операций"
			pagination={false}
			contained={false}
		/>
	);
});
