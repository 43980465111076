import { makeAutoObservable } from 'mobx';
import {
	TProduct,
	TSortBy,
	TSortDirection,
	TProductSearch,
	TStock,
	TCompanyPriceList,
	TPriceList,
} from 'types/product';
import {
	fetchAnalogCounts,
	fetchProductsStockList,
	fetchCompanyPriceList,
	fetchMultisearchAnalogs,
	postMultiSearchItems,
} from 'api/product/products';
import { addNumToArray } from 'utils/common';
import { TSort, TTabs } from 'types/search';
import { ButtonAlertLink } from 'components/common/ButtonAlertLink';
import { toast } from 'react-toastify';
import { SINGLE_SEARCH_PER_PAGE, MAP_SEARCH_PER_PAGE } from 'consts/products';
import { RootStore } from 'stores/RootStore';

export class ProductStore {
	DEFAULT = {
		stockAnalogs: [],
		stockTotal: 0,
		uid: '',
		sort: 'b2bCostCash-ASC',
		tab: 'products',
		activeSearch: 'single',
		stockProducts: [],
		companyPriceList: [],
		companyPriceListTotal: 0,
		updateTimer: null,
		isLoading: true,
	};

	uid = '';
	sort: TSort = 'b2bCostCash-ASC';
	tab: TTabs = 'products';
	activeSearch: 'single' | 'multi' = 'single';
	mode: 'list' | 'map' = 'list';
	updateTimer = null;
	isLoading = true;

	itemsPerPage = SINGLE_SEARCH_PER_PAGE;

	stockAnalogs: TStock[] = [];
	stockTotal = 0;

	analogsTotal = 0;

	stockProducts: TStock[] = [];

	companyPriceList: TCompanyPriceList[] = [];
	companyPriceListTotal = 0;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	getIsInCart = (code: string, priceList: TPriceList, coreBrandId: number) => {
		const targets = this.root.cartStore.cartItems.filter((item) => {
			return (
				item.price_list_id === priceList.id &&
				code === item.code &&
				item.core_brand_id === coreBrandId
			);
		});
		return targets.reduce((result, item) => {
			result += item.quantity;
			return result;
		}, 0);
	};

	startUpdateTimer = () => {
		clearTimeout(this.updateTimer);
		this.updateTimer = setTimeout(async () => {
			const res = await this.root.appStore.setActiveModal(
				'consent',
				'small-size',
				{
					title: 'Обновите результаты поиска',
					description:
						'Обновите результаты поиска, чтобы увидеть актуальную информацию',
					buttonText: 'Обновить',
				}
			);
			if (res) location.reload();
		}, 5 * 60000);
	};

	setViewTypeMap = async () => {
		this.root.sellerProductStore.reset();
		this.itemsPerPage = MAP_SEARCH_PER_PAGE;
		this.mode = 'map';
	};

	setViewTypeList = async () => {
		this.root.sellerProductStore.reset();
		this.itemsPerPage = SINGLE_SEARCH_PER_PAGE;
		this.mode = 'list';
	};

	getMultisearchAnalogs = async (
		uid: string,
		sort: string,
		filters: Record<string, unknown>,
		officeSpecified?: boolean
	) => {
		const res = await fetchMultisearchAnalogs(
			this.productSearchPayload(
				officeSpecified
					? ['delivery_region_id']
					: ['delivery_region_id', 'office_id'],
				{
					uid,
					sort,
					...filters,
				}
			)
		);

		return res;
	};

	setSingleSearch = () => {
		this.root.filterStore.setFilter(['office_id'], null);
		this.activeSearch = 'single';
	};

	setSearchAnalogs = async (uid, data) => {
		if (data) {
			await postMultiSearchItems({ uidAnalog: data.uid, uidOriginal: uid });
		} else {
			await postMultiSearchItems({ uidOriginal: uid });
		}

		this.update();
	};

	setMultiSearch = () => {
		this.activeSearch = 'multi';
	};

	update = async () => {
		if (!this.uid) return null;

		if (this.activeSearch === 'single') {
			await this.updateProducts();
			await this.getAnalogsCount();
		} else {
			await this.root.multiSearchStore.update();
		}
	};

	getStockList = async () => {
		const res = await fetchProductsStockList(this.uid);
		if (res === null) return;

		const { analogs, products, count } = res;

		this.stockAnalogs = analogs;
		this.stockProducts = products;
		this.stockTotal = count;
	};

	updateProducts = async () => {
		// this.root.sellerProductStore.reset();
		// this.root.partnerProductStore.reset();
		this.root.gettzapProductStore.reset();

		// await this.root.sellerProductStore.update(
		// 	this.productSearchPayload(['brands'])
		// );

		if (this.root.authStore.user && this.root.authStore.user.partner) {
			// await this.root.partnerProductStore.update(
			// 	this.productSearchPayload(['brands', 'metro_stations'])
			// );
			await this.root.gettzapProductStore.update(
				this.productSearchPayload(['brands', 'metro_stations'])
			);
		}

		// await this.getStockList();
	};

	setSort = async (sort: TSort) => {
		if (!this.uid) return null;

		if (sort === 'distance-DESC' && !this.root.searchStore.searchAddress) {
			toast.info(
				'Чтобы сортировать по расстоянию необходимо указать адресс доставки',
				{ theme: 'colored' }
			);
			await this.root.appStore.setActiveModal('region_search');
			if (!this.root.searchStore.searchAddress) {
				toast.warning(
					'Чтобы сортировать по расстоянию необходимо указать адресс доставки',
					{ theme: 'colored' }
				);
				return null;
			}
		}

		this.sort = sort;
		this.update();
	};

	setTab = (tab: TTabs) => {
		this.tab = tab;
		this.update();
	};

	getSortParams = (value: string) => {
		const [sort_by, sort_direction] = value.split('-');
		const sort = { sort_by, sort_direction } as {
			sort_by: TSortBy;
			sort_direction: TSortDirection;
			user_points?: string;
		};
		if (sort_by === 'distance') {
			const { lat, lng } = this.root.searchStore.searchAddress.location;
			sort.user_points = `${lat},${lng}`;
		}

		return sort;
	};

	productSearchPayload = (
		exception?: string[],
		params?: {
			per_page?: number;
			uid?: string;
			sort?: string;
			partner?: boolean;
			discount?: number;
			page?: number;
		}
	): TProductSearch => {
		const isInParams = (key: string) => {
			if (params && params[key] !== undefined) {
				return params[key];
			}
			return false;
		};

		const payload = {
			uid: this.uid,
			delivery_region_id: this.root.searchStore.searchRegion.id,
			per_page: this.itemsPerPage,
			...this.root.filterStore.getFiltersParams(exception),
			...this.getSortParams(isInParams('sort') ? params.sort : this.sort),
			// ...(authStore.user &&
			// 	authStore.user.company_id && { company_id: authStore.user.company_id }),
			...params,
		};

		exception.forEach((key) => {
			delete payload[key];
		});

		return payload;
	};

	reset = () => {
		clearTimeout(this.updateTimer);
		Object.keys(this.DEFAULT).map((key) => (this[key] = this.DEFAULT[key]));
	};

	addManyToCart = async (data: TProduct[]) => {
		const res = await this.root.cartStore.addManyToCart(data);
		if (res === null) return;

		toast.success(
			ButtonAlertLink({
				title: 'Товар успешно добавлен в корзину',
				buttonText: 'Перейти в корзину',
				link: '/cart',
			}),
			{
				theme: 'colored',
			}
		);

		this.root.cartStore.initCart();
	};

	addToCart = async (data, isApi?: boolean) => {
		const res = await this.root.cartStore.addToCart(data, isApi);

		if (res === null) return;

		toast.success(
			ButtonAlertLink({
				title: 'Товар успешно добавлен в корзину',
				buttonText: 'Перейти в корзину',
				link: '/cart',
			}),
			{
				theme: 'colored',
			}
		);
	};

	splitRegions = (data: TProduct[]) => {
		if (data && data.length) {
			const splitIndex = data.findIndex((_, index) => {
				if (index === data.length - 1) return false;
				return data[index + 1].regionWeight !== data[index].regionWeight;
			});
			return addNumToArray(data, { separator: true }, splitIndex) as TProduct[];
		}
		return [];
	};

	getAnalogsCount = async () => {
		const res = await fetchAnalogCounts({
			uid: this.uid,
		});
		if (res === null) return;

		const { analogUids, existAnalogs } = res;

		this.analogsTotal = existAnalogs > 0 ? existAnalogs : analogUids;
	};

	getCompanyPriceList = async () => {
		const res = await fetchCompanyPriceList(this.uid);
		if (res === null) return;

		const {
			meta: { count },
			items,
		} = res;

		this.companyPriceList = items;
		this.companyPriceListTotal = count;
	};

	init = async (uid?: string, params?: { office_id?: string }) => {
		if (!uid) return null;
		this.uid = uid;

		if (params && params.office_id) {
			this.root.filterStore.setFilter(['office_id'], params.office_id);
		}

		!this.root.authStore.isAuthLoaded && (await this.root.authStore.initMe());
		await this.update();
		await this.getCompanyPriceList();

		this.root.cartStore.initCart();
		this.isLoading = false;
	};
}
