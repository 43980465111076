import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ShopContact } from './comp/ShopContact';
import { ShopRating } from './comp/ShopRating';
import { ShopMap } from './comp/ShopMap';
import { ShopGallery } from './comp/ShopGallery';
import { ShopHeader } from './comp/ShopHeader';
import { ShopReviews } from './comp/ShopReviews';
import { Button } from 'react-bootstrap';

import 'styles/Shop.scss';
import {
	useFavoriteShopStore,
	useShopStore,
} from 'providers/RootStoreProvider';

export const Shop = observer(() => {
	const { shopPassport, getReviews, selectedOffice } = useShopStore();
	const { addFavoriteShop, isInFavorite, deleteFavoriteShop } =
		useFavoriteShopStore();
	const [inFavorite, setInFavorite] = useState<boolean>(
		isInFavorite(selectedOffice)
	);

	const handleAddToFavorite = async () => {
		const res = await addFavoriteShop(selectedOffice);
		res && setInFavorite(true);
	};

	const handleDeleteFavorite = async () => {
		const res = await deleteFavoriteShop(selectedOffice);
		res && setInFavorite(false);
	};

	if (!shopPassport) return null;

	return (
		<>
			<ShopMap shopPassport={shopPassport} />
			<div className="Shop p-5">
				<ShopHeader shopPassport={shopPassport} />
				<hr className="my-4" />
				<Button
					onClick={() =>
						inFavorite ? handleDeleteFavorite() : handleAddToFavorite()
					}
					variant={inFavorite ? 'outline-primary' : 'primary'}
					className="w-100"
				>
					{inFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
				</Button>
				<ShopContact shopPassport={shopPassport} />
				<ShopGallery shopPassport={shopPassport} />
				<ShopRating shopPassport={shopPassport} />
				<ShopReviews
					getReviews={(sort_params) =>
						getReviews({
							...sort_params,
							for_office_id_eq: selectedOffice,
						})
					}
					shopPassport={shopPassport}
				/>
			</div>
		</>
	);
});
