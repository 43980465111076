import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { TableColumn } from 'helpers/TableColumn';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { Table } from 'components/common/Table';
import { useSearchStore } from 'providers/RootStoreProvider';

export const SearchHistoryTable = observer(() => {
	const { getSearchHistory, isLoading, searchHistory } = useSearchStore();
	useEffect(() => {
		getSearchHistory(100);
	}, []);

	const columns = [
		TableColumn({
			header: 'Бренд',
			width: 150,
			cellClassName: 'pe-3',
			headerClassName: 'pe-1',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData) => {
				const { brand_name } = rowData.row.original;

				return <>{brand_name}</>;
			},
		}),
		TableColumn({
			header: 'Артикул',
			width: 350,
			cellClassName: 'pe-3',
			headerClassName: 'pe-3',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData) => {
				const { name, product_number, uid } = rowData.row.original;
				return (
					<OverflowedTextTooltip
						focus
						value={
							<Link to={`/products/${uid}`} className="text-primary">
								{name} ({product_number})
							</Link>
						}
						text={`${name} (${product_number})`}
					/>
				);
			},
		}),

		TableColumn({
			width: 150,
			header: 'Время поиска',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData) => {
				const { updated_at } = rowData.row.original;
				return <>{dayjs(updated_at).format('DD/MM/YY HH:mm:ss')}</>;
			},
		}),
	];

	return (
		<>
			<Table
				isLoading={isLoading}
				data={searchHistory}
				itemsPerPage={100}
				columns={columns}
				title="История поиска"
				pagination={false}
				contained={true}
			/>
		</>
	);
});
