import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/RootStoreProvider';

export const MainLayout = observer(() => {
	const { initProfile, initMe, isAuthLoaded, isAuthorized } = useAuthStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthorized && isAuthLoaded) {
			navigate('/authentication/login');
		}
		if (isAuthorized && !isAuthLoaded) {
			initMe();
			initProfile();
			// 9f474591757c4910530e350d006a5d53
		}
	}, [isAuthorized, isAuthLoaded]);

	return <Outlet />;
});
