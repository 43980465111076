import { makeAutoObservable } from 'mobx';
import { TTyre, TTyreParams } from 'types/tyre';
import { fetchTyres, aggregationFetch } from 'api/tyre/tyre';
import { RootStore } from 'stores/RootStore';
import { TOption } from 'types/tyre';

export class TyreStore {
	DEFAULT_PARAMS = {
		width: null,
		height: null,
		season: null,
		diameter: null,
		spike: null,
		brands: null,
		min_stock: null,
		min_cost: null,
		max_cost: null,
	};

	isLoading = true;
	tyres: TTyre[] = [];
	params: TTyreParams = this.DEFAULT_PARAMS;
	total = 0;
	page = 1;
	perPage = 25;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (value) => {
		this.page = value;
		this.update();
	};

	getAggregation = async (name: keyof TTyreParams) => {
		const copy = { ...this.getParams() };
		delete copy[name];
		const res = await aggregationFetch[name](copy);

		if (res === null) return;

		const Seasons = { 1: 'Летние', 2: 'Зимние', 3: 'Всесезонные' };

		const converterSeasons = (list) =>
			Object.values(list).map((value) => ({
				value: value,
				label: Seasons[value as string],
			}));
		const converterNumbers = (list) =>
			list.map((value) => ({
				value: value,
				label: value,
			}));
		const converterSpike = (list) =>
			Object.values(list).map((value) => ({
				value: value,
				label: value ? 'С шипами' : 'Без шипов',
			}));
		const converterBrand = (list) =>
			// @ts-ignore
			Object.values(list).map(({ name, coreBrandId }) => ({
				value: coreBrandId,
				label: name,
			}));

		const converter = {
			width: converterNumbers,
			height: converterNumbers,
			brands: converterBrand,
			season: converterSeasons,
			spike: converterSpike,
			diameter: converterNumbers,
		};

		return converter[name](res);
	};

	resetParams = () => {
		this.params = this.DEFAULT_PARAMS;
		this.update();
	};

	setParamsArray = (name: string, options: TOption[]) => {
		this.params[name] = Array.isArray(options) ? options : [];

		if (!this.isLoading) {
			return this.update();
		}
	};

	setParams = (name: string, option: TOption) => {
		this.params[name] = option?.value === this.params[name] ? null : option;

		if (!this.isLoading) {
			return this.update();
		}
	};

	getParams = () => {
		return Object.keys(this.params).reduce((result, key) => {
			const param = this.params[key];
			if (param) {
				result[key] = Array.isArray(param)
					? param.map((i) => (i.value ? i.value : i))
					: param.value;
			}
			return result;
		}, {});
	};

	update = async () => {
		this.isLoading = true;

		const res = await fetchTyres({
			...this.getParams(),
			page: this.page,
			per_page: this.perPage,
		});

		if (res === null) {
			this.isLoading = false;
			this.tyres = [];
			this.total = 0;

			return false;
		}

		const {
			items,
			meta: { count, maxCost, minCost },
		} = res;

		this.tyres = items;
		this.total = count;
		this.params['min_cost'] = minCost;
		this.params['max_cost'] = maxCost;

		this.isLoading = false;
		return true;
	};
}
