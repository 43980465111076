import qs from 'qs';

import { CUSTOMER_API } from 'consts';

export const customerApiUrl = (path, query = null) => {
  return query
    ? `${CUSTOMER_API}/${path}?${qs.stringify(query, {
        arrayFormat: 'brackets',
        skipNulls: true
      })}`
    : `${CUSTOMER_API}/${path}`;
};
