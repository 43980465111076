import { observer } from 'mobx-react';
import { Table as DefaultTable } from 'react-bootstrap';

type TProps = {
	totalCostCash: number;
	totalCostBank: number;
	customerCost: number;
};

export const NoteTableFooter = observer(
	({ totalCostCash, totalCostBank, customerCost }: TProps) => {
		return (
			<div className="d-flex justify-content-end">
				<DefaultTable borderless size="sm" className="fs--1 text-end w-50">
					<tbody>
						<tr>
							<th className="text-900">Сумма, нал:</th>
							<td className="fw-semi-bold">
								{`${new Intl.NumberFormat('ru-RU').format(totalCostCash)} ₽`}
							</td>
						</tr>
						<tr>
							<th className="text-900">Сумма, безнал:</th>
							<td className="fw-semi-bold">
								{`${new Intl.NumberFormat('ru-RU').format(totalCostBank)} ₽`}
							</td>
						</tr>
						<tr>
							<th className="text-900">Сумма, клиент:</th>
							<td className="fw-semi-bold">
								{`${new Intl.NumberFormat('ru-RU').format(customerCost)} ₽`}
							</td>
						</tr>
					</tbody>
				</DefaultTable>
			</div>
		);
	}
);
