import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'react-bootstrap';
import {
	TAdminCompanies,
	TAdminCompanyPayload,
	TAdminManagers,
} from 'types/adminCompanies';
import { Formik } from 'formik';

type TProps = {
	onSubmit: (payload: TAdminCompanyPayload) => Promise<void>;
	initialData: TAdminCompanies | null;
	managers: TAdminManagers[];
};

export const AdminModalForm = observer(
	({ onSubmit, initialData, managers }: TProps) => {
		return (
			<Formik
				initialValues={
					initialData
						? initialData
						: {
								name: '',
								address: '',
								managerId: null,
						  }
				}
				enableReinitialize
				onSubmit={async (values, actions) => {
					await onSubmit(values);
					actions.resetForm();
				}}
			>
				{({ values, handleChange, handleSubmit, isValid }) => (
					<Form
						onSubmit={async (e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="ps-2">
							<Form.Group>
								<Form.Label>Название</Form.Label>
								<Form.Control
									placeholder="Название компании"
									value={values.name}
									name="name"
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Адрес</Form.Label>
								<Form.Control
									placeholder="Адрес"
									value={values.address}
									name="address"
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Мененджер</Form.Label>
								<Form.Select
									aria-label="Выберите менеджера"
									onChange={handleChange}
									name="managerId"
								>
									{!values.managerId && <option>Выберите менеджера</option>}
									{managers.map((manager) => {
										return (
											<>
												<option
													key={`id_${manager.id}_name_${manager.name}`}
													value={manager.id}
													selected={values.managerId === manager.id}
												>
													{manager.name}
												</option>
											</>
										);
									})}
								</Form.Select>
							</Form.Group>
						</div>
						<div className="border-dashed-bottom my-3" />
						<Button
							disabled={!isValid}
							variant="primary"
							type="submit"
							className="me-4"
						>
							Сохранить
						</Button>
					</Form>
				)}
			</Formik>
		);
	}
);
