import React, { useEffect, useState } from 'react';
import { Modal, InputGroup, Form, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';

export const NoteModal = observer(
	({
		onHide,
		onSave,
		note,
		show,
	}: {
		onHide: () => void;
		onSave: (note: string) => void;
		note: string;
		show: boolean;
	}) => {
		const [noteValue, setNoteValue] = useState('');

		useEffect(() => {
			setNoteValue(note);
		}, []);

		return (
			<Modal
				show={show}
				onHide={onHide}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="middle-size"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Примечаниe
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<InputGroup>
						<Form.Control
							style={{ width: '100%' }}
							as="textarea"
							placeholder="Примечание"
							aria-label="Примечание"
							aria-describedby="basic-addon2"
							value={noteValue}
							onChange={({ target }) => setNoteValue(target.value)}
						/>
					</InputGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button variant={'success'} onClick={() => onSave(noteValue)}>
						Сохранить
					</Button>
					<Button variant={'secondary'} onClick={onHide}>
						Закрыть
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
);
