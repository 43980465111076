import { useMemo, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Card, Alert, Button, Form } from 'react-bootstrap';
import { TCartItem } from 'types/product';
import { OfficeOrder } from './OfficeOrder';
import { OrderHeader } from './OrderHeader';
import { DELIVERY_OPTIONS } from 'consts/deliveryOptions';
import {
	TDeliveryProfile,
	TDeliveryOptionType,
	TDelivery,
} from 'types/delivery.d';
import { User } from 'stores/user/User';

type TProps = {
	index: number;
	deliveryOrders: Record<number, TCartItem[]>;
	id: string;
	deliveryProfile: TDeliveryProfile;
	delivery: TDelivery;
	setDeliveryType: (
		officeId: string,
		deliveryType: TDeliveryOptionType
	) => void;
	setDeliveryComment: (officeId: string, comment: string) => void;
	user: User;
	changePaymentCart: (cash: boolean) => void;
	setActivePayment: (id: string, value: string) => void;
	activePayment: string;
};

export const DeliveryOrdersList = observer(
	({
		delivery,
		setDeliveryType,
		setDeliveryComment,
		deliveryOrders,
		id,
		index,
		user,
		changePaymentCart,
		setActivePayment,
		activePayment,
	}: TProps) => {
		const orders = deliveryOrders[id];
		const { office, price_list } = orders[0];

		const isOrderPartner = id === 'partner';
		const isUserPartner = user && user.partner;
		const ordersTotal = orders.reduce((result, item) => {
			result += item.cost;
			return result;
		}, 0);
		const avaliableBalance =
			user.availableBalance !== null ? user.availableBalance : 0;

		const deliveryOptions = useMemo(
			() =>
				DELIVERY_OPTIONS.map(
					(option) => price_list[option.value] && option
				).filter((i) => i),
			[DELIVERY_OPTIONS, orders]
		);

		useEffect(() => {
			if (deliveryOptions.length === 1) {
				setDeliveryType(id, deliveryOptions[0].value);
			}
		}, []);

		const handleSetActivePayment = useCallback(
			(payment: 'cash' | 'balance') => {
				setActivePayment(id, payment);
				changePaymentCart(payment === 'cash');
			},
			[]
		);

		return (
			<Card className="office-order-list-item mb-3">
				<Card.Header className="order-header bg-light btn-reveal-trigger d-flex flex-between-center">
					<OrderHeader
						total={Object.keys(deliveryOrders).length}
						name={isOrderPartner ? 'Бесплатная доставка' : office.name}
						undertitle={
							isOrderPartner ? '' : `${office.city.name}, ${office.address} `
						}
						index={index}
					/>
				</Card.Header>
				<Card.Body className="pb-0">
					<div>
						{orders.map((order: TCartItem) => (
							<OfficeOrder
								key={order.id}
								isPartner={isOrderPartner}
								order={order}
							/>
						))}
						<hr />
					</div>
					{!isOrderPartner && office.notes && (
						<Alert className="office-order-notes" variant="light">
							<div dangerouslySetInnerHTML={{ __html: office.notes }} />
						</Alert>
					)}
					<hr className="mt-0" />
					<div className="pb-3">
						{deliveryOptions.length > 1 && (
							<div
								className={`delivery-option-container d-flex pb-3  justify-content-${
									deliveryOptions.length === 4 ? 'between' : 'end'
								} align-items-center`}
							>
								{deliveryOptions.map((option) => {
									return (
										<Button
											className="delivery-option-button"
											key={office.id + option.value}
											style={{
												width: '33.333%',
												whiteSpace: 'nowrap',
											}}
											onClick={() => setDeliveryType(id, option.value)}
											type={'submit'}
											active={delivery ? delivery.type === option.value : false}
											variant="outline-primary"
										>
											{option.label}
										</Button>
									);
								})}
							</div>
						)}
						<div>
							<Form.Group>
								<Form.Control
									as="textarea"
									type="text"
									placeholder="Комментарий"
									value={delivery ? delivery.comment : ''}
									name="comment"
									onChange={(e) => setDeliveryComment(id, e.target.value)}
								/>
							</Form.Group>
						</div>
						{isOrderPartner && isUserPartner && (
							<>
								<div className="mb-2 pt-2 fs-14 color-red">
									{!activePayment && 'Выберите способ оплаты'}
								</div>
								<div className="d-flex justify-content-start align-items-center">
									<Button
										active={activePayment === 'cash'}
										variant={'outline-primary'}
										className="me-3 fs-14"
										onClick={() => handleSetActivePayment('cash')}
									>
										Оплата наличными
									</Button>
									{ordersTotal <= avaliableBalance && (
										<Button
											active={activePayment === 'balance'}
											variant={'outline-primary'}
											className="me-3 fs-14"
											onClick={() => handleSetActivePayment('balance')}
										>
											Списать с баланса
										</Button>
									)}
									<strong className="fs-14">
										<span className="me-1 w-100">Ваш баланс:</span>
										{`${new Intl.NumberFormat('ru-RU').format(
											avaliableBalance
										)} ₽`}
									</strong>
								</div>
							</>
						)}
					</div>
				</Card.Body>
			</Card>
		);
	}
);
