import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
	Clusterer,
	GeolocationControl,
	Map,
	Placemark,
	withYMaps,
	WithYMapsProps,
	ZoomControl,
} from 'react-yandex-maps';
import { YMaps as YandexMapContainer } from 'react-yandex-maps';
import { ReactComponent as ActivePlacemark } from 'assets/img/markers/active.svg';
import { ReactComponent as NormalPlacemark } from 'assets/img/markers/normal.svg';
import { ReactComponent as SeenPlacemark } from 'assets/img/markers/seen.svg';
import { ReactComponent as OrangePlacemark } from 'assets/img/markers/orange.svg';
import { observer } from 'mobx-react-lite';

import { TLocation } from 'types/common';
import { YANDEX_MAP_KEY } from 'consts/map';
import { russia } from 'consts/regions';
import { TOfficeMapPoint } from 'types/office';

interface IProps {
	activeIds?: number[];
	offices: TOfficeMapPoint[];
	b2b_companies: TOfficeMapPoint[];
	onSelect: (ids: number[]) => void;
	regionLocation: TLocation;
	mode: string;
}

const locationToArray = (location: TLocation): number[] => [
	location.lat,
	location.lng,
];

const isCenterOfRussia = (point: number[]) => {
	return point[0] === russia.location?.lat && point[1] === russia.location.lng;
};

const GeoMap: React.FC<IProps & WithYMapsProps> = observer(
	({
		activeIds = [],
		offices,
		b2b_companies,
		onSelect,
		ymaps,
		regionLocation,
		mode,
	}) => {
		const mapRef = useRef(null);
		const mapCenter = useMemo(
			() => locationToArray(regionLocation || russia.location),
			[regionLocation]
		);

		const zoom = useMemo(
			() => (isCenterOfRussia(mapCenter) ? 3 : 10),
			[mapCenter]
		);

		const ClusterContentLayout = useMemo(() => {
			return ymaps.templateLayoutFactory.createClass(
				`<div class="cluster-marker ">{{ properties.geoObjects.length }}</div>`
			);
		}, [ymaps]);

		const ClusterContentOfficeLayout = useMemo(() => {
			return ymaps.templateLayoutFactory.createClass(
				`<div class="cluster-marker orange">{{ properties.geoObjects.length }}</div>`
			);
		}, [ymaps]);

		const PlaceLayoutName = useMemo(() => {
			return ymaps.templateLayoutFactory.createClass(
				[
					`<div class="place-marker-container">`,
					`<div class="place-marker-cost">ID: {{ properties.resultId }}<div>{{ properties.name }}</div></div>`,
					`<svg width="31" height="39" class="place-marker {{properties.type}}">`,
					'<use href="#map-{{properties.state}}-placemark"/>',
					'</svg>',
					'</div>',
				].join('')
			);
		}, [ymaps]);

		const PlaceLayoutWithoutName = useMemo(() => {
			return ymaps.templateLayoutFactory.createClass(
				[
					`<div class="place-marker-container">`,
					`<svg width="31" height="39" class="place-marker {{properties.type}}">`,
					'<use href="#map-{{properties.state}}-placemark"/>',
					'</svg>',
					'</div>',
				].join('')
			);
		}, [ymaps]);

		const onMarkClick = useCallback(
			(event) => {
				const target = event.get('target');
				if (target && target.properties) {
					const resultId: number = target.properties.get('resultId');

					if (resultId) {
						onSelect([resultId]);
						return;
					}

					const geoObjects = target.properties.get('geoObjects');
					if (!geoObjects) {
						return;
					}
					const ids: number[] = geoObjects.map((o) =>
						o.properties.get('resultId')
					);

					onSelect(ids);
				}
			},
			[onSelect]
		);

		useEffect(() => {
			if (!mapRef.current) {
				return;
			}
			mapRef.current.setCenter(mapCenter, zoom);
		}, [mapCenter, zoom]);

		if (!ymaps) return null;

		return (
			<>
				<NormalPlacemark />
				<SeenPlacemark />
				<OrangePlacemark />
				<ActivePlacemark />
				<Map
					className="map"
					defaultState={{
						center: mapCenter,
						zoom,
					}}
					instanceRef={(map) => {
						mapRef.current = map;
					}}
				>
					<Clusterer
						options={{
							preset: 'islands#invertedBlueClusterIcons',
							groupByCoordinates: false,
							zoomMargin: 300,
							clusterIconLayout: ClusterContentOfficeLayout,
							clusterIconShape: {
								type: 'Circle',
								coordinates: [0, 0],
								radius: 20,
							},
						}}
						onClick={onMarkClick}
					>
						{mode !== 'b2b_companies' &&
							offices &&
							offices.length > 0 &&
							offices.map(({ location, id, name }) => {
								if (location && location.lat && location.lng)
									return (
										<Placemark
											key={id}
											options={{
												iconLayout: activeIds.includes(id)
													? PlaceLayoutName
													: PlaceLayoutWithoutName,
												iconShape: {
													type: 'Rectangle',
													coordinates: [
														[-31, -15],
														[39, 33],
													],
												},
											}}
											geometry={[location.lat, location.lng]}
											properties={{
												name,
												resultId: id,
												state: activeIds.includes(id) ? 'active' : 'orange',
											}}
										/>
									);
							})}
					</Clusterer>
					<Clusterer
						options={{
							preset: 'islands#invertedBlueClusterIcons',
							groupByCoordinates: false,
							zoomMargin: 300,
							clusterIconLayout: ClusterContentLayout,
							clusterIconShape: {
								type: 'Circle',
								coordinates: [0, 0],
								radius: 20,
							},
						}}
						onClick={onMarkClick}
					>
						{mode !== 'office' &&
							b2b_companies &&
							b2b_companies.length > 0 &&
							b2b_companies.map(({ location, id, name }) => {
								if (location && location.lat && location.lng)
									return (
										<Placemark
											key={id}
											options={{
												iconLayout: activeIds.includes(id)
													? PlaceLayoutName
													: PlaceLayoutWithoutName,
												iconShape: {
													type: 'Rectangle',
													coordinates: [
														[-31, -15],
														[39, 33],
													],
												},
											}}
											geometry={[location.lat, location.lng]}
											properties={{
												name,
												resultId: id,
												state: activeIds.includes(id) ? 'active' : 'normal',
											}}
										/>
									);
							})}
					</Clusterer>
					<ZoomControl
						options={{
							position: {
								top: 'auto',
								right: 20,
								bottom: 100,
								left: 'auto',
							},
						}}
					/>
					<GeolocationControl
						options={{
							position: {
								top: 'auto',
								right: 20,
								bottom: 50,
								left: 'auto',
							},
						}}
					/>
				</Map>
			</>
		);
	}
);

const WithYMap = withYMaps(GeoMap, true, [
	'templateLayoutFactory',
]) as unknown as React.FC<IProps>;

export const OfficeMap = observer((props: IProps) => {
	return (
		<YandexMapContainer
			query={{
				lang: 'ru_RU',
				apikey: YANDEX_MAP_KEY,
			}}
		>
			<WithYMap {...props} />
		</YandexMapContainer>
	);
});
