import React from 'react';
import { observer } from 'mobx-react';
import { BadgeItem } from 'components/common/BadgeItem';
import IconButton from '@mui/material/IconButton';
import { TooltipElement } from 'components/common/TooltipElement';

type TProps = {
	list: unknown[];
	keyName: string | string[];
	remove?: (index: number) => void;
	add?: () => void;
	onClickItem?: (item) => void;
	analogs?: Record<string, string>;
	tooltipKey?: string;
};

export const BadgeItemRowList = observer(
	({ list, keyName, remove, add, onClickItem, tooltipKey }: TProps) => {
		return (
			<div className="search-items-container">
				<div className="search-items-content d-flex justify-content-start flex-row  align-items-center">
					{list.map((item, i) => {
						const value = Array.isArray(keyName)
							? keyName.map((key) => item[key]).join(' ')
							: item[keyName];

						return (
							<TooltipElement
								focus={false}
								disabled={!tooltipKey}
								placement="top"
								key={'multisearchitem_page_' + i}
								value={<>{item[tooltipKey]}</>}
							>
								<BadgeItem
									showClose={!!remove}
									handleClose={() => (remove ? remove(i) : null)}
									onClick={() => (onClickItem ? onClickItem(item) : null)}
									className={`${
										onClickItem ? 'cursor-pointer' : ''
									} d-flex justify-content-center align-items-center mb-2`}
								>
									{item && item['uidAnalog'] ? (
										<div className="w-100 text-center p-1">
											<div className="fw-600 fs-12">
												{item['uidAnalog']['code']} {item['uidAnalog']['brand']}
											</div>
											<div className="badge-item-analog">
												<span>{value}</span>
											</div>
										</div>
									) : (
										<div className="w-100 text-center p-1">
											<div className="fw-600 fs-12">{value}</div>
										</div>
									)}
								</BadgeItem>
							</TooltipElement>
						);
					})}
				</div>
				{add && (
					<IconButton
						color="primary"
						component="label"
						onClick={add}
						className="square-icon add-button-badgeItem"
						style={{
							height: '32px',
							margin: '0.5em 0.5em 0em 0em',
						}}
					>
						<TooltipElement focus={true} value={<>Добавить артикул к поиску</>}>
							<div>+</div>
						</TooltipElement>
					</IconButton>
				)}
			</div>
		);
	}
);
