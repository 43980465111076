import { castError, defaultHeaders, b2bApiUrl } from 'api/helpers';
import { TCar, TCarPayload } from 'types/car';
import { TDefaultPagination } from 'types/common';
import request from 'helpers/request';
import { TListReponse } from 'types/common';

export const fetchCar = async (car_id: number): Promise<TCar> => {
	return request(
		fetch(b2bApiUrl(`bayer/cars/${car_id}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchCars = async (
	query_params: TDefaultPagination
): Promise<TListReponse<TCar>> => {
	return request(
		fetch(b2bApiUrl(`bayer/cars`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const deleteCar = async (id: number): Promise<void> => {
	return request(
		fetch(b2bApiUrl(`bayer/cars/${id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const patchCar = async (
	id: number,
	payload: TCarPayload
): Promise<TCar> => {
	return request(
		fetch(b2bApiUrl(`bayer/cars/${id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const postCar = async (payload: TCarPayload): Promise<TCar> => {
	return request(
		fetch(b2bApiUrl(`bayer/cars`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};
