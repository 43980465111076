import { observer } from 'mobx-react';
import { TAdminCompanies } from 'types/adminCompanies';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { MaterialIcon } from 'components/common/MaterialIcon';

type TProps = {
	company: TAdminCompanies;
	handleEdit: (id: number) => void;
};

export const ListItem = observer(({ company, handleEdit }: TProps) => {
	const { name, address, createdAt, id, managerName } = company;

	return (
		<div className="d-flex justify-content-between align-items-center flex-row">
			<div className="w-100 d-flex justify-content-flex-start flex-column">
				<div className="fs-16 fw-semi-bold">
					{name && `${name}`}
					<br />
					{address && `${address}`}
					<br />
					{managerName ? (
						`${managerName}`
					) : (
						<span className="text-danger">Менеджер не назначен</span>
					)}
				</div>
				<div className="fs-13">{dayjs(createdAt).format('DD/MM/YY HH:MM')}</div>
			</div>
			<div className="w-100  d-flex justify-content-end align-items-center gap-3 flex-row">
				<Button
					size="sm"
					className="border d-flex justify-content-center"
					variant={'light'}
					onClick={() => handleEdit(id)}
				>
					<MaterialIcon name="edit" className="" />
				</Button>
				<div className="fs-15 fw-semi-bold">ID {id}</div>
			</div>
		</div>
	);
});
