import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table, Button } from 'react-bootstrap';
import { AnimatedListWrapper } from 'components/common/AnimatedListWrapper';

const AdvanceTable = ({
	getTableProps,
	headers,
	page,
	prepareRow,
	headerClassName,
	rowClassName,
	tableProps,
	selectedRowIds,
	onRowSelect,
	collapsedRows,
	onClickRow,
	Footer,
	onRowClassName,
	onRowHover,
	onRowLeave,
	animationOff,
	productAnimation,
	separateRow,
	showWithShowMoreButton,
}) => {
	const [showMore, setShowMore] = useState(!showWithShowMoreButton);
	const noAnimation = animationOff || !!showWithShowMoreButton;

	useEffect(() => {
		onRowSelect && onRowSelect(selectedRowIds);
	}, [selectedRowIds]);

	useEffect(() => {
		!!showWithShowMoreButton && !showMore && window.scrollTo(0, 0);
	}, [showMore]);

	const getGridAutoColumns = (columns) => {
		columns = columns.map((column) => {
			if (column.id && column.id === 'selection') {
				column.width = 50;
			}
			return column;
		});
		const widthFull = columns.reduce((value, column) => {
			value += column.width;
			return value;
		}, 0);

		const widthProportion = columns.map((column) => {
			const proportion = column.width / widthFull;
			return proportion + 'fr';
		});

		return widthProportion.join(' ');
	};

	return (
		<SimpleBarReact>
			<Table {...getTableProps(tableProps)}>
				<thead className={`d-flex flex-column ${headerClassName}`}>
					<tr
						style={{ gridAutoColumns: getGridAutoColumns(headers) }}
						className={`table-row-container`}
					>
						{headers.map((column, index) => {
							return (
								<th
									key={index}
									{...column.getHeaderProps(
										column.getSortByToggleProps(column.headerProps)
									)}
								>
									{column.render('Header')}
									{column.canSort ? (
										column.isSorted ? (
											column.isSortedDesc ? (
												<span className="sort desc" />
											) : (
												<span className="sort asc" />
											)
										) : (
											<span className="sort" />
										)
									) : (
										''
									)}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className="d-flex flex-column">
					<AnimatedListWrapper off={noAnimation} isProducts={productAnimation}>
						{page.map((row, i) => {
							prepareRow(row);
							const columns = row.cells.map((cell) => cell.column);
							const isSeparateRow = row.cells.find(
								(cell) => cell.row.original.separator
							);

							if (
								showMore ||
								(showWithShowMoreButton && i < showWithShowMoreButton)
							) {
								return (
									<tr
										key={i}
										onMouseEnter={() => onRowHover && onRowHover(row.original)}
										onMouseLeave={() => onRowLeave && onRowLeave(row.original)}
										onClick={() => onClickRow && onClickRow(i)}
										style={{
											gridAutoColumns: isSeparateRow
												? '1fr'
												: getGridAutoColumns(columns),
											zIndex: 10000 - i,
											position: 'relative',
										}}
										className={`table-row-container ${onRowClassName(
											row.original
										)} ${rowClassName} ${
											collapsedRows && collapsedRows.includes(row.original.id)
												? 'active'
												: ''
										}`}
										{...row.getRowProps()}
									>
										{isSeparateRow ? (
											<>
												{separateRow ? (
													separateRow(row.cells[0].row.original)
												) : (
													<div
														style={{ gridAutoColumns: '1fr' }}
														className="table-separator"
													>
														<div className="separator-line"></div>
														<div className="text-primary">
															Предложения по Вашему запросу в других городах
														</div>
														<div className="separator-line"></div>
													</div>
												)}
											</>
										) : (
											<>
												{row.cells.map((cell, index) => {
													return (
														<td
															key={index}
															{...cell.getCellProps(cell.column.cellProps)}
														>
															{cell.render('Cell')}
														</td>
													);
												})}
											</>
										)}
									</tr>
								);
							}
						})}
						{!!showWithShowMoreButton && (
							<div className="w-100 d-flex mt-3  justify-content-center align-items-center ">
								<Button
									className="fs-14  p-2"
									variant={'link'}
									onClick={() => setShowMore(!showMore)}
								>
									{showMore ? 'Скрыть' : 'Показать больше'}
								</Button>
							</div>
						)}
					</AnimatedListWrapper>
				</tbody>
			</Table>

			{Footer && <Footer data={page} />}
		</SimpleBarReact>
	);
};

AdvanceTable.propTypes = {
	getTableProps: PropTypes.func,
	headers: PropTypes.array,
	page: PropTypes.array,
	prepareRow: PropTypes.func,
	headerClassName: PropTypes.string,
	rowClassName: PropTypes.string,
	tableProps: PropTypes.object,
	onRowSelect: PropTypes.func,
	selectedRowIds: PropTypes.object,
	collapsedRows: PropTypes.array,
	onClickRow: PropTypes.func,
	Footer: PropTypes.object,
	CollapsedContainer: PropTypes.object,
	onRowClassName: PropTypes.func,
	onRowHover: PropTypes.func,
	onRowLeave: PropTypes.func,
	separateRow: PropTypes.func,
	animationOff: PropTypes.bool,
	productAnimation: PropTypes.bool,
	showWithShowMoreButton: PropTypes.number,
};

export default AdvanceTable;
