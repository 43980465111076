import React from 'react';
import notFoundImage from 'assets/img/not_found.png';

type TProps = {
	text?: string | React.ReactNode;
	hideIcon?: boolean;
};

export const NotFound = ({ text = 'Ничего не найдено', hideIcon }: TProps) => {
	return (
		<div
			className={`d-flex justify-content-start align-items-center flex-column ${
				!hideIcon ? 'p-3' : ''
			}`}
		>
			{!hideIcon && (
				<img
					style={{ maxWidth: '200px' }}
					src={notFoundImage}
					alt=""
					className="mb-3"
				/>
			)}
			<strong className="fs-16">{text}</strong>
		</div>
	);
};
