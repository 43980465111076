import { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';

type TProps = {
	modalData: unknown;
	close: (state: boolean) => void;
};

type TModalData = {
	title: string;
	description: string;
	buttonText?: string;
};

export const ConsentModal = observer(({ modalData, close }: TProps) => {
	const { title, description, buttonText } = modalData as TModalData;

	const handleAccept = useCallback(() => {
		close(true);
	}, []);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{description}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant={'primary'} onClick={handleAccept}>
					{buttonText ? buttonText : 'Подтвердить'}
				</Button>
			</Modal.Footer>
		</>
	);
});
