export const version = '3.5.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
	isFluid: false,
	isRTL: false,
	isDark: false,
	navbarPosition: 'vertical',
	showBurgerMenu: false, // controls showing vertical nav on mobile
	currency: '$',
	isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
	navbarStyle: 'transparent',
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
