import React from 'react';
import { Link } from 'react-router-dom';
import success_image from 'assets/img/success_signup.png';

export const SuccessRegistration = () => {
	return (
		<div className="d-flex flex-column justify-content-center align-items-center">
			<div className="d-flex p-3 justify-content-center align-items-center display-1 w-100 text-300 fs-error">
				<img className="w-50" src={success_image} alt="" />
			</div>
			<p className="lead display-6 text-center mt-4 text-800 font-sans-serif fw-semi-bold">
				Заявка принята
			</p>
			<p className="lead text-center font-sans-serif">
				Мы свяжемся с Вами для завершения регистрации.
			</p>
			<Link className="btn btn-primary btn mt-3" to="/">
				{'<'} Вернуться на главную
			</Link>
		</div>
	);
};
