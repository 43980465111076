import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import classNames from 'classnames';
import Background from 'components/common/Background';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-3.png';
import { useDashboardStore } from 'providers/RootStoreProvider';

export const DashboardInfo = observer(() => {
	const { companiesCount, orderCount } = useDashboardStore();

	return (
		<div className="d-flex flex-row justify-content-between align-items-start mb-3">
			<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
				<Background image={bg1} className="bg-card" />
				<Card.Body className="position-relative">
					<h6>Количество компаний:</h6>
					<div
						className={classNames(
							'text-warning',
							'display-4 fs-4 mb-2 fw-normal font-sans-serif'
						)}
					>
						<CountUp
							start={0}
							end={companiesCount}
							duration={2.3}
							suffix={''}
							prefix={''}
							separator=","
							decimals={0}
							decimal="."
						/>
					</div>
				</Card.Body>
			</Card>
			<Card className={classNames('overflow-hidden', 'w-100')}>
				<Background image={bg2} className="bg-card" />
				<Card.Body className="position-relative">
					<h6>Количество заказов:</h6>
					<div
						className={classNames(
							'text-success',
							'display-4 fs-4 mb-2 fw-normal font-sans-serif'
						)}
					>
						<CountUp
							start={0}
							end={orderCount}
							duration={2.3}
							suffix={''}
							prefix={''}
							separator=","
							decimals={0}
							decimal="."
						/>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
});
