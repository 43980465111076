import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import 'yup-phone';
import * as Yup from 'yup';
import { useAuthStore } from 'providers/RootStoreProvider';

const ValidationSchema = Yup.object().shape({
	name: Yup.string(),
	email: Yup.string().email('Не верный формат email'),
	phone: Yup.string().phone('ru', true, 'Не верный формат номера телефона'),
	date_of_birth: Yup.date()
		.nullable()
		.default(null)
		.max(new Date(), 'Дата рождения не может быть в будущем'),
});

export const ProfileSettings = observer(() => {
	const { user } = useAuthStore();
	const [isSubmitting, setSubmitting] = useState(false);

	return (
		<Formik
			initialValues={{
				name: user.name,
				email: user.email,
				phone: user.phone,
				home_region: user.home_region,
				date_of_birth: user.date_of_birth,
			}}
			enableReinitialize
			validationSchema={ValidationSchema}
			onSubmit={async (values) => {
				setSubmitting(true);
				await user.patchUserProfile(values);
				setSubmitting(false);
			}}
		>
			{({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
				<Card>
					{/* @ts-ignore */}
					<FalconCardHeader title="Персональная информация" />
					<Card.Body className="bg-light">
						<Form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							<Row className="mb-3 g-3">
								<Form.Group as={Col} lg={12} controlId="fullname">
									<Form.Label>ФИО</Form.Label>
									<Form.Control
										type="text"
										placeholder="ФИО"
										value={values.name}
										name="name"
										onChange={handleChange}
									/>
									<Form.Text>{errors.name}</Form.Text>
								</Form.Group>
							</Row>
							<Row className="mb-3 g-3">
								<Form.Group as={Col} lg={6} controlId="email">
									<Form.Label>Email</Form.Label>
									<Form.Control
										disabled
										type="email"
										placeholder="Email"
										value={values.email}
										name="email"
										onChange={handleChange}
									/>
									<Form.Text>{errors.email}</Form.Text>
								</Form.Group>
								<Form.Group as={Col} lg={6} controlId="phone">
									<Form.Label>Телефон</Form.Label>
									<InputMask
										mask="+7 (999) 999 99-99"
										value={values.phone}
										onChange={handleChange}
									>
										{(inputProps) => (
											<Form.Control
												type="text"
												placeholder="Телефон"
												name="phone"
												{...inputProps}
											/>
										)}
									</InputMask>
									<Form.Text>{errors.phone}</Form.Text>
								</Form.Group>
							</Row>
							<Row className="mb-3 g-3">
								<Form.Group as={Col} lg={6} controlId="home_region">
									<Form.Label>Домашний регион</Form.Label>
									<Form.Control
										type="text"
										placeholder="Домашний регион"
										value={values.home_region}
										name="home_region"
										onChange={handleChange}
									/>
									<Form.Text>{errors.home_region}</Form.Text>
								</Form.Group>
								<Form.Group as={Col} lg={6} controlId="date_of_birth">
									<Form.Label>Дата рождения</Form.Label>
									<DatePicker
										selected={
											values.date_of_birth
												? new Date(values.date_of_birth)
												: null
										}
										onChange={(date) =>
											setFieldValue('date_of_birth', date.toString())
										}
										className="form-control"
										placeholderText="Select Date"
										dateFormat="dd-MM-yyyy"
									/>
									<Form.Text>{errors.date_of_birth}</Form.Text>
								</Form.Group>
							</Row>
							<div className="text-end">
								<Button variant="primary" type="submit">
									{isSubmitting ? 'Обновление...' : 'Сохранить'}
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			)}
		</Formik>
	);
});
