import {
	castError,
	defaultHeaders,
	customerApiUrl,
	b2bApiUrl,
} from 'api/helpers';
import { TSearchItem, TSearchHistoryItem } from 'types/search';
import request from 'helpers/request';

export const searchProductFetch = async (
	query_params
): Promise<TSearchItem[]> => {
	return request(
		fetch(b2bApiUrl(`common/products/search_products`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const searchHistoryFetch = async (
	query_params
): Promise<{ data: TSearchHistoryItem[]; meta }> => {
	return request(
		fetch(customerApiUrl(`search_histories`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
