import React, { useState, useRef, useEffect, memo } from 'react';
import AsyncSelect from 'react-select/async';
import { MultiValue } from 'react-select';
import { observer } from 'mobx-react';
import { components } from 'react-select';

export const TyreAggregationMulti = observer(
	({ initValue = '', setValue, label, value, get, ...rest }) => {
		const [inputValue, setInputValue] = useState(initValue);
		const [defaultOptions, setDefaultOptions] = useState([]);
		const inputRef = useRef(null);

		useEffect(() => {
			setInputValue(initValue);
			inputRef.current.setValue(initValue);
			inputRef.current.blur();
			inputRef.current.focus();
			inputRef.current.blur();
		}, [initValue]);

		type TOption = {
			value?: string;
			label?: string;
		};

		const getSuggestions = async (query: string) => {
			const suggestions = await get(query);
			return suggestions;
		};

		const search = async (search_query: string): Promise<TOption[]> => {
			const suggestions = await getSuggestions(search_query);
			setDefaultOptions(suggestions);
			return suggestions;
		};

		const Option = memo((props: { data: { label: string } }) => {
			return (
				<>
					{/* @ts-ignore */}
					<components.Option {...props}>
						<div className="cursor-pointer" style={{ color: '#111' }}>
							{props?.data?.label}
						</div>
					</components.Option>
				</>
			);
		});

		return (
			<div className="mb-3">
				<h5 className="fs-14 mb-2">{label}</h5>
				<AsyncSelect
					isMulti
					noOptionsMessage={() => <>...</>}
					loadingMessage={() => <>Загрузка...</>}
					defaultOptions={defaultOptions}
					placeholder={'Все'}
					classNamePrefix="react-select"
					className="cursor-pointer"
					loadOptions={search}
					isSearchable={false}
					inputValue={inputValue}
					value={value}
					components={{
						Option,
					}}
					onChange={(data: MultiValue<{ label: string; value: string }>) => {
						setValue(data);
						inputRef.current.blur();
						inputRef.current.focus();
					}}
					ref={inputRef}
					onMenuOpen={() => search(inputValue)}
					{...rest}
				/>
			</div>
		);
	}
);
