import { ReactNode } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { TProduct } from 'types/product';
import { ProductItemName } from './comp/product-list-item/ProductItemName';
import { ProductItemDeliveryNote } from './comp/product-list-item/ProductItemDeliveryNote';
import { ProductItemButtonBuy } from './comp/product-list-item/ProductItemButtonBuy';
import { ProductItemDeliveryTime } from './comp/product-list-item/ProductItemDeliveryTime';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TooltipElement } from 'components/common/TooltipElement';

import 'styles/Product.scss';
import {
	useAppStore,
	useAuthStore,
	useNoteStore,
	useProductStore,
	useSearchStore,
} from 'providers/RootStoreProvider';

type TProps = {
	data: TProduct[];
	header?: ReactNode;
	pagination: boolean;
	contained?: boolean;
	tableMinHeight?: string;
	multiBuy?: boolean;
	showEmpty?: boolean;
	footer?: ReactNode | null;
	title?: ReactNode;
	loadingTableHeight?: string;
	showIconEmpty?: boolean;
	totalItems?: number;
	setPage?: (page: number) => void;
	page?: number;
	isLoading: boolean;
	cardBodyClassName?: string;
	footerClassName?: string;
	hide?: boolean;
	type?: string;
	itemsPerPage?: number;
	showWithShowMoreButton?: number;
};

type TRowData = { row: { original: TProduct } };

export const ProductTable = observer(
	({
		setPage,
		page,
		totalItems,
		data,
		header,
		pagination,
		isLoading,
		contained = false,
		showEmpty = false,
		hide = false,
		footer = null,
		type = 'products',
		itemsPerPage,
		...rest
	}: TProps) => {
		const { addToCart, activeSearch, getIsInCart } = useProductStore();
		const { searchRegion } = useSearchStore();
		const { isAuthorized } = useAuthStore();
		const { addToNote } = useNoteStore();
		const { setActiveModal } = useAppStore();

		const { uid } = useParams();
		const isProduct = type === 'products';

		const columns = [
			TableColumn({
				header: 'Наименование',
				width: 350,
				cellClassName: 'pe-3',
				minHeight: 76,
				cellStyle: {
					whiteSpace: 'break-spaces',
					fontSize: '12px',
				},
				cell: (rowData: TRowData) => {
					const {
						uniqId,
						brand,
						code,
						name,
						imageUrl,
						used,
						office,
						multiplicity,
						storage,
						uid: productUid,
					} = rowData.row.original;

					const handleAddToNote = () => {
						activeSearch === 'multi'
							? setActiveModal('analogs', 'middle-size', {
									uid: productUid,
									brand,
									code,
									officeSpecified: false,
							  })
							: addToNote(uniqId, office.id, multiplicity ? multiplicity : 1);
					};

					return (
						<ProductItemName
							{...{
								iconName:
									activeSearch === 'multi' ? 'dynamic_feed' : 'add_to_photos',
								iconTooltip:
									activeSearch === 'multi'
										? 'Найти аналоги'
										: 'Добавить в блокнот',
								isAuthorized,
								handleAddToNote,
								uid,
								brand,
								code,
								name,
								used,
								isLoading,
								imageUrl,
								disabled: !!storage,
							}}
						/>
					);
				},
			}),
			TableColumn({
				header: 'Способы доставки',
				width: 275,
				cellClassName: 'pe-3',
				headerClassName: 'pe-1',
				minHeight: 76,
				cellStyle: {
					whiteSpace: 'break-spaces',
					fontSize: '12px',
				},
				cell: (rowData: TRowData) => {
					const {
						priceList,
						office,
						storage,
						partner,
						today,
						deliveryMessage,
					} = rowData.row.original;

					return (
						<ProductItemDeliveryNote
							{...{
								deliveryMessage,
								today,
								hideDelivery: storage || partner,
								priceList,
								isLoading,
								office,
							}}
						/>
					);
				},
			}),
			TableColumn({
				header: 'Наличие',
				width: 125,
				cellClassName: 'pe-3',
				headerClassName: 'pe-1',
				minHeight: 76,
				cellStyle: {
					whiteSpace: 'break-spaces',
					fontSize: '12px',
				},
				cell: (rowData: TRowData) => {
					const { priceList, stock, cost, storage, externalApi } =
						rowData.row.original;

					if (storage) return null;

					return (
						<ProductItemDeliveryTime
							{...{ cost, priceList, stock, isLoading, externalApi }}
						/>
					);
				},
			}),
			TableColumn({
				header: 'Стоимость',
				width: 135,
				minHeight: 76,
				cellStyle: {
					fontSize: '12px',
				},
				cell: (rowData: TRowData) => {
					const {
						costWithMarkup,
						cashback,
						partner,
						b2bCostCash,
						customerCost,
					} = rowData.row.original;

					return (
						<div className="w-100 d-flex flex-row">
							<div className="cursor-pointer">
								<div className="d-flex flex-column align-items-start w-100 me-3">
									<div className="d-flex flex-row justify-content-start align-items-center w-100">
										{customerCost ? (
											<>
												<TooltipElement
													className="me-2"
													value={<>Цена с вашей наценкой</>}
												>
													<span className="fw-bold blue-color fs-16">
														{new Intl.NumberFormat('ru-RU').format(
															customerCost
														)}{' '}
														₽
													</span>
												</TooltipElement>
											</>
										) : (
											<>
												<span className="fw-bold fs-16">
													{new Intl.NumberFormat('ru-RU').format(
														partner ? b2bCostCash : costWithMarkup
													)}{' '}
													₽
												</span>
											</>
										)}
									</div>
									{partner && cashback > 0 && (
										<div className="fs-10 badge badge-soft-success rounded-pil">
											Cashback +
											{`${new Intl.NumberFormat('ru-RU').format(cashback)} ₽`}
										</div>
									)}
								</div>
							</div>
						</div>
					);
				},
			}),
			TableColumn({
				width: isProduct ? 80 : 200,
				minHeight: 76,
				cellStyle: {
					whiteSpace: 'break-spaces',
					fontSize: '12px',
					alignItems: 'flex-end',
				},
				cell: (rowData: TRowData) => {
					const {
						storage,
						costWithMarkup,
						brand,
						multiplicity,
						stock,
						code,
						priceList,
						coreBrandId,
						deliveryDate,
						customerCost,
						isReturnable,
						imageUrl,
						time,
						uid,
					} = rowData.row.original;

					if (storage) return null;

					return (
						<ProductItemButtonBuy
							isAuthorized={isAuthorized}
							isLoading={isLoading}
							showNote={!isProduct}
							showQuantity={!isProduct}
							textButton={isProduct ? 'Купить' : ''}
							isInCart={() => getIsInCart(code, priceList, coreBrandId)}
							multiplicity={multiplicity}
							stock={stock}
							addToCart={(quantity, note) =>
								addToCart(
									{
										...rowData.row.original,
										cash: true,
										b2b: !isProduct,
										selectedPrice: costWithMarkup,
										quantity,
										...(type !== 'products' && {
											api_data: {
												isReturnable,
												customerCost,
												brand_vendor: brand,
												stock,
												multiplicity,
												uid,
												...(time && { time }),
												...(imageUrl && { imageUrl }),
												...(deliveryDate && { deliveryDate }),
												...(note && { note }),
											},
										}),
									},
									type !== 'products'
								)
							}
						/>
					);
				},
			}),
		];

		return (
			<div className="product-table">
				<Table
					showEmpty={showEmpty}
					pagination={pagination}
					isLoading={isLoading}
					data={data}
					itemsPerPage={itemsPerPage}
					setPage={setPage}
					page={page}
					columns={columns}
					tableMinHeight={'0px'}
					contained={contained}
					productAnimation={true}
					hide={hide}
					notFoundText={
						<>
							Ничего не найдено в регионе
							<span className="ms-1 text-primary">{searchRegion.name}</span>
						</>
					}
					footer={footer ? <>{footer}</> : null}
					{...(pagination && { totalItems })}
					{...(header && { header })}
					{...rest}
				/>
			</div>
		);
	}
);
