import qs from 'qs';

import { B2B_API } from 'consts';

export const b2bApiUrl = (path, query = null) => {
	return query
		? `${B2B_API}/${path}?${qs.stringify(query, {
				skipNulls: true,
				arrayFormat: 'comma',
				encode: false,
		  })}`
		: `${B2B_API}/${path}`;
};
