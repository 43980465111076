import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TVendor } from 'types/vendor';
import Checkbox from '@mui/material/Checkbox';
import { useVendorStore } from 'providers/RootStoreProvider';

type TRowData = {
	row: { original: TVendor };
};

export const VendorTable = observer(() => {
	const {
		init,
		vendors,
		totalItems,
		itemsPerPage,
		page,
		setPage,
		isLoading,
		enable,
		disable,
	} = useVendorStore();

	useEffect(() => {
		init();
	}, []);

	const columns = [
		TableColumn({
			header: '',
			width: 25,
			cellClassName: '',
			cell: (rowData: TRowData) => {
				const { vendorId, isActive } = rowData.row.original;

				return (
					<Checkbox
						onClick={() => (!isActive ? enable(vendorId) : disable(vendorId))}
						checked={isActive}
					/>
				);
			},
		}),
		TableColumn({
			header: 'Название',
			width: 350,
			cellClassName: 'py-2 pe-2',
			cell: (rowData: TRowData) => {
				const { name } = rowData.row.original;
				return <>{name}</>;
			},
		}),
	];

	return (
		<>
			<Table
				isLoading={isLoading}
				data={vendors}
				totalItems={totalItems}
				itemsPerPage={itemsPerPage}
				setPage={setPage}
				page={page}
				columns={columns}
				title="Поставщики"
				contained={true}
			/>
		</>
	);
});
