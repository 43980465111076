import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { TooltipElement } from 'components/common/TooltipElement';
import { useAuthStore, useCartStore } from 'providers/RootStoreProvider';

export const CartNotification = observer(() => {
	const { isAuthorized } = useAuthStore();
	const { cartItems, isLoading } = useCartStore();

	return (
		<TooltipElement
			disabled={isAuthorized}
			focus={true}
			placement="bottom"
			value={
				<>
					Нет доступа, необходимо{' '}
					<Link to={`/authentication/login`}>авторизоваться</Link>
				</>
			}
		>
			<div className="cart-icon-container">
				<Nav.Item as="li">
					<Nav.Link
						as={Link}
						to="/cart"
						className={classNames(
							'px-0',
							(isLoading || !isAuthorized) && 'opacity-50'
						)}
					>
						<div className="cart-circle-count">{cartItems.length}</div>
						<FontAwesomeIcon
							icon="shopping-cart"
							transform="shrink-7"
							className="fs-4"
						/>
					</Nav.Link>
				</Nav.Item>
			</div>
		</TooltipElement>
	);
});
