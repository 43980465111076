import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDebouncedCallback } from 'use-debounce';
import { Form } from 'react-bootstrap';
import { useGazStore } from 'providers/RootStoreProvider';

export const GazHeader = observer(() => {
	const { update } = useGazStore();
	const [name, setName] = useState('');
	const search = useDebouncedCallback(() => update(name), 200);

	return (
		<div className="p-3 d-flex justify-content-start align-items-center">
			<h5 className="white-space-nowrap me-3 mb-0">Нормы часов а/м ГАЗ</h5>
			<Form.Group className="w-100">
				<Form.Control
					placeholder={'Название...'}
					value={name}
					name="name"
					onChange={({ target: { value } }) => {
						setName(value);
						search();
					}}
					type="text"
				/>
			</Form.Group>
		</div>
	);
});
