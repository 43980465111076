import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { TTasks } from 'types/tasks';
import { NotFound } from 'components/common/NotFound';
import { Button } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TooltipElement } from 'components/common/TooltipElement';
import confirmBadge from 'assets/img/icons/confirm-badge.svg';
import notConfirmBadge from 'assets/img/icons/not-confirm-badge.svg';
import dayjs from 'dayjs';
import 'styles/Tasks.scss';
import { useAppStore, useTasksStore } from 'providers/RootStoreProvider';

type TProps = {
	tasks?: TTasks[];
};

export const CompanyTasks = observer(({ tasks }: TProps) => {
	const { setActiveId, removeTask } = useTasksStore();
	const { setActiveModal } = useAppStore();

	const handleSuccesTask = async (id: number) => {
		setActiveId(id);
		await setActiveModal('success_task');
	};

	const handleEdit = async (id: number) => {
		setActiveId(id);
		await setActiveModal('edit_task');
	};

	const handleDelete = async (id: number) => {
		await removeTask(id);
	};

	return (
		<>
			{tasks?.length ? (
				tasks?.map((task) => {
					return (
						<Card className="mb-3 cursor-pointer" key={task.id}>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center flex-row">
									<div className="w-100 d-flex flex-column">
										<div className="fs-18 fw-semi-bold mb-3">{task.name}</div>
										<div className="fs-14 mb-3">
											<strong>Описание:</strong>
											<div className="breaking-text">{task.descriptions}</div>
										</div>
										{task.comment && (
											<div className="fs-14 mb-3">
												<strong>Комментарий:</strong>
												<div className="breaking-text">{task.comment}</div>
											</div>
										)}
										<div className="fs-14">
											Создано: {dayjs(task.createdAt).format('DD/MM/YY HH:MM')}
										</div>
									</div>
									<div className="w-100 d-flex justify-content-end align-items-center flex-row gap-3">
										{task.status === 1 && (
											<>
												<Button
													size="sm"
													variant={'light'}
													className="border d-flex justify-content-center"
													onClick={(e) => {
														e.stopPropagation();
														handleEdit(task.id);
													}}
													name="edit"
												>
													<MaterialIcon name="edit" className="" />
												</Button>
												<Button
													size="sm"
													variant={'light'}
													className="border d-flex justify-content-center"
													onClick={() => handleDelete(task.id)}
													name="delete"
												>
													<MaterialIcon name="delete" className="" />
												</Button>
												<Button
													size="sm"
													variant={'success'}
													className="border d-flex justify-content-center"
													onClick={() => handleSuccesTask(task.id)}
												>
													Выполнить
												</Button>
											</>
										)}
										<TooltipElement
											value={task.status === 1 ? <>Открыта</> : <>Завершена</>}
										>
											<img
												src={task.status === 2 ? confirmBadge : notConfirmBadge}
												width="30px"
											/>
										</TooltipElement>
									</div>
								</div>
							</Card.Body>
						</Card>
					);
				})
			) : (
				<NotFound />
			)}
		</>
	);
});
