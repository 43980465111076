import { makeAutoObservable } from 'mobx';
import { fetchPriceLists } from 'api/priceList/priceList';
import { TWarehouse } from 'types/warehouse';
import { RootStore } from 'stores/RootStore';

export class WarehouseStore {
	isLoading = true;
	warehouses: TWarehouse[] = [];

	total = 0;
	itemsPerPage = 100;
	page = 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	init = async () => {
		this.isLoading = true;

		const res = await fetchPriceLists({
			per_page: this.itemsPerPage,
			page: this.page,
		});

		if (res === null) {
			this.isLoading = false;
			return;
		}

		const {
			data,
			meta: { count },
		} = res;

		this.warehouses = data;
		this.total = count;
		this.isLoading = false;
	};
}
