import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { TTasks } from 'types/tasks';
import { Button } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import dayjs from 'dayjs';
import { TooltipElement } from 'components/common/TooltipElement';
import confirmBadge from 'assets/img/icons/confirm-badge.svg';
import notConfirmBadge from 'assets/img/icons/not-confirm-badge.svg';
import 'styles/Tasks.scss';

type TProps = {
	task: TTasks;
	handleDelete: (result?: unknown) => void;
	handleSuccesTask: (result?: unknown) => void;
	handleEdit: (result?: unknown) => void;
};

export const ListItem = observer(
	({ task, handleDelete, handleSuccesTask, handleEdit }: TProps) => {
		const { name, createdAt, descriptions, comment, status, companyId } = task;

		const openInNewTab = useCallback((url: string) => {
			window.open(url, '_blank', 'noreferrer');
		}, []);

		return (
			<div
				onClick={() => openInNewTab(`/companies/${companyId}`)}
				className="d-flex justify-content-between align-items-center flex-row"
			>
				<div className="w-100 d-flex justify-content-flex-start flex-column">
					<div className="fs-18 fw-semi-bold mb-3">{name}</div>
					<div className="fs-14 mb-3">
						<strong>Описание:</strong>
						<div className="breaking-text">{descriptions}</div>
					</div>
					{comment && (
						<div className="fs-14 mb-3">
							<strong>Комментарий:</strong>
							<div className="breaking-text">{comment}</div>
						</div>
					)}
					<div className="fs-14">
						Создано: {dayjs(createdAt).format('DD/MM/YY HH:MM')}
					</div>
				</div>
				<div className="w-100 d-flex justify-content-end align-items-center flex-row gap-3">
					{status === 1 && (
						<>
							<Button
								size="sm"
								variant={'light'}
								className="border d-flex justify-content-center"
								onClick={(e) => {
									e.stopPropagation();
									handleEdit(task.id);
								}}
								name="edit"
							>
								<MaterialIcon name="edit" className="" />
							</Button>
							<Button
								size="sm"
								variant={'light'}
								className="border d-flex justify-content-center"
								onClick={(e) => {
									e.stopPropagation();
									handleDelete(task.id);
								}}
								name="delete"
							>
								<MaterialIcon name="delete" className="" />
							</Button>
							<Button
								size="sm"
								variant={'success'}
								className="border d-flex justify-content-center"
								onClick={(e) => {
									e.stopPropagation();
									handleSuccesTask(task.id);
								}}
							>
								Выполнить
							</Button>
						</>
					)}
					<TooltipElement value={status === 1 ? <>Открыта</> : <>Завершена</>}>
						<img
							src={status === 2 ? confirmBadge : notConfirmBadge}
							width="30px"
						/>
					</TooltipElement>
				</div>
			</div>
		);
	}
);
