import { makeAutoObservable } from 'mobx';
import { searchProductsPartners } from 'api/product/products';
import { RootStore } from 'stores/RootStore';

export class PartnerProductStore {
	DEFAULT = {
		total: 0,
		page: 0,
		products: [],
		isLoading: true,
	};

	total = 0;
	page = 0;
	products = [];
	isLoading = true;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (value) => {
		this.page = value;
	};

	reset = () => {
		Object.keys(this.DEFAULT).map((key) => (this[key] = this.DEFAULT[key]));
	};

	update = async (payload) => {
		this.isLoading = true;

		const discount = this.root.authStore.user.discount;

		const res = await searchProductsPartners({
			...payload,
			page: this.page,
			discount,
		});

		if (res === null) return;

		const { data, meta } = res;

		const formattedData = data.map((item) => ({ ...item, time: meta.time }));
		this.products = this.root.productStore.splitRegions(formattedData);
		this.total = meta ? meta.count : 0;

		this.isLoading = false;
	};
}
