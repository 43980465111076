import { makeAutoObservable } from 'mobx';
import { TRequisites, TRequisitesFull } from 'types/company';
import {
	fetchRequisites,
	postRequisite,
	fetchSuggetionsCompany,
	fetchSuggetionsBank,
} from 'api/company/requisites';
import { toast } from 'react-toastify';
import { RootStore } from 'stores/RootStore';

export class RequisitesStore {
	requisite: TRequisitesFull = null;
	isLoading = true;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	init = async () => {
		this.isLoading = true;
		const res = await fetchRequisites();
		if (res === null) {
			this.isLoading = false;
			return;
		}
		this.requisite = res[0];
		this.isLoading = false;
	};

	getSuggetionsCompany = async (query: string) => {
		const res = await fetchSuggetionsCompany({ query, count: 10 });

		if (res === null) return false;
		return res.suggestions;
	};
	getSuggetionsBank = async (query: string) => {
		const res = await fetchSuggetionsBank({ query, count: 10 });
		if (res === null) return false;

		return res.suggestions;
	};

	update = async (payload: TRequisites) => {
		const res = await postRequisite(payload);
		if (res === null) return false;

		// this.requisite = res;

		toast.success('Реквизиты отправлены на модерацию', {
			theme: 'colored',
		});

		return true;
	};
}
