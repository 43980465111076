import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { OfficeMap } from './OfficeMap';
import { Card, Button } from 'react-bootstrap';
import { useOfficeStore } from 'providers/RootStoreProvider';
import 'styles/Map.scss';

export const OfficeMapPage = observer(() => {
	const { offices, getOffices, b2b_companies } = useOfficeStore();
	const [mode, setMode] = useState('');
	const [activeIds, setActiveIds] = useState([]);

	useEffect(() => {
		getOffices();
	}, []);

	const handleSelect = useCallback((ids: number[]) => {
		setActiveIds(ids);
	}, []);

	const handleSetMode = useCallback((mode: string) => {
		setActiveIds([]);
		setMode(mode);
	}, []);

	return (
		<>
			<div className="search-results-map-yandex-map">
				<div className="header-map-container d-flex justify-content-end flex-row align-items-center">
					<div className="d-flex header-map-container-buttons justify-content-between align-items-start flex-row">
						<Card className="h-100">
							<Card.Body className="active-product-list-content-card-body p-3">
								<Button
									variant={mode === '' ? 'primary' : 'outline-primary'}
									onClick={() => handleSetMode('')}
									className="w-100 mb-2"
								>
									Все
								</Button>
								<Button
									variant={mode === 'office' ? 'primary' : 'outline-primary'}
									onClick={() =>
										handleSetMode(mode === 'office' ? '' : 'office')
									}
									className="w-100 mb-2"
								>
									Офисы
								</Button>
								<Button
									variant={
										mode === 'b2b_companies' ? 'primary' : 'outline-primary'
									}
									className="w-100 white-space-nowrap"
									onClick={() =>
										handleSetMode(
											mode === 'b2b_companies' ? '' : 'b2b_companies'
										)
									}
								>
									Автосервисы
								</Button>
							</Card.Body>
						</Card>
					</div>
				</div>
				<OfficeMap
					regionLocation={{ lat: 55.7522, lng: 37.6156 }}
					onSelect={handleSelect}
					offices={offices}
					b2b_companies={b2b_companies}
					activeIds={activeIds}
					mode={mode}
				/>
			</div>
		</>
	);
});
