import React from 'react';
import { observer } from 'mobx-react';
import { ConfirmIcon } from 'components/common/ConfirmIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMAGE_HOST } from 'consts/api';
import Flex from 'components/common/Flex';
import Rating from 'react-rating';
import { IS_OFFLINE } from 'consts/api';
import image_placeholder from 'assets/img/image-holder.png';
import { TShopPassport } from 'types/shop';

type TProps = {
	shopPassport: TShopPassport;
};

export const ShopHeader = observer(
	({ shopPassport: { rating_count, name, rating_value, shop } }: TProps) => {
		return (
			<div className="shop-header mb-4">
				<Flex direction="row" alignItems="center" justifyContent="between">
					<Flex direction="column" alignItems="start" justifyContent="between">
						<div className="header-info">
							<div className="shop-name pb-1">
								<h5>
									{name} <ConfirmIcon confirmed={shop.confirmed} />
								</h5>
							</div>
							<div className="rating">
								{/* @ts-ignore */}
								<Rating
									readonly
									fractions={2}
									initialRating={rating_value}
									fullSymbol={
										<FontAwesomeIcon icon="star" className="text-warning" />
									}
									emptySymbol={
										<FontAwesomeIcon icon="star" className="text-300" />
									}
									direction={'ltr'}
								/>{' '}
								<span className="ms-2">
									{rating_value.toFixed(1)} ({rating_count} отзыва)
								</span>
							</div>
						</div>
					</Flex>
					<div className="shop-image">
						{!IS_OFFLINE ? (
							<img src={IMAGE_HOST + shop.logo_url} alt="" />
						) : (
							<img src={image_placeholder} alt="" />
						)}
					</div>
				</Flex>
				<div className="shop-description pt-3">{shop.description}</div>
			</div>
		);
	}
);
