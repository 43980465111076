import { observer } from 'mobx-react';
import { TCartItem } from 'types/product';
import Flex from 'components/common/Flex';
import { Alert } from 'react-bootstrap';
import dayjs from 'dayjs';

export const OfficeOrder = observer(
	({ order, isPartner }: { order: TCartItem; isPartner: boolean }) => {
		return (
			<div className="order-item mb-3">
				<Flex justifyContent="between" alignItems="center" className="mb-2">
					<div className="order-product-name-container">
						<Flex justifyContent="start" alignItems="center" className="mb-2">
							<div className="order-product-name">
								<div>
									<strong>
										{order.core_brand.name} ({order.code}){' '}
										{isPartner && `| ${order.office.name}`}
										{order?.product_data?.api_data?.isReturnable !==
											undefined &&
											order?.product_data?.api_data?.isReturnable === false && (
												<span className="ms-2" style={{ color: '#e63757' }}>
													Возврату не подлежит
												</span>
											)}
									</strong>
								</div>
								<div>{order.name}</div>
								{order?.product_data?.api_data?.deliveryDate && (
									<div className="mt-1 color-red">
										Доставка:{' '}
										{dayjs(order?.product_data.api_data.deliveryDate).format(
											'DD.MM.YY HH:MM'
										)}{' '}
										{order?.product_data?.api_data?.time && (
											<>(актуально на {order?.product_data?.api_data?.time})</>
										)}
									</div>
								)}
							</div>
						</Flex>
					</div>
					<div className="order-product-info ">
						<div className="order-product-cost d-flex justify-content-end flex-row align-items-center">
							<div className="order-product-quantity me-1 fs-14">
								{order.quantity} шт.
							</div>
							<div
								className="d-flex flex-column align-items-end"
								style={{ minWidth: '120px' }}
							>
								<span>
									<strong>
										{new Intl.NumberFormat('ru-RU').format(order.cost)} ₽
									</strong>
								</span>
								<span className="fs-12">
									Кешбэк:{' '}
									{new Intl.NumberFormat('ru-RU').format(order.cashback)} ₽
								</span>
							</div>
						</div>
					</div>
				</Flex>
				{order?.product_data?.api_data?.note && (
					<Alert className="office-order-notes p-2 fs-14" variant="light">
						{order.product_data.api_data.note}
					</Alert>
				)}
			</div>
		);
	}
);
