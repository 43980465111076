import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProfileSettings } from './ProfileSettings';
import { AccountSettings } from './AccountSettings';
import { ChangePassword } from './ChangePassword';
import { observer } from 'mobx-react-lite';
import { DeliveryAddressSettings } from './DeliveryAddressSettings';
import { useAuthStore } from 'providers/RootStoreProvider';

import 'styles/Account.scss';

export const Settings = observer(() => {
	const { user } = useAuthStore();

	useEffect(() => {
		user && !user.isProfileLoaded && user.fetchProfileInfo();
	}, [user]);

	if (!user) return null;

	return (
		<div className="Account mt-3">
			<Row className="g-3">
				<Col lg={8}>
					<ProfileSettings />
					<DeliveryAddressSettings />
				</Col>
				<Col lg={4}>
					<div className="sticky-sidebar">
						<AccountSettings />
						<ChangePassword />
					</div>
				</Col>
			</Row>
		</div>
	);
});
