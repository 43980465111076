import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { NoteModal } from './comp/NoteModal';
import { SavedProductBrand } from './comp/SavedProductBrand';
import { SavedComment } from './comp/SavedComment';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import Flex from 'components/common/Flex';
import { useSavedProductsStore } from 'providers/RootStoreProvider';

export const SavedProductsTable = observer(() => {
	const {
		isLoading,
		savedProducts,
		editNoteItem,
		removeFromSavedProducts,
		totalItems,
		itemsPerPage,
		page,
		setPage,
		init,
	} = useSavedProductsStore();
	const [activeNote, setActiveNote] = useState('');
	const [activeNoteId, setActiveNoteId] = useState<number>(null);

	useEffect(() => {
		init();
	}, []);

	const onSaveNote = async (note: string) => {
		await editNoteItem(activeNoteId, note);
		setActiveNote('');
		setActiveNoteId(null);
	};

	const columns = [
		TableColumn({
			header: 'Бренд',
			width: 150,
			headerClassName: 'pe-1',
			cellClassName: 'py-2',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData) => {
				const { brand_name, product_images, preview } = rowData.row.original;

				const image =
					product_images.length > 0 ? product_images[0].image_url : preview;

				return <SavedProductBrand brand_name={brand_name} preview={image} />;
			},
		}),
		TableColumn({
			header: 'Артикул',
			width: 150,
			cell: (rowData) => {
				const { code, uid } = rowData.row.original;
				return (
					<Link to={`/products/${uid}`} className="text-primary">
						{code}
					</Link>
				);
			},
		}),
		TableColumn({
			header: 'Наименование',
			width: 450,
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData) => {
				const { id, name, note } = rowData.row.original;

				return (
					<Flex
						style={{ width: '100%' }}
						direction="row"
						alignItems="between"
						justifyContent="between"
						className="p-1 pe-4 ps-0"
					>
						<div className="d-flex align-items-center">{name}</div>
						<SavedComment
							{...{
								id,
								name,
								note,
								setActiveNoteId,
								setActiveNote,
							}}
						/>
					</Flex>
				);
			},
		}),
		TableColumn({
			header: 'Дата добавления',
			width: 150,
			cell: (rowData) => {
				const { created_at } = rowData.row.original;
				return <>{dayjs(created_at).format('DD/MM/YY HH:MM')}</>;
			},
		}),
		TableColumn({
			width: 100,
			cellStyle: {
				alignItems: 'flex-end',
			},
			cell: (rowData) => {
				const { note, id } = rowData.row.original;

				const handleDelete = async (id) => {
					await removeFromSavedProducts(id);
				};

				return (
					<div className="py-1">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item
									onClick={() => {
										setActiveNote(note);
										setActiveNoteId(id);
									}}
								>
									Редактировать
								</Dropdown.Item>
								<Dropdown.Item onClick={() => handleDelete(id)}>
									Удалить
								</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
				);
			},
		}),
	];

	return (
		<>
			<Table
				isLoading={isLoading}
				data={savedProducts}
				totalItems={totalItems}
				itemsPerPage={itemsPerPage}
				setPage={setPage}
				page={page}
				columns={columns}
				title="Сохраненные продукты"
				contained={true}
			/>
			{!!activeNoteId && (
				<NoteModal
					note={activeNote}
					show={!!activeNoteId}
					onHide={() => setActiveNoteId(null)}
					onSave={onSaveNote}
				/>
			)}
		</>
	);
});
