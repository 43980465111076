import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TMultiSearchAnalogs } from 'types/product';
import { Form } from 'react-bootstrap';
import { offersWord } from 'utils/product';
import { SortSelect } from 'components/common/SortSelect';
import { useAuthStore, useProductStore } from 'providers/RootStoreProvider';
import { ImageModal } from 'components/product/ImageModal';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import imagePlaceholderIcon from 'assets/img/image-holder.png';
import { ProductSingleImage } from 'components/common/ProductSingleImage';

type TProps = {
	data;
	close: (uid: string) => void;
};

type TRowData = {
	row: { original: TMultiSearchAnalogs };
};

type TSort = 'count-DESC' | 'b2bCostCash-ASC';

export const AnalogsSingleSearchModal = observer(({ data }: TProps) => {
	const [showImage, setShowImage] = useState([]);
	const { user } = useAuthStore();
	const { getMultisearchAnalogs } = useProductStore();
	const [isLoading, setIsLoading] = useState(true);
	const [sort, setSort] = useState<TSort>('count-DESC');
	const [filter, setFilter] = useState('partners');
	const [list, setList] = useState([]);
	const [search, setSearch] = useState('');
	const [initialSource, setIntialSource] = useState([]);

	const handleSearch = useCallback(
		(value: string) => {
			setSearch(value);
			const sourceFiltered = initialSource.filter(
				(i) =>
					!i.brand.toLowerCase().indexOf(value.toLowerCase()) ||
					!i.name.toLowerCase().indexOf(value.toLowerCase())
			);
			setList(value ? sourceFiltered : initialSource);
		},
		[initialSource]
	);

	useEffect(() => {
		const init = async () => {
			setIsLoading(true);
			const { uid, officeSpecified } = data;
			const filters = {};

			if (filter === 'all') {
				filters['partner'] = false;
			}

			if (filter === 'partners') {
				filters['partner'] = true;
			}

			if (filter === 'partners-instock') {
				filters['partner'] = true;
				filters['delivery_time_type'] = 1;
			}

			const res = await getMultisearchAnalogs(
				uid,
				sort,
				filters,
				officeSpecified
			);

			if (res === null) return;
			setList(res);
			setIntialSource(res);
			setIsLoading(false);
		};

		init();
	}, [sort, filter]);

	const columns = [
		TableColumn({
			header: 'Наименование товара',
			width: 250,
			cellClassName: 'py-2 pe-3',
			cell: (rowData: TRowData) => {
				const { name, brand, code, imageUrl } = rowData.row.original;

				const images = imageUrl.split(',').filter((i) => i);

				return (
					<div className="d-flex justify-content-start align-items-center w-100">
						<div className="me-2">
							<ProductSingleImage
								onClick={() => setShowImage(images)}
								image={imageUrl ? images[0] : imagePlaceholderIcon}
								size={'small'}
								isPreviewed={!!imageUrl}
								clickable={!!imageUrl}
							/>
						</div>
						<div
							style={{ width: 'calc(100% - 50px)' }}
							className="d-flex justify-content-start align-items-center"
						>
							<span style={{ width: '95%' }}>
								<b className="me-2">
									{brand} ({code})
								</b>
								<OverflowedTextTooltip
									text={name}
									focus={true}
									placement="bottom"
								/>
							</span>
						</div>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Цена, от',
			width: 50,
			cellClassName: 'py-2',
			headerClassName: 'text-center',
			cell: (rowData: TRowData) => {
				const { minCost } = rowData.row.original;
				return (
					<div className=" text-center w-100">{`${new Intl.NumberFormat(
						'ru-RU'
					).format(minCost)} ₽`}</div>
				);
			},
		}),
		TableColumn({
			header: 'Кол-во предложений',
			width: 50,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { count } = rowData.row.original;

				return (
					<div className="ps-3 w-100 ">
						{count} {offersWord(parseInt(count))}
					</div>
				);
			},
		}),
		TableColumn({
			cellClassName: 'py-2',
			width: 50,
			cellStyle: {
				alignItems: 'flex-end',
			},
			cell: (rowData: TRowData) => {
				const { uid } = rowData.row.original;

				const handleClick = () => {
					const hrefSplit = window.location.href.split('?');
					const params = hrefSplit.length > 0 ? hrefSplit[1] : '';
					window
						.open(
							params ? `/products/${uid}?${params}` : `/products/${uid}`,
							'_blank'
						)
						.focus();
				};

				return (
					<Button size="sm" variant={'outline-primary'} onClick={handleClick}>
						Найти
					</Button>
				);
			},
		}),
	];

	return (
		<>
			<ImageModal
				images={showImage}
				show={showImage.length > 0}
				onHide={() => setShowImage([])}
			/>
			<div className="analogs-modal position-relative">
				<Modal.Header closeButton className="modal-header-container">
					<Modal.Title className="modal-title">
						Аналоги для {data.brand} {data.code}
					</Modal.Title>
					<div className="modal-header-filter d-flex flex-row justify-content-start align-items-center flex-row">
						<Form.Group
							style={{ height: '35px', width: '70%' }}
							className="pe-3"
						>
							<Form.Control
								disabled={isLoading}
								placeholder={'Поиск по брендам'}
								value={search}
								name="search"
								onChange={({ target: { value } }) => handleSearch(value)}
								type="text"
							/>
						</Form.Group>
						{user && user.partner && (
							<SortSelect
								style={{ height: '35px', width: '50%' }}
								className="pe-3 form-select-container me-3"
								value={filter}
								sort={[
									{
										value: 'partners',
										label: 'Только поставщики',
									},
									{
										value: 'partners-instock',
										label: 'Только поставщики (в наличии)',
									},
								]}
								onChange={({ value }) => setFilter(value)}
							/>
						)}
						<SortSelect
							style={{ height: '35px', width: '35%' }}
							className="form-select-container"
							sort={[
								{
									value: 'count-DESC',
									label: 'По предложениям',
								},
								{
									value: 'b2bCostCash-ASC',
									label: 'По цене',
								},
							]}
							onChange={({ value }) => setSort(value as TSort)}
						/>
					</div>
				</Modal.Header>
				<Modal.Body className="background-pale-color">
					<Table
						isLoading={isLoading}
						data={list}
						columns={columns}
						showHeader={false}
						pagination={false}
						tableMinHeight="580px"
						loadingTableHeight="580px"
					/>
				</Modal.Body>
			</div>
		</>
	);
});
