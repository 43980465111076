import { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';

export const ConsentModal = observer(
	({
		title,
		description,
		onHide,
		show,
		onAccept,
	}: {
		title: string;
		description: string;
		order_id: number;
		onHide: () => void;
		show: boolean;
		onAccept: () => void;
	}) => {
		const handleCancel = useCallback(() => {
			onAccept();
			onHide();
		}, []);

		return (
			<Modal
				show={show}
				onHide={onHide}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="middle-size"
			>
				<>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{title}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>{description}</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant={'primary'} onClick={handleCancel}>
							Подтвердить
						</Button>
						<Button variant={'secondary'} onClick={onHide}>
							Отменить
						</Button>
					</Modal.Footer>
				</>
			</Modal>
		);
	}
);
