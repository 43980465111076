import { observer } from 'mobx-react-lite';
import { useAuthStore, useCompanyStore } from 'providers/RootStoreProvider';
import { Modal } from 'react-bootstrap';

export const TelegramInstructionModal = observer(() => {
	const { company } = useCompanyStore();
	const { user } = useAuthStore();

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>Чат-бот Gettzap в Telegram</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Функция чат-бота – возможность получить оперативную информацию в
					привычном мессенджере, в удобном формате, быстро и без использования
					компьютера.
				</p>
				<p>
					При изменении статуса заказа или возврата вы получите уведомление в
					стандартном формате Telegram.
				</p>
				<p>Как настроить чат-бота:</p>
				<ol>
					<li>
						В своей учетной записи Telegram нужно найти и выбрать&nbsp;
						<b>@b2b_gettzap_bot</b>
					</li>
					<li>
						Нажмите кнопку <b>Начать</b>
					</li>
					<li>
						Бот запросит ваш идентификатор, который нужно отправить в сообщении:
						<b>
							{' '}
							{company.id}
							{user.id}
						</b>
					</li>
					<li>Готово! Уведомления настроены</li>
				</ol>
				<p>
					Мы стремимся повысить комфорт и скорость получения информации для
					наших клиентов!
				</p>
				<p>Ждем вас в Telegram!</p>
			</Modal.Body>
		</>
	);
});
