import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductTable } from '../../product-list/ProductTable';
import CircularProgress from '@mui/material/CircularProgress';
import { BadgeItemRowList } from 'components/common/BadgeItemRowList';
import { MultiSearchTableHeader } from './MultiSearchTableHeader';
import { NotFound } from 'components/common/NotFound';
import { TSearchItem } from 'types/search';
import { AnimatedListWrapper } from 'components/common/AnimatedListWrapper';
import { ClickMoveOnTopWrapper } from 'components/common/ClickMoveOnTopWrapper';
import {
	useAppStore,
	useMultiSearchStore,
	useProductStore,
} from 'providers/RootStoreProvider';

export const MultiSearchList = observer(() => {
	const {
		update,
		isLoading,
		searchItems,
		searchProducts,
		searchOffices,
		updateProducts,
		searchOfficePending,
	} = useMultiSearchStore();
	const { setMultiSearch, setViewTypeList } = useProductStore();
	const { setActiveModal } = useAppStore();
	const [show, setShow] = useState<Record<number, boolean>>({});

	useEffect(() => {
		const init = async () => {
			await setViewTypeList();
			setMultiSearch();
			await update();
		};
		init();
	}, []);

	useEffect(() => {
		searchOffices.length && setShow({ [searchOffices[0].office.id]: true });
	}, [searchOffices]);

	const handleClick = useCallback(
		async (office_id: number) => {
			if (!searchProducts[office_id]) {
				await updateProducts(office_id);
			}
			setShow({ ...show, [office_id]: !show[office_id] });
		},
		[show, searchProducts]
	);

	const onClickSearchItem = useCallback(
		async (item: TSearchItem, officeSpecified?: boolean) => {
			await setActiveModal('analogs', 'xl-size', {
				...item,
				officeSpecified,
			});
		},
		[]
	);

	const getMissing = useCallback(
		(finded: string[]) => {
			const missing = searchItems.filter(({ uid, uidAnalog }) => {
				if (uidAnalog) {
					return !finded.includes(uidAnalog.uid);
				} else {
					return !finded.includes(uid);
				}
			});

			return missing;
		},
		[searchItems]
	);

	return (
		<>
			{searchItems.length > 0 && (
				<div className="pb-7">
					{isLoading ? (
						<div className="p-5 d-flex justify-content-center align-items-center">
							<CircularProgress size={60} />
						</div>
					) : (
						<>
							{searchOffices.length ? (
								<AnimatedListWrapper>
									{searchOffices.map(({ office, minTotalCost, count }, i) => {
										return (
											<ClickMoveOnTopWrapper
												key={'multisearch_item_' + i + office.id}
											>
												{({ moveTop }) => (
													<ProductTable
														isLoading={isLoading}
														data={
															searchProducts[office.id]
																? searchProducts[office.id]
																: []
														}
														hide={!show[office.id]}
														pagination={false}
														tableMinHeight={'0px'}
														multiBuy={true}
														type="products"
														header={
															<div
																onClick={async () => {
																	await handleClick(office.id);
																	if (!show[office.id]) moveTop();
																}}
																className="cursor-pointer"
															>
																<MultiSearchTableHeader
																	isLoading={searchOfficePending === office.id}
																	office={office}
																	totalCost={minTotalCost}
																	foundedItems={count}
																	searchedItems={searchItems.length}
																/>
															</div>
														}
														footer={
															show[office.id] &&
															getMissing(searchOffices[i].uids).length ? (
																<div className="pt-2 ps-3">
																	<hr className="m-0 mb-3" />
																	<span className="fs-14 me-3">
																		Не найдено:
																	</span>
																	<BadgeItemRowList
																		list={getMissing(searchOffices[i].uids)}
																		onClickItem={(item) =>
																			onClickSearchItem(item, true)
																		}
																		keyName={['code', 'brand']}
																	/>
																</div>
															) : null
														}
													/>
												)}
											</ClickMoveOnTopWrapper>
										);
									})}
								</AnimatedListWrapper>
							) : (
								<NotFound />
							)}
						</>
					)}
				</div>
			)}
		</>
	);
});
