const DELIVERY_TIME_TYPE_VALUE = {
	today: 2,
	todayOrTomorrow: 4,
	onOrder: 3,
};

const USED_VALUE = {
	used: true,
	new: false,
};

const DELIVERY_VALUE = {
	delivery: true,
	transport_company: true,
	pickup: true,
};

const PAYMENT_VALUE = {
	vat_exist: { vat_exist: true },
	vat_true: { vat: true },
	vat_false: { vat: false },
};

const RATING_VALUE = {
	confirmed: true,
	min_rating_value: 4,
	min_cashback: 1,
	office_favorites: true,
};

export const FILTER_TYPE = {
	delivery_time_type: 'select',
	used: 'select',
	delivery: 'select',
	payment: 'select',
	rating: 'check',
};

export const FILTER_VALUE = {
	delivery_time_type: DELIVERY_TIME_TYPE_VALUE,
	used: USED_VALUE,
	delivery: DELIVERY_VALUE,
	rating: RATING_VALUE,
	payment: PAYMENT_VALUE,
};
