import {
	castError,
	b2bApiUrl,
	defaultHeaders,
	customerApiUrl,
} from 'api/helpers';
import { TListReponse } from 'types/common';
import {
	TProductSearch,
	TCoreProduct,
	TSavedProduct,
	TCoreProductID,
	TProduct,
	TMultiSearchOffices,
	TCompanyPriceList,
	TMultiSearchAnalogs,
	TStockRes,
} from 'types/product';
import request from 'helpers/request';
import { TMultiSearch } from 'types/search';

export const deleteMultiSearchItems = async (uid): Promise<TMultiSearch[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/multi-search/${uid}`), {
			headers: {
				...defaultHeaders(),
			},
			method: 'DELETE',
		}).then(castError)
	);
};

export const deleteAllMultiSearchItems = async (): Promise<TMultiSearch[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/multi-search`), {
			headers: {
				...defaultHeaders(),
			},
			method: 'DELETE',
		}).then(castError)
	);
};

export const postMultiSearchItems = async (
	payload
): Promise<TMultiSearch[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/multi-search`), {
			headers: {
				...defaultHeaders(),
			},
			method: 'POST',
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const fetchMultiSearchItems = async (): Promise<TMultiSearch[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/multi-search`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchMultisearchAnalogs = async (
	payload
): Promise<TMultiSearchAnalogs[]> => {
	return request(
		fetch(b2bApiUrl(`common/products/find-analogs-multi-search`, payload), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchUid = async (payload): Promise<string> => {
	return request(
		fetch(b2bApiUrl(`common/catalog/search`, payload), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchProductsStockList = async (
	uid: string
): Promise<TStockRes> => {
	return request(
		fetch(b2bApiUrl(`bayer/stock/products`, { uid }), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const multiSearchProducts = async (
	query_params: TProductSearch,
	payload: string[]
): Promise<TProduct[]> => {
	return request(
		fetch(b2bApiUrl(`common/products/multi-search`, query_params), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ uids: payload }),
		}).then(castError)
	);
};

export const multiSearchOffices = async (
	query_params: TProductSearch,
	payload: string[]
): Promise<TMultiSearchOffices[]> => {
	return request(
		fetch(b2bApiUrl(`common/products/multi-search-agg`, query_params), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ uids: payload }),
		}).then(castError)
	);
};

export const searchAnalogs = async (
	query_params: TProductSearch
): Promise<TListReponse<TProduct>> => {
	return request(
		fetch(b2bApiUrl(`common/analogs/search`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchAnalogCounts = async (
	query_params: TProductSearch
): Promise<{ analogUids: number; existAnalogs: number }> => {
	return request(
		fetch(b2bApiUrl(`common/core_products/analogs-count`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const searchProducts = async (
	query_params: TProductSearch
): Promise<TListReponse<TProduct>> => {
	return request(
		fetch(b2bApiUrl(`common/products/search`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const searchProductsPartnersGettzap = async (
	query_params: TProductSearch
): Promise<TListReponse<TProduct>> => {
	return request(
		fetch(b2bApiUrl(`common/products/search-gettzap-partners`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const searchProductsPartners = async (
	query_params: TProductSearch
): Promise<TListReponse<TProduct>> => {
	return request(
		fetch(b2bApiUrl(`common/products/search-partners`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const findCoreProduct = async (uid?: string): Promise<TCoreProduct> => {
	return request(
		fetch(b2bApiUrl(`common/core_products/find`, { uid }), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchSavedProducts = async (
	params
): Promise<TListReponse<TSavedProduct>> => {
	return request(
		fetch(customerApiUrl(`saved_products`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const saveToSavedProducts = async (
	payload: TCoreProductID
): Promise<TSavedProduct> => {
	return request(
		fetch(customerApiUrl(`saved_products`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const removeSavedProducts = async (
	id: number
): Promise<TSavedProduct[]> => {
	return request(
		fetch(customerApiUrl(`saved_products/${id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const saveNoteSavedProducts = async (
	id: number,
	note: string
): Promise<TSavedProduct> => {
	return request(
		fetch(customerApiUrl(`saved_products/${id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ note }),
		}).then(castError)
	);
};

export const fetchCompanyPriceList = async (
	uid: string
): Promise<{ items: TCompanyPriceList[]; meta: { count: number } }> => {
	return request(
		fetch(b2bApiUrl(`common/products/company-price-lists`, { uid }), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
