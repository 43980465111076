import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useAppStore } from 'providers/RootStoreProvider';
import { useCallback } from 'react';

export const ToggleButton = observer(() => {
	const { isMenuOpen, setMenuOpen } = useAppStore();

	const handleClick = useCallback(() => {
		document
			.getElementsByTagName('html')[0]
			.classList.toggle('navbar-vertical-collapsed');
		setMenuOpen(!isMenuOpen);
	}, [isMenuOpen]);

	return (
		<div className={`toggle-icon-wrapper menu-trigger `}>
			<div className="d-flex flex-row justify-content-start align-items-center">
				<Button
					variant="link"
					className={classNames(
						'navbar-toggler-humburger-icon navbar-vertical-toggle text-decoration-none',
						isMenuOpen && 'active'
					)}
					id="toggleNavigationTooltip"
					onClick={handleClick}
				>
					<MaterialIcon name={'menu'} className="text-decoration-none" />
				</Button>
			</div>
		</div>
	);
});
