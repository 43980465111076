import { useCallback, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { TFilters } from 'types/filter';
import { FilterButton } from 'components/common/filter/FilterButton';

type TProps = {
	filters: TFilters;
	id: string;
	setFilter: (
		keys: string[],
		value: number | string | boolean | string[],
		update?: boolean
	) => void;
	applyFilters: (params: { resetPage: boolean }) => void;
	filterValue: string | null;
	children: ReactNode;
};

export const FilterToggleButton = observer(
	({ filterValue, id, filters, setFilter, applyFilters, children }: TProps) => {
		const isActive = filters[id] === filterValue;

		const handleToggle = useCallback(() => {
			setFilter([id], isActive ? null : filterValue);
			applyFilters({ resetPage: true });
		}, [filterValue, isActive, id]);

		return (
			<FilterButton
				showReset={filterValue !== null}
				onClick={handleToggle}
				onReset={() => setFilter([id], null, true)}
				isActive={isActive}
			>
				{children}
			</FilterButton>
		);
	}
);
