import pickBy from 'lodash/pickBy';

export const defaultHeaders = (params) =>
	pickBy({
		'Content-Type': 'application/json',
		// 'X-Authorization': '0e9819521c468bd706f169bcb5c3c59a',
		// 'X-Authorization': '74b8fda4da83fd9af67a3c6b203fa10a',
		'X-Authorization': localStorage.getItem('token'),
		...params,
	});
