import { useState } from 'react';
import { observer } from 'mobx-react';
import Flex from 'components/common/Flex';
import { MaterialIcon } from 'components/common/MaterialIcon';
import CircularProgress from '@mui/material/CircularProgress';

export const OrderHeader = observer(
	({
		total,
		name,
		undertitle,
		index,
		color,
		title,
		onRemove,
	}: {
		color?: 'success' | 'primary';
		name?: string;
		title?: string;
		total?: number;
		undertitle?: string;
		index?: number;
		onRemove?: () => Promise<void>;
	}) => {
		const [isLoading, setLoading] = useState(false);

		const handleRemove = async () => {
			setLoading(true);
			await onRemove();
			setLoading(false);
		};

		return (
			<Flex
				justifyContent="between"
				alignItems="center"
				className="w-100 order-header-content"
			>
				<>
					{title && (
						<div className="order-number pe-3 me-3">
							<h5 className={`mb-0 text-${color}`}>
								{title} {total ? total > 1 && `№${index + 1}` : <></>}
							</h5>
						</div>
					)}
					<div className="order-office-name">
						<p className="mb-0">
							{name && (
								<strong
									className={`me-3 pe-3 ${undertitle ? 'border-right' : ''}`}
								>
									{name}
								</strong>
							)}
							{undertitle && <span className="mb-0">{undertitle}</span>}
						</p>
					</div>
				</>

				{onRemove && (
					<div
						onClick={handleRemove}
						className="order-office-remove-btn cursor-pointer p-2"
					>
						{isLoading ? (
							<CircularProgress color="inherit" size={16} />
						) : (
							<MaterialIcon className="" name="delete" />
						)}
					</div>
				)}
			</Flex>
		);
	}
);
