import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';
import { SearchBox } from 'components/navbar/top/Searchbox/SearchBox';
import { TSearchItem } from 'types/search';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useNavigate } from 'react-router-dom';
import { AnimatedListWrapper } from 'components/common/AnimatedListWrapper';
import {
	useMultiSearchStore,
	useProductStore,
} from 'providers/RootStoreProvider';

type TProps = {
	close: () => void;
};

export const MultiSearchModal = observer(({ close }: TProps) => {
	const {
		update,
		searchItems,
		removeSearchItem,
		clearSearchItems,
		getSearchItems,
		addSearchItem,
	} = useMultiSearchStore();
	const { setMultiSearch } = useProductStore();
	const navigate = useNavigate();

	useEffect(() => {
		getSearchItems();
	}, []);

	const handleSelect = useCallback((searchItem: TSearchItem) => {
		addSearchItem(searchItem);
	}, []);

	const handleSubmit = useCallback(() => {
		close();
		setMultiSearch();
		update();
		navigate('/multisearch');
	}, []);

	const removeFromQuery = useCallback((index: number) => {
		removeSearchItem(index);
	}, []);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Мультипоиск
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-5 pt-4">
				<div className="mb-3">
					<SearchBox
						exception={
							searchItems && searchItems.length > 0
								? searchItems.map((i) => i && i.uid).filter((i) => i)
								: []
						}
						onSelect={handleSelect}
					/>
				</div>
				<div>
					<AnimatedListWrapper>
						{searchItems.map(({ brand, code, name }, index) => {
							return (
								<div
									key={'selected_products_' + brand + code}
									className="p-2 fs-12 multi-selected-item d-flex flex-row justify-content-between"
								>
									<div className="d-flex flex-row justify-content-start">
										<MaterialIcon name="check" className="me-1 text-primary" />
										<div className="d-flex flex-row">
											<div className="p-1">
												<strong>
													{brand} {code}
												</strong>
											</div>
											<div className="p-1">{name}</div>
										</div>
									</div>
									<MaterialIcon
										onClick={() => removeFromQuery(index)}
										name="close"
										className="ms-2 cursor-pointer"
									/>
								</div>
							);
						})}
					</AnimatedListWrapper>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant={'outline-info'}
					disabled={searchItems.length === 0}
					onClick={clearSearchItems}
				>
					Очистить
				</Button>
				<Button variant={'success'} onClick={handleSubmit}>
					Применить
				</Button>
			</Modal.Footer>
		</>
	);
});
