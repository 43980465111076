import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import useApi from 'hooks/useApi';
import { TreeviewListItem } from 'components/common/TreeviewListItem';
import { TListQuickGroup } from 'types/catalog';
import { Form } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useCatalogStore } from 'providers/RootStoreProvider';

const searchInTree = (nodes, phrase) => {
	return nodes
		.map((record) => {
			const children = searchInTree(record.children || [], phrase);
			if (record.name.toLowerCase().includes(phrase) || children.length > 0) {
				return {
					...record,
					children,
				};
			}
			return null;
		})
		.filter((record) => !!record);
};

const findFirstLink = (listQuickGroup) => {
	return listQuickGroup.reduce((acc, item) => {
		if (acc) {
			return acc;
		}
		if (item.link === 'true') {
			return item;
		}
		if (item.children) {
			return findFirstLink(item.children);
		}
	}, null);
};

export const ListQuickGroup = observer(() => {
	const { getListQuickGroup } = useCatalogStore();
	const navigate = useNavigate();
	const { mode, brand, code, ssd, vehicle_id, category_id } = useParams();
	const [data, setData] = useState(null);
	const [isOpenAll, setIsOpenAll] = useState(false);
	const [query, setQuery] = useState('');

	const {
		data: listQuickGroup,
		loading: listQuickGroupLoading,
		fetch: fetchListQuickGroup,
	} = useApi(getListQuickGroup);

	const isLoaded = !listQuickGroupLoading;

	useEffect(() => {
		fetchListQuickGroup({ catalog: code, ssd, vehicle_id });
	}, []);

	const handleClick = useCallback(
		({ quickgroupid }: TListQuickGroup) => {
			navigate(
				`/catalog-model/quicklist/${brand}/${code}/${ssd}/${vehicle_id}/${quickgroupid}`
			);
		},
		[brand, code, ssd, vehicle_id]
	);

	const handleChange = useCallback((e) => {
		const query = e.target.value;
		setQuery(query);
	}, []);

	const reset = useCallback(() => {
		listQuickGroup &&
			listQuickGroup.length &&
			setData(listQuickGroup[0].children);
	}, [listQuickGroup]);

	useEffect(() => {
		if (isLoaded && mode === 'quicklist') {
			if (category_id === '0') {
				const { quickgroupid } = findFirstLink(listQuickGroup);
				navigate(
					`/catalog-model/quicklist/${brand}/${code}/${ssd}/${vehicle_id}/${quickgroupid}`
				);
			} else {
				navigate(
					`/catalog-model/quicklist/${brand}/${code}/${ssd}/${vehicle_id}/${category_id}`
				);
			}
		}
	}, [listQuickGroupLoading, mode]);

	useEffect(() => {
		reset();
	}, [listQuickGroup]);

	useEffect(() => {
		if (query) {
			setData(searchInTree(data, query.toLowerCase()));
		} else {
			reset();
		}
		setIsOpenAll(!!query);
	}, [query]);

	return (
		<>
			{isLoaded ? (
				<>
					<div className="treeview-container">
						<Form.Group className="search-input pb-3">
							<Form.Control
								placeholder={'Поиск по названию детали'}
								value={query}
								onChange={handleChange}
								type="text"
							/>
							{query && (
								<IconButton
									color="primary"
									component="label"
									onClick={() => setQuery('')}
									className={`clear-trigger cursor-pointer fs-16 fs-10 me-2 d-flex justify-content-center align-items-center`}
								>
									<MaterialIcon className="" name="close" />
								</IconButton>
							)}
						</Form.Group>
						{listQuickGroup.length > 0 ? (
							<>
								{data.map((category, index) => {
									return (
										<TreeviewListItem
											query={query}
											isOpenAll={isOpenAll}
											key={index}
											activeKey={'quickgroupid'}
											activeValue={category_id}
											isLink={(item) => item.link === 'true'}
											keyKey={'code'}
											nameKey={'name'}
											item={category}
											handleClick={handleClick}
										/>
									);
								})}
							</>
						) : (
							<div className="p-3">Нет групп</div>
						)}
					</div>
				</>
			) : (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={35} />
				</div>
			)}
		</>
	);
});
