import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { FilterButton } from 'components/common/filter/FilterButton';
import { useShipmentStore } from 'providers/RootStoreProvider';

export const HeaderShipment = observer(() => {
	const { setPaidFilter, filterPaid,  } = useShipmentStore();

	return (
		<Card className="mb-3">
			<Card.Body className="position-relative ">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<h4 className="mb-0">Отгрузки</h4>
					<div className="d-flex flex-row justify-content-start align-items-center">
						<FilterButton
							onClick={() => setPaidFilter('all')}
							isActive={filterPaid === 'all'}
							className="me-2"
						>
							Все
						</FilterButton>
						<FilterButton
							onClick={() => setPaidFilter('true')}
							isActive={filterPaid === 'true'}
							className="me-2"
						>
							Оплачено
						</FilterButton>
						<FilterButton
							onClick={() => setPaidFilter('false')}
							isActive={filterPaid === 'false'}
							className="me-2"
						>
							Не оплачено
						</FilterButton>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
});
