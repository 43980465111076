import React, {
	useState,
	useRef,
	CSSProperties,
	useEffect,
	memo,
	useCallback,
	useMemo,
} from 'react';
import { observer } from 'mobx-react-lite';
import { components, OptionProps, ClearIndicatorProps } from 'react-select';
import { TSearchItem } from 'types/search';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'styles/SearchBox.scss';
import { useSearchStore } from 'providers/RootStoreProvider';

interface IProps {
	onSelect: (searchItem: TSearchItem) => void;
	mobile?: boolean;
	exception?: string[];
}

export const SearchBox = observer(({ mobile, onSelect, exception }: IProps) => {
	const { getSearchItems, getSearchHistory } = useSearchStore();
	const [searchInputValue, setSearchInputValue] = useState('');
	const [mount, setMount] = useState(true);
	const searchInputRef = useRef(null);

	const search = useCallback(async (search_query: string) => {
		const query = search_query.replace('|', '').replace(' ', '');
		const suggestions = query
			? await getSearchItems(query)
			: await getSearchHistory(10);

		return { options: suggestions };
	}, []);

	const Option = memo((props: OptionProps<TSearchItem>) => {
		if (exception && exception.includes(props.data.uid)) return null;

		return (
			<>
				<components.Option
					className="searchbox-custom-option cursor-pointer"
					{...props}
				>
					<div>
						<strong>{props.data.name}</strong>
					</div>
					<div>
						{searchInputValue
							? `${props.data.brand} (${props.data.code})`
							: `${props.data.brand_name} (${props.data.product_number})`}
					</div>
				</components.Option>
			</>
		);
	});

	const Menu = (props) => {
		return (
			<>
				<components.Menu {...props}>
					{!searchInputValue && <div className="p-2">История поиска</div>}
					{/* eslint-disable-next-line react/prop-types */}
					{props.children}
				</components.Menu>
			</>
		);
	};

	const ClearIndicator = memo((props: ClearIndicatorProps) => {
		const {
			getStyles,
			innerProps: { ref, ...restInnerProps },
		} = props;

		return (
			<div
				{...restInnerProps}
				ref={ref}
				style={{
					...(getStyles('clearIndicator', props) as CSSProperties),
					padding: '6px',
				}}
			>
				<div style={{ padding: '0px 5px' }}>
					<FontAwesomeIcon
						icon="times"
						transform="shrink-7"
						className="fs-4 cursor-pointer"
					/>
				</div>
			</div>
		);
	});

	const customStyles = useMemo(() => {
		return {
			control: (base) => ({
				...base,
				borderRadius: 5,
				height: 45,
				minHeight: 45,
			}),
			option: (provided, state) => ({
				...provided,
				color: state.isSelected ? '#565656 !important' : '#344050 !important',
			}),
		};
	}, []);

	useEffect(() => {
		if (!mount) setMount(true);
	}, [mount]);

	if (!mount) return <></>;

	return (
		<AsyncPaginate
			// @ts-ignore
			loadOptions={search}
			controlShouldRenderValue={true}
			loadOptionsOnMenuOpen={true}
			styles={customStyles}
			value={null}
			selectRef={searchInputRef}
			isClearable={true}
			debounceTimeout={300}
			className={`search-box ${mobile && 'mobile'}`}
			noOptionsMessage={() => <>Ничего не найдено</>}
			loadingMessage={() => <>Загрузка...</>}
			components={{
				DropdownIndicator: () => null,
				IndicatorSeparator: () => null,
				ClearIndicator,
				Option,
				Menu,
			}}
			placeholder="Поиск..."
			classNamePrefix="react-select"
			inputValue={searchInputValue}
			onInputChange={(value, action) => {
				if (action.action === 'input-change') {
					setSearchInputValue(value);
				}
			}}
			onChange={(body: TSearchItem | null) => {
				setSearchInputValue('');
				searchInputRef.current.blur();
				searchInputRef.current.focus();
				searchInputRef.current.blur();
				onSelect(body);
				setMount(false);
			}}
		/>
	);
});
