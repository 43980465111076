import flow from 'lodash/flow';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Clusterer,
	GeolocationControl,
	Map,
	Placemark,
	withYMaps,
	WithYMapsProps,
	ZoomControl,
} from 'react-yandex-maps';
import { YMaps as YandexMapContainer } from 'react-yandex-maps';
import { ReactComponent as ActivePlacemark } from 'assets/img/markers/active.svg';
import { ReactComponent as NormalPlacemark } from 'assets/img/markers/normal.svg';
import { ReactComponent as SeenPlacemark } from 'assets/img/markers/seen.svg';
import { TLocation } from 'types/common';
import { YANDEX_MAP_KEY } from 'consts/map';
import { russia } from 'consts/regions';
import { TProduct } from 'types/product';
import { observer } from 'mobx-react-lite';

interface IProps {
	activeProductsIds: number[];
	products: TProduct[];
	onSelect: (ids: number[]) => void;
	regionLocation: TLocation;
}

const locationToArray = (location: TLocation): number[] => [
	location.lat,
	location.lng,
];

const isCenterOfRussia = (point: number[]) => {
	return point[0] === russia.location?.lat && point[1] === russia.location.lng;
};

const GeoMap: React.FC<IProps & WithYMapsProps> = observer(
	({ activeProductsIds, products, onSelect, ymaps, regionLocation }) => {
		const mapRef = useRef(null);

		const [visitedIds, setVisitedIds] = useState<number[]>([]);

		const mapCenter = useMemo(
			() => locationToArray(regionLocation || russia.location),
			[regionLocation]
		);

		const zoom = useMemo(
			() => (isCenterOfRussia(mapCenter) ? 3 : 10),
			[mapCenter]
		);

		const productsByLocation = useMemo(() => {
			return flow(
				(pds) => groupBy(pds, (p) => p.office.id),
				(pds) =>
					map(pds, (value) => ({
						location: value[0].office.location,
						officeId: value[0].office.id,
						cost: value[0].costWithMarkup,
					}))
			)(products);
		}, [products]);

		const addVisited = useCallback(
			(visitedIds: number[]) =>
				setVisitedIds((ids) => uniq([...ids, ...visitedIds])),
			[setVisitedIds]
		);

		const ClusterContentLayout = useMemo(() => {
			return ymaps.templateLayoutFactory.createClass(
				`<div class="cluster-marker">{{ properties.geoObjects.length }}</div>`
			);
		}, [ymaps]);

		const PlaceLayout = useMemo(() => {
			return ymaps.templateLayoutFactory.createClass(
				[
					`<div class="place-marker-container">`,
					`<div class="place-marker-cost">{{ properties.cost }} руб</div>`,
					`<svg width="31" height="39" class="place-marker">`,
					'<use href="#map-{{properties.state}}-placemark"/>',
					'</svg>',
					'</div>',
				].join('')
			);
		}, [ymaps]);

		const onMarkClick = useCallback(
			(event) => {
				const target = event.get('target');
				if (target && target.properties) {
					const resultId: number = target.properties.get('resultId');

					if (resultId) {
						// Click on Location
						addVisited([resultId]);
						onSelect([resultId]);
						return;
					}

					const geoObjects = target.properties.get('geoObjects');
					if (!geoObjects) {
						return;
					}
					// Click on Cluster
					const ids: number[] = geoObjects.map((o) =>
						o.properties.get('resultId')
					);
					addVisited(ids);
					onSelect(ids);
				}
			},
			[addVisited, onSelect]
		);

		useEffect(() => {
			if (!mapRef.current) {
				return;
			}
			mapRef.current.setCenter(mapCenter, zoom);
		}, [mapCenter, zoom]);

		if (!ymaps) {
			return null;
		}

		return (
			<>
				<NormalPlacemark />
				<SeenPlacemark />
				<ActivePlacemark />
				<Map
					className="map"
					defaultState={{
						center: mapCenter,
						zoom,
					}}
					instanceRef={(map) => {
						mapRef.current = map;
					}}
				>
					<Clusterer
						options={{
							preset: 'islands#invertedBlueClusterIcons',
							groupByCoordinates: false,
							zoomMargin: 300,
							clusterIconLayout: ClusterContentLayout,
							clusterIconShape: {
								type: 'Circle',
								coordinates: [0, 0],
								radius: 20,
							},
						}}
						onClick={onMarkClick}
					>
						{productsByLocation.map(({ location, officeId, cost }) => (
							<Placemark
								key={officeId}
								options={{
									iconLayout: PlaceLayout,
									iconShape: {
										type: 'Rectangle',
										coordinates: [
											// the first number is the x-axis
											// the second number is the inverted y-axis
											[-31, -15],
											[39, 33],
										],
									},
								}}
								geometry={[location.lat, location.lng]}
								properties={{
									cost,
									resultId: officeId,
									state: activeProductsIds.includes(officeId)
										? 'active'
										: visitedIds.includes(officeId)
										? 'seen'
										: 'normal',
								}}
							/>
						))}
					</Clusterer>
					<ZoomControl
						options={{
							position: {
								top: 'auto',
								right: 20,
								bottom: 100,
								left: 'auto',
							},
						}}
					/>
					<GeolocationControl
						options={{
							position: {
								top: 'auto',
								right: 20,
								bottom: 50,
								left: 'auto',
							},
						}}
					/>
				</Map>
			</>
		);
	}
);

const WithYMap = withYMaps(GeoMap, true, [
	'templateLayoutFactory',
]) as unknown as React.FC<IProps>;

export const ProductMap = observer((props: IProps) => {
	return (
		<YandexMapContainer
			query={{
				lang: 'ru_RU',
				apikey: YANDEX_MAP_KEY,
			}}
		>
			<WithYMap {...props} />
		</YandexMapContainer>
	);
});
