import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TDefaultPagination } from 'types/common';
import { TListReponse } from 'types/common';
import { TTasks, TTasksPayload } from 'types/tasks';

export const fetchTasks = async (
	query_params?: TDefaultPagination
): Promise<TListReponse<TTasks>> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchTasksWithId = async (query_params?: {
	company_id?: number;
}): Promise<{ items: TTasks[] }> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchTaskById = async (task_id?: number): Promise<TTasks> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks/${task_id}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const postTasks = async (payload: TTasksPayload): Promise<TTasks> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const patchTasksSuccess = async (
	task_id: number,
	payload: TTasksPayload
): Promise<TTasks> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks/${task_id}/success`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const patchTasks = async (
	task_id: number,
	payload: TTasksPayload
): Promise<TTasks> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks/${task_id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const deleteTask = async (task_id?: number): Promise<TTasks> => {
	return request(
		fetch(b2bApiUrl(`manager/tasks/${task_id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
