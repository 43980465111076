import { OPERATION_STATUS } from 'consts/balance';
import { useEffect } from 'react';
import ru from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'components/common/Table';
import { TableColumn } from 'helpers/TableColumn';
import useApi from 'hooks/useApi';
import { BalanceFilter } from './BalaceFilter';
import { TBalanceHistory } from 'types/balance';
import { useBalanceStore } from 'providers/RootStoreProvider';

import 'styles/Balance.scss';
registerLocale('ru', ru);

type TRowData = {
	row: { original: TBalanceHistory };
};

export const BalanceTable = observer(() => {
	const { getBalanceHistory } = useBalanceStore();
	const {
		data: historyList,
		loading: isLoading,
		fetch: fetchHistoryList,
	} = useApi(getBalanceHistory);

	useEffect(() => {
		fetchHistoryList();
	}, []);

	const columns = [
		TableColumn({
			header: 'Дата',
			width: 100,
			cell: (rowData: TRowData) => {
				const { createdAt } = rowData.row.original;
				return (
					<div>
						{dayjs(createdAt).format('YYYY-MM-DD')}{' '}
						{dayjs(createdAt).format('HH:MM')}
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Номер заказа',
			width: 100,
			headerClassName: 'p-0',
			cell: (rowData: TRowData) => {
				const { description, orderId } = rowData.row.original;
				return (
					<div>
						<span>{description}</span>
						{orderId && (
							<a
								href={`/order-details/${orderId}`}
								target="_blank"
								rel="noreferrer"
								style={{ marginLeft: '10px', color: '#5e6e82' }}
							>
								<FontAwesomeIcon icon="external-link-alt" />
							</a>
						)}
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Статус заказа',
			width: 100,
			headerClassName: 'p-0',
			cell: (rowData: TRowData) => {
				const { operationType } = rowData.row.original;
				return <div>{OPERATION_STATUS[operationType].status}</div>;
			},
		}),
		TableColumn({
			header: 'Сумма',
			width: 150,
			headerClassName: 'p-0',
			cell: (rowData: TRowData) => {
				const { amount } = rowData.row.original;
				return (
					<div>{`${new Intl.NumberFormat('ru-RU').format(amount)} ₽`}</div>
				);
			},
		}),
	];

	return (
		<>
			<div className="balance">
				<BalanceFilter get={fetchHistoryList} />
				<Table
					showHeader={false}
					isLoading={isLoading}
					// @ts-ignore
					data={historyList ?? []}
					columns={columns}
					pagination={false}
					contained={false}
					notFoundText="Нет заказов."
				/>
			</div>
		</>
	);
});
