import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { Card, Button } from 'react-bootstrap';
import { useAppStore, useRequisitesStore } from 'providers/RootStoreProvider';
import { RequisiteContent } from './comp/RequisiteContent';

import 'styles/Company.scss';

export const CompanyRequisites = observer(() => {
	const { isLoading, requisite, init } = useRequisitesStore();
	const { setActiveModal } = useAppStore();

	useEffect(() => {
		init();
	}, []);

	const handleButton = useCallback(() => {
		setActiveModal('requisites', 'middle-size');
	}, [requisite]);

	if (isLoading) return null;

	return (
		<Card className="p-4">
			<div className="d-flex flex-column justify-content-start align-items-start ">
				<div>
					{requisite ? (
						<RequisiteContent requisite={requisite} />
					) : (
						<>Реквизитов нет</>
					)}
				</div>
				<Button
					variant={'outline-primary'}
					className="d-flex mt-4 justify-content-center align-items-center"
					onClick={handleButton}
				>
					<MaterialIcon className={`me-1 cursor-pointer fs-1`} name="send" />
					Изменить реквизиты
				</Button>
			</div>
		</Card>
	);
});
