import { useEffect, useState } from 'react';
import { ProductListFilterSearchCheck } from './ProductListFilterSearchCheck';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FilterWrapper } from 'components/common/filter/FilterWrapper';
import { FilterToggleButton } from './FilterToggleButton';
import { FilterQuantity } from 'components/common/filter/FilterQuantity';
import { CustomerCost } from 'components/common/filter/CustomerCost';
import {
	useCoreProductStore,
	useFilterStore,
	useProductStore,
} from 'providers/RootStoreProvider';
import { TooltipElement } from 'components/common/TooltipElement';

export const Filters = observer(() => {
	const { tab, activeSearch, updateProducts, update } = useProductStore();
	const { isLoading } = useCoreProductStore();
	const {
		filters,
		setFilter,
		toggleCheckFilter,
		getAnalogBrands,
		toggleListItemFilter,
		setCustomerCost,
	} = useFilterStore();
	const [searchParams, setSearchParams] = useSearchParams();
	const [showFilter, setShowFilter] = useState('');

	useEffect(() => {
		Object.keys(filters).forEach((key) => {
			const filter = searchParams.get(key);
			if (filter) setFilter([key], filter);
		});
	}, []);

	useEffect(() => {
		const appliedFilters = Object.keys(filters).reduce((result, key) => {
			const filterValue = filters[key];
			if (Array.isArray(filterValue)) {
				if (filterValue.length > 0) {
					result[key] = filterValue;
				}
			} else {
				if (filterValue) {
					result[key] = filterValue;
				}
			}
			return result;
		}, {});
		setSearchParams(appliedFilters);
	}, [JSON.stringify(filters)]);

	useEffect(() => {
		const customer_cost = localStorage.getItem('customer_cost');
		setCustomerCost(parseInt(customer_cost));
	}, []);

	const filterProps = {
		isLoading,
		filters,
		setFilter,
		setShowFilter,
		showFilter,
		applyFilters: () =>
			activeSearch === 'single' ? updateProducts() : update(),
		toggleCheckFilter,
		toggleListItemFilter,
	};

	return (
		<div className="filter-containers py-3 d-flex justify-content-start align-items-center flex-row flew-wrap">
			<FilterWrapper classContainer="me-2">
				<FilterToggleButton
					filterValue={null}
					id="delivery_time_type"
					{...filterProps}
				>
					Любое наличие
				</FilterToggleButton>
			</FilterWrapper>
			<FilterWrapper classContainer="me-2">
				<FilterToggleButton
					filterValue="today"
					id="delivery_time_type"
					{...filterProps}
				>
					Только сегодня
				</FilterToggleButton>
			</FilterWrapper>
			<FilterWrapper classContainer="me-2">
				<FilterToggleButton
					filterValue="todayOrTomorrow"
					id="delivery_time_type"
					{...filterProps}
				>
					Сегодня/Завтра
				</FilterToggleButton>
			</FilterWrapper>
			<FilterWrapper classContainer="me-2">
				<FilterToggleButton filterValue="used" id="used" {...filterProps}>
					Показать Б/У
				</FilterToggleButton>
			</FilterWrapper>
			<FilterWrapper
				classContainer="filter-input-container me-2"
				classContent="d-flex justify-content-start align-items-center"
			>
				<FilterQuantity
					textButton="Любое кол-во"
					update={(value) => setFilter(['min_stock'], value, true)}
					disabled={isLoading}
				/>
			</FilterWrapper>
			{tab === 'analogs' && (
				<ProductListFilterSearchCheck
					id="brands"
					title="Бренды"
					getSource={getAnalogBrands}
					sort={[
						{ value: 'costWithMarkup', label: 'По цене' },
						{ value: 'count', label: 'По предложениям' },
					]}
					{...filterProps}
				/>
			)}
			<TooltipElement className="w-100" value={<>Укажите вашу наценку</>}>
				<div className="d-flex justify-content-start align-items-center ms-5">
					<FilterWrapper
						classContainer="filter-input-container"
						classContent="d-flex justify-content-start align-items-center"
					>
						<CustomerCost
							update={(value) => setCustomerCost(value)}
							disabled={isLoading}
							width={75}
						/>
					</FilterWrapper>
				</div>
			</TooltipElement>
		</div>
	);
});
