import { makeAutoObservable } from 'mobx';
import { TShopPassport } from 'types/shop';
import { TReviewSort, TReviews } from 'types/review';
import { fetchReviews, fetchShop } from 'api/shop/shop';
import { RootStore } from 'stores/RootStore';

export class ShopStore {
	isLoading = true;
	selectedOffice: number = null;
	shopPassport: TShopPassport;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	closeShop = () => {
		this.root.appStore.setActiveOffcanvas('');
		this.root.appStore.setOffcanvasClassName('');
	};

	activateShop = async (officeId: number) => {
		this.isLoading = true;

		this.selectedOffice = officeId;
		this.root.appStore.setActiveOffcanvas('shop');
		this.root.appStore.setOffcanvasClassName(
			'office-passport-offcanvas-container'
		);
		await this.getShopPassport(officeId);

		this.isLoading = false;
	};

	getReviews = async (sort_params: TReviewSort): Promise<TReviews> => {
		const res = await fetchReviews(sort_params);

		if (res === null) return;

		const { data, meta } = res;

		return { reviews: data, count: meta.count };
	};

	getShopPassport = async (officeId: number): Promise<TShopPassport> => {
		const res = await fetchShop(officeId);

		if (res === null) return null;

		this.shopPassport = res;
	};
}
