import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';
import { TCar } from 'types/car';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { useAppStore, useCarStore } from 'providers/RootStoreProvider';

type TRowData = {
	row: { original: TCar };
};

type TProps = {
	close: (result?: unknown) => void;
	modalData: unknown;
};

export const CarTableModal = observer(({ close }: TProps) => {
	const { cars, init, isLoading } = useCarStore();
	const { setActiveModal } = useAppStore();

	useEffect(() => {
		!cars.length && init();
	}, []);

	const columns = [
		TableColumn({
			header: 'Название',
			width: 160,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { name } = rowData.row.original;
				return <>{name}</>;
			},
		}),
		TableColumn({
			cellClassName: 'py-2',
			cellStyle: {
				alignItems: 'flex-end',
			},
			cell: (rowData: TRowData) => {
				const { id } = rowData.row.original;
				return (
					<>
						<Button
							size="sm"
							variant={'outline-primary'}
							onClick={() => handleSelect(id)}
						>
							Выбрать
						</Button>
					</>
				);
			},
		}),
	].filter((i) => i);

	const create = async () => {
		const id = await setActiveModal('car_form', 'middle-size');
		close(id);
	};

	const handleSelect = useCallback((id: number) => {
		close(id);
	}, [])

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Проценка</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table
					isLoading={isLoading}
					data={cars}
					columns={columns}
					showHeader={false}
					pagination={false}
					title="Сохраненные автомобили"
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant={'outline-primary'} onClick={create}>
					Создать проценку
				</Button>
			</Modal.Footer>
		</>
	);
});
