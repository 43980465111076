import { makeAutoObservable } from 'mobx';
import {
	TDashboardCompaniesCount,
	TDashboardOrdersCount,
} from 'types/dashboard';
import { fetchDashboard } from './../../api/dashboard/dashboard';
import { validateDate } from 'helpers/validateDate';
import { RootStore } from 'stores/RootStore';

type TSearchItems = {
	from?: string;
	to?: string;
};

export class DashboardStore {
	isLoading = true;
	companiesCount = 0;
	orderCount = 0;
	companiesCountByManager: TDashboardCompaniesCount[] = [];
	ordersCountByManager: TDashboardOrdersCount[] = [];

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	getDashboard = async (searchItems?: TSearchItems) => {
		const obj = {} as TSearchItems;

		if (searchItems?.from && !validateDate(searchItems?.from, 'DD.MM.YYYY')) {
			obj.from = searchItems.from;
		}

		if (searchItems?.to && !validateDate(searchItems?.to, 'DD.MM.YYYY')) {
			obj.to = searchItems.to;
		}

		const res = await fetchDashboard(obj);

		if (res === null) return;

		const { b2b_companies_count_by_b2b_manager, orders_count_by_b2b_manager } =
			res;

		this.companiesCount = res.b2b_companies_count;
		this.orderCount = res.b2b_order_items_count;
		this.companiesCountByManager = b2b_companies_count_by_b2b_manager;
		this.ordersCountByManager = orders_count_by_b2b_manager;
	};

	init = async () => {
		this.isLoading = true;

		await this.getDashboard();

		this.isLoading = false;
	};
}
