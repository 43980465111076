import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';

export const FilterInput = observer(
	({
		value,
		disabled,
		onChange,
		width = 'inheriat',
		children = undefined,
		...rest
	}) => {
		const handleChange = (e) => {
			onChange(
				e.target.value ? parseInt(e.target.value) : 0,
				!e?.nativeEvent?.inputType
			);
		};
		return (
			<div className="position-relative w-100">
				<Form.Control
					disabled={disabled}
					type="number"
					style={{ width }}
					value={value === 0 ? '' : value}
					onChange={handleChange}
					{...rest}
				/>
				{children}
			</div>
		);
	}
);
