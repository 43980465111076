import { toast } from 'react-toastify';

const request = async (request) => {
	try {
		return await request;
	} catch (error) {
		toast.error(error.message, { theme: 'colored' });
		return null;
	}
};

export default request;
