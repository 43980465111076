import {
	castError,
	defaultHeaders,
	customerApiUrl,
	b2bApiUrl,
} from 'api/helpers';
import { TCart, TProductItemID } from 'types/product';
import { TOrder } from 'types/order';
import { TCartCheckout } from 'types/cart';
import request from 'helpers/request';

export const patchCartItemNote = async (cartItemId, note): Promise<TOrder> => {
	return request(
		fetch(b2bApiUrl(`bayer/cart/change-cart-item-note/${cartItemId}`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ note }),
		}).then(castError)
	);
};

export const patchCartPayment = async (cartId: string, cash: boolean) => {
	return request(
		fetch(b2bApiUrl(`bayer/cart/change-payment-method/${cartId}`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ cash }),
		}).then(castError)
	);
};

export const fetchCart = async (): Promise<TCart> => {
	return request(
		fetch(customerApiUrl(`cart`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const putManyItemCart = async (payload: {
	items: TProductItemID[];
}): Promise<TCart> => {
	return request(
		fetch(customerApiUrl(`cart/add_many_items`), {
			method: 'PUT',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const putItemCart = async (payload: TProductItemID): Promise<TCart> => {
	return request(
		fetch(customerApiUrl(`cart/add_item`), {
			method: 'PUT',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};
``;

export const updateCartItem = async (id: number, payload): Promise<TCart> => {
	return request(
		fetch(customerApiUrl(`cart/update_item/${id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const removeCartItem = async (id: number): Promise<TCart> => {
	return request(
		fetch(customerApiUrl(`cart/remove_item/${id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const checkoutCart = async (
	payload: TCartCheckout
): Promise<TOrder[]> => {
	return request(
		fetch(customerApiUrl(`orders/create_many`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};
