import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import { Card } from 'react-bootstrap';
import { BalanceTable } from './BalanceTable';
import { BalanceInfo } from './BalanceInfo';
import { useBalanceStore } from 'providers/RootStoreProvider';

export const Balance = observer(() => {
	const {
		init,
		isLoading,
		balance,
		completedOrderItemsSum,
		notCompletedOrderItemsSum,
		availableBalance,
	} = useBalanceStore();

	useEffect(() => {
		init();
	}, []);

	if (isLoading) {
		return (
			<div className="p-5 d-flex justify-content-center align-items-center">
				<CircularProgress size={60} />
			</div>
		);
	}

	return (
		<>
			<Card className="p-4">
				<h4 className="mb-3">Баланс и платежи</h4>
				<BalanceInfo
					balance={balance}
					completedOrderItemsSum={completedOrderItemsSum}
					notCompletedOrderItemsSum={notCompletedOrderItemsSum}
					availableBalance={availableBalance}
				/>
				<BalanceTable />
			</Card>
		</>
	);
});
