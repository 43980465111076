import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Card, Button } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { Link, useNavigate } from 'react-router-dom';
import { SelectModel } from 'components/common/SelectModel';
import useApi from 'hooks/useApi';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const CatalogSelect = observer(() => {
	const { wizardSearch } = useCatalogStore();
	const { brand, code } = useParams();
	const { data: wizardFirst, fetch: getWizardFirst } = useApi(wizardSearch);
	const { data: wizardSecond, fetch: getWizardSecond } = useApi(wizardSearch);
	const [firstSelectName, setFirstSelectName] = useState<string>('');
	const [selectsOptions, setSelectsOptions] = useState<Record<string, string>>(
		{}
	);
	const [ssd, setSsd] = useState<string>('');
	const navigate = useNavigate();

	const modelNames = [
		'Модель',
		'Серия',
		'Семейство',
		'Vehicle Family',
		'Торговое обозначение',
		'Тип',
	];

	const handleShowCars = useCallback(async () => {
		navigate(`/catalog/${brand}/${code}/${ssd}`);
	}, [brand, code, ssd]);

	const onChange = useCallback((name: string, label: string, value: string) => {
		setSsd(value);
		setSelectsOptions((prevState) => ({
			...prevState,
			[name]: label,
		}));
	}, []);

	const firstSelect = useMemo(() => {
		if (!wizardFirst) {
			return null;
		}

		if (wizardFirst.length === 0) {
			return <>Параметров нет</>;
		}

		const data = wizardFirst.find((record) => modelNames.includes(record.name));

		setFirstSelectName(data ? data.name : wizardFirst[0].name);

		return (
			<div className="mb-3">
				<SelectModel
					label="Модель"
					data={data ? data : wizardFirst[0]}
					selectsOptions={selectsOptions}
					onChange={onChange}
				/>
			</div>
		);
	}, [wizardFirst, selectsOptions, ssd, onChange]);

	const othersSelects = useMemo(() => {
		if (!wizardSecond || !firstSelectName || !ssd) {
			return null;
		}

		if (wizardSecond.length === 0) {
			return <>Дополнительных параметров нет</>;
		}

		const selects = wizardSecond.filter(
			(record) => record.name !== firstSelectName
		);

		return selects.map((select, index) => (
			<div key={select.name + index} className="mb-3">
				<SelectModel
					isDetermined={select.determined === 'true'}
					label="Модель"
					data={select}
					selectsOptions={selectsOptions}
					onChange={onChange}
				/>
			</div>
		));
	}, [firstSelectName, ssd, wizardSecond, selectsOptions, onChange]);

	useEffect(() => {
		getWizardFirst({ catalog: code });
	}, []);

	useEffect(() => {
		ssd && getWizardSecond({ catalog: code, ssd });
	}, [ssd]);

	return (
		<>
			<Card className="p-4">
				<div className="model-badge mb-3">
					{brand}
					<Link to="/catalog">
						<IconButton color="primary" component="label" className={`fs-10`}>
							<MaterialIcon name="close" className="" />
						</IconButton>
					</Link>
				</div>
				<div>
					{firstSelect}
					{othersSelects}
				</div>
				<div className="d-flex mt-3">
					<Button disabled={!ssd} variant={'primary'} onClick={handleShowCars}>
						Показать автомобили
					</Button>
				</div>
			</Card>
		</>
	);
});
