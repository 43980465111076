import { useCallback, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Form } from 'react-bootstrap';
import { useRequisitesStore } from 'providers/RootStoreProvider';
import { Formik } from 'formik';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components, OptionProps } from 'react-select';
import {
	TRequisiteSuggestionBank,
	TRequisiteSuggestionCompany,
} from 'types/requisites';
import { TRequisitesFull } from 'types/company';
import { isEmpty } from 'lodash';

type TProps = {
	close: () => void;
};

export const RequisitesModal = observer(({ close }: TProps) => {
	const { requisite, update, getSuggetionsCompany, getSuggetionsBank } =
		useRequisitesStore();
	const [data, setData] = useState(requisite);
	const [companyInput, setCompanyInput] = useState('');
	const [bankInput, setBankInput] = useState('');
	const bankInputRef = useRef(null);
	const companyInputRef = useRef(null);

	const companyReq = [
		{ name: 'fullName', label: 'Организация', disabled: true },
		{ name: 'inn', label: 'ИНН', disabled: true },
		{ name: 'kpp', label: 'КПП', disabled: true },
		{ name: 'ogrn', label: 'ОГРН', disabled: true },
		{ name: 'okpo', label: 'ОКПО', disabled: true },
		{ name: 'generalDirector', label: 'Руководитель', disabled: true },
		{ name: 'legalAddress', label: 'Юридический адрес', disabled: true },
		{ name: 'postAddress', label: 'Почтовый адрес', disabled: true },
	];

	const bankReq = [
		{ name: 'bankAccount', label: 'Расчётный счёт', disabled: false },
		{ name: 'correspondentAccount', label: 'Корр. счёт', disabled: true },
		{ name: 'bik', label: 'БИК', disabled: true },
		{ name: 'bankName', label: 'Банк', disabled: true },
	];

	const handleSubmit = useCallback((payload) => {
		update(payload);
		close();
	}, []);

	const setCompanyReq = (value: TRequisiteSuggestionCompany) => {
		const copy = { ...data };
		copy.fullName = value?.data?.name.full ?? '';
		copy.inn = value?.data?.inn ?? '';
		copy.kpp = value?.data?.kpp ?? '';
		copy.ogrn = value?.data?.ogrn ?? '';
		copy.okpo = value?.data?.okpo ?? '';
		copy.legalAddress = value?.data?.address?.unrestricted_value ?? '';
		copy.generalDirector = value.data?.management?.name ?? '';
		copy.postAddress = '';

		console.log({ copy });

		setData(copy);
	};

	const setBankReq = (value: TRequisiteSuggestionBank) => {
		const copy = { ...data };

		copy.correspondentAccount = value?.data?.correspondent_account ?? '';
		copy.bik = value?.data?.bic ?? '';
		copy.bankName = value?.value ?? '';

		setData(copy);
	};

	const searchCompany = useCallback(async (search_query: string) => {
		const suggestions = await getSuggetionsCompany(search_query);
		return { options: suggestions };
	}, []);

	const searchBank = useCallback(async (search_query: string) => {
		const suggestions = await getSuggetionsBank(search_query);
		return { options: suggestions };
	}, []);

	const Option = (
		props: OptionProps<TRequisiteSuggestionBank | TRequisiteSuggestionCompany>
	) => {
		const isSelected = props.data.data.inn === data.inn;

		return (
			<>
				<components.Option
					className="searchbox-custom-option cursor-pointer"
					{...props}
					isSelected={isSelected}
				>
					<div>
						<strong className="me-2" style={{ color: '#000' }}>
							{props.data.value}
						</strong>
						<span className="me-2" style={{ color: '#000' }}>
							ИНН: {props.data.data.inn}
						</span>
						<span className="me-2" style={{ color: '#000' }}>
							ОГРН: {props.data.data.ogrn}
						</span>
					</div>
				</components.Option>
			</>
		);
	};

	const setPostLikeLegalAddress = () => {
		setData((val) => ({ ...val, postAddress: val.legalAddress }));
	};

	useEffect(() => {
		if (isEmpty(data)) {
			const initialData = [...companyReq, ...bankReq].reduce((acc, item) => {
				acc[item.name] = '';
				return acc;
			}, {}) as TRequisitesFull;

			console.log({ initialData });
			setData(initialData);
		}
	}, []);

	return (
		<div className="analogs-modal position-relative">
			<Formik
				initialValues={data}
				enableReinitialize
				onSubmit={async (values) => {
					handleSubmit(values);
				}}
			>
				{({ values, handleChange, handleSubmit, isValid }) => (
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<Modal.Header closeButton className="modal-header-container">
							<Modal.Title className="p-0">Реквизиты организации</Modal.Title>
						</Modal.Header>
						<Modal.Body className="background-pale-color border-dashed-bottom pb-4">
							<div className="ps-2">
								<Form.Group className="w-100">
									<Form.Label>
										Введите название организации, ИНН или ОГРН
									</Form.Label>
									<AsyncPaginate
										// @ts-ignore
										loadOptions={searchCompany}
										noOptionsMessage={() => <>Ничего не найдено</>}
										loadingMessage={() => <>Загрузка...</>}
										placeholder={'Название организации, ИНН или ОГРН'}
										classNamePrefix="react-select"
										className="w-100 mb-3"
										debounceTimeout={300}
										inputValue={companyInput}
										onFocus={() => {
											const copy = `${companyInput}`;
											setCompanyInput('');
											setTimeout(() => {
												setCompanyInput(copy);
											}, 0);
										}}
										onInputChange={(value, action) => {
											if (action.action === 'input-change') {
												setCompanyInput(value);
											}
										}}
										components={{
											Option,
										}}
										onChange={(value: TRequisiteSuggestionCompany) => {
											if (!value) return;

											setCompanyInput(value.value);
											setCompanyReq(value);

											setTimeout(() => {
												companyInputRef.current.blur();
												companyInputRef.current.focus();
												companyInputRef.current.blur();
											}, 0);
										}}
										selectRef={companyInputRef}
									/>
								</Form.Group>
								{companyReq.map(({ name, label, disabled }, i) => {
									if (name === 'postAddress') {
										return (
											<Form.Group key={'form_' + i + name}>
												<Form.Label className="mt-2">{label}</Form.Label>

												<Form.Control
													placeholder={label}
													value={
														values && values[name]
															? (values[name] as string | number)
															: undefined
													}
													name={name}
													disabled={disabled}
													onChange={handleChange}
												/>
												<div
													onClick={setPostLikeLegalAddress}
													className="mt-2 fs-14 link-type-text"
												>
													Совпадает с юридическим
												</div>
											</Form.Group>
										);
									}

									return (
										<Form.Group key={'form_' + i + name}>
											<Form.Label className="mt-2">{label}</Form.Label>
											<Form.Control
												placeholder={label}
												value={
													values && values[name]
														? (values[name] as string | number)
														: undefined
												}
												name={name}
												disabled={true}
												onChange={handleChange}
											/>
										</Form.Group>
									);
								})}
							</div>
						</Modal.Body>
						<Modal.Header className="modal-header-container ">
							<Modal.Title className="p-0">Банковские реквизиты</Modal.Title>
						</Modal.Header>
						<Modal.Body className="background-pale-color pb-4">
							<div className="ps-2">
								<Form.Group className="w-100">
									<Form.Label>Введите БИК</Form.Label>
									<AsyncPaginate
										// @ts-ignore
										loadOptions={searchBank}
										noOptionsMessage={() => <>Ничего не найдено</>}
										loadingMessage={() => <>Загрузка...</>}
										placeholder={'БИК'}
										classNamePrefix="react-select"
										className="w-100 mb-3"
										debounceTimeout={300}
										inputValue={bankInput}
										onInputChange={(value, action) => {
											if (action.action === 'input-change') {
												setBankInput(value);
											}
										}}
										components={{
											Option,
										}}
										onFocus={() => {
											const copy = `${bankInput}`;
											setBankInput('');
											setTimeout(() => {
												setBankInput(copy);
											}, 0);
										}}
										onChange={(value: TRequisiteSuggestionBank) => {
											if (!value) return;

											setBankInput(value.value);
											setBankReq(value);

											setTimeout(() => {
												bankInputRef.current.blur();
												bankInputRef.current.focus();
												bankInputRef.current.blur();
											}, 0);
										}}
										selectRef={bankInputRef}
									/>
								</Form.Group>
								{bankReq.map(({ name, label, disabled }, i) => {
									return (
										<Form.Group key={'form_' + i + name}>
											<Form.Label className="mt-2">{label}</Form.Label>
											<Form.Control
												placeholder={label}
												value={
													values && values[name]
														? (values[name] as string | number)
														: undefined
												}
												name={name}
												disabled={disabled}
												onChange={handleChange}
											/>
										</Form.Group>
									);
								})}
							</div>
						</Modal.Body>
						<div className="border-dashed-bottom mb-3" />
						<div className="d-flex justify-content-end align-items-center">
							<Button
								disabled={!isValid}
								variant="primary"
								type="submit"
								className="mx-3 mb-3"
							>
								Сохранить
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
});
