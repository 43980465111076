import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import { TasksFormModal } from './comp/TasksFormModal';
import { TTasksPayload } from 'types/tasks';
import { useTasksStore } from 'providers/RootStoreProvider';

type TProps = {
	close: (result?: unknown) => void;
};

export const TasksModal = observer(({ close }: TProps) => {
	const { createTasks } = useTasksStore();
	const { id: companyId } = useParams();

	const handleCreateTask = async (payload: TTasksPayload) => {
		await createTasks(payload);
		close();
	};

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Создать задачу
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TasksFormModal
					mode="create"
					onSubmit={handleCreateTask}
					companyId={+companyId}
				/>
			</Modal.Body>
		</>
	);
});
