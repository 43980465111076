import React from 'react';
import { Nav } from 'react-bootstrap';
import { ProfileDropdown } from 'components/navbar/top/ProfileDropdown';
import { CartNotification } from 'components/navbar/top/CartNotification';
import { SavedProductsNotification } from 'components/navbar/top/SavedProductsNotification';

const TopNavRightSideNavItem = () => {
	return (
		<Nav
			navbar
			className="navbar-nav-icons ms-auto flex-row align-items-center"
			as="ul"
		>
			<SavedProductsNotification />
			<CartNotification />
			<ProfileDropdown />
		</Nav>
	);
};

export default TopNavRightSideNavItem;
