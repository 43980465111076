import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';

type TProps = {
	note?: string;
	onSave: (note: string) => Promise<boolean>;
};

export const NoteEdit = observer(({ onSave, note }: TProps) => {
	const [isOpen, setOpen] = useState(false);
	const [input, setInput] = useState(note || '');
	const inputRef = useRef(null);

	useEffect(() => {
		setInput(note);
	}, [note]);

	const handleSave = async () => {
		if (!note && !input) return;

		const res = await onSave(input);
		!res && setInput(note);
	};

	useEffect(() => {
		isOpen && inputRef.current && inputRef.current.focus();
	}, [isOpen, inputRef.current]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		handleSave();
	};

	const handleKeyEnter = (e) => e.keyCode === 13 && handleClose();

	return (
		<Alert
			onClick={handleOpen}
			className={`office-order-notes p-0 fs-14 mt-2 mb-0 cursor-pointer ${
				!note && 'transparent'
			}`}
			variant={'light'}
		>
			<div className="note-edit-input-container">
				{isOpen ? (
					<>
						<input
							type="text"
							value={input}
							onChange={(e) => setInput(e.target.value)}
							className="note-edit-input active p-1"
							ref={inputRef}
							onBlur={handleClose}
							onKeyDown={handleKeyEnter}
						/>
						<div onClick={handleSave} className="note-edit-input-save-button">
							<MaterialIcon name="save" className="save" />
						</div>
					</>
				) : (
					<>
						{note ? (
							<div className="p-1">
								<OverflowedTextTooltip text={input} />
							</div>
						) : (
							<input
								type="text"
								placeholder="Примечание"
								className="note-edit-input cursor-pointer p-1"
							/>
						)}
						<MaterialIcon name="edit" className="" />
					</>
				)}
			</div>
		</Alert>
	);
});
