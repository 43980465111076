import { useCallback } from 'react';
import Flex from 'components/common/Flex';
import { observer } from 'mobx-react';
import { TWorkHours } from 'types/product';
import { Accordion, ListGroup } from 'react-bootstrap';
import dayjs from 'dayjs';
import { MaterialIcon } from 'components/common/MaterialIcon';

type TProps = {
	work_hours: TWorkHours;
};

const days = {
	mon: 'Понедельник',
	tue: 'Вторник',
	wed: 'Среда',
	thu: 'Четверг',
	fri: 'Пятница',
	sat: 'Суббота',
	sun: 'Восресенье',
};

export const ShopSchedule = observer(({ work_hours }: TProps) => {
	const getToday = useCallback(() => {
		dayjs.locale('en');
		return dayjs().format('ddd').toLocaleLowerCase();
	}, []);

	const getEndWorkingHoursToday = useCallback(() => {
		const current_time_hour = parseInt(dayjs().format('hh'));
		const today = getToday();

		if (work_hours && !work_hours[today]) return 'Выходной';

		const start_hour = Math.ceil(work_hours[today].start / 60);
		const end_hour = Math.ceil(work_hours[today].end / 60);

		return current_time_hour > end_hour
			? `Открыто с ${start_hour}:00`
			: `Работает до ${end_hour}:00`;
	}, []);

	const getHour = useCallback((time: number) => {
		return Math.ceil(time / 60) + ':00';
	}, []);

	return (
		<Accordion style={{ width: '100%' }} defaultActiveKey="0" flush>
			<Accordion.Item eventKey="0">
				<Accordion.Header>
					<MaterialIcon
						className={`pe-3 fs-1 text-secondary`}
						name="schedule"
					/>

					{getEndWorkingHoursToday()}
				</Accordion.Header>
				<Accordion.Body>
					<ListGroup variant="flush">
						{Object.keys(days).map((day) => {
							return (
								<ListGroup.Item key={`working_hours_day_${day}`}>
									<Flex
										direction="row"
										alignItems="start"
										justifyContent="between"
										className="fs-14"
									>
										<span>
											{day === getToday() && (
												<span
													className={`material-icons dot-active text-primary fs-12`}
												>
													circle
												</span>
											)}{' '}
											{days[day]}
										</span>
										<span>
											{work_hours[day]
												? `${getHour(work_hours[day].start)} - ${getHour(
														work_hours[day].end
												  )}`
												: 'Выходной'}
										</span>
									</Flex>
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
});
