import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import {
	TCashback,
	TCashbackRequisite,
	TCashbackRequisitePayload,
} from 'types/cashback';

export const fetchCashback = async (query_params: {
	from?: string;
	to?: string;
}): Promise<TCashback> => {
	return request(
		fetch(b2bApiUrl(`bayer/cashback`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchRequisitesCashback = async (): Promise<
	TCashbackRequisite[]
> => {
	return request(
		fetch(b2bApiUrl(`bayer/requisites-cashback`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const postRequisitesCashback = async (
	payload: TCashbackRequisitePayload
): Promise<TCashbackRequisite> => {
	return request(
		fetch(b2bApiUrl(`bayer/requisites-cashback`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const patchRequisitesCashback = async (
	id: number,
	payload: TCashbackRequisitePayload
): Promise<TCashbackRequisite> => {
	return request(
		fetch(b2bApiUrl(`bayer/requisites-cashback/${id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const deleteRequisitesCashback = async (
	id: number
): Promise<TCashbackRequisite> => {
	return request(
		fetch(b2bApiUrl(`bayer/requisites-cashback/${id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
