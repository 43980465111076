import { observer } from 'mobx-react';
import { TCar } from 'types/car';
import { Placeholder } from 'react-bootstrap';
import dayjs from 'dayjs';

type TProps = {
	car: TCar;
	handleClick: () => void;
};

export const CarItemInfo = observer(({ car, handleClick }: TProps) => {
	return (
		<>
			{car ? (
				<div
					onClick={handleClick}
					className="car-info w-100 d-flex justify-content-flex-between flex-column"
				>
					<div className="fs-16 fw-semi-bold">
						{car.name && `${car.name}`}
						{car.model && `, ${car.model}`}
						{car.carNumber && `, ${car.carNumber}`}
						{car.vin && `, ${car.vin}`}
						{car.year && `, ${car.year}`}
					</div>
					<div className="fs-13">
						{dayjs(car.createdAt).format('DD/MM/YY HH:MM')}
					</div>
					<div className="fs-13">{car.note}</div>
				</div>
			) : (
				<div className="car-info w-50 d-flex justify-content-flex-between flex-column">
					<div style={{ height: '18px' }} className="d-flex flex-row mb-2">
						<Placeholder xs={3} />
					</div>
					<div style={{ height: '18px' }} className="d-flex flex-row">
						<Placeholder xs={2} />
					</div>
				</div>
			)}
		</>
	);
});
