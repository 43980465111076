import { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { TOrder } from 'types/order';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { ORDER_STATUS } from 'consts/order';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ConsentModal } from 'components/modals/ConsentModal';
import { ReviewModal } from 'components/modals/ReviewModal';
import { Table } from 'components/common/Table';
import { TableColumn } from 'helpers/TableColumn';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TooltipElement } from 'components/common/TooltipElement';
import { OrderFilters } from './comp/OrderFilters';
import {
	useOrderStore,
	useReviewStore,
	useShopStore,
} from 'providers/RootStoreProvider';
import { Button } from 'react-bootstrap';
import 'styles/OrdersTable.scss';
import { NoteEdit } from 'components/e-commerce/cart/components/NoteEdit';

type TRowData = {
	row: { original: TOrder };
};

export const OrdersTable = observer(() => {
	const [filters, setFilter] = useState({
		comment: '',
		status: null,
		from: null,
		to: null,
		code: '',
		brand: '',
		name: '',
		cash: null,
		order_id: null,
	});

	const {
		isLoading,
		update,
		orders,
		itemsPerPage,
		page,
		totalItems,
		setPage,
		cancelOrder,
		ORDER_STATUS_KEYS,
		totalCost,
		editNote,
		downloadXLS,
		returnOrder,
	} = useOrderStore();
	const { sendReview } = useReviewStore();
	const { activateShop } = useShopStore();
	const [activeModal, setActiveModal] = useState<
		'review' | 'cancel' | '' | 'return'
	>('');
	const [activeOrderId, setActiveOrderId] = useState(null);

	const handleCloseModal = useCallback(() => {
		setActiveOrderId(null);
		setActiveModal('');
	}, []);

	const download = useCallback((blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}, []);

	const handleDownloadXLS = async () => {
		const blob = await downloadXLS(filters);
		download(blob, `gettzap_orders`);
	};

	const columns = [
		TableColumn({
			header: 'Заказ',
			width: 100,
			cellClassName: 'py-2 pe-2',
			cell: (rowData: TRowData) => {
				const { orderId, createdAt } = rowData.row.original;

				return (
					<div className="d-flex w-100 justify-content-start align-items-start flex-column">
						<div className="w-100 d-flex justify-content-start align-items-start flex-row">
							<Link
								style={{ whiteSpace: 'pre' }}
								to={`/order-details/${orderId}`}
							>
								<strong className="cursor-pointer  me-1">№ {orderId}</strong>
							</Link>
						</div>
						<span>{dayjs(createdAt).format('DD.MM.YY')}</span>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Продавец',
			width: 115,
			cellClassName: 'py-2 pe-2',
			cell: (rowData: TRowData) => {
				const { officeName, officeId } = rowData.row.original;

				const onShopName = () => activateShop(officeId);

				return (
					<div className="d-flex w-100 justify-content-start align-items-start flex-column">
						<OverflowedTextTooltip
							text={officeName}
							value={
								<span
									onClick={onShopName}
									className="cursor-pointer text-primary hover"
								>
									{officeName}
								</span>
							}
						/>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Наименование',
			width: 300,
			headerClassName: 'pe-1',
			cellClassName: 'py-2 pe-3',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},

			cell: (rowData: TRowData) => {
				const { id, code, name, product_name, apiData } = rowData.row.original;

				return (
					<div className="w-100">
						<strong>
							Арт. {code}, {name}
						</strong>
						<p className="mb-1 fs--1">
							{product_name && <OverflowedTextTooltip text={product_name} />}
							{apiData?.deliveryDate && (
								<div>
									Доставка:{' '}
									{dayjs(apiData?.deliveryDate).format('DD.MM.YY HH:MM')}
								</div>
							)}
							<NoteEdit
								onSave={(note) => editNote(id, note)}
								note={apiData?.note}
							/>
						</p>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Кол-во',
			width: 70,
			headerClassName: 'pe-1',
			cellClassName: 'py-2',

			cell: (rowData: TRowData) => {
				const { quantity } = rowData.row.original;
				return <div className="w-100">{quantity}</div>;
			},
		}),
		TableColumn({
			header: 'Оплата',
			width: 80,
			headerClassName: 'pe-1',
			cellClassName: 'py-2',

			cell: (rowData: TRowData) => {
				const { cash } = rowData.row.original;
				return <div className="w-100">{cash ? 'Нал' : 'Безнал'}</div>;
			},
		}),
		TableColumn({
			header: 'Статус',
			width: 140,
			headerClassName: 'px-2 fs-12',
			cellClassName: 'px-2 pe-4 fs-14',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { id, status } = rowData.row.original;
				const order_status = ORDER_STATUS[status];

				const handleCancel = () => {
					setActiveOrderId(id);
					setActiveModal('cancel');
				};

				const handleReturn = () => {
					setActiveOrderId(id);
					setActiveModal('return');
				};

				return (
					<>
						<div className="d-flex w-100 flex-row justify-content-start align-items-center">
							<SoftBadge
								style={{ maxWidth: '150px' }}
								pill
								bg={classNames(order_status && order_status.type)}
								className="d-block px-3 me-3 py-1 white-space-break-spaces"
							>
								{order_status ? order_status.status : status}
							</SoftBadge>
							{ORDER_STATUS_KEYS.in_work.includes(status) && (
								<TooltipElement focus={true} value={<>Отменить заказ</>}>
									<MaterialIcon
										onClick={handleCancel}
										className=" cursor-pointer fs-18"
										name="close"
									/>
								</TooltipElement>
							)}
							{ORDER_STATUS_KEYS.issued.includes(status) && (
								<TooltipElement
									focus={true}
									value={<>Отправить заявку на возврат</>}
								>
									<MaterialIcon
										onClick={handleReturn}
										className=" cursor-pointer fs-18"
										name="assignment_return"
									/>
								</TooltipElement>
							)}
						</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Сумма',
			width: 100,
			cellClassName: 'py-2',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { totalCost } = rowData.row.original;
				return (
					<>
						<strong className="fs-16 fw-bold">
							{totalCost &&
								`${new Intl.NumberFormat('ru-RU').format(totalCost)} ₽`}
						</strong>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Кешбэк',
			width: 100,
			cellClassName: 'py-2',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { cashback } = rowData.row.original;
				return (
					<>
						<strong className="fs-16 fw-bold">
							{cashback &&
								`${new Intl.NumberFormat('ru-RU').format(cashback)} ₽`}
						</strong>
					</>
				);
			},
		}),
	];

	return (
		<>
			{activeModal === 'return' && (
				<ConsentModal
					order_id={activeOrderId}
					show={!!activeModal}
					onHide={() => setActiveModal('')}
					onAccept={async () => {
						await returnOrder({ order_id: activeOrderId });
						update(filters);
					}}
					title="Подтвердите возврат заказа"
					description="Вы действительно хотите отправить заказ на возврат? Возобновить работу по заказу
							будет невозможно."
				/>
			)}
			{activeModal === 'review' && (
				<ReviewModal
					show={!!activeModal}
					onHide={handleCloseModal}
					sendReview={(payload) =>
						sendReview({ order_id: activeOrderId, ...payload })
					}
				/>
			)}
			{activeModal === 'cancel' && (
				<ConsentModal
					order_id={activeOrderId}
					show={!!activeModal}
					onHide={() => setActiveModal('')}
					onAccept={() => cancelOrder({ order_id: activeOrderId })}
					title="Подтвердите отмену"
					description="Вы действительно хотите отменить заказ? Возобновить работу по заказу
							будет невозможно."
				/>
			)}
			<div className="d-flex justify-content-between align-items-center flex-row mb-3 mt-2">
				<OrderFilters
					filters={filters}
					update={update}
					totalCost={totalCost}
					setFilter={setFilter}
				/>
			</div>
			<Table
				isLoading={isLoading}
				data={orders}
				totalItems={totalItems}
				itemsPerPage={itemsPerPage}
				setPage={setPage}
				page={page}
				columns={columns}
				contained={true}
				pagination={true}
				title={
					<>
						<div className="w-100 d-flex justify-content-between align-items-center flex-row">
							<h5 className="mb-0">Заказы</h5>
							<Button
								type="submit"
								color="primary"
								className="fs-12"
								disabled={!orders.length || isLoading}
								onClick={handleDownloadXLS}
							>
								Скачать XLS
							</Button>
						</div>
					</>
				}
			/>
		</>
	);
});
