import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MultiSearchHeader } from './comp/MultiSearchHeader';
import { MultiSearchList } from './comp/MultiSearchList';
import {
	useMultiSearchStore,
	useProductStore,
} from 'providers/RootStoreProvider';

export const MultiSearch = observer(() => {
	const { update } = useMultiSearchStore();
	const { setMultiSearch, setViewTypeList } = useProductStore();

	useEffect(() => {
		const init = async () => {
			await setViewTypeList();
			setMultiSearch();
			await update();
		};
		init();
	}, []);

	return (
		<>
			<MultiSearchHeader />
			<MultiSearchList />
		</>
	);
});
