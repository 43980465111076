import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { CatalogTable } from './CatalogTable';
import { TWizardVehicle } from 'types/catalog';
import useApi from 'hooks/useApi';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const CatalogTables = observer(() => {
	const { wizardFindModel } = useCatalogStore();
	const { code, ssd } = useParams();
	const [data, setData] = useState(null);
	const [defaultAttributes, setDefaultAttributes] = useState([]);
	const { data: wizardList, fetch: getWizardModelList } =
		useApi(wizardFindModel);

	const formatGroups = useCallback((list: TWizardVehicle[]) => {
		return list.reduce((result, item) => {
			result[item.name] = result[item.name]
				? [...result[item.name], item]
				: [item];
			return result;
		}, {});
	}, []);

	useEffect(() => {
		getWizardModelList({ catalog: code, ssd });
	}, []);

	useEffect(() => {
		if (wizardList && !data) {
			let defaultAttributes = [];
			wizardList.forEach((i) => {
				if (i.attributes.length > defaultAttributes.length) {
					defaultAttributes = i.attributes;
				}
			});
			const data = formatGroups(wizardList);
			setDefaultAttributes(defaultAttributes);
			setData(data);
		}
	}, [wizardList]);

	return (
		<>
			{data &&
				Object.keys(data).map((key, index) => {
					return (
						<div key={`${key}_${index}`} className="mb-3">
							<CatalogTable
								name={key}
								data={data[key]}
								defaultAttributes={defaultAttributes}
							/>
						</div>
					);
				})}
		</>
	);
});
