import React from 'react';
import { observer } from 'mobx-react';
import { TCashbackRequisite } from 'types/cashback';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { Button } from 'react-bootstrap';

type TProps = {
	data: TCashbackRequisite;
	handleEdit: () => void;
};

export const ListItem = observer(({ data, handleEdit }: TProps) => {
	const { bankAccount, bik, fullName, inn, name } = data;

	return (
		<div className="d-flex justify-content-between align-items-center flex-row">
			<div className="car-info w-100 d-flex justify-content-flex-start flex-column">
				<div className="fs-16 mb-1 fw-semi-bold">{name}</div>
				<div className="fs-13">{fullName}, ИНН {inn}</div>
				<div className="fs-13">БИК {bik}, р/с {bankAccount}</div>
			</div>
			<div className="car-control w-100 d-flex justify-content-end align-items-center flex-row">
				<Button
					size="sm"
					className="border me-2 d-flex justify-content-center"
					variant={'light'}
					onClick={handleEdit}
				>
					<MaterialIcon name="edit" className="" />
				</Button>
			</div>
		</div>
	);
});
