import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CarItem } from 'components/cars/comp/CarItem';
import { useNavigate } from 'react-router-dom';
import { NoteTable } from './comp/NoteTable';
import {
	useAppStore,
	useCarStore,
	useNoteStore,
} from 'providers/RootStoreProvider';

export const NoteCarTable = observer(() => {
	const { downloadPdf } = useNoteStore();
	const { removeCar, activeCar: car, setActiveCar } = useCarStore();
	const { setActiveModal } = useAppStore();
	const navigate = useNavigate();
	const { id: carId } = useParams();

	useEffect(() => {
		setActiveCar(parseInt(carId));
	}, []);

	const handleDownload = useCallback(async () => {
		const blob = await downloadPdf(car.id);
		download(blob, `Проценка #${car.id}.pdf`);
	}, [car]);

	const download = useCallback((blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}, []);

	const handleEdit = useCallback(async () => {
		await setActiveModal('car_form', 'middle-size', {
			initialData: car,
		});
	}, [car]);

	const handleDelete = useCallback(async () => {
		await removeCar(car.id);
		navigate('/saved-cars');
	}, [car]);

	return (
		<Card>
			<Card.Header>
				<CarItem
					handleClick={() => navigate(`/car-notes/${car.id}`)}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					handleDownload={handleDownload}
					car={car}
				/>
			</Card.Header>
			<hr className="m-0" />
			<Card.Body>
				<NoteTable />
			</Card.Body>
		</Card>
	);
});
