import { TDashboardCompaniesCount } from 'types/dashboard';
import { Card, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

type TProps = {
	companiesCountByManager?: TDashboardCompaniesCount[];
};

export const CompaniesTable = ({ companiesCountByManager = [] }: TProps) => {
	const navigate = useNavigate();

	return (
		<Card className={classNames('overflow-hidden', 'w-100', 'me-3')}>
			<Table>
				<thead className="bg-200">
					<tr>
						<th className="py-2 fs--1">Имя</th>
						<th className="py-2 fs--1">Количество компаний</th>
					</tr>
				</thead>
				<tbody>
					{companiesCountByManager.map((item) => (
						<tr key={item.id}>
							<td
								className="py-2 fs--1 cursor-pointer"
								onClick={() => navigate(`/dashboards/manager_id/${item.id}`)}
							>
								{item.name}
							</td>
							<td className="py-2 fs--1">{item.count}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Card>
	);
};
