import { observer } from 'mobx-react';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { offersWord } from 'utils/product';

type TProps = {
	id?: string;
	title: string;
	total?: number;
	hide: boolean;
	onClick: () => void;
	children?: React.ReactNode;
	showTotal?: boolean;
};

export const TableHeader = observer(
	({ title, total = 0, hide, children, onClick, showTotal = true }: TProps) => {
		return (
			<div className="p-3  d-flex flex-row w-100 align-items-center justify-content-between">
				<div className="d-flex flex-row w-100 table-header-collase-title-container">
					<div
						onClick={onClick}
						className="d-flex cursor-pointer justify-content-start"
					>
						<div className="arrow-collapse-container">
							<MaterialIcon
								name={'arrow_back_ios'}
								className={`arrow-collapse me-3 ${hide ? 'up' : 'down'}`}
							/>
						</div>
						<b className="white-space-nowrap pe-5">{title}</b>
					</div>
					{children}
				</div>
				{showTotal && (
					<div className="white-space-nowrap fs-12">
						Найдено {total} {offersWord(total)}
					</div>
				)}
			</div>
		);
	}
);
