import { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import usePagination from '@mui/material/usePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import Flex from 'components/common/Flex';

export const Pagination = observer(
	({
		setActivePage,
		activePage,
		totalPages,
	}: {
		setActivePage: (page: number) => void;
		activePage: number;
		totalPages: number;
	}) => {
		const { items } = usePagination({
			count: totalPages,
			page: activePage,
		});

		const canNextPage = activePage !== totalPages - 1;
		const canPreviousPage = activePage > 1;

		const nextPage = useCallback(() => {
			setActivePage(activePage + 1);
		}, []);

		const previousPage = useCallback(() => {
			setActivePage(activePage - 1);
		}, []);

		return (
			<Flex alignItems="center" justifyContent="center" className="">
				<ul className="pagination mb-0 mx-1">
					{items.map(({ page, type, ...item }, index) => {
						let children = null;
						if (type === 'start-ellipsis' || type === 'end-ellipsis') {
							children = <span className="mx-1 me-2">...</span>;
						} else if (type === 'page') {
							children = (
								<li
									key={`pagination_button_${page}`}
									className={classNames({ active: activePage === page })}
									{...item}
								>
									<Button
										size="sm"
										variant="falcon-default"
										className={classNames('page me-1')}
										onClick={() => setActivePage(page)}
									>
										{page}
									</Button>
								</li>
							);
						} else {
							if (type === 'next') {
								children = (
									<Button
										size="sm"
										variant="falcon-default"
										onClick={() => nextPage()}
										className={classNames({ disabled: !canNextPage }, '')}
									>
										<FontAwesomeIcon icon="chevron-right" />
									</Button>
								);
							}
							if (type === 'previous') {
								children = (
									<Button
										size="sm"
										variant="falcon-default"
										onClick={() => previousPage()}
										className={classNames(
											{ disabled: !canPreviousPage },
											'me-1'
										)}
									>
										<FontAwesomeIcon icon="chevron-left" />
									</Button>
								);
							}
						}
						return <li key={index}>{children}</li>;
					})}
				</ul>
			</Flex>
		);
	}
);
