import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TDefaultPagination } from 'types/common';
import { TListReponse } from 'types/common';
import { TPaymentsShopList, TPaymentsShopItem } from 'types/paymentsShop';

export const fetchPaymentsShop = async (
	query_params: TDefaultPagination
): Promise<TListReponse<TPaymentsShopList>> => {
	return request(
		fetch(b2bApiUrl(`keeper/comissioner`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchPaymentsShopItem = async (
	id: number
): Promise<TPaymentsShopItem> => {
	return request(
		fetch(b2bApiUrl(`keeper/comissioner/${id}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const patchPaymentsShopItem = async (
	id: number
): Promise<TPaymentsShopItem> => {
	return request(
		fetch(b2bApiUrl(`keeper/comissioner/confirm/${id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
