import { observer } from 'mobx-react-lite';
import { Filters } from './filter-comp/Filters';
import { ProductSorting } from 'components/product/product-list/comp/product-list-table-header/product-list-table-sort/comp/ProductSorting';

import 'styles/Filter.scss';

export const ProductFilter = observer(() => {
	return (
		<div className="d-flex w-100 justify-content-between align-items-center flew-wrap flex-row">
			<Filters />
			<ProductSorting />
		</div>
	);
});
