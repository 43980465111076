import {
	castError,
	defaultHeaders,
	commonApiUrl,
	customerApiUrl,
} from 'api/helpers';
import {
	TListCatalog,
	TWizard,
	TWizardVehicle,
	TVehicleInfo,
	TListCatalogs,
	TListCategory,
	TListQuickGroup,
	TListUnits,
	TListQuickDetail,
	TListImageMapByUnit,
	TListDetailByUnit,
	TCatalogSearchHistory,
	TSearchByVin,
	TSearchByStateNumber,
} from 'types/catalog';
import request from 'helpers/request';

export const fetchListDetailByUnit = async (
	params
): Promise<TListDetailByUnit[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_detail_by_unit`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchListImageMapByUnit = async (
	params
): Promise<TListImageMapByUnit[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_image_map_by_unit`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchUnitInfo = async (params): Promise<TListUnits[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/unit_info`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchListCatalog = async (): Promise<TListCatalogs[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_catalogs`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchCatalogInfo = async (params): Promise<TListCatalog[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/catalog_info`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchVehicleInfo = async (params): Promise<TVehicleInfo[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/vehicle_info`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchListQuickGroup = async (
	params
): Promise<TListQuickGroup[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_quick_group`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchListQuickDetail = async (
	params
): Promise<TListQuickDetail[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_quick_detail`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchListCategories = async (params): Promise<TListCategory[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_categories`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchListUnits = async (params): Promise<TListUnits[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/list_units`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchWizardFindCar = async (params): Promise<TWizardVehicle[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/find_vehicle_by_wizard2`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchWizard = async (params): Promise<TWizard[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/wizard2`, params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchCatalogSearchHistory = async (): Promise<{
	data: TCatalogSearchHistory[];
}> => {
	return request(
		fetch(customerApiUrl(`cars_search_logs`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchSeacrhByVin = async (query_params: {
	ident_string?: string;
}): Promise<TSearchByVin[]> => {
	return request(
		fetch(commonApiUrl(`original_catalog/find_vehicle`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchSeacrhByStateNumber = async (query_params: {
	number?: string;
}): Promise<TSearchByStateNumber> => {
	return request(
		fetch(commonApiUrl(`original_catalog/vin_by_number`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
