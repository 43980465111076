import { castError, defaultHeaders, customerApiUrl } from 'api/helpers';
import { TReview, TReviewPostBody, TReviewPatchBody } from 'types/review';
import { TListReponse } from 'types/common';
import request from 'helpers/request';

export const postOrderReview = async (
	payload: TReviewPostBody
): Promise<TReview> => {
	return request(
		fetch(customerApiUrl(`reviews`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const patchOrderReview = async (
	review_id: number,
	payload: TReviewPatchBody
): Promise<TReview> => {
	return request(
		fetch(customerApiUrl(`reviews/${review_id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const deleteOrderReview = async (
	review_id: number
): Promise<TReview> => {
	return request(
		fetch(customerApiUrl(`reviews/${review_id}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const getMyReviews = async (query): Promise<TListReponse<TReview>> => {
	return request(
		fetch(customerApiUrl(`orders`, query), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
