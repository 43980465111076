import { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { FilterQuantity } from 'components/common/filter/FilterQuantity';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TooltipElement } from 'components/common/TooltipElement';

export const ProductItemButtonBuy = observer(
	({
		isLoading,
		addToCart,
		isAuthorized,
		showQuantity = false,
		multiplicity = 1,
		textButton = '',
		stock = 1,
		showNote = false,
		isInCart = undefined,
	}) => {
		const [pending, setPending] = useState(false);
		const [quantity, setQuantity] = useState(1);
		const [note, setNote] = useState('');
		const [save, setSave] = useState(false);
		const buyButtonDisabled = !isAuthorized || pending || quantity <= 0;
		const inCart = isInCart();

		const validate = async () => {
			if (multiplicity) {
				if (quantity > 0) {
					let adjusted =
						Math.max(Math.ceil(quantity / multiplicity), 1) * multiplicity;
					if (stock && adjusted > stock) {
						adjusted =
							Math.max(Math.floor(stock / multiplicity), 1) * multiplicity;
						setQuantity(adjusted);
					} else {
						setQuantity(adjusted);
					}
				} else {
					setQuantity(
						Math.max(Math.ceil(quantity / multiplicity), 1) * multiplicity
					);
				}
			}
		};

		const handleQuantity = useCallback(
			(value: number) => {
				if (value > stock) return;
				setQuantity(value);
			},
			[stock]
		);

		const handleAddToCart = useCallback(async () => {
			setPending(true);
			// setShowTooltip(false);
			await addToCart(quantity, note);
			setPending(false);
		}, [quantity, note]);

		const handleKeyDown = useCallback((event) => {
			if (event.key === 'Enter') {
				// setShowTooltip(false);
			}
		}, []);

		return (
			<div className="ps-3 position-relative">
				{!isLoading && (
					<div className="d-flex justify-content-between align-items-end flex-column">
						<div className="d-flex justify-content-start align-items-start flex-row">
							<TooltipElement
								disabled={isAuthorized}
								focus={true}
								value={
									!isAuthorized && (
										<>
											Чтобы добавить в корзину нужно{' '}
											<Link to={`/authentication/login`}>авторизоваться</Link>
										</>
									)
								}
							>
								<div className="d-flex justify-content-start align-items-center flex-row">
									{showQuantity && (
										<TooltipElement
											disabled={!multiplicity}
											className="w-100"
											value={
												<>Минимальное кол-во для заказа - {multiplicity} шт.</>
											}
										>
											<FilterQuantity
												isLabeled={false}
												value={quantity}
												update={handleQuantity}
												disabled={isLoading}
												defaultValue={multiplicity ? multiplicity : 1}
												max={stock}
												step={multiplicity}
												onBlur={() => validate()}
												style={{
													width: '50px',
													padding: '3px 0px 3px 10px',
													boxSizing: 'border-box',
													textAlign: 'center',
												}}
											/>
										</TooltipElement>
									)}
									<Button
										disabled={buyButtonDisabled}
										variant="primary"
										className={`${
											buyButtonDisabled ? 'pale' : ''
										} page w-100  px-2 ms-2 d-flex justify-content-center align-items-center`}
										size="sm"
										style={{
											padding: '7.5px',
										}}
										onClick={handleAddToCart}
									>
										<FontAwesomeIcon
											className={`${pending && 'spin'} ms-1 me-1`}
											icon={pending ? 'spinner' : 'cart-plus'}
										/>
										{inCart > 0 && (
											<span
												className="fs-12"
												style={{
													lineHeight: '1',
													position: 'relative',
													top: '0.5px',
												}}
											>
												({inCart})
											</span>
										)}
										{textButton && (
											<div
												className="fs-12 ms-1"
												style={{
													lineHeight: '1',
													position: 'relative',
													top: '0.5px',
												}}
											>
												{textButton}
											</div>
										)}
									</Button>
								</div>
							</TooltipElement>
						</div>
						{showNote && (
							<div className="position-relative mt-1 d-flex justify-content-start align-items-start flex-row">
								<textarea
									style={{
										border: 'none',
										borderBottom: save ? '1px solid #111' : 'none',
										textAlign: !save ? 'right' : 'left',
										position: 'relative',
										top: '2px',
										resize: 'none',
										lineHeight: '1.3',
										paddingRight: save ? '30px' : '0px',
									}}
									rows={2}
									className="w-100 fs-12"
									value={note}
									placeholder={!save ? 'Примечание к заказу' : ''}
									onChange={(e) => {
										setNote(e.target.value);
									}}
									onClick={() => setSave(true)}
									onBlur={() => setSave(false)}
									onKeyDown={handleKeyDown}
								/>

								{save && (
									<MaterialIcon
										style={{
											position: 'absolute',
											right: '5px',
											top: '10px',
											zIndex: 1000,
										}}
										className={`cursor-pointer text-primary fs-1`}
										name={`done`}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
);
