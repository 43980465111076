import { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import { TooltipElement } from 'components/common/TooltipElement';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const SearchByVin = observer(() => {
	const { getSearchByVin, getSearchByStateNumber, SearchByVin } =
		useCatalogStore();
	const [searched, setSearched] = useState(false);
	const [searchByVin, setSearchByVin] = useState('');
	const [searchStateNumber, setSearchStateNumber] = useState('');

	const handleSearch = useCallback(async () => {
		if (!searchStateNumber && searchByVin) {
			await getSearchByVin({ ident_string: searchByVin });
		} else {
			const vin = await getSearchByStateNumber({
				number: searchStateNumber,
			});
			await getSearchByVin({ ident_string: vin });
		}
		setSearched(true);
	}, [searchStateNumber, searchByVin]);

	const openInNewTab = useCallback((url: string) => {
		window.open(url, '_blank', 'noreferrer');
	}, []);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Поиск по VIN
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex align-items-center gap-2">
					<TooltipElement
						value={
							<>
								Используйте только русские символы. <br /> Например: А008АА197
							</>
						}
					>
						<Form.Control
							placeholder="Введите госномер"
							value={searchStateNumber}
							onChange={(e) => {
								setSearched(false);
								setSearchStateNumber(e.target.value.toUpperCase());
								setSearchByVin('');
							}}
						/>
					</TooltipElement>
					<div>Или</div>
					<Form.Control
						className="w-50"
						placeholder="Введите VIN"
						value={searchByVin}
						onChange={(e) => {
							setSearched(false);
							setSearchByVin(e.target.value);
							setSearchStateNumber('');
						}}
					/>
					<Button variant="primary" onClick={handleSearch}>
						Поиск
					</Button>
				</div>
				<div className="my-3">
					Мы определим VIN по госномеру. VIN нам нужен для точного и
					безошибочного определения модификации вашего автомобиля в оригинальном
					каталоге запчастей.
				</div>
				<hr />
				{!!SearchByVin.length &&
					SearchByVin.map((item) => {
						return (
							<>
								<Card
									className="p-3 mb-3"
									key={`searchVin_${item.name}_${item.vehicleid}`}
								>
									<div>
										{item.brand} {item.name}
									</div>
								</Card>
								<Button
									onClick={() =>
										openInNewTab(`/catalog-model/quicklist/${item.brand}/${item.catalog}/${item.ssd}/${item.vehicleid}/0
                                            `)
									}
								>
									Перейти в каталог
								</Button>
							</>
						);
					})}
				{!SearchByVin.length && searched && (
					<div className="fw-bold">
						Автомобиль по VIN номеру не найден. Проверьте правильность
						введённого номера или воспользуйтесь{' '}
						<a href="/catalog">поиском по модели</a>
					</div>
				)}
			</Modal.Body>
		</>
	);
});
