import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Avatar from '@mui/material/Avatar';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useAuthStore } from 'providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';

export const Support = observer(() => {
	const { getSupport, support } = useAuthStore();

	useEffect(() => {
		getSupport();
	}, []);

	const Content = () => {
		const { id, name, phone, additions, email, whatsup, telegram, photo } =
			support;

		if (!id) return <>Сотрудник поддержки не назначен</>;

		return (
			<>
				<div className="d-flex flex-row justify-content-start align-items-start">
					<div className="me-4">
						{photo ? (
							<Avatar sx={{ width: 56, height: 56 }} alt={name} src={photo} />
						) : (
							<Avatar sx={{ width: 56, height: 56 }}>
								<MaterialIcon className="" name="contact_support" />
							</Avatar>
						)}
					</div>
					<div>
						<h4>{name}</h4>
						<div className="d-flex flex-wrap flex-row">
							<div className="support-contact-item d-flex flex-row w-50">
								<div className="me-3">Телефон</div>
								<div>
									<strong>{phone}</strong>
								</div>
							</div>
							<div className="support-contact-item d-flex flex-row w-50">
								<div className="me-3">Email</div>
								<div>
									<strong>{email}</strong>
								</div>
							</div>
							<div className="support-contact-item d-flex flex-row w-50">
								<div className="me-3">Whatsup</div>
								<div>
									<strong>{whatsup}</strong>
								</div>
							</div>
							<div className="support-contact-item d-flex flex-row w-50">
								<div className="me-3">Telegram</div>
								<div>
									<strong>{telegram}</strong>
								</div>
							</div>
							<div className="support-contact-item mt-3 d-flex flex-row w-50">
								<div>{additions}</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<Card className="p-4">{support && <Content />}</Card>
		</>
	);
});
