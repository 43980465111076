import { Navbar } from 'react-bootstrap';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { ToggleButton } from '../vertical/ToggleButton';
import { observer } from 'mobx-react-lite';
import { SearchBoxContainer } from './Searchbox/SearchBoxContainer';
import Logo from 'components/common/Logo';
import { useAuthStore } from 'providers/RootStoreProvider';

export const NavbarTop = observer(() => {
	const { isAuthorized } = useAuthStore();

	return (
		<Navbar className="navbar-glass fs--1 navbar-top" expand={true}>
			{isAuthorized && <ToggleButton />}
			<Logo at="navbar-top" />
			<SearchBoxContainer />
			<TopNavRightSideNavItem />
		</Navbar>
	);
});
