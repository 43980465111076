import { makeAutoObservable } from 'mobx';
import { TCompany } from 'types/company';
import { TLocation } from 'types/common.d';
import { toast } from 'react-toastify';
import {
	featchCompany,
	addCompany,
	deleteCompany,
	updateCompany,
} from 'api/company/company';
import { RootStore } from 'stores/RootStore';

class Company implements TCompany {
	id = 0;
	name = '';
	address = '';
	location = {
		lat: null,
		lng: null,
	};

	constructor(
		name?: string,
		address?: string,
		location?: TLocation,
		id?: number
	) {
		this.id = id ? id : this.id;
		this.name = name ? name : this.name;
		this.address = address ? address : this.address;
		this.location = location ? location : this.location;
	}
}

export class CompanyStore {
	company: TCompany = null;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	init = async () => {
		const res = await featchCompany();
		if (res === null) return;

		const { name, address, location, id } = res;
		this.company = new Company(name, address, location, id);
	};

	addCompany = async (payload: TCompany) => {
		const res = await addCompany(payload);
		if (res === null) return;
		const { name, address, location, id } = res;

		this.company = new Company(name, address, location, id);
		toast.success('Компания успешно сохранена', {
			theme: 'colored',
		});
	};

	updateCompany = async (payload: TCompany) => {
		const res = await updateCompany(payload);
		if (res === null) return;
		const { name, address, location, id } = res;

		this.company = new Company(name, address, location, id);
		toast.success('Компания успешно обновлена', {
			theme: 'colored',
		});
	};

	deleteCompany = async () => {
		const res = await deleteCompany();
		if (res === null) return;

		toast.success('Компания успешно удалена', {
			theme: 'colored',
		});
		this.company = new Company();
	};
}
