import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate, Outlet } from 'react-router-dom';

type TProps = {
	// @ts-ignore
	children?: JSX.Element;
	isLoaded: boolean;
	isAllowed: boolean;
	redirectedPath?: string | '/authentication/login';
};

export const PrivateRoute = ({
	isLoaded,
	isAllowed,
	redirectedPath,
	children,
}: TProps) => {
	if (!isLoaded) {
		return (
			<>
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
				<Outlet />
			</>
		);
	}

	if (!isAllowed) {
		return <Navigate to={redirectedPath} replace />;
	}

	return children ? children : <Outlet />;
};
