import React, { useState, useRef, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { observer } from 'mobx-react';
import { getAddressSuggestion } from 'api/user/getAddressSuggestion';
import { TLocation } from 'types/common';

type TProps = {
	setValue: (value: { name: string; location: TLocation }) => void;
	initValue: string;
	onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
	placeholder?: string;
};

export const AddressSuggections = observer(
	({ initValue = '', setValue, ...rest }: TProps) => {
		const [addressInputValue, setAddressInputValue] = useState(initValue);
		const [defaultOptions, setDefaultOptions] = useState([]);
		const deliveryAddressInputRef = useRef(null);

		useEffect(() => {
			setAddressInputValue(initValue);
			deliveryAddressInputRef.current.setValue(initValue);
			deliveryAddressInputRef.current.blur();
			deliveryAddressInputRef.current.focus();
			deliveryAddressInputRef.current.blur();
		}, [initValue]);

		type TOption = {
			value?: string;
			unrestricted_value?: string;
		};

		const getAddressSuggestions = async (query: string) => {
			const { suggestions } = await getAddressSuggestion({
				query,
				count: 5,
			});
			return suggestions.map((i) => {
				i.label = i.value;
				return i;
			});
		};

		const deliveryAddressSearch = async (
			search_query: string,
			callback: (options: TOption[]) => void
		): Promise<TOption[]> => {
			const suggestions = await getAddressSuggestions(search_query);
			callback(suggestions);
			return suggestions;
		};

		type TSuggection = {
			value: string;
			data: { geo_lon: number; geo_lat: number };
		};

		return (
			<AsyncSelect
				noOptionsMessage={() => <>...</>}
				loadingMessage={() => <>Загрузка...</>}
				defaultOptions={defaultOptions}
				placeholder={'Адрес доставки'}
				classNamePrefix="react-select"
				loadOptions={deliveryAddressSearch}
				inputValue={addressInputValue}
				onInputChange={(value, action) => {
					if (action.action === 'input-change') {
						setAddressInputValue(value);
					}
				}}
				onChange={(data) => {
					if (!data || !data.value) return null;
					const {
						value,
						data: { geo_lon, geo_lat },
					}: TSuggection = data;
					setValue({
						name: value,
						location: { lng: geo_lon, lat: geo_lat },
					});
					setAddressInputValue(value);
					deliveryAddressInputRef.current.blur();
					deliveryAddressInputRef.current.focus();
				}}
				ref={deliveryAddressInputRef}
				onMenuOpen={() =>
					deliveryAddressSearch(addressInputValue, setDefaultOptions)
				}
				{...rest}
			/>
		);
	}
);
