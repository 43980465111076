import { castError, defaultHeaders, customerApiUrl } from 'api/helpers';
import request from 'helpers/request';

export const changePassword = (params) => {
	return request(
		fetch(customerApiUrl('profile/change_password'), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(params),
		}).then(castError)
	);
};
