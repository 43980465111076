import { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductTable } from '../ProductTable';
import { TableHeader } from 'components/common/TableHeader';
import { Button } from 'react-bootstrap';
import {
	useGettzapProductStore,
	useProductStore,
} from 'providers/RootStoreProvider';

export const GettzapTable = observer(() => {
	const {
		products,
		setPage,
		total,
		isLoading,
		page,
		filteredProducts,
		isFiltered,
		setApiSearch,
		isApiSearch,
	} = useGettzapProductStore();
	const { itemsPerPage } = useProductStore();
	const [hide, setHide] = useState(false);

	const isPaginated = useCallback(
		() => Math.ceil(total / itemsPerPage) > 1,
		[total, itemsPerPage]
	);

	return (
		<div className="mb-3">
			<ProductTable
				isLoading={isLoading}
				header={
					<TableHeader
						id={'partnersGettzap'}
						title={'Предложения GETTZAP'}
						total={isFiltered() ? filteredProducts.length : products.length}
						hide={hide}
						onClick={() => setHide(!hide)}
					>
						<Button
							className="ms-3 p-1 px-2 fs-14"
							size="sm"
							variant="link"
							onClick={() => setApiSearch(!isApiSearch)}
						>
							{!isApiSearch ? 'Заказные позиции' : 'Поиск по наличию'}
						</Button>
					</TableHeader>
				}
				pagination={!isLoading ? isPaginated() : false}
				showIconEmpty={true}
				data={isFiltered() ? filteredProducts : products}
				hide={hide}
				totalItems={total}
				itemsPerPage={9999}
				loadingTableHeight="0px"
				setPage={(page) => setPage(page)}
				page={page}
				footer={null}
				type="partnersGettzap"
			/>
		</div>
	);
});
