import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'react-bootstrap';
import { TTasksPayload, TTasks } from 'types/tasks';
import { Field, Formik } from 'formik';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';

registerLocale('ru', ru);

type TProps = {
	onSubmit: (payload: TTasksPayload) => Promise<void>;
	initialData?: TTasks | null;
	companyId: number;
	mode: string;
};

export const TasksFormModal = observer(
	({ onSubmit, companyId, initialData, mode }: TProps) => {
		return (
			<Formik
				initialValues={
					initialData
						? initialData
						: {
								name: '',
								descriptions: '',
								comment: '',
								companyId: companyId,
								dateVisit: '',
						  }
				}
				enableReinitialize
				onSubmit={async (values, actions) => {
					await onSubmit({
						...values,
						dateVisit: dayjs(values.dateVisit).format('YYYY-MM-DD'),
					});
					actions.resetForm();
				}}
			>
				{({ values, handleChange, handleSubmit, isValid, setFieldValue }) => (
					<Form
						onSubmit={async (e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<div className="ps-2">
							<div className="d-flex gap-5 mb-3">
								<Form.Group>
									<Form.Label>Название</Form.Label>
									<Form.Control
										placeholder="Название задачи"
										value={values?.name}
										name="name"
										onChange={handleChange}
										disabled={mode === 'success' ? true : false}
										required
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>Дата визита</Form.Label>
									<Field name="dateVisit">
										{({ field }) => (
											<ReactDatePicker
												{...field}
												selected={values.dateVisit}
												onChange={(date) => setFieldValue('dateVisit', date)}
												locale="ru"
												disabled={mode === 'success' ? true : false}
												className="form-control"
												dateFormat="dd-MM-yyyy"
												autoComplete="off"
											/>
										)}
									</Field>
								</Form.Group>
							</div>
							<Form.Group>
								<Form.Label>Описание</Form.Label>
								<Form.Control
									as="textarea"
									rows={4}
									placeholder="Описание"
									value={values?.descriptions}
									name="descriptions"
									onChange={handleChange}
									disabled={mode === 'success' ? true : false}
								/>
							</Form.Group>

							{mode === 'success' && (
								<div className="mt-3">
									<Form.Group>
										<Form.Label>
											Укажите комментарий перед завершением
										</Form.Label>
										<Form.Control
											as="textarea"
											rows={3}
											placeholder="Комментарий"
											value={values.comment}
											name="comment"
											onChange={handleChange}
											disabled={mode === 'success' ? false : true}
											required
										/>
									</Form.Group>
								</div>
							)}
						</div>
						<div className="border-dashed-bottom my-3" />
						{mode === 'create' && (
							<Button
								disabled={!isValid}
								variant="primary"
								type="submit"
								className="me-4"
							>
								Создать задачу
							</Button>
						)}
						{mode === 'success' && (
							<Button
								disabled={!isValid}
								variant="success"
								type="submit"
								className="me-4"
							>
								Завершить
							</Button>
						)}
						{mode === 'edit' && (
							<Button
								disabled={!isValid}
								variant="warning"
								type="submit"
								className="me-4"
							>
								Отредактировать
							</Button>
						)}
					</Form>
				)}
			</Formik>
		);
	}
);
