import { makeAutoObservable } from 'mobx';
import {
	TAdminCompanies,
	TAdminCompanyPayload,
	TAdminManagers,
} from 'types/adminCompanies';
import {
	fetchAdminCompanies,
	fetchAdminCompany,
	patchAdminCompany,
	fetcAdminManagers,
} from './../../api/adminCompanies/adminCompanies';
import { RootStore } from 'stores/RootStore';

export class AdminCompaniesStore {
	isLoading = true;
	list: TAdminCompanies[] = [];
	managers: TAdminManagers[] = [];
	activeId: number = null;
	total = 0;
	itemsPerPage = 10;
	page = 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	setActiveId = (id: number | null) => {
		this.activeId = id;
	};

	getCompanyById = async (id: number) => {
		const company = this.list.find((i) => i.id === id);
		if (company) {
			return company;
		} else {
			const res = await fetchAdminCompany(id);
			if (res === null) return;

			this.list.push(res);
			return res;
		}
	};

	updateCompany = async (
		id: number,
		payload: TAdminCompanyPayload
	): Promise<TAdminCompanies> => {
		const res = await patchAdminCompany(id, payload);
		if (res === null) return;

		const index = this.list.findIndex((i) => i.id === id);
		this.list[index] = res;

		return res;
	};

	getCompanies = async () => {
		const res = await fetchAdminCompanies({
			per_page: this.itemsPerPage,
			page: this.page,
		});

		if (res === null) return;

		const {
			meta: { count },
		} = res;

		this.list = res.items;
		this.total = count;
	};

	getAdminManagers = async () => {
		const res = await fetcAdminManagers();

		if (res === null) return;

		this.managers = res.items;
	};

	init = async () => {
		this.isLoading = true;

		await this.getCompanies();

		this.isLoading = false;
	};
}
