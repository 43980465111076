import { useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { AddressSuggections } from 'components/common/AddressSuggections';

type TProps = {
	close: (address: string) => void;
};

export const AddressModal = observer(({ close }: TProps) => {
	const [address, setAddress] = useState('');

	const handleSave = useCallback(() => {
		close(address);
	}, [address]);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Укажите адрес доставки
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Вы всегда сможете изменить адрес доставки в настройке профиля или при
					оформлении заказа.
				</p>
				<Form.Group>
					<Form.Label>Адрес доставки</Form.Label>
					<AddressSuggections
						initValue={address}
						setValue={({ name }) => setAddress(name)}
					/>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={!address} variant={'primary'} onClick={handleSave}>
					Сохранить
				</Button>
			</Modal.Footer>
		</>
	);
});
