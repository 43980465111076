import { makeAutoObservable } from 'mobx';

class Support {
	id = 0;
	name = '';
	phone = '';
	additions = '';
	email = '';
	whatsup = '';
	telegram = '';
	photo = '';
	deleted = false;
	createdAt = '';
	updatedAt = '';

	constructor(data: Record<string, unknown>) {
		this.setData(data);
		makeAutoObservable(this);
	}

	setData = (data: Record<string, unknown>) => {
		Object.keys(data).forEach((key) => {
			if (key in this) {
				switch (key) {
					default:
						this[key] = data[key];
				}
			}
		});
	};
}

export { Support };
