import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useLocation, Link } from 'react-router-dom';
import { ProductMap } from './comp/ProductMap';
import { TProduct } from 'types/product';
import { ActiveProductsList } from './comp/ActiveProductsList';
import { ProductMapFilter } from './comp/ProductMapFilter';
import {
	useProductStore,
	useSearchStore,
	useSellerProductStore,
} from 'providers/RootStoreProvider';

import 'styles/Map.scss';

export const ProductMapPage = observer(() => {
	const { isLoading, products: Products } = useSellerProductStore();
	const { reset, init, setViewTypeMap } = useProductStore();
	const { searchRegion } = useSearchStore();
	const { pathname } = useLocation();
	const { uid } = useParams();
	const [activeProducts, setActiveProducts] = useState<TProduct[]>([]);
	const [activeIds, setActiveIds] = useState<number[]>([]);
	const [showActiveProducts, setShowActiveProducts] = useState(false);
	const [showFilterMap, setShowFilter] = useState(false);
	const products = useMemo(() => Products.filter((i) => i.office), [Products]);

	useEffect(() => {
		setViewTypeMap();
		if (uid) init(uid);
		return () => reset();
	}, [uid]);

	useEffect(() => {
		const activeProducts = activeIds
			.map((id) => products.find((i) => i.office.id === id))
			.filter((i) => i);
		setActiveProducts(activeProducts);
	}, [products]);

	if (!uid) return null;

	const getProductUrl = useCallback(() => {
		const splitted = pathname.split('/');
		splitted.splice(splitted.length - 1, 1);
		return splitted.join('/');
	}, [pathname]);

	const handleSelect = useCallback((ids: number[]) => {
		setActiveIds(ids);
		const activeProducts = ids.map((id) =>
			products.find((i) => i.office.id === id)
		);
		setActiveProducts(activeProducts);
		setShowActiveProducts(true);
	}, []);

	return (
		<>
			<div className="product-search-map-container">
				<ProductMapFilter isLoading={isLoading} showFilterMap={showFilterMap} />
				<div className="header-map-container d-flex justify-content-end flex-row align-items-center">
					<div className="d-flex header-map-container-buttons justify-content-between align-items-start flex-row">
						<Link to={getProductUrl()} className="text-decoration-none w-100">
							<Button
								size="sm"
								variant={'primary'}
								className="d-flex justify-content-center w-100 align-items-center"
							>
								<MaterialIcon
									className={`me-1 cursor-pointer fs-1`}
									name="list"
								/>
								Список
							</Button>
						</Link>
						<Button
							size="sm"
							variant={'primary'}
							className="filter-button-mobile ms-3 w-100 justify-content-center align-items-center"
							onClick={() => setShowFilter(!showFilterMap)}
						>
							<MaterialIcon
								className={`me-1 cursor-pointer fs-1`}
								name="list"
							/>
							Фильтры
						</Button>
					</div>
				</div>
				<ActiveProductsList
					activeProducts={activeProducts}
					showActiveProducts={showActiveProducts}
					onClose={() => setShowActiveProducts(false)}
				/>
			</div>
			<div className="search-results-map-yandex-map">
				<ProductMap
					regionLocation={searchRegion.location}
					onSelect={handleSelect}
					activeProductsIds={products.map((i) => i.office.id)}
					products={products}
				/>
			</div>
		</>
	);
});
