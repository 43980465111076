import { castError, defaultHeaders, commonApiUrl } from 'api/helpers';
import request from 'helpers/request';

export const getAddressSuggestion = (params) => {
	return request(
		fetch(commonApiUrl('dadata/suggestions/api/4_1/rs/suggest/address'), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(params),
		}).then(castError)
	);
};
