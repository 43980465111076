import { makeAutoObservable } from 'mobx';
import { getAddressSuggestion } from 'api/user/getAddressSuggestion';

export class DeliveryAddress {
	id: number;
	address = '';
	apartment = '';
	needUpdate = false;
	_destroy = false;

	constructor(
		needUpdate: boolean,
		address?: string,
		apartment?: string,
		id?: number
	) {
		this.needUpdate = needUpdate;
		this.id = id;
		this.address = address || '';
		this.apartment = apartment || '';

		makeAutoObservable(this);
	}

	setData = (key: string, value: string) => {
		this.needUpdate = true;
		this[key] = value;
	};

	getAddressSuggestions = async (query: string) => {
		const res = await getAddressSuggestion({
			query,
			count: 5,
		});

		if (res === null) return;
		const { suggestions } = res;

		return suggestions.map((i) => {
			i.label = i.value;
			return i;
		});
	};

	remove = () => {
		this.needUpdate = true;
		this._destroy = true;
	};

	payloadProps() {
		if (this._destroy) {
			return {
				id: this.id,
				_destroy: this._destroy,
			};
		}

		return {
			address: this.address,
			apartment: this.apartment,
			id: this.id,
		};
	}
}
