import { makeAutoObservable } from 'mobx';
import { fetchNormGaz } from 'api/gaz/gaz';
import { RootStore } from 'stores/RootStore';

export class GazStore {
	data = [];
	isLoading = true;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	update = async (name?: string) => {
		this.isLoading = true;

		const res = await fetchNormGaz(name);

		if (res === null) {
			this.isLoading = false;
			return;
		}

		this.data = res;

		this.isLoading = false;
	};
}
