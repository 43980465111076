import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { FilterButton } from 'components/common/filter/FilterButton';
import { FilterInput } from '../../product/product-list/comp/table-header/comp/FilterInput';

export const CustomerCost = observer(
	({ update, textButton = '', disabled, value = undefined, ...rest }) => {
		const [input, setInput] = useState(0);

		const handleFilter = useDebouncedCallback(() => update(input), 500);

		useEffect(() => {
			if (disabled) return;
			handleFilter();
		}, [input]);

		useEffect(() => {
			const customer_cost = localStorage.getItem('customer_cost');
			setInput(parseInt(customer_cost) ?? 0);
		}, []);

		return (
			<>
				{textButton && (
					<FilterButton
						disabled={disabled}
						onClick={() => setInput(0)}
						isActive={!input}
					>
						{textButton}
					</FilterButton>
				)}
				<div
					className={`filter-input fs-14 d-flex justify-content-between align-items-center full-border`}
				>
					<FilterInput
						value={value !== undefined ? value : input}
						onChange={setInput}
						disabled={disabled}
						min={0}
						{...rest}
					/>
				</div>
			</>
		);
	}
);
