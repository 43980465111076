import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TDashboard, TDashboardManagerInfo } from 'types/dashboard';

export const fetchDashboard = async (query_params: {
	from?: string;
	to?: string;
}): Promise<TDashboard> => {
	return await request(
		fetch(b2bApiUrl('admin/dashboard', query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchDashboardByManager = async (
	id: number,
	query_params: { from?: string; to?: string }
): Promise<TDashboardManagerInfo> => {
	return await request(
		fetch(b2bApiUrl(`admin/dashboard/manager_id/${id}`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
