import { castError, b2bApiUrl, defaultHeaders } from 'api/helpers';
import { TGaz } from 'types/gaz';
import request from 'helpers/request';

export const fetchNormGaz = async (name): Promise<TGaz[]> => {
	return request(
		fetch(b2bApiUrl(`common/norm_gaz`, { name }), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
