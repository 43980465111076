/* eslint-disable react/prop-types */
import { observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-bootstrap';
import {
	useTable,
	useSortBy,
	usePagination,
	useRowSelect,
	useGlobalFilter,
} from 'react-table';

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();

		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<Form.Check
				type="checkbox"
				className="form-check fs-0 mb-0 d-flex align-items-center"
			>
				<Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
			</Form.Check>
		);
	}
);

const AdvanceTableWrapper = observer(
	({
		children,
		columns,
		data,
		sortable,
		selection,
		selectionColumnWidth,
		pagination,
		defaltSelected = false,
		perPage = 10,
	}) => {
		const {
			getTableProps,
			headers,
			page,
			prepareRow,
			canPreviousPage,
			canNextPage,
			nextPage,
			previousPage,
			setPageSize,
			gotoPage,
			pageCount,
			state: { pageIndex, pageSize, selectedRowIds, globalFilter },
			setGlobalFilter,
		} = useTable(
			{
				columns,
				data,
				disableSortBy: !sortable,
				initialState: {
					pageSize: pagination ? perPage : data.length,
					selectedRowIds: defaltSelected
						? Object.keys(data).reduce((acc, _, i) => {
								acc[i] = true;
								return acc;
						  }, {})
						: {},
				},
			},
			useGlobalFilter,
			useSortBy,
			usePagination,
			useRowSelect,
			(hooks) => {
				if (selection) {
					hooks.visibleColumns.push((columns) => [
						{
							id: 'selection',
							Header: ({ getToggleAllRowsSelectedProps }) => (
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							),
							headerProps: {
								style: {
									paddingLeft: '0.75rem',
									maxWidth: selectionColumnWidth,
								},
							},
							cellProps: {
								style: {
									paddingLeft: '0.75rem',
									maxWidth: selectionColumnWidth,
								},
							},
							Cell: ({ row }) => (
								<div style={{ width: '20px' }}>
									<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
								</div>
							),
						},
						...columns,
					]);
				}
			}
		);

		const recursiveMap = (children) => {
			return React.Children.map(children, (child) => {
				if (child?.props?.children) {
					return React.cloneElement(child, {
						children: recursiveMap(child.props.children),
					});
				} else {
					if (child?.props?.table) {
						return React.cloneElement(child, {
							...child.props,
							getTableProps,
							headers,
							page,
							prepareRow,
							canPreviousPage,
							canNextPage,
							nextPage,
							previousPage,
							gotoPage,
							pageCount,
							pageIndex,
							selectedRowIds,
							pageSize,
							setPageSize,
							globalFilter,
							setGlobalFilter,
						});
					} else {
						return child;
					}
				}
			});
		};

		return (
			// <>
			//   {React.Children.map(children, child => {
			//     if (child.props.table) {
			//       return React.cloneElement(child, {
			//         ...child.props,
			//         getTableProps,
			//         headers,
			//         page,
			//         prepareRow,
			//         canPreviousPage,
			//         canNextPage,
			//         nextPage,
			//         previousPage,
			//         gotoPage,
			//         pageCount,
			//         pageIndex,
			//         selectedRowIds,
			//         pageSize,
			//         setPageSize
			//       });
			//     } else {
			//       return child;
			//     }
			//   })}
			// </>
			<>{recursiveMap(children)}</>
		);
	}
);

export default AdvanceTableWrapper;
