import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TStock } from 'types/product';
import { useProductStore } from 'providers/RootStoreProvider';

type TRowData = {
	row: { original: TStock };
};

export const StockListModal = observer(() => {
	const { stockAnalogs, stockProducts } = useProductStore();
	const columns = [
		TableColumn({
			header: 'Название склада',
			width: 150,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { priceListName } = rowData.row.original;
				return <>{priceListName}</>;
			},
		}),
		TableColumn({
			header: 'Наименование',
			cellClassName: 'py-2',
			width: 200,
			cell: (rowData: TRowData) => {
				const { code, brand } = rowData.row.original;
				return (
					<>
						{code} {brand}
					</>
				);
			},
		}),
		TableColumn({
			header: 'Количество',
			cellClassName: 'py-2',
			width: 100,
			cell: (rowData: TRowData) => {
				const { stock } = rowData.row.original;
				return <>{stock}</>;
			},
		}),
		TableColumn({
			header: 'Стоимость',
			width: 100,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { costWithMarkup } = rowData.row.original;
				return <>{new Intl.NumberFormat('ru-RU').format(costWithMarkup)} ₽</>;
			},
		}),
	];

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Склад</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{stockProducts.length > 0 && (
					<Table
						isLoading={false}
						data={stockProducts}
						columns={columns}
						pagination={false}
						title="Запрошенный номер на складе"
						tableMinHeight="0"
						footer={null}
					/>
				)}
				{stockAnalogs.length > 0 && (
					<Table
						isLoading={false}
						data={stockAnalogs}
						columns={columns}
						pagination={false}
						title="Аналоги на складе"
						tableMinHeight="0"
						footer={null}
					/>
				)}
			</Modal.Body>
		</>
	);
});
