import {
	castError,
	b2bApiUrl,
	defaultHeaders,
	commonApiUrl,
} from 'api/helpers';
import { TRequisitesFull, TRequisites } from 'types/company';
import request from 'helpers/request';
import { TRequisiteSuggestionCompany } from 'types/requisites';

export const fetchRequisites = async (): Promise<TRequisitesFull> => {
	return request(
		fetch(b2bApiUrl(`bayer/requisites`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchSuggetionsCompany = async (
	payload
): Promise<{ suggestions: TRequisiteSuggestionCompany }> => {
	return request(
		fetch(commonApiUrl(`dadata/suggestions/api/4_1/rs/suggest/party`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const fetchSuggetionsBank = async (
	payload
): Promise<{ suggestions: TRequisiteSuggestionCompany }> => {
	return request(
		fetch(commonApiUrl(`dadata/suggestions/api/4_1/rs/findById/bank`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const postRequisite = async (
	payload: TRequisites
): Promise<TRequisitesFull> => {
	return request(
		fetch(b2bApiUrl(`bayer/requisites`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};
