import { makeAutoObservable } from 'mobx';
import {
	fetchBilling,
	downloadBillingPdf,
	postBill,
} from 'api/billing/billing';
import { TBilling } from 'types/billing';
import { RootStore } from 'stores/RootStore';

export class BillingStore {
	isLoading = true;
	billing: TBilling[] = [];
	totalItems: number;
	page: 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	init = async () => {
		this.isLoading = true;

		const res = await fetchBilling({ page: 1, per_page: 25 });
		if (res === null) {
			this.isLoading = false;
			return;
		}

		const {
			items,
			meta: { count },
		} = res;

		this.billing = items;
		this.totalItems = count;

		this.isLoading = false;
	};

	sendBill = async (values) => {
		this.isLoading = true;

		const { amount } = values;
		const res = await postBill(amount);
		if (res === null) {
			return;
		}

		this.billing.unshift(res);

		this.isLoading = false;
	};

	downloadPdf = async (id: number) => {
		const res = await downloadBillingPdf(id);
		return res ? res.blob() : null;
	};
}
