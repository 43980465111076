import { observer } from 'mobx-react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';

type TProps = {
	close: (note: string) => void;
};

export const NoteFormModal = observer(({ close }: TProps) => {
	return (
		<Formik
			initialValues={{ note: '' }}
			enableReinitialize
			onSubmit={async ({ note }, actions) => {
				close(note);
				actions.resetForm();
			}}
		>
			{({ values, handleChange, handleSubmit, isValid }) => (
				<Form
					onSubmit={async (e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Проценка
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="ps-2 pb-2">
							<Form.Group>
								<Form.Label>Комментарий</Form.Label>
								<Form.Control
									as="textarea"
									placeholder="Комментарий"
									value={values.note}
									name="note"
									onChange={handleChange}
									className="note-form-modal-textarea"
								/>
							</Form.Group>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button disabled={!isValid} variant="primary" type="submit">
							Сохранить
						</Button>
					</Modal.Footer>
				</Form>
			)}
		</Formik>
	);
});
