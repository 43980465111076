import { makeAutoObservable } from 'mobx';
import { getItemFromStore } from 'helpers/utils';
import { fetchMyProfile } from 'api/profile';
import { patchMyProfile, changePassword, fetchBalanceInfo } from 'api/user';
import { toast } from 'react-toastify';
import { TShop } from 'types/shop';
import { DeliveryAddress } from './DeliveryAddress';
import { TDeliveryAddress } from 'types/address';
import { RootStore } from 'stores/RootStore';

class User {
	root: RootStore;
	isProfileLoaded = false;
	id = 0;
	email = '';
	name = '';
	phone = '';
	token = '';
	shop_id = 0;
	shop: TShop | null = null;
	disabled = false;
	show_on_shop_page = false;
	email_for_notifications = '';
	present_position = '';
	rating_value = 0;
	rating_count = 0;
	date_of_birth = '';
	reviews_count = 0;
	home_region = '';
	address = '';
	apartment = '';
	roles: string[] = [];
	avatar: { url: string } | null = null;
	office_ids: string[] = [];
	accepted_resources: string[] = [];
	notify_about_new_orders_pit_stop_parts = false;
	notify_about_order_ready_for_issue = false;
	notify_about_order_rejection = false;
	updated_at = '';
	created_at = '';
	company_id = null;
	partner = false;
	discount = 0;
	availableBalance = null;

	delivery_addresses: DeliveryAddress[] = [];

	constructor(root, user_data: Record<string, unknown>) {
		this.root = root;
		this.setData(user_data);
		makeAutoObservable(this);
	}

	initDeliveryAddress = async () => {
		const address = await this.root.appStore.setActiveModal('address_modal');
		this.delivery_addresses.push(new DeliveryAddress(true, address as string));
		this.patchUserProfile();
	};

	addDeliveryAddress = () => {
		this.delivery_addresses.push(new DeliveryAddress(true));
	};

	updatePassword = async (
		old_password: string,
		new_password: string
	): Promise<boolean> => {
		const res = await changePassword({ old_password, new_password });
		if (res === null) return false;

		toast.success(`Пароль успешно обновлен`, {
			theme: 'colored',
		});

		return true;
	};

	fetchProfileInfo = async () => {
		const res = await fetchMyProfile();
		if (res === null) return;

		this.setData(res);
		this.isProfileLoaded = true;

		this.root.cartStore.initCustomerContact(this);

		if (this.delivery_addresses.length === 0) {
			this.initDeliveryAddress();
		}
	};

	patchUserProfile = async (values?: Record<string, unknown>) => {
		const token = getItemFromStore('token');
		const payload = { ...this.payloadProps(), ...values };

		const res = await patchMyProfile(token, payload);

		if (res === null) {
			await this.root.authStore.initMe();
			await this.root.authStore.initProfile();
			return;
		}

		toast.success(`Персональные данные успешно обновлены`, {
			theme: 'colored',
		});

		this.setData(res);
	};

	getBalance = async () => {
		const res = await fetchBalanceInfo();

		this.setData(res);
	};

	setData = (user_data: Record<string, unknown>) => {
		Object.keys(user_data).forEach((key) => {
			if (key in this) {
				switch (key) {
					case 'delivery_addresses':
						this[key] = (user_data[key] as TDeliveryAddress[]).map((i) => {
							return i.isNew
								? new DeliveryAddress(i.isNew, i.address, i.apartment)
								: new DeliveryAddress(i.isNew, i.address, i.apartment, i.id);
						});
						break;
					default:
						this[key] = user_data[key];
				}
			}
		});
	};

	payloadProps = () => {
		return {
			email: this.email,
			email_for_notifications: this.email_for_notifications,
			home_region: this.home_region,
			name: this.name,
			notify_about_new_orders_pit_stop_parts:
				this.notify_about_new_orders_pit_stop_parts,
			notify_about_order_ready_for_issue:
				this.notify_about_order_ready_for_issue,
			notify_about_order_rejection: this.notify_about_order_rejection,
			phone: this.phone,
			date_of_birth: this.date_of_birth,
			delivery_addresses_attributes: this.delivery_addresses.map((i) => {
				return i.needUpdate && i.payloadProps();
			}),
		};
	};
}

export { User };
