import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal } from 'react-bootstrap';
import { TProduct, TPaymentMethod } from 'types/product';

type TProps = {
	data: unknown;
	close: (paymentMethod: TPaymentMethod) => void;
};

export const PaymentMethodModal = observer(({ data, close }: TProps) => {
	const { b2bCostCash, cashback, b2bCostBank, bankCashback } = data as TProduct;

	const hadnleSelect = useCallback((paymentMethod: TPaymentMethod) => {
		close(paymentMethod);
	}, []);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Выберите способ оплаты
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex flex-row">
					{b2bCostBank && (
						<Button
							onClick={() => hadnleSelect('bank')}
							className="p-3 w-50 me-3"
						>
							<div className="d-flex flex-column align-items-start w-100 me-3">
								<div className="fs-18">Оплата картой:</div>
								<strong className="fs-16 fw-bold">
									{b2bCostBank &&
										`${new Intl.NumberFormat('ru-RU').format(b2bCostBank)} ₽`}
								</strong>
								{bankCashback > 0 && (
									<div className="fs-12">
										Кешбэк{' '}
										{`${new Intl.NumberFormat('ru-RU').format(bankCashback)} ₽`}
									</div>
								)}
							</div>
						</Button>
					)}
					{b2bCostCash && (
						<Button onClick={() => hadnleSelect('cash')} className="p-3 w-50">
							<div className="d-flex flex-column align-items-start w-100">
								<div className="fs-18">Оплата наличными:</div>
								<strong className="fs-16 fw-bold">
									{b2bCostCash &&
										`${new Intl.NumberFormat('ru-RU').format(b2bCostCash)} ₽`}
								</strong>
								{cashback > 0 && (
									<div className="fs-12">
										Кешбэк{' '}
										{`${new Intl.NumberFormat('ru-RU').format(cashback)} ₽`}
									</div>
								)}
							</div>
						</Button>
					)}
				</div>
			</Modal.Body>
		</>
	);
});
