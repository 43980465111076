import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { TOrderShipment } from 'types/order';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { Table } from 'components/common/Table';
import { TableColumn } from 'helpers/TableColumn';
import { TableHeader } from 'components/common/TableHeader';
import { ORDER_STATUS } from 'consts/order';
import { useShipmentStore } from 'providers/RootStoreProvider';
import { HeaderShipment } from './comp/HeaderShipment';
import { FileLink } from './comp/FileLink';

import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { PaginationWrapper } from 'components/common/PaginationWrapper';

type TRowData = {
	row: { original: TOrderShipment };
};

export const ShipmentsTable = observer(() => {
	const {
		total,
		page,
		orders,
		isLoading,
		setPage,
		update,
		downloadPDF,
		itemsPerPage,
	} = useShipmentStore();
	const [activeOrderId, setActiveOrderId] = useState(null);

	useEffect(() => {
		update();
	}, []);

	const columns = [
		TableColumn({
			header: 'Заказ',
			width: 100,
			cellClassName: 'py-2 pe-2',
			cell: (rowData: TRowData) => {
				const { orderId, createdAt } = rowData.row.original;

				return (
					<div className="d-flex w-100 justify-content-start align-items-start flex-column">
						<div className="w-100 d-flex justify-content-start align-items-start flex-row">
							<a
								style={{ whiteSpace: 'pre' }}
								href={`/order-details/${orderId}`}
								target="_blank"
								rel="noreferrer"
							>
								<strong className="cursor-pointer  me-1">№ {orderId}</strong>
							</a>
						</div>
						<span>{dayjs(createdAt).format('DD.MM.YY')}</span>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Наименование',
			width: 350,
			headerClassName: 'pe-1',
			cellClassName: 'py-2 pe-3',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},

			cell: (rowData: TRowData) => {
				const { code, name, brand } = rowData.row.original;

				return (
					<div className="w-100">
						<OverflowedTextTooltip
							text={`Арт. ${code}, ${brand} ,${name}`}
							value={
								<div>
									<div>{`Арт. ${code}, ${brand}`}</div>
									<div>{name}</div>
								</div>
							}
						/>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Кол-во',
			width: 70,
			headerClassName: 'pe-1',
			cellClassName: 'py-2',

			cell: (rowData: TRowData) => {
				const { quantity } = rowData.row.original;
				return <div className="w-100">{quantity}</div>;
			},
		}),
		TableColumn({
			header: 'Оплата',
			width: 80,
			headerClassName: 'pe-1',
			cellClassName: 'py-2',

			cell: (rowData: TRowData) => {
				const { cash } = rowData.row.original;
				return <div className="w-100">{cash ? 'Нал' : 'Безнал'}</div>;
			},
		}),
		TableColumn({
			header: 'Статус',
			width: 150,
			headerClassName: 'px-2 fs-12',
			cellClassName: 'px-2 pe-4 fs-14',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { status } = rowData.row.original;
				const order_status = ORDER_STATUS[status];

				return (
					<>
						<div className="d-flex w-100 flex-row justify-content-start align-items-center">
							<SoftBadge
								style={{ maxWidth: '150px' }}
								pill
								bg={classNames(order_status && order_status.type)}
								className="d-block px-3 me-3 py-1 white-space-break-spaces"
							>
								{order_status ? order_status.status : status}
							</SoftBadge>
						</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Сумма',
			width: 100,
			cellClassName: 'py-2',
			cellStyle: {
				whiteSpace: 'break-spaces',
			},
			cell: (rowData: TRowData) => {
				const { totalCost } = rowData.row.original;
				return (
					<>
						<strong className="fs-16 fw-bold">
							{totalCost &&
								`${new Intl.NumberFormat('ru-RU').format(totalCost)} ₽`}
						</strong>
					</>
				);
			},
		}),
	];

	const handleDownload = async (id: number) => {
		const blob = await downloadPDF(id);
		download(blob, `${id}-TOРГ12.pdf`);
	};

	const download = useCallback((blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}, []);

	return (
		<>
			<HeaderShipment />
			<PaginationWrapper
				totalItems={total}
				itemsPerPage={itemsPerPage}
				page={page}
				setPage={setPage}
			>
				{orders.map((order) => {
					return (
						<div key={order.id + 'shipment'} className="mb-3">
							<Table
								isLoading={isLoading}
								data={order.orderItems}
								totalItems={total}
								itemsPerPage={25}
								setPage={setPage}
								page={page}
								columns={columns}
								contained={true}
								tableMinHeight="0px"
								hide={activeOrderId !== order.shipmentId}
								pagination={false}
								cardClassName="mb-0"
								showFooter={!order.paid}
								footer={
									<img
										src={`https://e-commerce.raiffeisen.ru/api/sbp/v1/qr/${order.paykeeperQrId}/image`}
										alt=""
									/>
								}
								header={
									<div className="w-100">
										<TableHeader
											showTotal={false}
											title={`Отгрузка №${order.shipmentId} `}
											hide={activeOrderId === order.shipmentId}
											onClick={() =>
												setActiveOrderId(
													activeOrderId !== order.shipmentId
														? order.shipmentId
														: null
												)
											}
										>
											<div className="w-100 d-flex justify-content-start align-items-center">
												<div className="d-flex w-100 justify-content-center align-items-center">
													<span className="me-2">Дата:</span>
													{dayjs(order.createdAt).format('HH:MM')}{' '}
													{dayjs(order.createdAt).format('DD.MM.YYYY')}
												</div>
												<div className="d-flex w-100 justify-content-center align-items-center">
													<span className="me-2">Способ оплаты:</span>
													{order.cash ? 'Нал' : 'Безнал'}
												</div>
												<div className="d-flex w-100 justify-content-center align-items-center">
													<span className="me-2">Статус оплаты:</span>
													{order.paid ? 'Оплачено' : 'Не оплачено'}
												</div>
												<div className="d-flex w-100 justify-content-center align-items-center">
													<FileLink
														className="fs-14"
														onClick={() => handleDownload(order.shipmentId)}
														text={`${order.shipmentId}-ТОРГ12.pdf`}
													/>
												</div>
											</div>
										</TableHeader>
									</div>
								}
							/>
						</div>
					);
				})}
			</PaginationWrapper>
		</>
	);
});
