import { useState, useEffect, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Dropdown } from 'react-bootstrap';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TReview, TReviewSort, TReviews } from 'types/review';
import { Pagination } from 'components/common/Pagination';
import Avatar from 'components/common/Avatar';
import { reviewsWord } from 'utils/product';
import CircularProgress from '@mui/material/CircularProgress';
import { TShopPassport } from 'types/shop';

import 'styles/Shop.scss';
import 'dayjs/locale/ru';

dayjs.locale('ru');
dayjs.extend(relativeTime);

type TProps = {
	getReviews: (sort_params: TReviewSort) => Promise<TReviews>;
	shopPassport: TShopPassport;
};

const review_sort = [
	{
		title: 'По дате добавления',
		sort_by: 'created_at',
		sort_direction: 'DESC',
	},
	{
		title: 'Сначала положительные',
		sort_by: 'rating',
		sort_direction: 'DESC',
	},
	{
		title: 'Сначала отрицательные',
		sort_by: 'rating',
		sort_direction: 'ASC',
	},
];

export const ShopReviews = observer(
	({ getReviews, shopPassport: { rating_count } }: TProps) => {
		const [activeSort, setActiveSort] = useState<TReviewSort>(review_sort[0]);
		const [activePage, setActivePage] = useState(1);
		const [reviews, setReviews] = useState<TReview[]>([]);
		const reviewContainer = useRef(null);
		const [isLoading, setIsLoading] = useState(true);

		dayjs.locale('ru');

		const handleSort = useCallback(
			async (sort: TReviewSort) => {
				setIsLoading(true);
				setActiveSort(sort);
				const sort_copy = { ...sort };
				delete sort_copy['title'];

				const { reviews } = await getReviews({
					...sort_copy,
					page: activePage,
				});
				setReviews(reviews);
				setIsLoading(false);
			},
			[activePage]
		);

		const handlePagination = useCallback(
			(page: number) => {
				reviewContainer.current.scrollIntoView({
					behavior: 'smooth',
				});
				setActivePage(page);
			},
			[reviewContainer]
		);

		useEffect(() => {
			handleSort(activeSort);
		}, [activePage]);

		return (
			<>
				<hr className="my-4" />
				<div
					ref={reviewContainer}
					style={{ width: '100%' }}
					className="review-container"
				>
					<div
						style={{ width: '100%' }}
						className="review-container-header d-flex align-items-center justify-content-between mb-3"
					>
						<span className="review-container-header d-flex align-items-center justify-content-between">
							<h5 className="me-3">Отзывы</h5>
							{isLoading && <CircularProgress size={15} />}
						</span>

						<Dropdown>
							<Dropdown.Toggle variant="link" id="dropdown-basic">
								{activeSort.title}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{review_sort.map((sort) => {
									return (
										<Dropdown.Item
											active={activeSort.title === sort.title}
											onClick={() => handleSort(sort)}
											key={`sort_${sort.title}`}
										>
											{sort.title}
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="reviews-content mb-5">
						{!reviews.length && <>Нет отзывов</>}
						{reviews.map((review, index) => (
							<div key={review.id}>
								<div className="mb-3">
									<div className="d-flex justify-content-start align-items-center mb-2">
										<Avatar
											size={'2xl'}
											src={review.user?.avatar?.url}
											className="me-3"
										/>
										<div className="d-flex flex-column justify-content-start ">
											<span className="me-3 text-dark fw-semi-bold">
												{review.user.name}
											</span>
											<span>
												{review.user.reviews_count}{' '}
												{reviewsWord(review.user.reviews_count)}
											</span>
										</div>
									</div>
									<p className="fs--1 mb-2 text-600">
										{/* @ts-ignore */}
										<Rating
											readonly
											fractions={2}
											initialRating={review.rating}
											fullSymbol={
												<FontAwesomeIcon icon="star" className="text-warning" />
											}
											emptySymbol={
												<FontAwesomeIcon icon="star" className="text-300" />
											}
											direction={'ltr'}
										/>
										<span className="ms-3">
											{dayjs().to(dayjs(review.updated_at))}
										</span>
									</p>
								</div>
								<p className="mb-0">{review.message}</p>
								{!(index === reviews.length - 1) && <hr className="my-4" />}
							</div>
						))}
					</div>
					{Math.ceil(rating_count / 25) > 1 && (
						<Pagination
							totalPages={Math.ceil(rating_count / 25)}
							activePage={activePage}
							setActivePage={handlePagination}
						/>
					)}
				</div>
			</>
		);
	}
);
