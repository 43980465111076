import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from 'providers/RootStoreProvider';

export const ProfileDropdown = observer(() => {
	const { user, signOut } = useAuthStore();

	return (
		<Dropdown navbar={true} as="li">
			<Dropdown.Toggle
				bsPrefix="toggle"
				as="div"
				className="pe-0 ps-2 nav-link"
			>
				{user ? (
					<Avatar src={user?.avatar?.url} />
				) : (
					<Link to="/authentication/login">Войти</Link>
				)}
			</Dropdown.Toggle>
			{user && (
				<Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end text-transform-default">
					<div className="bg-white rounded-2 py-2 dark__bg-1000">
						<Dropdown.Header className="fs--1" as={'div'}>
							{user.name}
						</Dropdown.Header>
						<Dropdown.Header className="fs--2" as={'div'}>
							{user.email}
						</Dropdown.Header>
						<Dropdown.Header className="fs--2" as={'div'}>
							ID: {user.company_id}-{user.id}
						</Dropdown.Header>
						<Dropdown.Divider />
						<Dropdown.Item as={Link} to="/user/settings">
							Настройки aккаунта
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item as={'button'} onClick={signOut}>
							Выйти
						</Dropdown.Item>
					</div>
				</Dropdown.Menu>
			)}
		</Dropdown>
	);
});
