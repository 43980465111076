import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ProductTableHeaderCoreProduct } from '../product-list/comp/product-list-table-header/product-list-table-core-product/ProductTableHeaderCoreProduct';
import { ProductTableHeaderSort } from '../product-list/comp/product-list-table-header/product-list-table-sort/ProductTableHeaderSort';
import { ProductFilter } from '../product-list/comp/product-list-table-header/product-list-table-filter/ProductFilter';
import { useCoreProductStore, useAuthStore } from 'providers/RootStoreProvider';

export const CoreProductHeader = observer(() => {
	const { isLoading, coreProduct, unSave, setSave, getCoreProduct } =
		useCoreProductStore();
	const { isAuthorized } = useAuthStore();
	const { uid } = useParams();

	useEffect(() => {
		const initiate = async () => {
			if (uid) await getCoreProduct(uid);
		};
		initiate();
	}, [uid]);

	if (!uid) return null;

	return (
		<Card className="product-header mb-3">
			<div className="p-3 pb-3">
				<ProductTableHeaderCoreProduct
					isLoading={isLoading}
					isAuthorized={isAuthorized}
					coreProduct={coreProduct}
					saveCoreProduct={setSave}
					unsaveCoreProduct={unSave}
				/>
				<hr />
				<ProductTableHeaderSort />
				<hr className="mb-0" />
				<ProductFilter />
			</div>
		</Card>
	);
});
