import { useEffect } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ProductTable } from './product-list/ProductTable';
import { useSearchParams } from 'react-router-dom';
import { CoreProductHeader } from './product-list/CoreProductHeader';
import { GettzapTable } from './product-list/comp/GettzapTable';

import 'styles/Product.scss';
import {
	useAuthStore,
	useProductStore,
	useSellerProductStore,
} from 'providers/RootStoreProvider';

export const SingleSearch = observer(() => {
	const { setViewTypeList, reset, init, setSingleSearch, startUpdateTimer } =
		useProductStore();
	const { products, total, isLoading, setPage, page } = useSellerProductStore();
	const { user } = useAuthStore();
	const { uid } = useParams();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const initiate = async () => {
			await setViewTypeList();
			setSingleSearch();
			if (uid) {
				await init(uid, { office_id: searchParams.get('office_id') });
			}
			startUpdateTimer();
		};
		initiate();
		return () => reset();
	}, [uid]);

	if (!uid) return null;

	if (user && user.partner) {
		return (
			<>
				<CoreProductHeader />
				<GettzapTable />
			</>
		);
	} else {
		return (
			<>
				<CoreProductHeader />
				<ProductTable
					isLoading={isLoading}
					pagination={true}
					showEmpty={true}
					data={products}
					totalItems={total}
					setPage={(page) => setPage(page)}
					page={page}
				/>
			</>
		);
	}
});
