import { observer } from 'mobx-react';
import { Card } from 'react-bootstrap';
import { TProduct } from 'types/product';
import 'styles/Map.scss';
import { ProductItemShop } from 'components/product/product-list/comp/product-list-item/ProductItemShop';
import { ProductItemButtonBuy } from 'components/product/product-list/comp/product-list-item/ProductItemButtonBuy';
import { ProductItemDeliveryTime } from 'components/product/product-list/comp/product-list-item/ProductItemDeliveryTime';
import { ProductItemDeliveryNote } from 'components/product/product-list/comp/product-list-item/ProductItemDeliveryNote';

type TProps = {
	product: TProduct;
	isLoading: boolean;
	onShopName: () => void;
	isAuthorized: boolean;
	addToCart;
};

export const ProductListItem = observer(
	({ product, isLoading, onShopName, isAuthorized, addToCart }: TProps) => {
		const {
			name,
			metroStation,
			shop,
			office,
			city,
			costWithMarkup,
			priceList,
			stock,
			cashback,
			deliveryMessage,
		} = product;

		return (
			<Card key={`product_item_map_${office.id}`} className="mb-3">
				<Card.Header className="fs-12 p-2">
					<strong>{name}</strong>
				</Card.Header>
				<hr className="my-1" />
				<Card.Body className="fs-12 p-2">
					<ProductItemShop
						{...{
							city,
							metroStation,
							office,
							isLoading,
							shop,
							onShopName,
						}}
					/>
				</Card.Body>
				<hr className="my-1" />
				<Card.Body className="fs-12 p-2">
					<div className="mb-3">
						<ProductItemDeliveryTime
							{...{
								cost: costWithMarkup,
								priceList,
								stock,
								isLoading,
							}}
						/>
					</div>
					<ProductItemDeliveryNote
						{...{ deliveryMessage, priceList, isLoading, office }}
					/>
				</Card.Body>
				<hr className="my-1" />
				<Card.Body className="fs-12 p-2">
					<div className="d-flex justify-content-between flex-row align-items-center">
						<div className="fs-16 d-flex flex-row">
							<div className="d-flex flex-column align-items-start w-100 me-3">
								<strong className="fs-16 fw-bold">
									{new Intl.NumberFormat('ru-RU').format(costWithMarkup)} ₽
								</strong>
								{cashback > 0 && (
									<div className="fs-12">
										Кешбэк:{' '}
										{`${new Intl.NumberFormat('ru-RU').format(cashback)} ₽`}
									</div>
								)}
							</div>
						</div>
						<ProductItemButtonBuy
							isAuthorized={isAuthorized}
							isLoading={isLoading}
							addToCart={() =>
								addToCart({ ...product, selectedPrice: costWithMarkup })
							}
						/>
					</div>
				</Card.Body>
			</Card>
		);
	}
);
