import { Col, Form, Row, Button } from 'react-bootstrap';
import { DeliveryAddress } from 'stores/user/DeliveryAddress';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { AddressSuggections } from 'components/common/AddressSuggections';

type TProps = {
	deliveryAddress: DeliveryAddress;
	index: number;
};

export const DeliveryAddressItem = observer(
	({ deliveryAddress, index }: TProps) => {
		const { address, apartment, setData, remove } = deliveryAddress;
		const isFirst = index === 0;

		return (
			<div>
				<Form>
					<Row className="mb-3 g-3">
						<Form.Group as={Col} lg={8} controlId="address">
							<Form.Label>Адрес доставки</Form.Label>
							<AddressSuggections
								initValue={address}
								setValue={({ name }) => setData('address', name)}
							/>
						</Form.Group>
						<Form.Group as={Col} lg={isFirst ? 4 : 3} controlId="apartment">
							<Form.Label>Квартира / Офис</Form.Label>
							<Form.Control
								type="text"
								placeholder="Квартира / Офис"
								value={apartment}
								name="apartment"
								onChange={({ target }) => setData('apartment', target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} lg={1}>
							<Flex
								style={{ height: '100%' }}
								alignItems="center"
								justifyContent="center"
								className="mt-3 text-primary cursor-pointer fs-0"
							>
								{!isFirst && (
									<Button variant="link" size="sm" onClick={remove}>
										<FontAwesomeIcon
											className="text-danger"
											icon="times-circle"
										/>
									</Button>
								)}
							</Flex>
						</Form.Group>
					</Row>
				</Form>
				<div className="border-dashed-bottom my-3" />
			</div>
		);
	}
);
