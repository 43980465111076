import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import NavbarVerticalMenuItem from '../NavbarVerticalMenuItem';
import { observer } from 'mobx-react-lite';
import { NavbarCollapseItems } from './NavbarCollapseItems';
import { TRoute } from 'types/common';
import {
	useAppStore,
	useAuthStore,
	useCompanyStore,
} from 'providers/RootStoreProvider';

type TProps = {
	routes?: TRoute[];
};

export const NavbarVerticalMenu = observer(({ routes }: TProps) => {
	const { setActiveModal, setMenuOpen, isTablet } = useAppStore();
	const { company } = useCompanyStore();
	const { user } = useAuthStore();

	const handleNavItemClick = useCallback((route: TRoute) => {
		if (route.modal) {
			setActiveModal(route.modal);
		}
		if (isTablet) {
			setMenuOpen(false);
		}
	}, []);

	const hideIfTrue = useCallback(
		(hideIfTrue?: string) => user && user[hideIfTrue],
		[user]
	);

	return (
		<>
			{routes.map((route) => {
				if (hideIfTrue(route.hideIfTrue)) return null;

				if (route.id && route.id === 'organization' && company) {
					route.name = company.id ? company.name : 'Добавить компанию';
					route.icon = company.id ? 'fact_check' : 'add_to_photos';
				}

				if (!route.children) {
					if (route.to) {
						return (
							<Nav.Item
								as="li"
								key={route.name}
								onClick={() => handleNavItemClick(route)}
							>
								<NavLink
									to={route.to}
									className={({ isActive }) =>
										isActive ? 'active nav-link' : 'nav-link'
									}
								>
									<NavbarVerticalMenuItem route={route} />
								</NavLink>
							</Nav.Item>
						);
					}

					return (
						<Nav.Item
							as="li"
							key={route.name}
							className="cursor-pointer"
							onClick={() => handleNavItemClick(route)}
						>
							<div className="nav-link">
								<NavbarVerticalMenuItem route={route} />
							</div>
						</Nav.Item>
					);
				}

				return <NavbarCollapseItems route={route} key={route.name} />;
			})}
		</>
	);
});
