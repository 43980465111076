import { observer } from 'mobx-react';
import { Placeholder } from 'react-bootstrap';

type TProps = {
	children: React.ReactNode;
	isLoading: boolean;
	placeholder?: number[][];
	className?: string;
};

export const PlaceholderWrapper = observer(
	({ isLoading, children, className, placeholder }: TProps) => {
		const render = (placeholder: number[] | number) => {
			if (Array.isArray(placeholder)) {
				return placeholder.map(render);
			}
			return (
				<span className="px-1">
					<Placeholder xs={placeholder} />
				</span>
			);
		};

		return (
			<>
				{isLoading ? (
					<div className={`${className}`}>{placeholder.map(render)}</div>
				) : (
					<>{children}</>
				)}
			</>
		);
	}
);
