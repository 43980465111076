import { makeAutoObservable } from 'mobx';
import { TSearchItem } from 'types/search';
import { searchProductFetch, searchHistoryFetch } from 'api/product';
import { detectRegion } from 'api/user/detectRegion';
import { searchLocation } from 'api/user/searchLocation';
import { TLocation, TRegion } from 'types/common.d';
import { TSearchRegion, TSearchHistoryItem } from 'types/search';
import { RootStore } from 'stores/RootStore';

class SearchRegion implements TRegion {
	id: number;
	name: string;
	time_zone: string;
	kladr: string;
	metro_map: string;
	location: TLocation;

	constructor(data: TRegion | TSearchRegion) {
		this.setData(data);
	}

	setData = (data: TRegion | TSearchRegion) => {
		Object.keys(data).forEach((key) => {
			if (key in this) this[key] = data[key];
		});

		if ('region_id' in data && data.region_id) {
			this.id = data.region_id;
		}

		localStorage.setItem(
			'gettzap.delivery_region',
			JSON.stringify(this.payloadProps())
		);
	};

	payloadProps = () => {
		return {
			id: this.id,
			name: this.name,
			time_zone: this.time_zone,
			kladr: this.kladr,
			metro_map: this.metro_map,
			location: this.location,
		};
	};
}

type TSearchAddress = { name: string; location: TLocation };

export class SearchStore {
	searchItems: TSearchItem[] = [];
	searchRegion: SearchRegion;
	searchAddress: TSearchAddress = null;
	searchHistory: TSearchHistoryItem[] = [];
	isLoading = false;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
		this.init();
	}

	setSearchAddress = (searchAddress: TSearchAddress) => {
		this.searchAddress = searchAddress;
		localStorage.setItem(
			'gettzap.delivery_address',
			JSON.stringify(searchAddress)
		);
	};

	init = () => {
		const string_region = localStorage.getItem('gettzap.delivery_region');
		const string_address = localStorage.getItem('gettzap.delivery_address');

		if (string_region) {
			const region: TRegion = JSON.parse(string_region);
			this.setSearchRegion(region);

			const address: TSearchAddress = JSON.parse(string_address);
			this.setSearchAddress(address);
		} else {
			this.setCurrentRegion();
		}
	};

	setCurrentRegion = async () => {
		const res: TSearchRegion = await detectRegion();
		if (res === null) return;

		this.setSearchRegion(res);
		return res;
	};

	setSearchRegion = (location: TSearchRegion | TRegion) => {
		this.searchRegion = new SearchRegion(location);
		if (this.root.productStore.uid) this.root.productStore.updateProducts();
	};

	searchLocation = async (search_query: string) => {
		const res = await searchLocation(search_query);
		if (res === null) return;

		return res.map((i) => {
			if (!i.location && i.region) {
				i.location = i.region.location;
			}
			// if (i.region) {
			// 	i.name = i.region.name;
			// }
			return i;
		});
	};

	getSearchHistory = async (per_page: number) => {
		this.isLoading = true;

		const res = await searchHistoryFetch({
			per_page: per_page,
			sort_by: 'updated_at',
			sort_direction: 'DESC',
		});

		if (res === null) {
			this.isLoading = false;
			return;
		}

		this.searchHistory = res.data.map((i) => {
			const name = i.product_name
				? i.product_name
				: `${i.brand_name} ${i.product_number}`;

			i.url = `/products/${i.uid}`;
			i.value = name;
			i.name = name;
			i.label = name;
			i.code = i.product_number;
			return i;
		});

		this.isLoading = false;
		return this.searchHistory;
	};

	getSearchItems = async (query: string) => {
		let res = await searchProductFetch({
			search_query: query,
		});

		if (res.length === 0) {
			res = await searchProductFetch({
				search_query: query.replace(/[-.,()\s+/]/g, ''),
			});
		}

		if (res === null) return [];

		this.getSearchHistory(10);

		return res.map((i) => {
			i.url = `/products/${i.uid}`;
			i.value = i.name;
			i.label = i.name;
			return i;
		});
	};

	clearItems = () => (this.searchItems = []);
}
