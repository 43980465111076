import React from 'react';
import classNames from 'classnames';

type TProps = {
  children: React.ReactNode | React.ReactNode[];
  justifyContent?: string;
  inline?: boolean;
  alignItems?: string;
  alignContent?: string;
  wrap?: string;
  className?: string;
  tag?: string | 'div';
  breakpoint?: string;
  direction?: string;
  onClick?: () => void;
  style?: Record<string, string>;
};

const Flex = ({
  justifyContent,
  alignItems,
  alignContent,
  inline,
  wrap,
  className,
  children,
  breakpoint,
  direction,
  style,
  ...rest
}: TProps) => {
  return (
    <div
      style={style}
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
