import { useState, useRef, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Form } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components, OptionProps } from 'react-select';
import { TSearchRegion } from 'types/search';
import locations from 'assets/img/locations.svg';
import { useSearchStore } from 'providers/RootStoreProvider';

type TProps = {
	close: () => void;
};

export const RegionSearchModal = observer(({ close }: TProps) => {
	const { searchLocation, searchRegion, setCurrentRegion, setSearchRegion } =
		useSearchStore();
	const initRegion = searchRegion ? searchRegion.name : '';
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [placeholder, setPlaceholder] = useState('');
	const [regionInput, setRegionInput] = useState('');
	const InputRef = useRef(null);

	useEffect(() => {
		if (!initRegion) {
			InputRef.current.setValue('');
			InputRef.current.blur();
		}
	}, [initRegion]);

	const getSuggestions = useCallback(async (query: string) => {
		const suggestions = await searchLocation(query);
		return suggestions.map((i) => {
			i.label = i.name;
			return i;
		});
	}, []);

	const search = useCallback(async (search_query: string) => {
		const suggestions = await getSuggestions(search_query);
		return { options: suggestions };
	}, []);

	const onSetCurrentRegion = useCallback(async () => {
		setPlaceholder('Определение региона...');
		const location = await setCurrentRegion();
		if (location) {
			setSelectedRegion(location);
			setRegionInput(location.region.name);
			setPlaceholder('');
		}
	}, []);

	const saveLocation = useCallback(() => {
		selectedRegion && setSearchRegion(selectedRegion);
		close();
	}, [selectedRegion]);

	const Option = (props: OptionProps<TSearchRegion>) => {
		return (
			<>
				<components.Option
					className="searchbox-custom-option cursor-pointer"
					{...props}
				>
					<div>
						<strong style={{ color: '#000' }}>{props.data.name}</strong>
					</div>
				</components.Option>
			</>
		);
	};

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Регион поиска
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="location-input-container p-4 d-flex justify-content-center flex-column align-items-center">
					<img className="w-50 mb-4" src={locations} alt="" />
					<div className="mb-3 w-100 d-flex justify-content-center align-items-center flex-row flex-wrap">
						<span className="me-1">Вам показаны товары с доставкой в</span>
						<span className="text-primary">{initRegion}</span>
					</div>
					<Form.Group className="w-100">
						<Form.Label>Укажите регион поиска</Form.Label>
						<AsyncPaginate
							// @ts-ignore
							loadOptions={search}
							noOptionsMessage={() => <>Ничего не найдено</>}
							loadingMessage={() => <>Загрузка...</>}
							placeholder={placeholder ? placeholder : 'Укажите другой регион'}
							classNamePrefix="react-select"
							className="w-100 mb-3"
							isClearable={true}
							debounceTimeout={300}
							inputValue={regionInput}
							onInputChange={(value, action) => {
								if (action.action === 'input-change') {
									setRegionInput(value);
								}
							}}
							components={{
								Option,
							}}
							onChange={(location: TSearchRegion) => {
								if (!location) return;

								setSelectedRegion(location);
								setRegionInput(location.name);

								InputRef.current.blur();
								InputRef.current.focus();
								InputRef.current.blur();
							}}
							selectRef={InputRef}
						/>
					</Form.Group>
					<Button variant={'link'} onClick={onSetCurrentRegion}>
						Определить регион автоматически
					</Button>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={!selectedRegion}
					variant={'success'}
					onClick={saveLocation}
				>
					Сохранить
				</Button>
			</Modal.Footer>
		</>
	);
});
