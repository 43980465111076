import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import { TasksFormModal } from './comp/TasksFormModal';
import { TTasksPayload } from 'types/tasks';
import { fetchTaskById } from 'api/tasks/tasks';
import { useTasksStore } from 'providers/RootStoreProvider';

type TProps = {
	close: (result?: unknown) => void;
};

export const TaskEditModal = observer(({ close }: TProps) => {
	const { activeId, editTasks } = useTasksStore();
	const [initialData, setInitialData] = useState(null);
	const { id: companyId } = useParams();

	useEffect(() => {
		const getInitialData = async () => {
			const taskInfo = await fetchTaskById(activeId);
			taskInfo &&
				setInitialData({
					...taskInfo,
					dateVisit: new Date(taskInfo.dateVisit),
				});
		};

		getInitialData();
	}, []);

	const handleEditTask = async (payload: TTasksPayload) => {
		await editTasks(activeId, payload);
		close();
	};

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Задача</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TasksFormModal
					mode="edit"
					initialData={initialData}
					onSubmit={handleEditTask}
					companyId={+companyId}
				/>
			</Modal.Body>
		</>
	);
});
