import { castError, defaultHeaders, b2bApiUrl } from 'api/helpers';
import { TListReponse } from 'types/common';
import { TOrder, TOrderItems, TPartnerOrders, TShipment } from 'types/order';
import request from 'helpers/request';

export const fetchXLS = async (filters) => {
	return request(
		fetch(b2bApiUrl(`bayer/orders/export/xls`, filters), {
			headers: {
				...defaultHeaders({}),
			},
		})
	);
};

export const fetchBrokerPDF = async (id: number) => {
	return request(
		fetch(b2bApiUrl(`broker/doc/torg12/download/${id}`), {
			headers: {
				...defaultHeaders({}),
			},
		})
	);
};

export const fetchShipmentPDF = async (id: number) => {
	return request(
		fetch(b2bApiUrl(`bayer/shipments/download/${id}`), {
			headers: {
				...defaultHeaders({}),
			},
		})
	);
};

export const fetchPDF = async (id: number, docType: string) => {
	return request(
		fetch(b2bApiUrl(`bayer/doc/${docType}/download/${id}`), {
			headers: {
				...defaultHeaders({}),
			},
		})
	);
};

export const patchOrderNote = async (orderItemId, note): Promise<TOrder> => {
	return request(
		fetch(b2bApiUrl(`bayer/orders/change-order-item-note/${orderItemId}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ note }),
		}).then(castError)
	);
};

export const patchStatusPartnerItem = async (
	orderItemIds: number[],
	status: number
): Promise<TOrder> => {
	return request(
		fetch(b2bApiUrl(`broker/change_items_status`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify({ orderItemIds, status }),
		}).then(castError)
	);
};

export const docCreate = async (orderItemIds: number[]) => {
	return request(
		fetch(b2bApiUrl(`broker/doc_create`), {
			method: 'POST',
			headers: {
				...defaultHeaders({}),
			},
			body: JSON.stringify({ orderItemIds }),
		})
	);
};

export const fetchPDFTemporary = async (id: number, docType: string) => {
	return request(
		fetch(b2bApiUrl(`merchant/temporary/${docType}/download/${id}`), {
			headers: {
				...defaultHeaders({}),
			},
		})
	);
};

export const fetchOrder = async (id: number): Promise<TOrderItems> => {
	return request(
		fetch(b2bApiUrl(`bayer/orders/${id}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchShipments = async (
	query
): Promise<TListReponse<TShipment>> => {
	return request(
		fetch(b2bApiUrl(`bayer/shipments`, query), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchOrders = async (query): Promise<TListReponse<TOrder>> => {
	return request(
		fetch(b2bApiUrl(`bayer/orders`, query), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const returnOrder = async ({ order_id }): Promise<TOrder> => {
	return request(
		fetch(b2bApiUrl(`bayer/orders/refund/${order_id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const cancelOrder = async ({ order_id }): Promise<TOrder> => {
	return request(
		fetch(b2bApiUrl(`bayer/orders/cancel/${order_id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchPartnerOrders = async (
	order_status: number,
	items_status: number,
	page: number,
	per_page: number
): Promise<TPartnerOrders> => {
	return request(
		fetch(
			b2bApiUrl(`broker/partner_order_items`, {
				order_status: order_status,
				order_item_status: items_status,
				page,
				per_page,
			}),
			{
				headers: {
					...defaultHeaders(),
				},
			}
		).then(castError)
	);
};
