import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { PaginationWrapper } from 'components/common/PaginationWrapper';
import { NotFound } from 'components/common/NotFound';
import { ListItem } from './comp/ListItem';
import { useAppStore, useTasksStore } from 'providers/RootStoreProvider';

export const Tasks = observer(() => {
	const {
		init,
		isLoading,
		tasks,
		itemsPerPage,
		setPage,
		page,
		total,
		setActiveId,
		removeTask,
	} = useTasksStore();
	const { setActiveModal } = useAppStore();

	useEffect(() => {
		init();
	}, []);

	const handleSuccesTask = async (id: number) => {
		setActiveId(id);
		await setActiveModal('success_task');
	};

	const handleDelete = async (id: number) => {
		await removeTask(id);
	};

	const handleEdit = async (id: number) => {
		setActiveId(id);
		await setActiveModal('edit_task');
	};

	return (
		<>
			{isLoading ? (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
			) : (
				<>
					<Card className="mb-3">
						<Card.Body className="position-relative ">
							<h4 className="mb-0">Список задач</h4>
						</Card.Body>
					</Card>

					<PaginationWrapper
						totalItems={total}
						itemsPerPage={itemsPerPage}
						page={page}
						setPage={setPage}
					>
						{tasks.length ? (
							tasks.map((task) => {
								return (
									<Card className="mb-3 cursor-pointer" key={task.id}>
										<Card.Body>
											<ListItem
												handleSuccesTask={handleSuccesTask}
												handleDelete={handleDelete}
												handleEdit={handleEdit}
												task={task}
											/>
										</Card.Body>
									</Card>
								);
							})
						) : (
							<NotFound />
						)}
					</PaginationWrapper>
				</>
			)}
		</>
	);
});
