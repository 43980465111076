import { useState } from 'react';
import { Card, Row, Col, Table } from 'react-bootstrap';
import { TableColumn } from 'helpers/TableColumn';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import { TOrder } from 'types/order';
import { Table as TableCustom } from 'components/common/Table';
import SoftBadge from 'components/common/SoftBadge';
import { ORDER_STATUS } from 'consts/order';
import classNames from 'classnames';
import { ConsentModal } from 'components/modals/ConsentModal';
import { observer } from 'mobx-react-lite';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TooltipElement } from 'components/common/TooltipElement';
import { Alert } from 'react-bootstrap';
import { useOrderStore } from 'providers/RootStoreProvider';

type TRowDataOrderContent = {
	row: { original: TOrder };
};

export const OrderProductsTable = observer(
	({
		data,
		total,
		update,
	}: {
		data: TOrder[];
		total: { cost: number; cashback: number };
		update: () => Promise<void>;
	}) => {
		const { cancelOrder, returnOrder, ORDER_STATUS_KEYS } = useOrderStore();
		const [activeModal, setActiveModal] = useState<'cancel' | '' | 'return'>(
			''
		);
		const [activeOrderId, setActiveOrderId] = useState(null);

		const columns = [
			TableColumn({
				header: 'Наименование',
				width: 450,
				cellClassName: 'py-2 pe-3',
				cell: (rowData: TRowDataOrderContent) => {
					const { name, code, product_name, apiData } = rowData.row.original;

					return (
						<>
							<div className="w-100">
								<strong>
									<OverflowedTextTooltip text={`Арт. ${code}, ${name}`} />
								</strong>
								<OverflowedTextTooltip text={product_name} />
								{apiData?.isReturnable !== undefined &&
									apiData?.isReturnable === false && (
										<div style={{ color: '#e63757' }}>Возврату не подлежит</div>
									)}
							</div>
							{apiData && apiData.note && (
								<Alert
									className="office-order-notes p-1 fs-12 mt-2"
									variant="light"
								>
									<OverflowedTextTooltip text={apiData.note} />
								</Alert>
							)}
						</>
					);
				},
			}),
			TableColumn({
				width: 150,
				header: 'Статус',
				cellClassName: 'pe-4',

				cell: (rowData: TRowDataOrderContent) => {
					const { id, status } = rowData.row.original;
					const order_status = ORDER_STATUS[status];

					const handleCancel = () => {
						setActiveOrderId(id);
						setActiveModal('cancel');
					};

					const handleReturn = () => {
						setActiveOrderId(id);
						setActiveModal('return');
					};

					return (
						<>
							<div className="d-flex w-100 flex-row justify-content-start align-items-center">
								<SoftBadge
									style={{ maxWidth: '150px' }}
									pill
									bg={classNames(order_status && order_status.type)}
									className="d-block px-3 me-3 py-1 white-space-break-spaces"
								>
									{order_status ? order_status.status : status}
								</SoftBadge>
								{ORDER_STATUS_KEYS.in_work.includes(status) && (
									<TooltipElement focus={true} value={<>Отменить заказ</>}>
										<MaterialIcon
											onClick={handleCancel}
											className=" cursor-pointer fs-18"
											name="close"
										/>
									</TooltipElement>
								)}
								{ORDER_STATUS_KEYS.issued.includes(status) && (
									<TooltipElement
										focus={true}
										value={<>Отправить заявку на возврат</>}
									>
										<MaterialIcon
											onClick={handleReturn}
											className=" cursor-pointer fs-18"
											name="close"
										/>
									</TooltipElement>
								)}
							</div>
						</>
					);
				},
			}),
			TableColumn({
				width: 100,
				header: 'Кол-во',
				cell: (rowData: TRowDataOrderContent) => {
					const { quantity } = rowData.row.original;
					return <>{quantity}</>;
				},
			}),
			TableColumn({
				width: 100,
				header: 'Кешбэк',
				cell: (rowData: TRowDataOrderContent) => {
					const { cashbackTotal } = rowData.row.original;
					return (
						<>
							{cashbackTotal &&
								`${new Intl.NumberFormat('ru-RU').format(cashbackTotal)} ₽`}
						</>
					);
				},
			}),
			TableColumn({
				width: 150,
				header: 'Цена товара',
				cell: (rowData: TRowDataOrderContent) => {
					const { cost } = rowData.row.original;
					return (
						<>
							<strong className="fw-bold">
								{cost && `${new Intl.NumberFormat('ru-RU').format(cost)} ₽`}
							</strong>
						</>
					);
				},
			}),
		];

		return (
			<>
				{activeModal === 'cancel' && (
					<ConsentModal
						order_id={activeOrderId}
						show={!!activeModal}
						onHide={() => setActiveModal('')}
						onAccept={async () => {
							await cancelOrder({ order_id: activeOrderId });
							update();
						}}
						title="Подтвердите отмену"
						description="Вы действительно хотите отменить заказ? Возобновить работу по заказу
							будет невозможно."
					/>
				)}
				{activeModal === 'return' && (
					<ConsentModal
						order_id={activeOrderId}
						show={!!activeModal}
						onHide={() => setActiveModal('')}
						onAccept={async () => {
							await returnOrder({ order_id: activeOrderId });
							update();
						}}
						title="Подтвердите возврат заказа"
						description="Вы действительно хотите отправить заказ на возврат? Возобновить работу по заказу
							будет невозможно."
					/>
				)}
				<Card className="mb-3">
					<Card.Body>
						<div className="table-responsive fs--1">
							<TableCustom
								isLoading={false}
								data={data}
								itemsPerPage={100}
								columns={columns}
								selection={false}
								pagination={false}
								contained={false}
								header={<></>}
								tableMinHeight={'100px'}
								footerClassName="p-0"
								footer={
									<Row className="g-0 justify-content-end">
										<Col xs="auto">
											<Table borderless size="sm" className="fs--1 text-end">
												<tbody>
													<tr>
														<th className="text-900">Сумма:</th>
														<td className="fw-semi-bold">
															{`${new Intl.NumberFormat('ru-RU').format(
																total.cost
															)} ₽`}
														</td>
													</tr>
													<tr>
														<th className="text-900">Кэшбек:</th>
														<td className="fw-semi-bold">
															{`${new Intl.NumberFormat('ru-RU').format(
																total.cashback
															)} ₽`}
														</td>
													</tr>
												</tbody>
											</Table>
										</Col>
									</Row>
								}
							/>
						</div>
					</Card.Body>
				</Card>
			</>
		);
	}
);
