import { Button, Form } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { AddressSuggections } from 'components/common/AddressSuggections';
import { useAuthStore } from 'providers/RootStoreProvider';

import 'yup-phone';
import 'styles/Registration.scss';

const ValidationSchema = Yup.object().shape({
	companyName: Yup.string().required('Поле обязательно к заполнению'),
	address: Yup.string().required('Поле обязательно к заполнению'),
	name: Yup.string().required('Поле обязательно к заполнению'),
	email: Yup.string()
		.email('Не верный формат email')
		.required('Поле обязательно к заполнению'),
	phone: Yup.string()
		.phone('ru', true, 'Не верный формат номера телефона. +7 (999) 999 99-99')
		.required('Поле обязательно к заполнению'),
	isAccepted: Yup.bool()
		.oneOf([true], 'Условия должны быть приняты')
		.required(''),
});

export const RegistrationForm = observer(() => {
	const navigate = useNavigate();
	const { signUp } = useAuthStore();

	return (
		<Formik
			isInitialValid={true}
			initialValues={{
				companyName: '',
				address: '',
				name: '',
				email: '',
				phone: '',
				isAccepted: false,
			}}
			enableReinitialize
			validationSchema={ValidationSchema}
			onSubmit={async ({ companyName, address, name, email, phone }) => {
				const res = await signUp({
					companyName,
					address,
					name,
					email,
					phone,
				});
				if (res) navigate('/authentication/success-sign-up');
			}}
		>
			{({
				values,
				errors,
				handleChange,
				handleSubmit,
				isValid,
				setFieldValue,
				touched,
				handleBlur,
			}) => (
				<Form className="pt-2" onSubmit={handleSubmit}>
					<Form.Group className="signup-field">
						<Form.Control
							placeholder={'Организация'}
							value={values.companyName}
							name="companyName"
							required
							onBlur={handleBlur}
							onChange={handleChange}
							type="text"
						/>
						{touched.companyName && (
							<Form.Text muted>{errors.companyName}</Form.Text>
						)}
					</Form.Group>
					<Form.Group className="signup-field">
						<AddressSuggections
							initValue={values.address}
							onBlur={handleBlur}
							placeholder={'Адрес'}
							setValue={({ name }) => setFieldValue('address', name)}
						/>

						{touched.address && <Form.Text muted>{errors.address}</Form.Text>}
					</Form.Group>
					<Form.Group className="signup-field">
						<Form.Control
							placeholder={'ФИО'}
							value={values.name}
							name="name"
							required
							onBlur={handleBlur}
							onChange={handleChange}
							type="text"
						/>
						{touched.name && <Form.Text muted>{errors.name}</Form.Text>}
					</Form.Group>
					<Form.Group className="signup-field">
						<Form.Control
							placeholder={'Email'}
							value={values.email}
							name="email"
							onBlur={handleBlur}
							onChange={handleChange}
							type="text"
						/>
						{touched.email && <Form.Text muted>{errors.email}</Form.Text>}
					</Form.Group>
					<Form.Group className="signup-field">
						<InputMask
							mask="+7 (999) 999 99-99"
							value={values.phone}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							{(inputProps) => (
								<Form.Control
									type="text"
									placeholder="Телефон"
									name="phone"
									{...inputProps}
								/>
							)}
						</InputMask>
						{touched.phone && <Form.Text muted>{errors.phone}</Form.Text>}
					</Form.Group>
					<Form.Group className="mb-3 mt-3">
						<Form.Check
							type="checkbox"
							id="acceptCheckbox"
							className="form-check"
						>
							<Form.Check.Input
								type="checkbox"
								name="isAccepted"
								checked={values.isAccepted}
								onChange={(e: { target: { name: string; checked: boolean } }) =>
									setFieldValue(e.target.name, e.target.checked)
								}
							/>
							<Form.Check.Label className="form-label">
								Я принимаю условия{' '}
								<a
									target="_blank"
									href="https://help.gettzap.ru/legal/conditions-of-use/"
									rel="noreferrer"
								>
									Пользовательского соглашения
								</a>{' '}
								и даю своё согласие «Gettzap» на обработку моей персональной
								информации на условиях, определенных{' '}
								<a
									target="_blank"
									href="https://help.gettzap.ru/legal/privacy-notice/"
									rel="noreferrer"
								>
									Политикой конфиденциальности
								</a>
							</Form.Check.Label>
						</Form.Check>
						<Form.Text muted>{errors.isAccepted}</Form.Text>
					</Form.Group>
					<Form.Group className="mb-4">
						<Button className="w-100" type="submit" disabled={!isValid}>
							Зарегистрироваться
						</Button>
					</Form.Group>
				</Form>
			)}
		</Formik>
	);
});
