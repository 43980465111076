import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown } from 'react-bootstrap';
import dayjs from 'dayjs';
import { OverflowedTextTooltip } from 'components/common/OverflowedTextTooltip';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ConsentModal } from 'components/modals/ConsentModal';
import { ReviewModal } from 'components/modals/ReviewModal';
import CardDropdown from 'components/common/CardDropdown';
import { TReview } from 'types/review';
import { reviewsWord } from 'utils/product';
import { TOrderOld } from 'types/order';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { useReviewStore, useShopStore } from 'providers/RootStoreProvider';

type TRowData = {
	row: { original: TOrderOld };
};

export const ReviewsTable = observer(() => {
	const {
		isLoading,
		reviews,
		init,
		totalItems,
		itemsPerPage,
		setPage,
		editReview,
		deleteReview,
		page,
	} = useReviewStore();
	const { activateShop } = useShopStore();
	const [activeModal, setActiveModal] = useState<'review' | 'cancel' | ''>('');
	const [activeOrderId, setActiveOrderId] = useState(null);
	const [activeReview, setActiveReview] = useState<TReview | null>(null);

	const handleCloseModal = useCallback(() => {
		setActiveOrderId(null);
		setActiveModal('');
	}, []);

	const columns = [
		TableColumn({
			header: 'Дата',
			width: 125,
			cellClassName: 'py-2 ',
			cell: (rowData: TRowData) => {
				const { created_at } = rowData.row.original;
				return (
					<>
						<div>{dayjs(created_at).format('YYYY-MM-DD')}</div>
						<div>{dayjs(created_at).format('HH:MM')}</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Заказ',
			width: 100,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { id } = rowData.row.original;

				return (
					<>
						<div className="d-flex justify-content-start align-items-start flex-row">
							<Link to={`/order/${id}`}>
								<strong className="cursor-pointer  me-1">№ {id}</strong>
							</Link>
						</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Магазин',
			width: 250,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { office, shop } = rowData.row.original;

				const onShopName = () => activateShop(office.id);

				return (
					<div className="d-flex justify-content-start align-items-start flex-column">
						<OverflowedTextTooltip
							text={shop.name}
							value={
								<span
									onClick={onShopName}
									className="cursor-pointer text-primary hover"
								>
									{shop.name}
								</span>
							}
						/>
						Рейтинг: {office.rating_value && office.rating_value.toFixed(1)} (
						{office.rating_count} {reviewsWord(office.rating_count)})
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Отзыв',
			width: 350,
			cellClassName: 'py-2 pe-2',
			cell: (rowData: TRowData) => {
				const { user_review } = rowData.row.original;

				return (
					<p className="mb-1 fs--1">
						<OverflowedTextTooltip focus text={user_review.message} />
					</p>
				);
			},
		}),
		TableColumn({
			header: 'Моя оценка',
			width: 100,
			cellClassName: 'py-2',
			cell: (rowData: TRowData) => {
				const { user_review } = rowData.row.original;

				return (
					<>
						{/* @ts-ignore */}
						<Rating
							readonly
							fractions={1}
							initialRating={user_review.rating}
							fullSymbol={
								<FontAwesomeIcon icon="star" className="text-warning" />
							}
							emptySymbol={<FontAwesomeIcon icon="star" className="text-300" />}
							direction={'ltr'}
						/>
					</>
				);
			},
		}),
		TableColumn({
			cellClassName: 'py-2 ps-4 text-end',
			cell: (rowData: TRowData) => {
				const { id, user_review } = rowData.row.original;

				const onReview = (order_id: number) => {
					setActiveOrderId(order_id);
					setActiveReview(user_review);
					setActiveModal('review');
				};
				const onCancel = (order_id: number) => {
					setActiveReview(user_review);
					setActiveOrderId(order_id);
					setActiveModal('cancel');
				};

				return (
					<div className="py-1">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item onClick={() => onReview(id)}>
									Редактировать
								</Dropdown.Item>
								<Dropdown.Item
									className="text-danger"
									onClick={() => onCancel(id)}
								>
									Удалить
								</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
				);
			},
		}),
	];

	useEffect(() => {
		init();
	}, []);

	return (
		<>
			{activeModal === 'review' && (
				<ReviewModal
					show={!!activeModal}
					onHide={handleCloseModal}
					sendReview={(payload) => editReview(activeReview.id, payload)}
					message={activeReview.message}
					rating={activeReview.rating}
				/>
			)}
			{activeModal === 'cancel' && (
				<ConsentModal
					order_id={activeOrderId}
					show={!!activeModal}
					onHide={() => setActiveModal('')}
					onAccept={() => deleteReview(activeReview.id)}
					title="Подтвердите удаление"
					description="Вы точно хотите удалить отзыв? Отменить действие будет невозможно."
				/>
			)}
			<Table
				isLoading={isLoading}
				data={reviews}
				totalItems={totalItems}
				itemsPerPage={itemsPerPage}
				setPage={setPage}
				page={page}
				columns={columns}
				title="Отзывы"
				contained={true}
			/>
		</>
	);
});
