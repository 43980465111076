import { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Card } from 'react-bootstrap';
import { TooltipElement } from 'components/common/TooltipElement';
import CircularProgress from '@mui/material/CircularProgress';
import { useCatalogStore } from 'providers/RootStoreProvider';

export const CatalogSearchByVin = observer(() => {
	const { getSearchByVin, getSearchByStateNumber, SearchByVin } =
		useCatalogStore();
	const [searched, setSearched] = useState(false);
	const [searchByVin, setSearchByVin] = useState('');
	const [searchStateNumber, setSearchStateNumber] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSearch = async () => {
		setIsLoading(true);
		if (!searchStateNumber && searchByVin) {
			await getSearchByVin({ ident_string: searchByVin });
		} else {
			const vin = await getSearchByStateNumber({
				number: searchStateNumber,
			});
			await getSearchByVin({ ident_string: vin });
		}
		setIsLoading(false);
		setSearched(true);
	};

	const openInNewTab = useCallback((url: string) => {
		window.open(url, '_blank', 'noreferrer');
	}, []);

	return (
		<>
			<Card className="catalog-search-by-vin">
				<Card.Header>Поиск по VIN</Card.Header>
				<hr className="m-0" />
				<Card.Body>
					<div className="d-flex w-100 align-items-center flex-column gap-2">
						<TooltipElement
							className="w-100"
							value={
								<>
									Используйте только русские символы. <br /> Например: А008АА197
								</>
							}
						>
							<Form.Control
								placeholder="Введите госномер"
								className="w-100"
								value={searchStateNumber}
								onChange={(e) => {
									setSearched(false);
									setIsLoading(false);
									setSearchStateNumber(e.target.value.toUpperCase());
									setSearchByVin('');
								}}
							/>
						</TooltipElement>
						<div className="fs-14">или</div>
						<Form.Control
							placeholder="Введите VIN"
							value={searchByVin}
							onChange={(e) => {
								setSearched(false);
								setIsLoading(false);
								setSearchByVin(e.target.value);
								setSearchStateNumber('');
							}}
						/>
						<Button
							className="w-100 mt-3 mb-3"
							variant="primary"
							onClick={handleSearch}
						>
							Поиск
						</Button>
					</div>
					{!searched && (
						<div className="my-2 fs--2 text-left">
							Мы определим VIN по госномеру. VIN нам нужен для точного и
							безошибочного определения модификации вашего автомобиля в
							оригинальном каталоге запчастей.
						</div>
					)}

					<hr />

					{isLoading ? (
						<div className="p-5 d-flex justify-content-center align-items-center">
							<CircularProgress size={60} />
						</div>
					) : (
						!!SearchByVin.length &&
						SearchByVin.map((item) => {
							return (
								<>
									<Card
										className="p-3 mb-3"
										key={`searchVin_${item.name}_${item.vehicleid}`}
									>
										<div>
											{item.brand} {item.name}
										</div>
									</Card>
									<Button
										className="w-100"
										variant="success"
										onClick={() =>
											openInNewTab(`/catalog-model/quicklist/${item.brand}/${item.catalog}/${item.ssd}/${item.vehicleid}/0
                                            `)
										}
									>
										Перейти в каталог
									</Button>
								</>
							);
						})
					)}
					{!SearchByVin.length && searched && (
						<div className="fw-bold">
							Автомобиль по VIN номеру не найден. Проверьте правильность
							введённого номера или воспользуйтесь{' '}
							<a href="/catalog">поиском по модели</a>
						</div>
					)}
				</Card.Body>
			</Card>
		</>
	);
});
