import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NavbarTop } from 'components/navbar/top/NavbarTop';
import { NavbarVertical } from 'components/navbar/vertical/NavbarVertical';
import { Modals } from 'components/modals/Modals';
import { observer } from 'mobx-react-lite';
import { OffcanvasContainer } from 'components/offcanvas/Offcanvas';
import { useAuthStore } from 'providers/RootStoreProvider';

import 'styles/Main.scss';

export const AppLayout = observer(() => {
	const { isAuthorized } = useAuthStore();
	const { hash, pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => {
			if (hash) {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({ block: 'start', behavior: 'smooth' });
				}
			}
		}, 0);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className={'main-container'}>
			<NavbarTop />
			<div className="main-content-container">
				{isAuthorized && <NavbarVertical />}
				<div className="main-content">
					<Outlet />
				</div>
			</div>
			<OffcanvasContainer />
			<Modals />
		</div>
	);
});
