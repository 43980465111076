import { makeAutoObservable } from 'mobx';
import { fetchCars, postCar, patchCar, deleteCar, fetchCar } from 'api/car/car';
import { TCar, TCarPayload } from 'types/car';
import { toast } from 'react-toastify';
import { RootStore } from 'stores/RootStore';
// eslint-disable-next-line
// type AnyFunction = (...args: any[]) => any;

export class CarStore {
	isLoading = true;
	cars: TCar[] = [];
	total = 0;
	itemsPerPage = 10;
	page = 1;
	activeCar: TCar = null;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	// loadingWrapper = <Func extends AnyFunction>(
	// 	fn: Func
	// ): ((...args: Parameters<Func>) => Promise<Awaited<ReturnType<Func>>>) => {
	// 	const wrappedFn = async (
	// 		...args: Parameters<Func>
	// 	): Promise<Awaited<ReturnType<Func>>> => {
	// 		this.isLoading = true;
	// 		const res = await fn(...args);
	// 		this.isLoading = false;
	// 		return res;
	// 	};
	// 	return wrappedFn;
	// };

	setPage = (page: number) => {
		this.page = page;
		this.init();
	};

	getCarById = async (id: number) => {
		const res = await fetchCar(id);
		if (res === null) return;
		return res;
	};

	addToCars = async (payload: TCarPayload): Promise<TCar> => {
		const res = await postCar(payload);
		if (res === null) return;
		await this.init();
		return res;
	};

	updateNote = async (id: number, note: string) => {
		const index = this.cars.findIndex((i) => i.id === id);
		this.cars[index].note = note;
		return this.updateCar(id, this.cars[index]);
	};

	removeCar = async (id: number): Promise<void> => {
		const res = await deleteCar(id);
		if (res === null) return;

		const index = this.cars.findIndex((i) => i.id === id);

		this.cars.splice(index, 1);

		toast.success('Автомобиль успешно удален', {
			theme: 'colored',
		});

		return res;
	};

	updateCar = async (id: number, payload: TCarPayload): Promise<TCar> => {
		const res = await patchCar(id, payload);
		if (res === null) return;

		const index = this.cars.findIndex((i) => i.id === id);
		this.cars[index] = res;

		if (this.activeCar.id === this.cars[index].id) {
			this.activeCar = this.cars[index];
		}

		return res;
	};

	setActiveCar = async (id: number) => {
		this.activeCar = await this.getCarById(id);
	};

	init = async () => {
		const res = await fetchCars({
			per_page: this.itemsPerPage,
			page: this.page,
		});

		if (res === null) return;

		const {
			items,
			meta: { count },
		} = res;

		this.cars = items;
		this.total = count;
		this.isLoading = false;
	};
}
