import { makeAutoObservable } from 'mobx';
import { TShopFavorite } from 'types/shop';
import {
	postFavoriteShop,
	fetchFavoriteShops,
	deleteFavoriteShop,
} from 'api/shop/shop';
import { isEmpty } from 'lodash';
import { RootStore } from 'stores/RootStore';

export class FavoriteShopStore {
	isLoading = true;
	favoriteShops: TShopFavorite[] = [];
	itemsPerPage = 25;
	totalItems = 0;
	page = 1;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	init = async () => {
		await this.getFavoriteShops();
	};

	setPage = (page: number) => {
		this.page = page;
		// this.getFavoriteShops();
	};

	isInFavorite = (officeId: number) => {
		return !!this.favoriteShops.find((i) => i.id === officeId);
	};

	addFavoriteShop = async (officeId: number) => {
		const res = await postFavoriteShop({ officeId });
		if (res === null) {
			return false;
		}

		this.favoriteShops.push(res);
		return true;
	};

	getFavoriteShops = async () => {
		this.isLoading = true;

		const res = await fetchFavoriteShops();
		if (res === null) {
			this.isLoading = false;
			return;
		}

		this.favoriteShops = !isEmpty(res) ? res : [];

		this.isLoading = false;
	};

	deleteFavoriteShop = async (id: number) => {
		const res = await deleteFavoriteShop(id);
		if (res === null) return false;

		this.favoriteShops = this.favoriteShops.filter((i) => i.id !== id);
		return true;
	};
}
