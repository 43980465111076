import React from 'react';
import Flex from 'components/common/Flex';
import { observer } from 'mobx-react';
import { ProgressBar } from 'react-bootstrap';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TShopPassport } from 'types/shop';

type TProps = {
	shopPassport: TShopPassport;
};

const numbers = {
	one: '1',
	two: '2',
	three: '3',
	four: '4',
	five: '5',
};

export const ShopRating = observer(
	({
		shopPassport: { rating_details, rating_count, rating_value },
	}: TProps) => {
		return (
			<>
				<hr className="my-4" />
				<h5 className="mb-3">Средняя оценка</h5>
				<div className="rating-content">
					<Flex
						style={{ width: '100%' }}
						direction="row"
						alignItems="start"
						justifyContent="start"
					>
						<div className="rating-bars">
							{Object.keys(rating_details)
								.reverse()
								.map((key) => {
									const value = rating_details[key];
									const percent = (value / rating_count) * 100;

									return (
										<Flex
											key={`rating_${key}`}
											style={{ width: '100%' }}
											direction="row"
											alignItems="center"
											justifyContent="between"
										>
											<div className="pe-3">{numbers[key]}</div>
											<ProgressBar now={percent} />
										</Flex>
									);
								})}
						</div>
						<div className="rating-info p-3 ps-4 pe-0">
							<h1>{rating_value.toFixed(1)}</h1>
							<div className="rating">
								<Flex
									style={{ width: '100%' }}
									direction="column"
									alignItems="start"
									justifyContent="start"
								>
									{/* @ts-ignore */}
									<Rating
										readonly
										fractions={2}
										initialRating={rating_value}
										fullSymbol={
											<FontAwesomeIcon icon="star" className="text-warning" />
										}
										emptySymbol={
											<FontAwesomeIcon icon="star" className="text-300" />
										}
										direction={'ltr'}
									/>
									<div className="fs-12">{rating_count} отзыва</div>
								</Flex>
							</div>
						</div>
					</Flex>
				</div>
			</>
		);
	}
);
