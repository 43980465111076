import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';
import { FilterButton } from 'components/common/filter/FilterButton';
import { FilterInput } from '../../product/product-list/comp/table-header/comp/FilterInput';

export const TyreFilterQuantity = observer(
	({
		update,
		textButton = '',
		disabled,
		isLabeled = true,
		defaultValue = 0,
		value = undefined,
		containerClassName = '',
		labelFirst = 'от',
		labelSecond = 'шт.',
		children = undefined,
		...rest
	}) => {
		const [quantity, setQuantity] = useState(null);

		const handleUpdate = () => update(quantity);

		const handleUpdateDebounce = useDebouncedCallback(
			(value) => update(value),
			500
		);

		const onFocus = () => {
			if (quantity === null) setQuantity(defaultValue);
		};

		useEffect(() => {
			setQuantity(defaultValue);
		}, [defaultValue]);

		return (
			<>
				{textButton && (
					<FilterButton
						disabled={disabled}
						onClick={() => setQuantity(0)}
						isActive={!quantity}
					>
						{textButton}
					</FilterButton>
				)}
				<div
					className={`filter-input fs-14 d-flex justify-content-between align-items-center  ${
						quantity ? 'active' : ''
					} ${containerClassName}`}
				>
					{isLabeled && <span className="mx-2">{labelFirst}</span>}
					<FilterInput
						onFocus={onFocus}
						onBlur={handleUpdate}
						value={value !== undefined ? value : quantity}
						onChange={(value, isArrowClicked) => {
							setQuantity(value);
							if (isArrowClicked) handleUpdateDebounce(value);
						}}
						placeholder={defaultValue}
						disabled={disabled}
						min={0}
						className={!textButton ? 'full-border' : ''}
						{...rest}
					>
						{children}
					</FilterInput>

					{isLabeled && <span className="mx-2">{labelSecond}</span>}
				</div>
			</>
		);
	}
);
