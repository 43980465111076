import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TMultiSearchAnalogs } from 'types/product';
import { Form } from 'react-bootstrap';
import { offersWord } from 'utils/product';
import { SortSelect } from 'components/common/SortSelect';
import {
	useAuthStore,
	useMultiSearchStore,
	useProductStore,
} from 'providers/RootStoreProvider';
import { ImageModal } from 'components/product/ImageModal';

type TProps = {
	data: { uid?: string; brand: string; code: string; officeSpecified: boolean };
	close: (uid: string) => void;
};

type TRowData = {
	row: { original: TMultiSearchAnalogs };
};

type TSort = 'count-DESC' | 'b2bCostCash-ASC';

export const AnalogsModal = observer(({ data, close }: TProps) => {
	const [showImage, setShowImage] = useState([]);
	const { user } = useAuthStore();
	const { searchItems } = useMultiSearchStore();
	const { getMultisearchAnalogs, setSearchAnalogs } = useProductStore();
	const [isLoading, setIsLoading] = useState(true);
	const [sort, setSort] = useState<TSort>('count-DESC');
	const [filter, setFilter] = useState('partners');

	const [list, setList] = useState([]);
	const [search, setSearch] = useState('');
	const [initialSource, setIntialSource] = useState([]);

	const handleSearch = useCallback(
		(value: string) => {
			setSearch(value);
			const sourceFiltered = initialSource.filter(
				(i) =>
					!i.brand.toLowerCase().indexOf(value.toLowerCase()) ||
					!i.name.toLowerCase().indexOf(value.toLowerCase())
			);
			setList(value ? sourceFiltered : initialSource);
		},
		[initialSource]
	);

	useEffect(() => {
		const init = async () => {
			setIsLoading(true);
			const { uid, officeSpecified } = data;
			const filters = {};

			if (filter === 'all') {
				filters['partner'] = false;
			}

			if (filter === 'partners') {
				filters['partner'] = true;
			}

			if (filter === 'partners-instock') {
				filters['partner'] = true;
				filters['delivery_time_type'] = 1;
			}

			const res = await getMultisearchAnalogs(
				uid,
				sort,
				filters,
				officeSpecified
			);

			if (res === null) return;
			setList(res);
			setIntialSource(res);
			setIsLoading(false);
		};

		init();
	}, [sort, filter]);

	const columns = [
		TableColumn({
			header: 'Наименование товара',
			width: 250,
			cellClassName: 'py-2 pe-3',
			cell: (rowData: TRowData) => {
				const { name, brand, code, imageUrl } = rowData.row.original;

				const images = imageUrl.split(',');

				console.log({ imageUrl });

				return (
					<>
						{showImage && (
							<ImageModal
								title={`${brand} (${code}) ${name}`}
								images={images}
								show={showImage.length > 0}
								onHide={() => setShowImage([])}
							/>
						)}
						<div>
							{imageUrl && (
								<div
									onClick={() => setShowImage(images)}
									style={{ backgroundImage: `url(${images[0]})` }}
									className="product-single-image"
								/>
							)}
							<div>
								<strong>
									{brand} ({code})
								</strong>
							</div>
							<div>{name}</div>
						</div>
					</>
				);
			},
		}),
		TableColumn({
			header: 'Цена, от',
			width: 50,
			cellClassName: 'py-2',
			headerClassName: 'text-center',
			cell: (rowData: TRowData) => {
				const { minCost } = rowData.row.original;
				return (
					<div className=" text-center w-100">{`${new Intl.NumberFormat(
						'ru-RU'
					).format(minCost)} ₽`}</div>
				);
			},
		}),
		TableColumn({
			header: 'Кол-во предложений',
			width: 50,
			cellClassName: 'py-2',
			headerClassName: 'text-center',
			cell: (rowData: TRowData) => {
				const { count } = rowData.row.original;
				return (
					<div className="w-100 text-center">
						{count} {offersWord(parseInt(count))}
					</div>
				);
			},
		}),
		TableColumn({
			cellClassName: 'py-2',
			width: 50,
			cellStyle: {
				alignItems: 'flex-end',
			},
			cell: (rowData: TRowData) => {
				const { brand, code, uid } = rowData.row.original;

				const active = searchItems.find((item) => {
					return item.uidAnalog && item.uidAnalog.uid === uid;
				});

				const handleSelect = () => {
					setSearchAnalogs(data.uid, active ? null : { brand, code, uid });
					close(data.uid);
				};

				return (
					<>
						<Button
							size="sm"
							variant={'outline-primary'}
							onClick={handleSelect}
							active={!!active}
						>
							{active ? 'Сбросить' : 'Выбрать'}
						</Button>
					</>
				);
			},
		}),
	];

	return (
		<>
			<div className="analogs-modal position-relative">
				<Modal.Header closeButton className="modal-header-container">
					<Modal.Title className="modal-title">
						Аналоги для {data.brand} {data.code}
					</Modal.Title>
					<div className="modal-header-filter d-flex flex-row justify-content-between align-items-center flex-row">
						<Form.Group
							style={{ height: '35px', width: '70%' }}
							className="pe-3"
						>
							<Form.Control
								disabled={isLoading}
								placeholder={'Поиск по брендам'}
								value={search}
								name="search"
								onChange={({ target: { value } }) => handleSearch(value)}
								type="text"
							/>
						</Form.Group>

						{user && user.partner && (
							<SortSelect
								style={{ height: '35px', width: '50%' }}
								value={filter}
								className="pe-3 form-select-container me-3"
								sort={[
									{
										value: 'partners',
										label: 'Только поставщики',
									},
									{
										value: 'partners-instock',
										label: 'Только поставщики (в наличии)',
									},
								]}
								onChange={({ value }) => setFilter(value)}
							/>
						)}
						<SortSelect
							style={{ height: '35px', width: '35%' }}
							className="form-select-container"
							sort={[
								{
									value: 'b2bCostCash-ASC',
									label: 'По цене',
								},
								{
									value: 'count-DESC',
									label: 'По предложениям',
								},
							]}
							onChange={({ value }) => setSort(value as TSort)}
						/>
					</div>
				</Modal.Header>
				<Modal.Body className="background-pale-color">
					<Table
						isLoading={isLoading}
						data={list}
						columns={columns}
						showHeader={false}
						pagination={false}
						tableMinHeight="580px"
						loadingTableHeight="580px"
					/>
				</Modal.Body>
			</div>
		</>
	);
});
