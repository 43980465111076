import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Table, Button } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { useProductStore } from 'providers/RootStoreProvider';

export const PriceListModal = observer(() => {
	const { companyPriceList, getCompanyPriceList } = useProductStore();
	useEffect(() => {
		getCompanyPriceList();
	}, []);

	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Онлайн поставщики
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table>
					<thead className="bg-200">
						<tr>
							<th className="py-2 fs--1">Компания</th>
							<th className="py-2 fs--1 w-25">Наименование</th>
							<th className="py-2 fs--1">Наличие</th>
							<th className="py-2 fs--1">Стоимость</th>
							<th className="py-2 fs--1">Сайт поставщика</th>
						</tr>
					</thead>
					<tbody>
						{companyPriceList.map((item, i) => (
							<tr key={`${i}_${item.code}_${item.name}`}>
								<td>
									<img
										style={{ maxWidth: '100%' }}
										src={`https://storage.yandexcloud.net/gettzap/vendors/${item.office.id}.jpg`}
										alt=""
									/>
								</td>
								<td>{item.name}</td>
								<td>
									<SoftBadge
										pill
										bg={classNames({
											primary: item.stock > 0,
											// warning: item.stock <= 5,
											// secondary: item.stock <= 1,
										})}
										className="p-2"
									>
										<strong>0-5 часов ({item.stock} шт.)</strong>
									</SoftBadge>
								</td>
								<td>{item.cost} ₽</td>
								<td>
									<Button
										href={`${item.account}`}
										rel="noreferrer"
										target="_blank"
									>
										Перейти
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Modal.Body>
		</>
	);
});
