import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'providers/RootStoreProvider';

export const Docs = observer(() => {
	const { history } = useAppStore();
	const { doc } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (doc === 'oferta') {
			window.open(
				'https://storage.yandexcloud.net/gettzap/Gettzap_B2B_Oferta.pdf',
				'_blank'
			);
		}
		navigate(history[0]);
	}, []);

	return <></>;
});
