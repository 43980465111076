import { useEffect, useCallback, useMemo } from 'react';
import { Navbar } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { NavbarVerticalMenuList } from './NavbarVerticalMenuList';
import { useAppStore } from 'providers/RootStoreProvider';

export const NavbarVerticalMenuContainer = observer(() => {
	const { isMobile, isTablet, isMenuOpen, setMenuOpen, onResize } =
		useAppStore();
	const HTMLClassList = useMemo(
		() => document.getElementsByTagName('html')[0].classList,
		[isMenuOpen]
	);
	let time = null;
	const { pathname } = useLocation();

	const handleResize = useDebouncedCallback(
		() => onResize(window.innerWidth),
		200
	);

	const handleMouseEnter = useCallback(() => {
		if (isMenuOpen) return null;
		time = setTimeout(() => {
			HTMLClassList.remove('navbar-vertical-collapsed');
		}, 100);
	}, [time, isMenuOpen, HTMLClassList]);

	const handleMouseLeave = useCallback(() => {
		if (isMenuOpen) return null;
		HTMLClassList.add('navbar-vertical-collapsed');
	}, [time, isMenuOpen, HTMLClassList]);

	useEffect(() => {
		(isTablet || isMobile) && setMenuOpen(false);
	}, [pathname]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	useEffect(() => {
		if (isMenuOpen) {
			HTMLClassList.remove('navbar-vertical-collapsed');
		} else {
			HTMLClassList.add('navbar-vertical-collapsed');
		}
		return () => {
			HTMLClassList.remove('navbar-vertical-collapsed-hover');
		};
	}, [isMenuOpen, HTMLClassList]);

	return (
		<Navbar.Collapse
			in={isTablet ? isMenuOpen : undefined}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<NavbarVerticalMenuList />
		</Navbar.Collapse>
	);
});
