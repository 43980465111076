import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { OrderSummary } from '../checkout/OrderSummary';
import { CartTable } from './CartTable';
import { useCartStore } from 'providers/RootStoreProvider';

export const ShoppingCart = observer(() => {
	const { initCart, officeOrders } = useCartStore();

	useEffect(() => {
		const init = async () => {
			await initCart();
		};
		init();
	}, []);

	return (
		<Row className="g-3">
			<Col lg={8}>
				<CartTable />
			</Col>
			<Col lg={{ span: 4, order: 1 }}>
				<OrderSummary isCart={true} officeOrders={officeOrders} />
			</Col>
		</Row>
	);
});
