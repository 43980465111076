import { makeAutoObservable } from 'mobx';
import { TProduct, TMultiSearchOffices } from 'types/product';
import {
	multiSearchProducts,
	multiSearchOffices,
	fetchMultisearchAnalogs,
	fetchMultiSearchItems,
	postMultiSearchItems,
	deleteMultiSearchItems,
	deleteAllMultiSearchItems,
} from 'api/product/products';
import { TMultiSearchItem } from 'types/search';
import { MULTI_SEARCH_SEARCH_PER_PAGE } from 'consts/products';
import { RootStore } from 'stores/RootStore';

export class MultiSearchStore {
	DEFAULT = {
		isLoading: true,
		searchProducts: {},
		searchOffices: [],
		searchItems: [],
		searchOfficePending: null,
	};

	isLoading = true;
	searchProducts: Record<number, TProduct[]> = {};
	searchOffices: TMultiSearchOffices[] = [];
	searchItems: TMultiSearchItem[] = [];
	searchOfficePending = null;

	reset = () => {
		Object.keys(this.DEFAULT).map((key) => (this[key] = this.DEFAULT[key]));
	};

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	setMultiSearchOfficePending = (id: number | null) => {
		this.searchOfficePending = id;
	};

	getAnalogs = async (
		uid: string,
		sort: string,
		partner: boolean,
		officeSpecified?: boolean
	) => {
		const res = await fetchMultisearchAnalogs(
			this.root.productStore.productSearchPayload(
				officeSpecified ? [] : ['office_id'],
				{
					uid,
					sort,
					partner: partner ? partner : undefined,
				}
			)
		);

		return res;
	};

	addSearchItem = async (searchItem) => {
		if (!searchItem) return null;

		this.searchItems = [...this.searchItems, searchItem];
		await postMultiSearchItems({ uidOriginal: searchItem.uid });

		this.isLoading = true;
	};

	clearSearchItems = async () => {
		this.searchItems = [];
		deleteAllMultiSearchItems();
	};

	removeSearchItem = async (index: number) => {
		const target = this.searchItems[index];

		await deleteMultiSearchItems(target.uid);
		this.searchItems.splice(index, 1);
	};

	getSearchItems = async () => {
		const res = await fetchMultiSearchItems();
		if (res === null) return;

		this.searchItems = res.map((i) => ({
			uidAnalog: i.uidAnalog ? i.uidAnalog : null,
			...i.uidOriginal,
		}));
	};

	updateProducts = async (office_id: number) => {
		this.searchProducts[office_id] = [];
		this.setMultiSearchOfficePending(office_id);

		!this.root.authStore.isAuthLoaded && (await this.root.authStore.initMe());

		await this.getSearchItems();

		const ids = this.searchItems.map((i) => {
			if (i.uidAnalog) return i.uidAnalog.uid;
			return i.uid;
		});

		if (ids.length > 0) {
			if (office_id) this.root.filterStore.setFilter(['office_id'], office_id);

			const res = await multiSearchProducts(
				this.root.productStore.productSearchPayload(['office_id'], {
					per_page: MULTI_SEARCH_SEARCH_PER_PAGE,
				}),
				ids
			);

			if (res === null) {
				return false;
			}

			this.searchProducts[office_id] = res;
			this.setMultiSearchOfficePending(null);
		} else {
			return false;
		}
	};

	updateSearchOffices = async () => {
		this.isLoading = true;

		!this.root.authStore.isAuthLoaded && (await this.root.authStore.initMe());

		await this.getSearchItems();

		const ids = this.searchItems.map((i) => {
			if (i.uidAnalog) return i.uidAnalog.uid;
			return i.uid;
		});

		if (ids.length > 0) {
			const res = await multiSearchOffices(
				this.root.productStore.productSearchPayload(['office_id']),
				ids
			);

			if (res === null) {
				this.isLoading = false;
				return false;
			}

			this.searchOffices = res;
			this.isLoading = false;
		} else {
			this.isLoading = false;
			return false;
		}
	};

	update = async () => {
		this.searchProducts = {};

		await this.updateSearchOffices();
		if (this.searchOffices[0]) {
			await this.updateProducts(this.searchOffices[0].office.id);
		}
	};
}
