import { Nav } from 'react-bootstrap';
import { TooltipElement } from 'components/common/TooltipElement';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
	useAppStore,
	useAuthStore,
	useCoreProductStore,
	useGettzapProductStore,
	usePartnerProductStore,
	useProductStore,
	useSellerProductStore,
} from 'providers/RootStoreProvider';

export const ProductTypeTabs = observer(() => {
	const { total: productsTotal } = useSellerProductStore();
	const { total: gettzapTotal } = useGettzapProductStore();
	const { total: partnerTotal } = usePartnerProductStore();
	const { coreProduct } = useCoreProductStore();
	const { isAuthorized, user } = useAuthStore();
	const { setActiveModal } = useAppStore();
	const { tab, analogsTotal, companyPriceListTotal, uid } = useProductStore();

	return (
		<Nav activeKey={tab} fill variant="pills">
			<Nav.Item className="me-2 my-1">
				<Nav.Link className="fs-14" eventKey="products">
					Запрошенный номер ({productsTotal + partnerTotal + gettzapTotal})
				</Nav.Link>
			</Nav.Item>
			<Nav.Item className="me-2 my-1">
				<TooltipElement
					disabled={isAuthorized}
					focus={true}
					value={
						<>
							Чтобы посмотреть аналоги нужно{' '}
							<Link to={`/authentication/login`}>авторизоваться</Link>
						</>
					}
				>
					<Nav.Link
						disabled={!isAuthorized}
						className="fs-14"
						eventKey="analogs"
						onClick={() =>
							setActiveModal('analogs_single_search', 'xl-size', {
								code: coreProduct.number,
								brand: coreProduct.core_brand.name,
								uid,
							})
						}
					>
						Аналоги ({analogsTotal})
					</Nav.Link>
				</TooltipElement>
			</Nav.Item>
			{(!user || (user && !user.partner)) && (
				<Nav.Item className="me-2 my-1">
					<Nav.Link
						disabled={!isAuthorized}
						className="fs-14"
						onClick={() => setActiveModal('company-price-list', 'xl-size')}
					>
						Поставщики ({companyPriceListTotal})
					</Nav.Link>
				</Nav.Item>
			)}
		</Nav>
	);
});
