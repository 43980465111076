import { axios } from '../axios';
import { b2bApiUrl } from 'api/helpers';
import { TCompany, TCompanyResponse } from 'types/company';

export const featchCompany = (): Promise<TCompanyResponse> =>
	axios.get(b2bApiUrl('bayer/company'));

export const addCompany = (data: TCompany): Promise<TCompanyResponse> =>
	axios.post(b2bApiUrl('bayer/company'), data);

export const deleteCompany = (): Promise<void> =>
	axios.delete(b2bApiUrl('bayer/company'));

export const updateCompany = (data: TCompany): Promise<TCompanyResponse> =>
	axios.patch(b2bApiUrl('bayer/company'), data);
