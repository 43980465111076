import { castError, defaultHeaders, b2bApiUrl } from 'api/helpers';
import { TBilling } from 'types/billing';
import { TDefaultPagination } from 'types/common';
import request from 'helpers/request';
import { TListReponse } from 'types/common';

export const fetchBilling = async (
	query_params: TDefaultPagination
): Promise<TListReponse<TBilling>> => {
	return request(
		fetch(b2bApiUrl(`bayer/billing`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const postBill = async (amount): Promise<TBilling> => {
	return request(
		fetch(b2bApiUrl(`bayer/billing`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: amount,
		}).then(castError)
	);
};

export const downloadBillingPdf = async (id) => {
	return request(
		fetch(b2bApiUrl(`bayer/billing/pdf/${id}`), {
			headers: {
				...defaultHeaders(),
			},
		})
	);
};
