import React from 'react';
import { ForgetPasswordForm } from 'components/authentication/ForgetPasswordForm';

export const ForgetPassword = () => {
  return (
    <div className="text-center">
      <h5 className="mb-0">Восстановление пароля</h5>
      <small>
        Укажите адрес эл. почты, который Вы использовали при регистрации в
        Gettzap. Мы вышлем Вам инструкцию по восстановлению пароля.
      </small>
      <ForgetPasswordForm />
    </div>
  );
};
