import { observer } from 'mobx-react-lite';
import { SortSelect } from 'components/common/SortSelect';
import { TSort } from 'types/search';
import { useProductStore } from 'providers/RootStoreProvider';

export const ProductSorting = observer(() => {
	const { setSort, mode } = useProductStore();
	if (mode === 'map') return null;

	return (
		<div
			style={{ background: '#fff' }}
			className="d-flex ps-3 justify-content-end align-items-center"
		>
			<SortSelect
				style={{ maxWidth: '250px', minWidth: '200px' }}
				sort={[
					{
						value: 'costWithMarkup-ASC',
						label: 'Сначала дешевле',
					},
					{
						value: 'costWithMarkup-DESC',
						label: 'Сначала дороже',
					},
				]}
				onChange={({ value }) => setSort(value as TSort)}
			/>
		</div>
	);
});
