import { useState, useRef, memo } from 'react';
import AsyncSelect from 'react-select/async';
import { observer } from 'mobx-react';
import { TOption } from 'types/tyre';
import { components } from 'react-select';

export const TyreAggregation = observer(
	({ setValue, label, get, value, ...rest }) => {
		const [defaultOptions, setDefaultOptions] = useState([]);
		const inputRef = useRef(null);

		const search = async (): Promise<TOption[]> => {
			const suggestions = await get();
			setDefaultOptions(suggestions);
			// @ts-ignore
			return suggestions;
		};

		const Option = memo((props: { data: { label: string; value: string } }) => {
			return (
				<>
					{/* @ts-ignore */}
					<components.Option
						isSelected={props?.data?.label === inputRef.current.value}
						{...props}
					>
						<div className="cursor-pointer" style={{ color: '#111' }}>
							{props?.data?.label}
						</div>
					</components.Option>
				</>
			);
		});

		return (
			<div className="mb-3">
				<h5 className="fs-14 mb-2">{label}</h5>
				<AsyncSelect
					noOptionsMessage={() => <>...</>}
					loadingMessage={() => <>Загрузка...</>}
					defaultOptions={defaultOptions}
					value={value}
					placeholder={'Все'}
					isClearable
					classNamePrefix="react-select"
					className="cursor-pointer"
					loadOptions={search}
					isSearchable={false}
					components={{
						Option,
					}}
					onChange={(data) => {
						if (inputRef.current.value !== data?.label) {
							setValue(data);
							inputRef.current.value = data?.label ?? '';
						}
						inputRef.current.blur();
						inputRef.current.focus();
					}}
					ref={inputRef}
					onMenuOpen={search}
					{...rest}
				/>
			</div>
		);
	}
);
