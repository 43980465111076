import { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Pagination } from 'components/common/Pagination';
import 'styles/Car.scss';

type TProps = {
	children: React.ReactNode;
	totalItems: number;
	itemsPerPage: number;
	page: number;
	setPage: (page: number) => void;
};

export const PaginationWrapper = observer(
	({ children, totalItems, itemsPerPage, page, setPage }: TProps) => {
		const totalPages = Math.ceil(totalItems / itemsPerPage);
		const wrapperRef = useRef(null);

		const onChangePage = useCallback(
			(page: number) => {
				wrapperRef.current.scrollIntoView();
				setPage(page);
			},
			[wrapperRef]
		);

		return (
			<div ref={wrapperRef} className="pagionation-wrapper">
				<div className="pagination-wrapper-container">{children}</div>
				{totalPages > 1 && (
					<Pagination
						totalPages={totalPages}
						activePage={page}
						setActivePage={onChangePage}
					/>
				)}
			</div>
		);
	}
);
