import { observer } from 'mobx-react';
import CircularProgress from '@mui/material/CircularProgress';

type TProps = {
	children: React.ReactNode;
	isLoading: boolean;
	size?: number;
	className?: string;
	placeholder?: React.ReactNode;
};

export const LoadingWrapper = observer(
	({ isLoading, children, size = 60, className }: TProps) => {
		return (
			<>
				{isLoading ? (
					<div
						className={`d-flex justify-content-center align-items-center ${className}`}
					>
						<CircularProgress size={size} />
					</div>
				) : (
					<>{children}</>
				)}
			</>
		);
	}
);
