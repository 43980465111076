import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Table } from 'components/common/Table';
import { TableColumn } from 'helpers/TableColumn';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TListDetailByUnit } from 'types/catalog';
import { TooltipElement } from 'components/common/TooltipElement';
import { useCatalogStore } from 'providers/RootStoreProvider';

type TProps = {
	hovered: TListDetailByUnit[];
	onHover: (item: TListDetailByUnit) => void;
	listDetailByUnit: TListDetailByUnit[];
};

type TRowData = {
	row: { original: TListDetailByUnit };
};

export const ListDetailByUnitTable = observer(
	({ hovered, onHover, listDetailByUnit }: TProps) => {
		const { getUid } = useCatalogStore();
		const { brand } = useParams();
		const isActive = useCallback(
			(codeonimage: string) =>
				hovered.length && codeonimage === hovered[0].codeonimage,
			[hovered]
		);

		const columns = [
			TableColumn({
				header: 'OEM',
				width: 100,
				cellClassName: 'py-2',
				cell: (rowData: TRowData) => {
					const { oem, codeonimage } = rowData.row.original;

					const hanleClick = async () => {
						const uid = await getUid(oem, brand);
						window.open(`/products/${uid}`, '_blank').focus();
					};

					return (
						<>
							<div
								onClick={hanleClick}
								className={`list-detail-unit-table-cell ${
									isActive(codeonimage) ? 'active' : ''
								} link-type-text`}
							>
								{oem}
							</div>
						</>
					);
				},
			}),
			TableColumn({
				header: 'Наименование детали',
				width: 200,
				cellClassName: 'py-2',
				cell: (rowData: TRowData) => {
					const { name, codeonimage } = rowData.row.original;

					return (
						<div
							className={`list-detail-unit-table-cell ${
								isActive(codeonimage) ? 'active' : ''
							}`}
						>
							{name}
						</div>
					);
				},
			}),
			TableColumn({
				width: 50,
				cellClassName: 'py-2',
				cell: (rowData: TRowData) => {
					const { attributes } = rowData.row.original;

					return (
						<TooltipElement
							focus={true}
							placement="bottom"
							value={
								<div className="item-attribute-container">
									{attributes.map((attr) => (
										<div
											key={attr.name}
											className="item-attribute-content pt-1 pb-1"
										>
											<div className="label pe-4">
												<strong>{attr.name}:</strong>
											</div>
											<div className="text-value">{attr.value}</div>
										</div>
									))}
								</div>
							}
						>
							<div className="cursor-pointer d-flex h-100 justify-content-center align-items-center">
								<MaterialIcon className={`blue-color fs-16`} name="info" />
							</div>
						</TooltipElement>
					);
				},
			}),
		];

		return (
			<div className="w-100 h-100">
				<Table
					isLoading={false}
					data={listDetailByUnit}
					columns={columns}
					contained={false}
					tableMinHeight={'10px'}
					header={<></>}
					onRowHover={(data: TListDetailByUnit) => onHover(data)}
					onRowLeave={() => onHover(null)}
				/>
			</div>
		);
	}
);
