import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { TRequisites } from 'types/company';

type TProps = {
	requisite: TRequisites;
};

export const RequisiteContent = observer(
	({
		requisite: {
			bankAccount,
			bankName,
			correspondentAccount,
			fullName,
			generalDirector,
			inn,
			kpp,
			legalAddress,
			ogrn,
			okpo,
			postAddress,
			bik,
		},
	}: TProps) => {
		return (
			<Card className="p-4">
				<div className="table-company-requisites">
					<div className="mb-4 flex-table-table">
						<h3>
							<>{fullName || '----'}</>
						</h3>
					</div>
					<div className="mb-4 flex-table-table">
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>ИНН</div>
							</div>
							<div className="flex-table-cell">
								<>{inn || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>КПП</div>
							</div>
							<div className="flex-table-cell">
								<>{kpp || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>ОГРН</div>
							</div>
							<div className="flex-table-cell">
								<>{ogrn || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>ОКПО</div>
							</div>
							<div className="flex-table-cell">
								<>{okpo || '----'}</>
							</div>
						</div>
					</div>
					<div className="mb-4 flex-table-table">
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>Расчетный счёт</div>
							</div>
							<div className="flex-table-cell">
								<>{bankAccount || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>Банк</div>
							</div>
							<div className="flex-table-cell">
								<>{bankName || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>БИК</div>
							</div>
							<div className="flex-table-cell">
								<>{bik || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>Корр. счёт</div>
							</div>
							<div className="flex-table-cell">
								<>{correspondentAccount || '----'}</>
							</div>
						</div>
					</div>
					<div className=" flex-table-table no-border">
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>Юридический адрес</div>
							</div>
							<div className="flex-table-cell">
								<>{legalAddress || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>Почтовый адрес</div>
							</div>
							<div className="flex-table-cell">
								<>{postAddress || '----'}</>
							</div>
						</div>
						<div className="flex-table-row">
							<div className="flex-table-cell">
								<div>Генеральный директор</div>
							</div>
							<div className="flex-table-cell">
								<>{generalDirector || '----'}</>
							</div>
						</div>
					</div>
				</div>
			</Card>
		);
	}
);
