import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RegistrationForm } from 'components/authentication/RegistrationForm';

export const Registration = () => (
	<>
		<Row className="align-items-center mb-2">
			<Col>
				<h5 id="modalLabel">Регистрация</h5>
			</Col>
			<Col xs="auto">
				<p className="fs--1 text-600 mb-0">
					У вас уже есть аккаунт? <Link to="/authentication/login">Войти</Link>
				</p>
			</Col>
		</Row>
		<RegistrationForm />
	</>
);
