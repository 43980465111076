import { useState } from 'react';
import { observer } from 'mobx-react-lite';

export const ProductSingleImage = observer(
	({
		image,
		onClick,
		size = 'normal',
		isPreviewed = true,
		clickable = true,
		...rest
	}: {
		image: string;
		onClick: () => void;
		size?: 'small' | 'normal';
		isPreviewed?: boolean;
		clickable?: boolean;
		style?: unknown;
	}) => {
		const [showPreview, setShowPreview] = useState(false);

		const handleMouseEnter = () => isPreviewed && setShowPreview(true);
		const handleMouseLeave = () => isPreviewed && setShowPreview(false);

		return (
			<div
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onClick}
				className={`position-relative ${clickable ? 'cursor-pointer' : ''}`}
			>
				<div
					className={`image-preview-window ${size} ${
						showPreview ? 'active' : ''
					}`}
				>
					<img className="shadow" src={image} alt="" />
				</div>
				<div
					style={{ backgroundImage: `url(${image})` }}
					className={`product-single-image ${size}`}
					{...rest}
				/>
			</div>
		);
	}
);
