import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { TTyre } from 'types/tyre';
import { Name } from './comp/list-item/Name';
import { DeliveryNote } from './comp/list-item/DeliveryNote';
import { ButtonBuy } from './comp/list-item/ButtonBuy';
import { DeliveryTime } from './comp/list-item/DeliveryTime';
import { TableColumn } from 'helpers/TableColumn';
import { Table } from 'components/common/Table';
import { TooltipElement } from 'components/common/TooltipElement';
import imagePlaceholderIcon from 'assets/img/image-holder.png';
import { ImageModal } from 'components/product/ImageModal';

import { ProductSingleImage } from 'components/common/ProductSingleImage';
import {
	useTyreStore,
	useAuthStore,
	useProductStore,
	useSearchStore,
} from 'providers/RootStoreProvider';

type TRowData = { row: { original: TTyre } };

export const TyreTable = observer(() => {
	const { update, isLoading, tyres, total, setPage, page, perPage } =
		useTyreStore();
	const { getIsInCart, addToCart } = useProductStore();
	const { searchRegion } = useSearchStore();
	const { isAuthorized } = useAuthStore();
	const { uid } = useParams();
	const [showImage, setShowImage] = useState([]);

	useEffect(() => {
		update();
	}, []);

	const columns = [
		TableColumn({
			header: 'Наименование',
			width: 280,
			cellClassName: 'pe-3',
			minHeight: 76,
			cellStyle: {
				whiteSpace: 'break-spaces',
				fontSize: '12px',
			},
			cell: (rowData: TRowData) => {
				const { brand, code, name, imageUrl, used, storage } =
					rowData.row.original;

				const images =
					imageUrl &&
					imageUrl
						.split(',')
						.filter((i) => i)
						.map((i) => {
							if (!i.includes('https')) {
								return 'https://gettzap.ru' + i;
							}
							return i;
						});

				return (
					<div className="tyre-name-container d-flex justify-content-start align-items-center flex-row">
						<div className="tyre-image me-3">
							<ProductSingleImage
								onClick={() => setShowImage(images)}
								image={imageUrl ? images[0] : imagePlaceholderIcon}
								isPreviewed={!!imageUrl}
								clickable={!!imageUrl}
							/>
						</div>
						<Name
							{...{
								isAuthorized,
								uid,
								brand,
								code,
								name,
								used,
								isLoading,
								imageUrl,
								disabled: !!storage,
							}}
						/>
					</div>
				);
			},
		}),
		TableColumn({
			header: 'Способы доставки',
			width: 150,
			cellClassName: 'pe-3',
			headerClassName: 'pe-1',
			minHeight: 76,
			cellStyle: {
				whiteSpace: 'break-spaces',
				fontSize: '12px',
			},
			cell: (rowData: TRowData) => {
				const { priceList, storage, partner, office, deliveryMessage } =
					rowData.row.original;

				return (
					<DeliveryNote
						{...{
							office,
							hideDelivery: storage || partner,
							priceList,
							isLoading,
							deliveryMessage,
						}}
					/>
				);
			},
		}),
		TableColumn({
			header: 'Наличие',
			width: 125,
			cellClassName: 'pe-3',
			headerClassName: 'pe-1',
			minHeight: 76,
			cellStyle: {
				whiteSpace: 'break-spaces',
				fontSize: '12px',
			},
			cell: (rowData: TRowData) => {
				const { priceList, stock, cost, storage } = rowData.row.original;

				if (storage) return null;

				return <DeliveryTime {...{ cost, priceList, stock, isLoading }} />;
			},
		}),
		TableColumn({
			header: 'Стоимость',
			width: 160,
			minHeight: 76,
			cellStyle: {
				fontSize: '12px',
			},
			cell: (rowData: TRowData) => {
				const { cashback, b2bCostCash } = rowData.row.original;

				const customerCost = null;

				return (
					<div className="w-100 d-flex flex-row">
						<div className="cursor-pointer">
							<div className="d-flex flex-column align-items-start w-100 me-3">
								<div className="d-flex flex-row justify-content-start align-items-center w-100">
									{customerCost ? (
										<>
											<TooltipElement
												className="me-2"
												value={<>Цена с вашей наценкой</>}
											>
												<span className="fw-bold blue-color fs-16">
													{new Intl.NumberFormat('ru-RU').format(customerCost)}{' '}
													₽
												</span>
											</TooltipElement>
										</>
									) : (
										<>
											<span className="fw-bold fs-16">
												{new Intl.NumberFormat('ru-RU').format(b2bCostCash)} ₽
											</span>
										</>
									)}
								</div>
								{cashback > 0 && (
									<div className="fs-10 badge badge-soft-success rounded-pil">
										Cashback +
										{`${new Intl.NumberFormat('ru-RU').format(cashback)} ₽`}
									</div>
								)}
							</div>
						</div>
					</div>
				);
			},
		}),
		TableColumn({
			width: 80,
			minHeight: 76,
			cellStyle: {
				whiteSpace: 'break-spaces',
				fontSize: '12px',
				alignItems: 'flex-end',
			},
			cell: (rowData: TRowData) => {
				const {
					storage,
					multiplicity,
					stock,
					code,
					priceList,
					coreBrandId,
					brand,
					costWithMarkup,
					imageUrl,
				} = rowData.row.original;

				if (storage) return null;

				return (
					<ButtonBuy
						isAuthorized={isAuthorized}
						isLoading={isLoading}
						showNote={true}
						showQuantity={true}
						isInCart={() => getIsInCart(code, priceList, coreBrandId)}
						multiplicity={multiplicity}
						stock={stock}
						addToCart={(quantity, note) =>
							addToCart({
								...rowData.row.original,
								cash: true,
								b2b: true,
								selectedPrice: costWithMarkup,
								quantity,
								api_data: {
									uid,
									brand_vendor: brand,
									stock,
									multiplicity,
									...(imageUrl && { imageUrl }),
									...(note && { note }),
								},
							})
						}
					/>
				);
			},
		}),
	];

	return (
		<div className="product-table">
			<ImageModal
				images={showImage}
				show={showImage.length > 0}
				onHide={() => setShowImage([])}
			/>
			<Table
				pagination={true}
				isLoading={isLoading}
				data={tyres}
				itemsPerPage={perPage}
				setPage={setPage}
				page={page}
				columns={columns}
				tableMinHeight={'0px'}
				contained={false}
				productAnimation={true}
				totalItems={total}
				header={<></>}
				notFoundText={
					<>
						Ничего не найдено в регионе
						<span className="ms-1 text-primary">{searchRegion.name}</span>
					</>
				}
			/>
		</div>
	);
});
