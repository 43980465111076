import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { PaginationWrapper } from 'components/common/PaginationWrapper';
import { Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import { TooltipElement } from 'components/common/TooltipElement';
import confirmBadge from 'assets/img/icons/confirm-badge.svg';
import notConfirmBadge from 'assets/img/icons/not-confirm-badge.svg';
import { usePaymentsShopStore } from 'providers/RootStoreProvider';

export const PaymentsShopList = observer(() => {
	const { init, isLoading, list, itemsPerPage, setPage, page, total } =
		usePaymentsShopStore();

	useEffect(() => {
		init();
	}, []);

	const navigate = useNavigate();

	return (
		<>
			{isLoading ? (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
			) : (
				<>
					<Card className="mb-3">
						<Card.Body className="position-relative ">
							<h4 className="mb-0">Список выплат</h4>
						</Card.Body>
					</Card>
					<PaginationWrapper
						totalItems={total}
						itemsPerPage={itemsPerPage}
						page={page}
						setPage={setPage}
					>
						{list.map((item, i) => {
							return (
								<>
									<Card
										onClick={() => navigate(`/payments-shop/${item.id}`)}
										className="mb-3 cursor-pointer"
										key={`${i}_${item.id}_${item.inn}`}
									>
										<Card.Body>
											<div className="d-flex justify-content-between">
												<div className="d-flex flex-column">
													<div className="fs-16 fw-semi-bold">
														{item.fullName}
													</div>
													<div className="fs-13"> ИНН: {item.inn}</div>
													<div className="fs-13">
														Дата создания:{' '}
														{dayjs(item.updatedAt).format('DD/MM/YY HH:MM')}
													</div>
												</div>
												<TooltipElement
													value={
														item.paid ? <>Оплачено</> : <>Ожидает оплаты</>
													}
												>
													<img
														src={item.paid ? confirmBadge : notConfirmBadge}
														width="30px"
													/>
												</TooltipElement>
											</div>
										</Card.Body>
									</Card>
								</>
							);
						})}
					</PaginationWrapper>
				</>
			)}
		</>
	);
});
