import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { TooltipElement } from 'components/common/TooltipElement';
import {
	useAuthStore,
	useSavedProductsStore,
} from 'providers/RootStoreProvider';

export const SavedProductsNotification = observer(() => {
	const { totalItems } = useSavedProductsStore();
	const { isAuthorized } = useAuthStore();

	return (
		<TooltipElement
			disabled={isAuthorized}
			focus={true}
			placement="bottom"
			value={
				<>
					Нет доступа, необходимо{' '}
					<Link to={`/authentication/login`}>авторизоваться</Link>
				</>
			}
		>
			<div className="cart-icon-container">
				<Nav.Item as="li" className="block">
					<Nav.Link
						as={Link}
						to="/saved-products"
						className={classNames('px-0', !isAuthorized && 'opacity-50')}
					>
						<div className="cart-circle-count">{totalItems}</div>
						<FontAwesomeIcon
							icon="heart"
							transform="shrink-7"
							className="fs-4"
						/>
					</Nav.Link>
				</Nav.Item>
			</div>
		</TooltipElement>
	);
});
