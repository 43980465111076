import { castError, defaultHeaders, b2bApiUrl } from 'api/helpers';
import { TNotePayload, TNote } from 'types/note';
import request from 'helpers/request';
import { TListReponse } from 'types/common';
import { TDefaultPagination } from 'types/common';

export const deleteNote = async (noteId: number): Promise<unknown> => {
	return request(
		fetch(b2bApiUrl(`bayer/notes/${noteId}`), {
			method: 'DELETE',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const updatePrice = async (noteId: number): Promise<TNote> => {
	return request(
		fetch(b2bApiUrl(`bayer/notes/product/${noteId}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchNote = async (
	carId: number,
	query_params: TDefaultPagination
): Promise<TListReponse<TNote>> => {
	return request(
		fetch(b2bApiUrl(`bayer/notes/${carId}`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const postNote = async (payload: TNotePayload): Promise<TNote> => {
	return request(
		fetch(b2bApiUrl(`bayer/notes`), {
			method: 'POST',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const patchNote = async (
	id: number,
	payload: TNotePayload
): Promise<TNote> => {
	return request(
		fetch(b2bApiUrl(`bayer/notes/${id}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const fetchPdf = async (id) => {
	return request(
		fetch(b2bApiUrl(`bayer/cars/markdown/${id}`), {
			headers: {
				...defaultHeaders(),
			},
		})
	);
};
