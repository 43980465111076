import { AuthStore } from './AuthStore';
import { AppStore } from './AppStore';
import { SearchStore } from './search/SearchStore';
import { ProductStore } from './product/ProductStore';
import { CartStore } from './product/CartStore';
import { SavedProductsStore } from './product/SavedProductsStore';
import { CompanyStore } from './company/CompanyStore';
import { OrderStore } from './order/OrderStore';
import { ReviewStore } from './review/ReviewStore';
import { FilterStore } from './product/FilterStore';
import { ShopStore } from './shop/ShopStore';
import { NoteStore } from './note/NoteStore';
import { FavoriteShopStore } from './shop/FavoriteShopStore';
import { CarStore } from './car/CarStore';
import { RequisitesStore } from './requisites/RequisitesStore';
import { VendorStore } from './vendor/VendorStore';
import { BalanceStore } from './balance/BalanceStore';
import { BillingStore } from './billing/BillingStore';
import { CashbackStore } from './cashback/CashbackStore';
import { WarehouseStore } from './warehouse/WarehouseStore';
import { CompaniesStore } from './companies/CompaniesStore';
import { CatalogStore } from './catalog/CatalogStore';
import { DashboardStore } from './dashboard/DashboardStore';
import { CoreProductStore } from './product/CoreProductStore';
import { MultiSearchStore } from './product/MultiSearchStore';
import { GazStore } from './gaz/GazStore';
import { SellerProductStore } from './product/SellerProductStore';
import { PartnerProductStore } from './product/PartnerProductStore';
import { PaymentsShopStore } from './paymentsShop/paymentsShopStore';
import { AdminCompaniesStore } from './adminCompanies/AdminCompaniesStore';
import { TasksStore } from './tasks/TaskskStore';
import { OfficeStore } from './office/OfficeStore';
import { GettzapProductStore } from './product/GettzapProductStore';
import { ShipmentStore } from './order/ShipmentStore';
import { TyreStore } from './tyre/TyreStore';

export class RootStore {
	authStore: AuthStore;
	appStore: AppStore;
	searchStore: SearchStore;
	productStore: ProductStore;
	cartStore: CartStore;
	savedProductsStore: SavedProductsStore;
	companyStore: CompanyStore;
	orderStore: OrderStore;
	reviewStore: ReviewStore;
	filterStore: FilterStore;
	shopStore: ShopStore;
	noteStore: NoteStore;
	favoriteShopStore: FavoriteShopStore;
	carStore: CarStore;
	requisitesStore: RequisitesStore;
	vendorStore: VendorStore;
	balanceStore: BalanceStore;
	billingStore: BillingStore;
	cashbackStore: CashbackStore;
	warehouseStore: WarehouseStore;
	companiesStore: CompaniesStore;
	catalogStore: CatalogStore;
	dashboardStore: DashboardStore;
	coreProductStore: CoreProductStore;
	multiSearchStore: MultiSearchStore;
	gazStore: GazStore;
	sellerProductStore: SellerProductStore;
	partnerProductStore: PartnerProductStore;
	paymentsShopStore: PaymentsShopStore;
	adminCompaniesStore: AdminCompaniesStore;
	tasksStore: TasksStore;
	officeStore: OfficeStore;
	gettzapProductStore: GettzapProductStore;
	shipmentStore: ShipmentStore;
	tyreStore: TyreStore;

	constructor() {
		this.authStore = new AuthStore(this);
		this.appStore = new AppStore(this);
		this.productStore = new ProductStore(this);
		this.cartStore = new CartStore(this);
		this.savedProductsStore = new SavedProductsStore(this);
		this.companyStore = new CompanyStore(this);
		this.orderStore = new OrderStore(this);
		this.reviewStore = new ReviewStore(this);
		this.filterStore = new FilterStore(this);
		this.shopStore = new ShopStore(this);
		this.noteStore = new NoteStore(this);
		this.favoriteShopStore = new FavoriteShopStore(this);
		this.carStore = new CarStore(this);
		this.requisitesStore = new RequisitesStore(this);
		this.vendorStore = new VendorStore(this);
		this.balanceStore = new BalanceStore(this);
		this.billingStore = new BillingStore(this);
		this.cashbackStore = new CashbackStore(this);
		this.warehouseStore = new WarehouseStore(this);
		this.companiesStore = new CompaniesStore(this);
		this.catalogStore = new CatalogStore(this);
		this.dashboardStore = new DashboardStore(this);
		this.coreProductStore = new CoreProductStore(this);
		this.multiSearchStore = new MultiSearchStore(this);
		this.gazStore = new GazStore(this);
		this.sellerProductStore = new SellerProductStore(this);
		this.partnerProductStore = new PartnerProductStore(this);
		this.paymentsShopStore = new PaymentsShopStore(this);
		this.adminCompaniesStore = new AdminCompaniesStore(this);
		this.tasksStore = new TasksStore(this);
		this.officeStore = new OfficeStore(this);
		this.gettzapProductStore = new GettzapProductStore(this);
		this.shipmentStore = new ShipmentStore(this);
		this.searchStore = new SearchStore(this);
		this.tyreStore = new TyreStore(this);
	}
}
