import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import Main from './Main';
import { RootStoreProvider } from './providers/RootStoreProvider';
import 'helpers/initFA';

ReactDOM.render(
	<React.StrictMode>
		<Main>
			<RootStoreProvider>
				<App />
			</RootStoreProvider>
		</Main>
	</React.StrictMode>,
	document.getElementById('main')
);
