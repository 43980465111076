import React from 'react';
import { observer } from 'mobx-react';
import { ListGroup } from 'react-bootstrap';
import { ShopSchedule } from './ShopSchedule';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { TShopPassport } from 'types/shop';

type TProps = {
	shopPassport: TShopPassport;
};

export const ShopContact = observer(
	({
		shopPassport: {
			work_hours,
			metro_stations,
			phone1,
			address,
			email,
			shop,
			city,
		},
	}: TProps) => {
		return (
			<div className="shop-contact mt-4">
				<h5 className="mb-3">Контактная информация</h5>
				<ListGroup variant="flush" className="p-0">
					{work_hours && (
						<ListGroup.Item className="ps-0">
							<ShopSchedule work_hours={work_hours} />
						</ListGroup.Item>
					)}
					{metro_stations.length > 0 && (
						<ListGroup.Item className="ps-0">
							<span className="contact-icon pe-3">
								<span
									style={{
										backgroundColor: `#${
											metro_stations[0].color
												? metro_stations[0].color
												: '6d97d1'
										}`,
									}}
									className="metro-icon"
								>
									M
								</span>
							</span>
							<span className="contact-content fs-14">
								{metro_stations[0].name}
							</span>
						</ListGroup.Item>
					)}
					{phone1 && (
						<ListGroup.Item className="ps-0">
							<span className="contact-icon pe-3">
								<MaterialIcon className={`fs-1 text-secondary`} name="phone" />
							</span>
							<span className="contact-content fs-14">{phone1}</span>
						</ListGroup.Item>
					)}
					{address && city && (
						<ListGroup.Item className="ps-0">
							<span className="contact-icon pe-3">
								<MaterialIcon className={`fs-1 text-secondary`} name="place" />
							</span>
							<span className="contact-content fs-14">
								{city.name}, {address}
							</span>
						</ListGroup.Item>
					)}
					{email && (
						<ListGroup.Item className="ps-0">
							<span className="contact-icon pe-3">
								<MaterialIcon className={`fs-1 text-secondary`} name="email" />
							</span>
							<span className="contact-content fs-14">
								<a href={`mailto:${email}`}>{email}</a>
							</span>
						</ListGroup.Item>
					)}
					{shop.main_site && (
						<ListGroup.Item className="ps-0">
							<span className="contact-icon pe-3">
								<MaterialIcon className={`fs-1 text-secondary`} name="shop_2" />
							</span>
							<span className="contact-content fs-14">
								<a href={shop.main_site}>{shop.main_site}</a>
							</span>
						</ListGroup.Item>
					)}
				</ListGroup>
			</div>
		);
	}
);
