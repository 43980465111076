import { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, InputGroup } from 'react-bootstrap';
import { TSortSelect } from 'types/sort';

type TProps = {
	onChange: (value: TSortSelect) => void;
	sort?: { value: string; label: string; format?: string }[];
	disabled?: boolean;
	className?: string;
	style?: unknown;
	value?: string | number;
};

export const SortSelect = observer(
	({ sort, onChange, style, value, ...rest }: TProps) => {
		const [activeSort, setActiveSort] = useState(sort[0]);

		return (
			<InputGroup size="sm" style={style}>
				<Form.Select
					value={value ? value : activeSort.value}
					onChange={({ target }: { target: { value: string } }) => {
						setActiveSort(sort.find((item) => target.value === item.value));
						onChange(sort.find((item) => target.value === item.value));
					}}
					{...rest}
				>
					{sort.map(({ value, label }) => {
						return (
							<option key={`option_${value}`} value={value}>
								{label}
							</option>
						);
					})}
				</Form.Select>
			</InputGroup>
		);
	}
);
