export const reviewsWord = (count: number) => {
	if (count === 1) return 'отзыв';
	if (count > 1 && count < 5) return 'отзыва';
	if (count >= 5) return 'отзывов';
};

export const offersWord = (count: number) => {
	if (count === 0) return 'предложений';
	if (count === 1) return 'предложение';
	if (count > 1 && count < 5) return 'предложения';
	if (count >= 5) return 'предложений';
};

export const isLinkToProduct = (link: string) => {
	return link.split('/')[1] === 'products';
};
