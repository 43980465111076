import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Card } from 'react-bootstrap';
import { ListCategories } from './comp/ListCategories';
import { ListQuickGroup } from './comp/ListQuickGroup';
import { VehicleInfo } from './comp/VehicleInfo';
import { TabWrapper } from 'components/common/TabWrapper';
import { ListQuickDetail } from './comp/ListQuickDetail';
import { ListUnits } from './comp/ListUnits';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useCatalogStore } from 'providers/RootStoreProvider';
import useApi from 'hooks/useApi';

export const CatalogModel = observer(() => {
	const { TABS, getVehicleInfo } = useCatalogStore();
	const navigate = useNavigate();
	const { mode, brand, code, ssd, vehicle_id } = useParams();

	const {
		data: vehicleInfo,
		loading: vehicleInfoLoading,
		fetch: fetchVehicleInfo,
	} = useApi(getVehicleInfo);

	const handleChangeTab = useCallback((key) => {
		navigate(
			`/catalog-model/${key}/${brand}/${code}/${ssd}/${vehicle_id}/null`
		);
	}, []);

	useEffect(() => {
		fetchVehicleInfo({ catalog: code, ssd, vehicle_id });
	}, []);

	const isLoaded = useCallback(
		(isLoading, data) => !isLoading && data && !!data.length,
		[]
	);
	const isLoadedInfo = isLoaded(vehicleInfoLoading, vehicleInfo);

	return (
		<>
			<div className="d-flex flex-row w-100 justify-content-start align-items-start">
				<div className="catalog-model-list-container me-3 d-flex flex-column justify-content-start align-items-start">
					<Card className="p-3 w-100 mb-3">
						<div className="d-flex justify-content-start align-items-center flex-row">
							<IconButton
								color="primary"
								component="label"
								onClick={() => navigate(`/catalog/${brand}/${code}`)}
								className={`fs-10 me-2 d-flex justify-content-center align-items-center`}
							>
								<MaterialIcon name="arrow_back" className="" />
							</IconButton>
							<VehicleInfo
								vehicleInfo={vehicleInfo}
								isLoading={!isLoadedInfo}
							/>
						</div>
					</Card>
					<Card className="p-3 w-100 h-100">
						<TabWrapper
							activeKey={mode}
							handleChange={handleChangeTab}
							tabsList={TABS}
						>
							<ListQuickGroup />
							<ListCategories />
						</TabWrapper>
					</Card>
				</div>
				<div className="d-flex flex-column w-100 justify-content-start align-items-start">
					{mode === TABS[0].key && <ListQuickDetail />}
					{mode === TABS[1].key && <ListUnits />}
				</div>
			</div>
		</>
	);
});
