import { castError, defaultHeaders, b2bApiUrl } from 'api/helpers';
import request from 'helpers/request';

export const fetchBalanceInfo = () => {
	return request(
		fetch(b2bApiUrl('bayer/balance/info'), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
