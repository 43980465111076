import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { MaterialIcon } from 'components/common/MaterialIcon';
import { useAuthStore } from 'providers/RootStoreProvider';

type TProps = {
	hasLabel?: boolean | false;
};

export const LoginForm = observer(({ hasLabel }: TProps) => {
	const { signIn } = useAuthStore();
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		remember: false,
	});
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = useCallback(
		(e: React.SyntheticEvent) => {
			e.preventDefault();
			const { email, password } = formData;
			signIn({ email, password });
		},
		[formData]
	);

	const handleFieldChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		},
		[formData]
	);

	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group className="mb-3">
				{hasLabel && <Form.Label>Email</Form.Label>}
				<Form.Control
					placeholder={!hasLabel ? 'Эл. почта' : ''}
					value={formData.email}
					name="email"
					onChange={handleFieldChange}
					type="email"
				/>
			</Form.Group>
			<Form.Group className="mb-3 position-relative">
				{hasLabel && <Form.Label>Пароль</Form.Label>}
				<Form.Control
					placeholder={!hasLabel ? 'Пароль' : ''}
					value={formData.password}
					name="password"
					onChange={handleFieldChange}
					type={showPassword ? 'text' : 'password'}
				/>
				<MaterialIcon
					onClick={() => setShowPassword(!showPassword)}
					className={`eye-trigger ${showPassword ? 'active' : ''}`}
					name="remove_red_eye"
				/>
			</Form.Group>
			<Row className="justify-content-between align-items-center">
				<Col xs="auto">
					<Form.Check type="checkbox" id="rememberMe">
						<Form.Check.Input
							type="checkbox"
							name="remember"
							checked={formData.remember}
							onChange={({ target }: { target: { checked: boolean } }) =>
								setFormData({
									...formData,
									remember: target.checked,
								})
							}
						/>
						<Form.Check.Label className="mb-0">Запомнить меня</Form.Check.Label>
					</Form.Check>
				</Col>

				<Col xs="auto">
					<Link className="fs--1 mb-0" to={`/authentication/forgot-password`}>
						Не помню пароль
					</Link>
				</Col>
			</Row>
			<Form.Group>
				<Button
					type="submit"
					color="primary"
					className="mt-3 w-100"
					disabled={!formData.email || !formData.password}
				>
					Войти
				</Button>
			</Form.Group>
		</Form>
	);
});
