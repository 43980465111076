import classNames from 'classnames';
import { Navbar } from 'react-bootstrap';
import { navbarBreakPoint } from 'config';
import { observer } from 'mobx-react';
import { NavbarVerticalMenuContainer } from './comp/NavbarVerticalMenuContainer';

import 'styles/Sidebar.scss';

export const NavbarVertical = observer(() => {
	return (
		<Navbar
			expand={navbarBreakPoint}
			className={classNames('navbar-vertical sidebar navbar-card')}
			variant="light"
		>
			<NavbarVerticalMenuContainer />
		</Navbar>
	);
});
