import { useState, useRef, useCallback } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Placement } from 'react-bootstrap/types';

export const OverflowedTextTooltip = observer(
	({
		text,
		value,
		multiline,
		focus,
		placement,
		...props
	}: {
		text: string;
		value?: JSX.Element;
		multiline?: boolean;
		style?: Record<string, string>;
		focus?: boolean;
		placement?: Placement;
	}) => {
		const [showTooltip, setShowTooltip] = useState(false);
		const tooltip_target = useRef(null);

		const isTextClamped = useCallback(
			(elm: {
				clientWidth: number;
				scrollWidth: number;
				scrollHeight: number;
				clientHeight: number;
			}) => {
				if (multiline) return elm.scrollHeight > elm.clientHeight;
				return elm.scrollWidth > elm.clientWidth;
			},
			[multiline]
		);

		const onTooltipHover = useCallback(
			(value: boolean) => {
				if (isTextClamped(tooltip_target.current)) setShowTooltip(value);
			},
			[tooltip_target]
		);

		const action = {
			onMouseEnter: () => onTooltipHover(true),
			onMouseLeave: () => onTooltipHover(false),
		};

		const actions_container = focus ? action : null;
		const actions_text = focus ? null : action;

		return (
			<div
				className={`${multiline ? 'multiline' : 'single'}-overflow-tooltip`}
				ref={tooltip_target}
				{...props}
				{...actions_container}
			>
				<Overlay
					target={tooltip_target.current}
					show={showTooltip}
					placement={placement || 'top'}
				>
					{(props) => (
						<Tooltip className="fs-12" {...props}>
							{text}
						</Tooltip>
					)}
				</Overlay>
				<span {...actions_text}>{value ? value : text}</span>
			</div>
		);
	}
);
