import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TPaymentsShopItem } from 'types/paymentsShop';
import { Card, Button } from 'react-bootstrap';
import {
	fetchPaymentsShopItem,
	patchPaymentsShopItem,
} from 'api/paymentsShop/paymentsShop';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

export const PaymentsShopItem = observer(() => {
	const { id } = useParams();
	const [item, setItem] = useState<TPaymentsShopItem>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getPaymentsShopById();
	}, []);

	const navigate = useNavigate();

	const getPaymentsShopById = useCallback(async () => {
		const item = await fetchPaymentsShopItem(+id);
		setItem(item);
		setIsLoading(false);
	}, [id]);

	const updatePaymentsShopItem = useCallback(async () => {
		await patchPaymentsShopItem(+id);

		toast.success(`Успешно оплачено`, {
			theme: 'colored',
		});

		navigate('/payments-shop');
	}, [id]);

	return (
		<>
			{isLoading ? (
				<div className="p-5 d-flex justify-content-center align-items-center">
					<CircularProgress size={60} />
				</div>
			) : (
				<>
					<Card className="p-4">
						<div className="d-flex flex-column justify-content-start align-items-start ">
							<Card className="p-4">
								<div className="table-company-requisites">
									<div className="mb-4 flex-table-table">
										<h3>
											<>{item.doc.fullName || '----'}</>
										</h3>
									</div>
									<div className="mb-4 flex-table-table">
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>ИНН</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.inn || '----'}</>
											</div>
										</div>
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>КПП</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.kpp || '----'}</>
											</div>
										</div>
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>ОГРН</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.ogrn || '----'}</>
											</div>
										</div>
									</div>
									<div className="mb-4 flex-table-table">
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>Расчетный счёт</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.bankAccount || '----'}</>
											</div>
										</div>

										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>БИК</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.bik || '----'}</>
											</div>
										</div>
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>Корр. счёт</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.correspondentAccount || '----'}</>
											</div>
										</div>
									</div>
									<div className=" flex-table-table no-border">
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>Юр. адрес</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.legalAddress || '----'}</>
											</div>
										</div>
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>Ген. директор</div>
											</div>
											<div className="flex-table-cell">
												<>{item.doc.generalDirector || '----'}</>
											</div>
										</div>
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>Назначение платежа</div>
											</div>
											<div className="flex-table-cell">
												Оплата по АГЕНТСКОМУ договору - Оферте от 01.01.2023 г.,
												за реализацию <br /> по заказу №0000{item.doc.orderId}{' '}
												от {dayjs(item.doc.updatedAt).format('DD.MM.YY')} г.
												Сумма{' '}
												{new Intl.NumberFormat('ru-RU').format(item.amount)} НДС
												не облагается
											</div>
										</div>
										<div className="flex-table-row">
											<div className="flex-table-cell">
												<div>Сумма оплаты</div>
											</div>
											<div className="flex-table-cell">
												<div className="fw-bold">
													{`${new Intl.NumberFormat('ru-RU').format(
														item.amount
													)} ₽` || '----'}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card>

							<Button
								variant={'outline-primary'}
								className="d-flex mt-4 justify-content-center align-items-center"
								onClick={() => updatePaymentsShopItem()}
								disabled={item.doc.paid}
							>
								Оплачено
							</Button>
						</div>
					</Card>
				</>
			)}
		</>
	);
});
