import { useRef, useCallback } from 'react';
import { observer } from 'mobx-react';

type TProps = {
	children: any; // eslint-disable-line
};

export const ClickMoveOnTopWrapper = observer(({ children }: TProps) => {
	const wrapperRef = useRef(null);

	const moveTop = useCallback(() => {
		setTimeout(() => {
			if (wrapperRef.current) {
				const y =
					wrapperRef.current.getBoundingClientRect().top + window.scrollY;
				window.scroll({
					top: y - 70,
					behavior: 'smooth',
				});
			}
		}, 400);
	}, [wrapperRef]);

	return (
		<div ref={wrapperRef} className="click-move-on-top-wrapper">
			{children({
				moveTop,
			})}
		</div>
	);
});
