import { TDeliveryOption } from 'types/delivery.d';

export const DELIVERY_OPTIONS: TDeliveryOption[] = [
	{
		value: 'pickup',
		label: 'Самовывоз из магазина',
		description: '',
	},
	{
		value: 'delivery',
		label: 'Курьер продавца',
		description: 'Курьерская доставка по городу',
		price: '',
	},
	{
		value: 'transport_company',
		label: 'Отправка ТК продавцом',
		description: 'Отправка заказов в регионы ТК',
	},
];
