type TProps = {
	header?: string;
	width?: number;
	cellClassName?: string;
	cellStyle?: Record<string, string>;
	headerStyle?: Record<string, string>;
	headerClassName?: string;
	cell: unknown;
	noHeader?: boolean;
	minHeight?: number;
};

export const TableColumn = ({
	width,
	cell,
	header = '',
	cellClassName = '',
	headerClassName = '',
	cellStyle = {},
	headerStyle = {},
	noHeader = false,
}: TProps) => {
	return {
		accessor: `${Math.random()}`,
		Header: header,
		disableSortBy: true,
		width: width || 100,
		headerProps: {
			className: !noHeader ? headerClassName + ' py-2' : 'p-0',
			style: {
				minWidth: width ? width + 'px' : '100%',
				height: '100%',
				...headerStyle,
			},
		},
		cellProps: {
			className: 'py-2 ' + cellClassName,
			style: {
				minWidth: width ? width + 'px' : '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'flex-start',
				whiteSpace: 'break-spaces',
				justifyContent: 'center',
				flexDirection: 'column',
				...cellStyle,
			},
		},
		Cell: (rowData) =>
			// @ts-ignore
			cell(rowData),
	};
};
