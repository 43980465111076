import { useState } from 'react';
import { DeliveryAddressItem } from './DeliveryAddressItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { observer } from 'mobx-react-lite';
import { Button, Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useAuthStore } from 'providers/RootStoreProvider';

export const DeliveryAddressSettings = observer(() => {
	const { user } = useAuthStore();
	const [isSubmitting, setSubmitting] = useState(false);
	const { delivery_addresses, addDeliveryAddress } = user;

	const handleSubmit = async () => {
		setSubmitting(true);
		await user.patchUserProfile();
		setSubmitting(false);
	};

	return (
		<Card className="mt-3">
			<FalconCardHeader title="Адреса доставки" />
			<Card.Body className="bg-light">
				{delivery_addresses.map(
					(address, index) =>
						!address._destroy && (
							<DeliveryAddressItem
								key={address.id || `delivery_address_item_${index}`}
								index={index}
								deliveryAddress={address}
							/>
						)
				)}
				<Flex
					alignItems="center"
					className="mb-4 text-primary cursor-pointer fs-0"
					onClick={addDeliveryAddress}
				>
					<span className="circle-dashed">
						<FontAwesomeIcon icon="plus" />
					</span>
					<span className="ms-3">Добавить адрес доставки</span>
				</Flex>
				<div className="text-end">
					<Button onClick={handleSubmit} variant="primary" type="submit">
						{isSubmitting ? 'Обновление...' : 'Сохранить'}
					</Button>
				</div>
			</Card.Body>
		</Card>
	);
});
