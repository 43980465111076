import { observer } from 'mobx-react-lite';
import { Button, Card, Form, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { TCompany } from 'types/company';
import { AddressSuggections } from 'components/common/AddressSuggections';

import { Formik } from 'formik';
import 'yup-phone';
import * as Yup from 'yup';
import { useCompanyStore } from 'providers/RootStoreProvider';
import { useCallback } from 'react';

const coordinates_error_lat =
	'Широта должна быть представлена в диапазоне от -180 до 180.';
const coordinates_error_lng =
	'Долгота должна быть представлена в диапазоне от -90 до 90.';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	address: Yup.string().required(),
	lat: Yup.number()
		.min(-180, coordinates_error_lat)
		.max(180, coordinates_error_lat),
	lng: Yup.number()
		.min(-90, coordinates_error_lng)
		.max(90, coordinates_error_lng),
});

export const Company = observer(() => {
	const { company, addCompany, updateCompany, deleteCompany } =
		useCompanyStore();

	if (!company) return null;

	const isNew = !company.id;

	const getPayload = useCallback((values): TCompany => {
		return {
			name: values.name,
			address: values.address,
			location: {
				lat: Number(values.lat),
				lng: Number(values.lng),
			},
		};
	}, []);

	return (
		<Formik
			initialValues={{
				name: company ? company.name : '',
				address: company ? company.address : '',
				lat: company ? `${company.location.lat || ''}` : '',
				lng: company ? `${company.location.lng || ''}` : '',
			}}
			enableReinitialize
			validationSchema={ValidationSchema}
			onSubmit={async (values) => {
				isNew
					? addCompany(getPayload(values))
					: updateCompany(getPayload(values));
			}}
		>
			{({
				values,
				errors,
				handleChange,
				setFieldValue,
				handleSubmit,
				isValid,
			}) => (
				<Card className="mb-3">
					<FalconCardHeader
						title={isNew ? 'Моя компания' : 'Добавить компанию'}
					/>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						<Card.Body className="bg-light">
							<div className="ps-2">
								<div className="mb-3">
									<Row>
										<Col lg={6}>
											<Form.Group>
												<Form.Label>Название организации *</Form.Label>
												<Form.Control
													placeholder="Название организации"
													value={values.name}
													name="name"
													onChange={handleChange}
												/>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="mb-3">
									<Row>
										<Col lg={6}>
											<Form.Group controlId="address">
												<Form.Label>Адрес доставки *</Form.Label>
												<AddressSuggections
													initValue={values.address}
													setValue={({ name }) =>
														setFieldValue('address', name)
													}
												/>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<Form.Group>
									<div className="mb-2">
										<Form.Label>Координаты</Form.Label>
									</div>
									<Row>
										<div className="mb-3">
											<Col lg={3}>
												<Row>
													<Col lg={3}>
														<Form.Label>Широта</Form.Label>
													</Col>
													<Col lg={9}>
														<Form.Control
															isInvalid={!!errors.lat}
															placeholder="Широта"
															value={values.lat}
															name="lat"
															onChange={handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</div>
										<div className="mb-3">
											<Col lg={3}>
												<Row>
													<Col lg={3}>
														<Form.Label>Долгота</Form.Label>
													</Col>
													<Col lg={9}>
														<Form.Control
															isInvalid={!!errors.lng}
															placeholder="Долгота"
															value={values.lng}
															name="lng"
															onChange={handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</div>
										<Col lg={6}>
											<div className="mb-1">
												<Form.Text>{errors.lng}</Form.Text>
											</div>
											<div className="mb-1">
												<Form.Text>{errors.lat}</Form.Text>
											</div>
										</Col>
									</Row>
								</Form.Group>
							</div>
							<div className="border-dashed-bottom my-3" />
							<Button
								disabled={!isValid}
								variant="primary"
								type="submit"
								className="me-4"
							>
								{isNew ? 'Сохранить' : 'Обновить'}
							</Button>
							{!isNew && (
								<Button variant="outline-secondary" onClick={deleteCompany}>
									Удалить компанию
								</Button>
							)}
						</Card.Body>
					</Form>
				</Card>
			)}
		</Formik>
	);
});
