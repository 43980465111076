import { makeAutoObservable } from 'mobx';
import { TCart, TCartItem } from 'types/product';
import { updateCartItem, removeCartItem, fetchCart } from 'api/product/cart';
import { toast } from 'react-toastify';
import { checkoutCart } from 'api/product/cart';
import {
	TDeliveryProfiles,
	TDeliveryProfile,
	TDeliveryValidation,
	TDelivery,
} from 'types/delivery.d';
import {
	TCartCheckout,
	TDeliveries,
	TOrderParams,
	TCustomerContact,
	TOfficeOrders,
} from 'types/cart';
import { TDeliveryOptionType } from 'types/delivery.d';
import { User } from 'stores/user/User';
import {
	putItemCart,
	putManyItemCart,
	patchCartPayment,
	patchCartItemNote,
} from 'api/product/cart';
import { TProduct } from 'types/product';
import { RootStore } from 'stores/RootStore';

export class CartStore {
	total = 0;
	totalItems = 0;
	isLoading = false;
	cartItems: TCartItem[] = [];
	deliveryProfiles: TDeliveryProfiles = {};
	customerContact: TCustomerContact = {
		customer_email: '',
		customer_name: '',
		customer_phone: '',
		address: '',
	};
	requiredFields = {
		pickup: {
			comment: false,
			customer_email: true,
			customer_name: true,
			customer_phone: true,
		},
		courier: {
			comment: false,
			address: true,
			customer_email: true,
			customer_name: true,
			customer_phone: true,
		},
		transport_company: {
			comment: false,
			address: true,
			transport_company: true,
			customer_email: true,
			customer_name: true,
			customer_phone: true,
		},
	};
	deliveryProfileValidation: TDeliveryValidation | null = 'emptyDelivery';
	selected: string[] = [];

	officeOrders: TOfficeOrders = {};
	deliveryOrders: TOfficeOrders = {};
	delivery: Record<string, TDelivery> = {};
	cartId = null;

	root: RootStore;
	constructor(root) {
		this.root = root;
		makeAutoObservable(this);
	}

	editNote = async (id, note) => {
		const res = await patchCartItemNote(id, note);

		if (res === null) return false;

		toast.success(`Примечание обновлено`, {
			theme: 'colored',
		});

		this.update();
		return true;
	};

	changePaymentCart = async (cash: boolean) => {
		await patchCartPayment(this.cartId, cash);
		this.update();
	};

	setDeliveryType = (id: string, value: TDeliveryOptionType) => {
		this.delivery[id] = { ...this.delivery[id], type: value };
		this.validateDelivery();
	};
	setDeliveryComment = (id: string, value: string) => {
		this.delivery[id] = { ...this.delivery[id], comment: value };
		this.validateDelivery();
	};

	setSelected = (selected: string[]) => {
		this.selected = selected;
		this.updateCheckout();
	};

	reset = () => {
		this.total = 0;
		this.totalItems = 0;
		this.isLoading = false;
		this.cartItems = [];
		this.selected = [];
	};

	initCustomerContact = (user: User) => {
		if (!user) return;

		this.customerContact = {
			customer_email: user.email,
			customer_name: user.name,
			customer_phone: user.phone,
			address:
				user.delivery_addresses.length > 0
					? user.delivery_addresses[0].address
					: '',
		};
	};

	setCustomerContact = (customerContact: TCustomerContact) => {
		this.customerContact = customerContact;
	};

	validateDeliveryProfileFields = (profile: TDeliveryProfile) => {
		let isValid = true;
		const profileRequiredFields = this.requiredFields[profile.delivery_type];
		Object.keys(profile).forEach((key) => {
			if (profileRequiredFields[key] && !profile[key]) {
				isValid = false;
			}
		});
		return isValid;
	};

	validateDelivery = () => {
		let error = null;
		Object.keys(this.deliveryOrders).forEach((id) => {
			if (!this.delivery[id]) {
				error = 'emptyDelivery';
			}
		});
		if (!this.customerContact.address) {
			error = 'emptyField';
		}
		this.deliveryProfileValidation = error;
	};

	checkout = async () => {
		const deliveries: TDeliveries[] = [];
		const order_params: TOrderParams[] = [];

		const allOrders = Object.keys(this.officeOrders).reduce(
			(result, delivery_key) => {
				result.push(...this.officeOrders[delivery_key]);
				return result;
			},
			[] as TCartItem[]
		);

		const deliveriesOffices = Object.keys(this.deliveryOrders).reduce(
			(result, delivery_key) => {
				this.deliveryOrders[delivery_key].forEach((order) => {
					const delivery = this.delivery[delivery_key];
					result[order.office.id] = {
						delivery_address: this.customerContact.address,
						delivery_type:
							delivery.type === 'delivery' ? 'courier' : delivery.type,
						officeId: order.office.id,
						...this.customerContact,
					};
				});
				return result;
			},
			{}
		);

		Object.keys(deliveriesOffices).forEach((id) => {
			deliveries.push({
				office_id: parseInt(id),
				...deliveriesOffices[id],
			});
		});

		Object.keys(deliveriesOffices).forEach((id) => {
			order_params.push({
				office_id: parseInt(id),
				comment: deliveriesOffices[id].comment ?? '',
				items: allOrders
					.filter((i) => JSON.stringify(i.office.id) == id)
					.map((item) => {
						return {
							cash: item.cash,
							tax: item.tax,
							price_list_id: item.price_list_id,
							core_brand_id: item.core_brand_id,
							code: item.code,
							quantity: item.quantity,
							cashback: item.cashback,
							cost: item.cost,
							...(item.product_data && {
								product_data: {
									...item.product_data,
									image_url: item.product_data.image_url
										? item.product_data.image_url
										: '',
								},
							}),
						};
					}),
			});
		});

		const { customer_email, customer_name, customer_phone } =
			this.customerContact;

		const payload: TCartCheckout = {
			customer_email,
			customer_name,
			customer_phone,
			b2b: true,
			deliveries,
			order_params,
		};

		const res = await checkoutCart(payload);
		if (res === null) return;

		return res.map((i) => i.id);
	};

	removeItemMany = async (indexes: string[]) => {
		let res: TCart;
		for (let i = 0; i < indexes.length; i++) {
			res = await removeCartItem(this.cartItems[indexes[i]].id);
		}
		this.updateData(res);
		toast.success(`${indexes.length} Товара успешно удалены из корзины`, {
			theme: 'colored',
		});
	};

	removeItem = async (id: number) => {
		const res = await removeCartItem(id);
		if (res === null) return;

		this.updateData(res);

		toast.success(`Товар успешно удален из корзины`, {
			theme: 'colored',
		});
	};

	setQuantity = async (id: number, quantity: number): Promise<boolean> => {
		const res = await updateCartItem(id, { quantity });

		if (res === null) return false;

		this.updateData(res);
		return true;
	};

	addManyToCart = async (data: TProduct[]) => {
		const items = data.map((item) => {
			const { priceList, office, multiplicity, cash, uid } = item;
			return {
				price_list_id: priceList.id,
				office_id: office.id,
				uid,
				quantity: multiplicity ? multiplicity : 1,
				cash,
				multiplicity,
			};
		});

		const res = await putManyItemCart({ items });
		if (res === null) return;

		this.updateData(res);

		return res;
	};

	addToCart = async (data, isApi?: boolean) => {
		const {
			code,
			priceList,
			office,
			multiplicity,
			cash,
			uid,
			b2b,
			name,
			coreBrandId,
			imageUrl,
			used,
			b2bCostCash,
			cashback,
			serviceCash,
			cost,
			api_data,
			quantity,
		} = data;

		let res = null;

		if (isApi) {
			res = await putItemCart({
				price_list_id: priceList.id,
				office_id: office.id,
				quantity: quantity ? quantity : multiplicity ? multiplicity : 1,
				multiplicity,
				uid,
				code,
				cash: true,
				b2b: true,
				api: true,
				tax: serviceCash,
				name,
				base_cost: cost,
				cost: b2bCostCash,
				used,
				image_url: imageUrl,
				core_brand_id: coreBrandId,
				cashback: cashback,
				api_data,
			});
		} else {
			res = await putItemCart({
				price_list_id: priceList.id,
				office_id: office.id,
				multiplicity,
				quantity: quantity ? quantity : multiplicity ? multiplicity : 1,
				uid,
				cash,
				b2b: !!b2b,
				api_data,
			});
		}

		this.updateData(res);

		return res;
	};

	getDeliveryOrders = () => {
		const officeOrders = {};

		let cartItems = this.selected.length
			? (this.selected.map((i) => {
					return this.cartItems[i];
			  }) as TCartItem[])
			: this.cartItems;

		cartItems = cartItems.filter((i) => i);

		cartItems.length > 0 &&
			cartItems.forEach((i) => {
				if (!i.office) return null;

				if (i.price_list.partner) {
					officeOrders['partner'] = officeOrders['partner']
						? [...officeOrders['partner'], i]
						: [i];
				} else {
					const office_id = i.office.id;
					officeOrders[office_id] = officeOrders[office_id]
						? [...officeOrders[office_id], i]
						: [i];
				}
			});

		return officeOrders;
	};

	getOfficeOrders = () => {
		return Object.keys(this.deliveryOrders).reduce((result, delivery_key) => {
			this.deliveryOrders[delivery_key].forEach((order) => {
				if (result[order.office.id]) {
					result[order.office.id] = [...result[order.office.id], order];
				} else {
					result[order.office.id] = [order];
				}
			});
			return result;
		}, {});
	};

	updateCheckout = () => {
		this.deliveryOrders = this.getDeliveryOrders();
		this.officeOrders = this.getOfficeOrders();
	};

	updateData = (data: TCart) => {
		this.cartItems = data.items;
		this.totalItems = data.items.reduce((value, item) => {
			return value + item.quantity;
		}, 0);
		this.total = data.total;
		this.cartId = data.id;
		this.updateCheckout();
	};

	initCheckout = async (indexes: string[]) => {
		this.reset();
		this.selected = indexes;

		this.isLoading = true;
		this.update();
		this.isLoading = false;
	};

	update = async () => {
		const res = await fetchCart();
		if (res === null) return;
		this.updateData(res);
	};

	initCart = async () => {
		this.reset();
		this.isLoading = true;
		this.update();
		this.isLoading = false;
	};
}
