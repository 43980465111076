import React from 'react';
import { observer } from 'mobx-react';
import { MaterialIcon } from 'components/common/MaterialIcon';

type TProps = {
	handleClose?: () => void;
	title?: string;
	showClose?: boolean;
	children: React.ReactNode;
	onClick?: () => void;
	className?: string;
};

export const BadgeItem = observer(
	({
		handleClose,
		title,
		showClose = false,
		children,
		className = '',
		onClick,
		...props
	}: TProps) => {
		return (
			<span className={`filter-item-container ${className}`} {...props}>
				<div
					onClick={onClick}
					className="filter-toggle p-2 d-flex justify-content-start"
				>
					{title && <div className="fs-12 filter-toggle-title">{title}</div>}
					{children}
				</div>
				{showClose && (
					<MaterialIcon
						onClick={handleClose ? handleClose : null}
						className="filter-toggle-close-trigger cursor-pointer fs-16"
						name="close"
					/>
				)}
			</span>
		);
	}
);
