import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Virtual, Lazy } from 'swiper';
import { ImageModal } from 'components/product/ImageModal';
import { TShopPassport } from 'types/shop';
import { IMAGE_HOST } from 'consts/api';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/lazy';
import 'swiper/css/virtual';

type TProps = {
	shopPassport: TShopPassport;
};

export const ShopGallery = observer(({ shopPassport: { photos } }: TProps) => {
	const [activeImage, setActiveImage] = useState<null | number>(null);

	if (!photos || !photos.length) return null;

	return (
		<>
			<hr className="my-4" />

			{activeImage !== null && (
				<ImageModal
					activeImage={activeImage}
					images={photos
						.slice(0, 100)
						.map((photo) => IMAGE_HOST + photo.image.url)}
					show={activeImage !== null}
					onHide={() => setActiveImage(null)}
				/>
			)}
			<h5 className="mb-3">Фотогалерея</h5>
			<div className="photo-gallery">
				<Swiper
					breakpoints={{
						1280: {
							slidesPerView: 4,
						},
						1024: {
							slidesPerView: 4,
						},
						650: {
							slidesPerView: 3,
						},
					}}
					spaceBetween={25}
					slidesPerView={2}
					navigation={true}
					lazy={true}
					modules={[Navigation, Pagination, Virtual, Lazy]}
				>
					{photos.slice(0, 100).map((photo, index) => {
						return (
							<SwiperSlide
								virtualIndex={index}
								className="shop-gallery-item"
								key={`gallery_item_${photo.image.url}`}
								onClick={() => setActiveImage(index)}
								style={{
									background: `url(${IMAGE_HOST + photo.image.url})`,
								}}
							></SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</>
	);
});
