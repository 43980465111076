import { b2bApiUrl, castError, defaultHeaders } from 'api/helpers';
import request from 'helpers/request';
import { TDefaultPagination } from 'types/common';
import { TListReponse } from 'types/common';
import { TAdminCompanies, TAdminCompanyPayload, TAdminManagers } from 'types/adminCompanies';

export const fetchAdminCompanies = async (
	query_params: TDefaultPagination
): Promise<TListReponse<TAdminCompanies>> => {
	return request(
		fetch(b2bApiUrl(`admin/company`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchAdminCompany = async (
	companyId: number
): Promise<TAdminCompanies> => {
	return request(
		fetch(b2bApiUrl(`admin/company/${companyId}`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const patchAdminCompany = async (
	companyId: number,
	payload: TAdminCompanyPayload
): Promise<TAdminCompanies> => {
	return request(
		fetch(b2bApiUrl(`admin/company/${companyId}`), {
			method: 'PATCH',
			headers: {
				...defaultHeaders(),
			},
			body: JSON.stringify(payload),
		}).then(castError)
	);
};

export const fetcAdminManagers = async (
): Promise<{items: TAdminManagers[]}> => {
	return request(
		fetch(b2bApiUrl(`admin/managers`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};