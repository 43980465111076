import { castError, defaultHeaders, b2bApiUrl } from 'api/helpers';
import { TBalance, TBalanceHistory } from 'types/balance';
import request from 'helpers/request';

export const fetchBalanceInfo = async (): Promise<TBalance> => {
	return request(
		fetch(b2bApiUrl(`bayer/balance/info`), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};

export const fetchBalanceHistory = async (query_params: {
	from?: string;
	to?: string;
}): Promise<TBalanceHistory[]> => {
	return request(
		fetch(b2bApiUrl(`bayer/balance/history`, query_params), {
			headers: {
				...defaultHeaders(),
			},
		}).then(castError)
	);
};
