import React from 'react';
import { observer } from 'mobx-react';
import image_placeholder from 'assets/img/image-holder.png';
import Avatar from 'components/common/Avatar';

export const ImageProductItem = observer(({ preview }) => {
	return (
		<>
			{preview ? (
				<Avatar size="xl" src={preview} className="me-2" />
			) : (
				<Avatar size="xl" src={image_placeholder} className="me-2 " />
			)}
		</>
	);
});
